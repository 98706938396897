@use '@oecd/util-breakpoint' as *;

.container-themable {
    --theme-bg-color: var(--color-background);

    background-color: var(--theme-bg-color);
    display: grid;
    grid-template-columns: [full-start] var(--grid-offset) [wrapper-start] minmax(0, var(--container-max-width)) [wrapper-end] var(--grid-offset) [full-end];
    margin-inline: calc(var(--grid-offset) * -1);
    padding-block: var(--layout-spacing-default);

    > .cmp-container {
        grid-column: wrapper;
    }

    @include breakpoint-from(desktop) {
        margin-left: 50%;
        padding-inline: 0;
        transform: translate3d(-50%, 0, 0);
        width: 100vw;
    }

    &.theme-default {
        --theme-bg-color: var(--color-background);
    }

    &.theme-highlight {
        --theme-bg-color: var(--color-primary-light);
    }

    &.theme-subtle {
        --theme-bg-color: var(--color-background-verylight);
    }

    // Vertical flow within a themable layout container
    > :where(.cmp-container)> :where(*:not(.title, :last-child, .title-and-link)) {
        margin-block-end: var(--layout-spacing-default);
    }
}
