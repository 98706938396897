@use 'sass:map';
@use '@oecd/foundation/src/site/config/spacing-vars' as *;
@use '@oecd/util-breakpoint' as *;
@use '@oecd/util-distance' as d;
@use '@oecd/util-text-style' as *;

.featured-navigation-item-container {
    .cmp-container {
        > * {
            &:not(:last-child) {
                margin-block-end: map.get($spacing, s-8);
            }
        }

        @include breakpoint-from(desktop) {
            column-gap: var(--grid-gap);
            columns: 2;

            > * {
                break-inside: avoid;

                &:not(:last-child) {
                    margin-block-end: map.get($spacing, s-16);
                }
            }
        }
    }
}

.featured-navigation-item {
    --featured-navigation-item-image-background-color: var(--color-primary-light);
    --featured-navigation-item-image-border-color: var(--color-border);
    --featured-navigation-item-image-width: #{d.rem(103)};
    --featured-navigation-item-image-aspect-ratio: 5/4;

    @include breakpoint-from(desktop) {
        --featured-navigation-item-image-width: #{d.rem(185)};
        --featured-navigation-item-image-aspect-ratio: 7/4;
    }

    --featured-navigation-item-link-color: var(--color-text-primary);
    --featured-navigation-item-link-color-hover: var(--color-text-link);
    --featured-navigation-item-tagline-color: var(--color-text-secondary);

    column-gap: d.rem(16);
    display: flex;
    padding-inline-end: map.get($spacing, s-16);
    position: relative;

    @include breakpoint-from(desktop) {
        padding-inline-end: map.get($spacing, s-32);
    }

    &__content {
        display: flex;
        flex-direction: column;
        justify-content: center;
    }

    // overwrite Core component style
    .image {
        aspect-ratio: var(--featured-navigation-item-image-aspect-ratio);
        margin: 0;
    }

    &__visual {
        align-self: flex-start;
        background-color: var(--featured-navigation-item-image-background-color);
        border: 1px solid var(--featured-navigation-item-image-border-color);
        flex: 0 0 var(--featured-navigation-item-image-width);
        position: relative;

        img {
            height: 100%;
            inset-block: 0;
            inset-inline: 0;
            object-fit: cover;
            position: absolute;
            width: 100%;
        }
    }

    &__link {
        .cmp-button {
            @include text-style(body, 4, b);

            @include breakpoint-from(desktop) {
                @include text-style(body, 2, b);
            }

            color: var(--featured-navigation-item-link-color);
            display: inline-block;
            text-decoration: none;

            @media (prefers-reduced-motion: no-preference) {
                transition: color 0.15s linear;
            }

            &::before {
                content: '';
                inset-block: 0;
                inset-inline: 0;
                position: absolute;
                z-index: 1;
            }

            &:hover {
                color: var(--featured-navigation-item-link-color-hover);
            }

            &:focus {
                outline: 0;
            }

            &:focus-visible {
                color: var(--featured-navigation-item-link-color-hover);

                &::before {
                    outline: 2px solid var(--color-focus-outline);
                    outline-offset: 2px;
                }
            }
        }
    }

    &__tagline {
        // If tagline is not filled in this will ensure content is center aligned
        display: contents;

        .cmp-text {
            @include text-style(label, 3);

            color: var(--featured-navigation-item-tagline-color);
            margin-block-end: auto;
        }
    }
}
