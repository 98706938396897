@use 'sass:map';
@use '@oecd/util-breakpoint' as *;
@use '@oecd/foundation/src/site/config/spacing-vars' as *;

.column-container {
    // -------------------------------
    // Vertical flow
    // -------------------------------
    &__container {
        --prose-negative-spacing: calc(var(--layout-spacing-prose) - #{map.get($spacing, s-24)});

        // All components except title components have prose spacing 32px
        > :where(.cmp-container) > :where(*:not(.title)) {
            margin-block-end: var(--layout-spacing-prose);
        }

        // Button following on text component can only have a spacing of 24px
        // Button following on button component can only have a spacing of 24px
        // So we need to have a negative margin top that equals the prose spacing minus 24px
        > :where(.cmp-container) > :where(.text + .button, .button + .button) {
            margin-block-start: calc(var(--prose-negative-spacing) * -1);
        }
    }

    // --------------------
    // Two Column Container
    // --------------------
    &--two-col {
        &-6-6 {
            > .column-container {
                @include breakpoint-from(desktop-small) {
                    column-gap: var(--grid-gap);
                    display: grid;
                    grid-template-columns: repeat(12, 1fr);
                }

                > .column-container__container {
                    @include breakpoint-from(desktop-small) {
                        &:first-child {
                            grid-column: 1 / span 6;
                        }

                        &:last-child {
                            grid-column: 7 / span 6;
                        }
                    }
                }
            }
        }

        &-4-8,
        &-8-4 {
            > .column-container {
                @include breakpoint-from(tablet) {
                    column-gap: var(--grid-gap);
                    display: grid;
                    grid-template-columns: repeat(12, 1fr);
                }
            }
        }

        &-4-8 {
            > .column-container {
                > .column-container__container {
                    @include breakpoint-from(tablet) {
                        &:first-child {
                            grid-column: 1 / span 4;
                        }

                        &:last-child {
                            grid-column: 5 / span 8;
                        }
                    }
                }
            }
        }

        &-8-4 {
            > .column-container {
                > .column-container__container {
                    @include breakpoint-from(tablet) {
                        &:first-child {
                            grid-column: 1 / span 8;
                        }

                        &:last-child {
                            grid-column: 9 / span 4;
                        }
                    }
                }
            }
        }
    }

    // ----------------------
    // Three Column Container
    // ----------------------
    &--three-col {
        .column-container {
            @include breakpoint-from(tablet) {
                column-gap: var(--grid-gap);
                display: grid;
                grid-template-columns: repeat(12, 1fr);
            }

            &__container {
                @include breakpoint-from(tablet) {
                    grid-column: span 4;
                }
            }
        }
    }

    // ----------------------
    // Equal height Container
    // ----------------------
    &--equal-height {
        .cmp-two-column-container__container > .cmp-container {
            display: flex;
            flex-direction: column;
            height: 100%;
            line-height: 0;
        }
    }
}
