@use 'sass:map';
@use 'sass:math';
@use '@oecd/foundation/src/site/config/spacing-vars' as *;
@use '@oecd/util-breakpoint' as *;
@use '@oecd/util-text-style' as *;

@mixin default-table-styling() {
    table {
        border: none;
        border-collapse: collapse;
        border-spacing: 0;
        max-width: 100%;
        width: 100%;
    }

    caption {
        background: var(--table-caption-background);
        color: var(--table-caption-color);
        padding: map.get($spacing, s-8);
        text-align: left;

        @include breakpoint-from(desktop-small) {
            text-align: center;
        }

        @include text-style('label', 2, b, false, true);
    }

    th,
    td {
        border: 1px solid var(--table-border-color);
        color: var(--table-text-color);
        padding: map.get($spacing, s-8);
        text-align: left;
        vertical-align: top;
    }

    th {
        @include text-style('label', 2, b, false, true);

        background-color: var(--table-header-background-color);

        &[scope='col'] {
            text-align: left;

            @include breakpoint-from(desktop-small) {
                text-align: center;
            }
        }
    }

    td {
        @include text-style('label', 2, null, false, true);
    }
}
