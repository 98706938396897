@use 'sass:map';
@use '@oecd/foundation/src/site/config/spacing-vars' as *;
@use '@oecd/util-breakpoint' as *;

.cmp-title-link {
    --title-and-link-layout-spacing: #{map.get($spacing, s-24)};

    @include breakpoint-from(tablet) {
        --title-and-link-layout-spacing: #{map.get($spacing, s-32)};
    }

    margin-block-end: var(--title-and-link-layout-spacing);

    @include breakpoint-from(tablet) {
        column-gap: var(--grid-gap);
        display: grid;
        grid-template-columns: repeat(12, 1fr);
    }

    &__content {
        @include breakpoint-from(tablet) {
            grid-column: 1 / span 8;
        }
    }

    &__cta {
        @include breakpoint-from(tablet) {
            grid-column: 10 / span 3;
            line-height: 0;
            place-self: end;
        }
    }

    .cmp-title__text {
        margin-block-end: 0;
    }

    .cmp-text {
        margin-block-start: map.get($spacing, s-8);
        padding-inline-end: map.get($spacing, s-64);

        @include breakpoint-from(tablet) {
            grid-column: 1 / span 8;
        }
    }

    .cmp-button {
        @include breakpoint-until(tablet) {
            margin-block-start: map.get($spacing, s-8);
        }
    }
}
