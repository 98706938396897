@use 'sass:map';
@use '@oecd/foundation/src/site/config/spacing-vars' as *;
@use '@oecd/util-a11y' as *;
@use '@oecd/util-breakpoint' as *;
@use '@oecd/util-button' as *;
@use '@oecd/util-distance' as d;
@use '@oecd/util-text-style' as *;

:root:has(.cmp-archive-main-navigation) {
    --main-navigation-height: 0;
}

.cmp-archive-main-navigation {
    --archive-main-navigation-background: var(--color-background-inverse);
    --archive-main-navigation-color: var(--color-text-primary-inverse);
    --archive-main-navigation-logo-width: #{d.rem(134)};
    --archive-main-navigation-logo-width-mobile: #{d.rem(116)};
    --archive-main-navigation-padding-block: #{map.get($spacing, s-16)};
    --archive-main-navigation-padding-inline: #{map.get($spacing, s-16)};
    --color-focus-outline: var(--color-accent);

    background: var(--archive-main-navigation-background);
    color: var(--archive-main-navigation-color);
    display: grid;
    grid-template-columns: [full-start] var(--grid-offset) [container-start] minmax(0, var(--container-max-width)) [container-end] var(--grid-offset) [full-end];
    padding-block: var(--archive-main-navigation-padding-block);

    &__container {
        align-items: center;
        display: flex;
        grid-column: container;

        @include breakpoint-until(tablet) {
            flex-wrap: wrap;
            gap: map.get($spacing, s-20);
        }

        @include breakpoint-from(tablet) {
            gap: map.get($spacing, s-24);
            justify-content: space-between;
        }
    }

    &__logo {
        color: var(--archive-main-navigation-logo-color);

        @include breakpoint-until(tablet) {
            width: 100%;
        }

        @include breakpoint-from(tablet) {
            flex: 0 0 auto;
        }

        svg {
            height: auto;
            width: var(--archive-main-navigation-logo-width-mobile);

            // On desktop and larger, the logo is larger
            @include breakpoint-from(desktop-small) {
                width: var(--archive-main-navigation-logo-width);
            }
        }

        &-tagline {
            @include text-style(body, 2);

            display: block;
        }

        &-text {
            @include visually-hidden;
        }
    }

    &__notification {
        @include breakpoint-until(tablet) {
            width: 100%;
        }

        @include breakpoint-from(tablet) {
            flex: 0 1 auto;
        }

        .cmp-text {
            @include text-style(label, 2, b);
        }
    }

    &__cta {
        @include breakpoint-until(tablet) {
            width: 100%;
        }

        @include breakpoint-from(tablet) {
            flex: 0 0 auto;
        }

        .cmp-button {
            --button-background: var(--color-background);
            --button-background-hover: var(--color-primary-light);
            --button-border: var(--color-background);
            --button-txt: var(--color-text-primary);
            --button-border-hover: var(--color-primary-light);
            --button-txt-hover: var(--button-txt);
            --button-outline: var(--color-focus-outline);

            @include button-style;
            @include button-icon(--icon-globe, $icon-only: false, $show-copy: true);
        }
    }
}
