@media print {
    .cmp-testimonial {
        --testimonial-background-color: var(--color-print-background);
        --testimonial-text-color: var(--color-text-print-dark);
        --testimonial-job-title-color: var(--color-text-print-default);

        column-gap: var(--grid-gap);
        display: grid;
        grid-template-columns: repeat(12, 1fr);

        // Override margin set in mobile media query.
        margin-inline: 0;
        min-height: var(--testimonial-min-height);
        page-break-inside: avoid;

        &__content {
            display: flex;
            flex-direction: column;
            grid-column: 1 / span 6;

            .cmp-button {
                display: none;
            }
        }

        &__link {
            margin-top: auto;
        }

        &__visual {
            grid-column: 7 / span 6;
            height: 100%;
            position: relative;
            width: 100%;
        }

        .cmp-image {
            --testimonial-visual-aspect-ratio: initial;

            height: 100%;
            position: absolute;
            width: 100%;
        }
    }
}
