@use 'sass:map';
@use '@oecd/foundation/src/site/config/spacing-vars' as *;

@media print {
    .cmp-report-header {
        --report-header-background-color: var(--color-print-background);
        --report-header-text-color: var(--color-text-print-normal);
        --report-header-title-color: var(--color-text-print-dark);
        --report-header-title-highlight-color: var(--color-text-print-dark);

        &__breadcrumb {
            display: none;
        }

        &__overlay-trigger {
            display: none;
        }

        &__container {
            column-gap: var(--grid-gap);
            display: grid;
            grid-template-columns: repeat(12, 1fr);
            padding-block-start: 0;
        }

        &__content-area {
            grid-column: 1 / span 6;
            padding-block-start: 3rem;
            padding-inline-end: map.get($spacing, s-32);
        }

        &__visual {
            grid-column: 7 / span 6;
        }

        .tag {
            --tag-background: var(--color-print-background-dark);
        }
    }
}
