@use 'sass:map';
@use '@oecd/foundation/src/site/config/spacing-vars' as *;
@use '@oecd/util-a11y' as *;
@use '@oecd/util-breakpoint' as *;
@use '@oecd/util-button' as *;
@use '@oecd/util-distance' as d;
@use '@oecd/util-focus' as *;
@use '@oecd/util-loading' as *;
@use '@oecd/util-text-style' as *;

.cmp-search-results {
    --search-results-count-color: var(--color-text-primary);
    --search-results-margin-block-start: #{map.get($spacing, s-48)};
    --grid-view-icon: var(--icon-grid-view);
    --list-view-icon: var(--icon-list-view);
    --view-mode-icon: var(--list-view-icon);
    --path-indicator-remove-icon: var(--icon-close-small);
    --path-indicator-remove-color: var(--color-primary);
    --path-indicator-breadcrumb-icon: var(--icon-chevron-small);
    --path-indicator-path-color: var(--color-text-secondary);
    --path-indicator-prefix-color: var(--color-text-primary);
    --path-indicator-border-color: var(--color-border);
    --path-indicator-border-color-hover: var(--color-primary);


    margin-block-start: var(--search-results-margin-block-start);

    @include breakpoint-from(desktop-small) {
        --search-results-margin-block-start: #{map.get($spacing, s-80)};
    }

    @include breakpoint-from(desktop-small) {
        --search-results-margin-block-start: 0;
    }

    &__header {
        @include breakpoint-from(tablet) {
            display: flex;
            gap: map.get($spacing, s-24);
            width: 100%;
        }

        &-actions {
            align-items: flex-end;
            display: flex;
            gap: map.get($spacing, s-16);
            padding-block-start: map.get($spacing, s-16);

            @include breakpoint-from(tablet) {
                flex: 0 0 auto;
                margin-inline-start: auto;
                padding-block-start: 0;
            }
        }
    }

    &__result-count {
        @include text-style(heading, 3);

        @include breakpoint-from(tablet) {
            @include text-style(heading, 2);
        }

        color: var(--search-results-count-color);

        @include breakpoint-until(tablet) {
            margin-block-end: map.get($spacing, s-16);
        }

        @include breakpoint-from(tablet) {
            flex: 1 0 auto;
        }
    }

    &__header-result {
        @include breakpoint-from(tablet) {
            flex: 1 1 auto;
            min-width: 0;
        }
    }

    &__path-filter {
        margin-block-start: map.get($spacing, s-16);
    }

    &__path-indicator {
        border: 1px solid var(--path-indicator-border-color);
        border-radius: 4px;
        display: inline-flex;
        gap: map.get($spacing, s-8);
        max-width: 100%;
        padding-block: d.rem(8);
        padding-inline: d.rem(36) map.get($spacing, s-12);
        position: relative;
        transition-duration: 250ms;
        transition-property: background-color, border-color;
        transition-timing-function: cubic-bezier(0.4, 0, 0.4, 1);

        &:hover,
        &:focus-within {
            border: 1px solid var(--path-indicator-border-color-hover);
        }

        &:focus-within {
            @include keyboardfocus;
        }

        &__path {
            display: flex;
            flex-wrap: wrap;
            gap: d.rem(2);

            &-prefix {
                color: var(--path-indicator-prefix-color);
                display: inline-block;
                vertical-align: middle;

                @include text-style('label', 2);
            }

            &-part {
                color: var(--path-indicator-path-color);
                position: relative;
                vertical-align: middle;

                @include text-style('label', 2);

                &:not(:last-child) {
                    padding-inline-end: d.rem(18);

                    &::after {
                        background: var(--path-indicator-path-color);
                        content: '';
                        display: inline-block;
                        height: d.rem(16);
                        inset-block-start: calc(50% + 1px);
                        inset-inline-end: 0;
                        mask-image: var(--path-indicator-breadcrumb-icon);
                        mask-position: center;
                        mask-repeat: no-repeat;
                        mask-size: contain;
                        position: absolute;
                        transform: translateY(-50%);
                        vertical-align: middle;
                        width: d.rem(16);

                        @media screen and (forced-colors: active) {
                            background: CanvasText;
                        }
                    }
                }
            }
        }

        &__action {
            border-radius: 4px;
            height: 100%;
            inset: 0;
            position: absolute;
            width: 100%;

            &:focus {
                outline: none;
            }

            &::after {
                background: var(--path-indicator-remove-color);
                content: '';
                display: inline-block;
                flex-shrink: 0;
                height: d.rem(16);
                inset-block-start: d.rem(10);
                inset-inline-start: d.rem(12);
                mask-image: var(--path-indicator-remove-icon);
                mask-position: center;
                mask-repeat: no-repeat;
                mask-size: contain;
                position: absolute;
                vertical-align: middle;
                width: d.rem(16);
            }

            &-label {
                @include visually-hidden;
            }
        }
    }

    &__view-modes {
        display: flex;
        gap: map.get($spacing, s-8);
    }

    &__view-mode {
        .cmp-button {
            @include button-icon(--view-mode-icon, true);

            &[aria-pressed='true'] {
                --button-txt: var(--color-icon-inverse);
                --button-background: var(--color-primary);
            }

            &[aria-pressed='false'] {
                --button-txt: var(--color-text-secondary);
                --button-txt-hover: var(--color-primary);
            }
        }

        &--list {
            --view-mode-icon: var(--list-view-icon);
        }

        &--grid {
            --view-mode-icon: var(--grid-view-icon);
        }
    }

    &__sort-controls {
        display: flex;
        gap: map.get($spacing, s-16);
    }

    &__sort-control {
        &-label {
            display: block;
            margin-block-end: map.get($spacing, s-8);
        }

        &-select {
            display: inline-block;
            width: auto;
        }
    }

    &__body {
        margin-top: map.get($spacing, s-32);
    }

    &__loading {
        @include loading-spinner;

        min-height: d.rem(150);
        width: 100%;

        @include breakpoint-from(desktop-small) {
            min-height: d.rem(250);
        }
    }
}
