@use 'sass:map';
@use '@oecd/util-a11y' as *;
@use '@oecd/util-breakpoint' as *;
@use '@oecd/util-distance' as d;
@use '@oecd/util-focus' as *;
@use '@oecd/util-scrollbar' as *;
@use '@oecd/util-text-style' as *;
@use '@oecd/foundation/src/site/config/spacing-vars' as *;

.cmp-facet-group {
    --facet-group-facet-background-color: var(--color-background-verylight);
    --facet-group-facet-closure-border-color: var(--color-border-transparent);
    --facet-group-facet-icon-size: #{d.rem(16)};
    --facet-group-facet-item-divider-color: #dee5ed;
    --facet-group-facet-input-color: var(--color-text-primary);
    --facet-group-facet-input-count-color: var(--color-text-primary-transparent);
    --facet-group-facet-header-color: var(--color-text-primary);
    --facet-group-facet-scroll-area-size: #{d.rem(240)};
    --facet-group-facet-trigger-icon-background: var(--color-background);
    --facet-group-facet-trigger-icon-color: var(--color-icon);
    --facet-group-facet-trigger-icon-size: #{d.rem(28)};
    --facet-group-facet-trigger-text: var(--color-text-primary);
    --facet-group-facet-trigger-text-hover: var(--color-text-accent);
    --facet-group-facet-scroll-focus-compensation: #{d.rem(4)};

    border: var(--facet-group-facet-closure-border-color);
    width: 100%;

    .cmp-facet-rail-mobile-dialog & {
        --facet-group-facet-trigger-icon-background: var(--color-background-light);
    }

    + .cmp-facet-group {
        .cmp-facet-group__header-button {
            border-top: 1px solid var(--facet-group-facet-item-divider-color);
        }
    }

    &__header {
        @include text-style('body', 2, b);

        color: var(--facet-group-facet-header-color);
        width: 100%;

        &-button {
            color: var(--facet-group-facet-trigger-text);
            display: block;
            padding-block: map.get($spacing, s-32);
            position: relative;
            text-align: initial;
            width: 100%;

            @include breakpoint-from(desktop-small) {
                padding-block: map.get($spacing, s-24);
            }

            @include breakpoint-from(desktop) {
                padding-block: map.get($spacing, s-32);
            }

            @media (prefers-reduced-motion: no-preference) {
                transition-duration: 250ms;
                transition-property: color;
                transition-timing-function: cubic-bezier(0.4, 0, 0.4, 1);
            }

            &:hover,
            &:focus {
                cursor: pointer;
            }

            &:hover {
                .cmp-facet-group__header-title {
                    color: var(--facet-group-facet-trigger-text-hover);
                }
            }

            &:focus-visible {
                outline: none;

                .js-user-is-tabbing & {
                    .cmp-facet-group__header-title {
                        @include keyboardfocus;
                    }
                }
            }

            &[aria-expanded='true'] .cmp-facet-group__header-icon::before {
                mask-image: var(--icon-minus);
            }
        }

        &-title {
            @include text-style('body', 4, b);

            @include breakpoint-from(desktop-small) {
                @include text-style('body', 2, b, null, true);
            }

            display: block;
            padding-inline-end: map.get($spacing, s-32);
            position: relative;
        }

        &-icon {
            background: var(--facet-group-facet-trigger-icon-background);
            border: 1px solid var(--facet-group-facet-trigger-icon-background);
            border-radius: 50%;
            display: block;
            height: var(--facet-group-facet-trigger-icon-size);
            inset-block-start: 50%;
            inset-inline-end: 0;
            position: absolute;
            transform: translateY(-50%);
            width: var(--facet-group-facet-trigger-icon-size);

            &::before {
                background-color: var(--facet-group-facet-trigger-icon-color);
                content: '';
                display: block;
                height: var(--facet-group-facet-icon-size);
                inset-block-start: 50%;
                inset-inline-start: 50%;
                mask-image: var(--icon-plus);
                mask-position: center;
                mask-repeat: no-repeat;
                mask-size: contain;
                position: absolute;
                transform: translate(-50%, -50%);
                width: var(--facet-group-facet-icon-size);

                @media screen and (forced-colors: active) {
                    background: ButtonText;
                }
            }
        }
    }

    &__content {
        display: none;
        padding-block: 0 map.get($spacing, s-32);

        &--expanded {
            display: block;
        }
    }

    &__scroll-area {
        margin: calc(var(--facet-group-facet-scroll-focus-compensation) * -1);
        max-height: var(--facet-group-facet-scroll-area-size);
        overflow-y: scroll;
        padding: var(--facet-group-facet-scroll-focus-compensation);

        @include custom-scrollbars;
    }

    &__fieldset {
        legend {
            @include text-style('label', 2, b);

            color: var(--facet-group-facet-header-color);
            display: block;
            margin-block-end: map.get($spacing, s-8);
        }
    }

    &__input {
        &-description {
            color: var(--facet-group-facet-input-color);
        }

        &-count {
            color: var(--facet-group-facet-input-count-color);
            margin-inline-start: 0.5ch;
        }
    }
}
