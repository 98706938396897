@use 'sass:map';
@use '@oecd/foundation/src/site/config/spacing-vars' as *;
@use '@oecd/util-a11y' as *;
@use '@oecd/util-distance' as d;
@use '@oecd/util-focus' as *;
@use '@oecd/util-text-style' as *;

.asset-item {
    --asset-link-color: var(--color-text-primary);
    --asset-link-color-hover: var(--color-text-link-hover);
    --asset-link-border-bottom-color: var(--color-border);
    --asset-link-meta-separator-symbol: '\2022';
    --assset-link-meta-color: var(--color-text-secondary);
    --asset-link-icon-background-color: var(--color-background-light);
    --asset-link-icon-dimension: #{d.rem(28)};
    --asset-link-icon: var(--icon-external);
    --asset-link-icon-size: #{d.rem(14)};
    --asset-link-icon-border-radius: 50%;
    --asset-link-icon-color: var(--color-icon);
    --asset-link-padding-inline-end: #{d.rem(44)};

    border-bottom: 1px solid var(--asset-link-border-bottom-color);
    color: var(--assset-link-meta-color);
    padding-block: map.get($spacing, s-12);
    padding-inline-end: var(--asset-link-padding-inline-end);
    position: relative;

    @media (prefers-reduced-motion: no-preference) {
        transition: 0.15s linear border-color;
    }

    &__link {
        @include text-style(label, 2, m);

        color: var(--asset-link-color);
        text-decoration: none;

        @media (prefers-reduced-motion: no-preference) {
            transition: 0.15s linear color;
        }

        &::before {
            content: '';
            inset: 0;
            position: absolute;
            z-index: 1;
        }

        &:hover {
            color: var(--asset-link-color-hover);
        }

        &:focus-visible {
            color: var(--asset-link-color-hover);
            outline: none;

            &::before {
                @include keyboardfocus;
            }
        }

        &[download] ~ .asset-item__icon {
            --asset-link-icon: var(--icon-download);
        }
    }

    &__metadata {
        font-size: 0;
        margin-block-start: map.get($spacing, s-8);

        li {
            display: inline-block;

            @include text-style(label, 2);

            &:not(:last-child) {
                &::after {
                    content: var(--asset-link-meta-separator-symbol);
                    margin-inline: map.get($spacing, s-4);

                    @supports (content: 'x' / 'y') {
                        content: var(--asset-link-meta-separator-symbol) / '';
                    }
                }
            }
        }
    }

    &__icon {
        background-color: var(--asset-link-icon-background-color);
        border-radius: var(--asset-link-icon-border-radius);
        height: var(--asset-link-icon-dimension);
        inset-block-end: map.get($spacing, s-12);
        inset-inline-end: 0;
        position: absolute;
        width: var(--asset-link-icon-dimension);

        &::after {
            background: var(--asset-link-icon-color);
            content: '';
            height: var(--asset-link-icon-size);
            inset-block-start: d.rem(7);
            inset-inline-start: d.rem(7);
            mask-image: var(--asset-link-icon);
            mask-position: center;
            mask-repeat: no-repeat;
            mask-size: contain;
            position: absolute;
            width: var(--asset-link-icon-size);

            @media screen and (forced-colors: active) {
                background: canvasText;
            }
        }
    }
}
