@use 'sass:map';
@use '@oecd/foundation/src/site/config/spacing-vars' as *;
@use '@oecd/util-a11y' as *;
@use '@oecd/util-breakpoint' as *;
@use '@oecd/util-distance' as d;
@use '@oecd/util-focus' as *;
@use '@oecd/util-text-style' as *;

.cmp-country-dropdown {
    --country-dropdown-title-color: var(--color-text-secondary);
    --country-dropdown-tab-color: var(--color-text-secondary);
    --country-dropdown-tab-color-active: var(--color-text-link);
    --country-dropdown-active-tab-icon: var(--icon-chevron-small);
    --country-dropdown-active-tab-icon-size: #{d.rem(16)};
    --country-dropdown-link-color: var(--color-text-primary);
    --country-dropdown-link-color-hover: var(--color-text-link);

    grid-column: 1/-1;

    .cmp-tabs-list {
        --tabs-list-link-border-bottom-color: transparent;
        --tabs-list-link-date-separator-symbol: '\2022';
        --tabs-list-link-text-color: var(--color-text-primary);
        --tabs-list-link-text-color-hover: var(--color-text-link-hover);
        --tabs-list-column-width: #{d.rem(288)};
        --tabs-list-search-icon-color: var(--color-text-secondary-transparent);

        .cmp-tabs-list__search-form {
            margin-inline-start: 0;
        }

        .cmp-tabs-list__search-input-container input[type='search'] {
            min-width: unset;
            width: 100%;

        }

        &__item-content {
            padding-block: d.rem(6);
        }
    }

    &__accordion-area {
        @include breakpoint-from(desktop) {
            display: none;
        }

        .cmp-country-dropdown__title,
        .cmp-tabs-list__search-message {
            @include visually-hidden;
        }


        &__cta {
            margin-block-start: map.get($spacing, s-24);
        }

        .cmp-tabs-list {
            &__tablist {
                margin-block-end: map.get($spacing, s-32);
                margin-inline: -#{map.get($spacing, s-24)};
                padding-inline: map.get($spacing, s-24);
            }

            &__search-form {
                margin-block: map.get($spacing, s-16);
            }
        }
    }

    &__tab-area {
        display: none;

        @include breakpoint-from(desktop) {
            display: block;
        }

        .cmp-tabs-list {
            &__search-form {
                align-self: start;
                grid-column: 1 / span 3;
                grid-row: 1;
                margin-block-end: map.get($spacing, s-16);
            }

            .cmp-tabs {
                &__tablist {
                    display: block;

                    // Compensate padding on first tab
                    margin-block-start: -#{map.get($spacing, s-8)};
                }

                &__tab {
                    --tabs-list-tab-background-color: var(--color-background);
                    --tabs-list-tab-border-color: var(--color-border);
                    --tabs-list-tab-border-color-hover: var(--color-primary);

                    border: 0;
                    padding-block: map.get($spacing, s-4);
                    padding-inline: 0;
                    position: relative;

                    @media (prefers-reduced-motion: no-preference) {
                        transition: color 0.15s linear;
                    }

                    &:hover,
                    &:focus-visible {
                        color: var(--country-dropdown-tab-color-active);
                    }

                    &:hover {
                        --tabs-list-tab-text-color: var(--tabs-list-tab-text-hover-color);
                        --tabs-list-tab-border-color: var(--tabs-list-tab-border-color-hover);
                    }

                    &--active {
                        --tabs-list-tab-background-color: var(--color-background);
                        --tabs-list-tab-border-color: transparent;
                        --tabs-list-tab-text-color: var(--country-dropdown-tab-color-active);

                        &:hover {
                            --tabs-list-tab-text-color: var(--color-text-primary-inverse);
                        }

                        &::after {
                            background-color: currentcolor;
                            content: '';
                            display: inline-block;
                            height: var(--country-dropdown-active-tab-icon-size);
                            margin-inline-start: map.get($spacing, s-8);
                            mask-image: var(--country-dropdown-active-tab-icon);
                            mask-position: center;
                            mask-repeat: no-repeat;
                            mask-size: contain;
                            vertical-align: middle;
                            width: var(--country-dropdown-active-tab-icon-size);

                            @media screen and (forced-colors: active) {
                                background: ButtonText;
                            }
                        }
                    }
                }
            }
        }
    }

    &__tabs {
        column-gap: var(--grid-gap);
        display: grid;
        grid-template-columns: repeat(12, 1fr);
        grid-template-rows: auto auto 1fr;
        place-content: start start;

        &.cmp-tabs-list--search-enabled {
            .js-tabs-list-search-source-container,
            .cmp-country-dropdown__tabs-list,
            .cmp-country-dropdown__tabs-cta {
                display: none;
            }

            .cmp-tabs-list__search-message {
                margin-block-end: map.get($spacing, s-32);
            }
        }

        &-panels {
            grid-column: 4/-1;
            grid-row: 1 / -1;
        }

        &-list {
            align-self: start;
            grid-column: 1 / span 3;
            grid-row: 2;
        }

        &-cta {
            align-self: start;
            grid-column: 1 / span 3;
            grid-row: 3;

            .cmp-button {
                margin-block-start: map.get($spacing, s-24);
            }
        }
    }

    &__title {
        @include text-style(label, 2);

        color: var(--country-dropdown-title-color);
        margin-block-end: map.get($spacing, s-20);
    }

    &__cta {
        .cmp-button {
            margin-block-start: map.get($spacing, s-24);

            @include breakpoint-until(desktop) {
                margin-inline-start: map.get($spacing, s-16);
            }
        }
    }
}
