@use 'sass:map';
@use 'sass:math';
@use '@oecd/foundation/src/site/config/spacing-vars' as *;
@use '@oecd/util-breakpoint' as *;
@use '@oecd/util-distance' as d;
@use '@oecd/util-focus' as *;
@use '@oecd/util-text-style' as *;

.top-story + .top-story {
    margin-block-start: map.get($spacing, s-16);
}

.cmp-top-story {
    --top-story-image-background-color: var(--color-background-light);
    --top-story-tagline-color: var(--color-text-secondary);
    --top-story-link-color: var(--color-text-primary);
    --top-story-link-color-hover: var(--color-primary);

    display: flex;
    position: relative;

    &__visual {
        aspect-ratio: 3/2;
        flex: 0 0 d.rem(160);
        margin-inline-end: map.get($spacing, s-16);
        position: relative;

        .cmp-image {
            aspect-ratio: 3/2;
            background: var(--top-story-image-background-color);
            height: 100%;
            inset-block-start: 0;
            inset-inline-start: 0;
            line-height: 0;
            position: absolute;
            width: 100%;

            &__image {
                height: 100%;
                object-fit: cover;
                object-position: center center;
                position: absolute;
                width: 100%;
            }
        }
    }

    &__body {
        flex: 1 1 auto;
    }

    &__tagline {
        .cmp-text {
            @include text-style(label, 3, b);

            color: var(--top-story-tagline-color);
        }
    }

    &__link {
        @include text-style(label, 2, b);

        .cmp-button {
            color: var(--top-story-link-color);
            text-decoration: none;

            @media (prefers-reduced-motion: no-preference) {
                transition-duration: 250ms;
                transition-property: color;
                transition-timing-function: cubic-bezier(0.4, 0, 0.4, 1);
            }

            &:hover {
                color: var(--top-story-link-color-hover);
            }

            &:focus {
                outline: none;
            }

            &:focus-visible {
                color: var(--top-story-link-color-hover);

                &::before {
                    @include keyboardfocus;
                }
            }

            &::before {
                content: '';
                inset: 0;
                position: absolute;
            }
        }
    }
}
