@use 'sass:map';
@use '@oecd/foundation/src/site/config/spacing-vars' as *;
@use '@oecd/util-a11y' as *;
@use '@oecd/util-distance' as d;
@use '@oecd/util-focus' as *;
@use '@oecd/util-text-style' as *;

.cmp-form {
    > * + * {
        margin-block-start: map.get($spacing, s-24);
    }

    // -----------------------
    // Text input styling
    // -----------------------
    &-text {
        // Used flex to place the help text beneath the input
        // Since there is no interactive content in the help message this won't cause an a11y issue is
        display: flex;
        flex-direction: column;

        &:has(input[required]) {
            label {
                &::after {
                    content: ' *';
                }
            }
        }

        label {
            display: block;
            margin-block-end: #{map.get($spacing, s-8)};
            order: 1;
        }

        &__text,
        &__textarea {
            order: 2;
            width: 100%;
        }

        &__help-block {
            @include text-style(label, 3);

            margin-block-start: map.get($spacing, s-12);
            order: 3;
        }
    }

    &-options {
        &__legend {
            @include text-style('label', 2, m);

            display: block;
            margin-block-end: map.get($spacing, s-8);
        }

        &__help-message {
            @include text-style(label, 3);

            margin-block-start: #{map.get($spacing, s-12)};
            order: 3;
        }

        // -----------------------
        // Dropdowns
        // -----------------------
        &--drop-down,
        &--multi-drop-down {
            .cmp-form-options__label {
                display: block;
                margin-block-end: map.get($spacing, s-8);
            }
        }

        // -----------------------
        // Custom Radio styling
        // -----------------------
        &--radio {
            .cmp-form-options__field-label {
                display: block;
                min-height: d.rem(24);
                padding-inline-start: d.rem(36);
                position: relative;

                + .cmp-form-options__field-label {
                    margin-block-start: map.get($spacing, s-16);
                }

                input {
                    &[disabled] + span {
                        cursor: not-allowed;

                        &:hover {
                            cursor: not-allowed;

                            &::after {
                                border-color: var(--color-border-strong);
                            }
                        }

                        &::before {
                            background-color: var(--color-icon-disabled);
                        }

                        &::after {
                            background-color: var(--color-background-verylight);
                        }
                    }

                    &[disabled]:checked + span {
                        &::after {
                            border: solid 1px var(--color-border-strong);
                        }

                        &::before {
                            background-color: var(--color-icon-disabled);
                            z-index: 1;
                        }
                    }

                    &:checked + span {
                        &::before {
                            background-color: var(--color-form-radio);
                            transform: translateY(-50%) scale(1);
                        }

                        &::after {
                            border: 1px solid var(--color-form-radio);
                        }
                    }

                    &:focus-within + span {
                        .js-user-is-tabbing & {
                            &::after {
                                @include keyboardfocus;

                                border-color: var(--color-form-radio);
                            }
                        }
                    }
                }
            }

            .cmp-form-options__field {
                @include visually-hidden;

                + span {
                    display: block;

                    // Added 1px to align the label with the input
                    padding-block-start: d.rem(1);

                    &:hover {
                        cursor: pointer;

                        &::after {
                            border-color: var(--color-form-radio);
                        }
                    }

                    &::before {
                        background-color: transparent;
                        border-radius: 100%;
                        content: '';
                        height: d.rem(12);
                        inset-block-start: d.rem(12);
                        inset-inline-start: d.rem(6);
                        position: absolute;
                        transform: translateY(-50%) scale(0.5);
                        transform-origin: center;
                        transition: transform 0.3s ease-in-out;
                        width: d.rem(12);
                    }

                    &::after {
                        border: solid 1px var(--color-border-strong);
                        border-radius: 100%;
                        content: '';
                        height: d.rem(24);
                        inset-block-start: 0;
                        inset-inline-start: 0;
                        position: absolute;
                        width: d.rem(24);
                    }
                }
            }
        }

        // -----------------------
        // Custom Checkbox styling
        // -----------------------
        &--checkbox {
            .cmp-form-options__field {
                @include visually-hidden;

                + span {
                    display: block;
                    padding-block-start: d.rem(1);

                    &:hover {
                        cursor: pointer;

                        &::after {
                            border-color: var(--color-form-radio);
                        }
                    }

                    &::before {
                        background-color: transparent;
                        content: '';
                        height: d.rem(16);
                        inset-block-start: d.rem(12);
                        inset-inline-start: d.rem(4);
                        mask-image: var(--icon-check);
                        mask-position: center;
                        mask-repeat: no-repeat;
                        mask-size: contain;
                        position: absolute;
                        transform: translateY(-50%) scale(0.5);
                        transform-origin: center;
                        transition: transform 0.3s ease-in-out;
                        vertical-align: middle;
                        width: d.rem(16);
                        z-index: 1;
                    }

                    &::after {
                        background: var(--color-background);
                        border: solid 1px var(--color-border-strong);
                        border-radius: 4px;
                        content: '';
                        height: d.rem(24);
                        inset-block-start: 0;
                        inset-inline-start: 0;
                        position: absolute;
                        width: d.rem(24);
                        z-index: 0;
                    }
                }
            }

            .cmp-form-options__field-label {
                display: block;
                min-height: d.rem(24);
                padding-inline-start: d.rem(36);
                position: relative;

                + .cmp-form-options__field-label {
                    margin-block-start: map.get($spacing, s-16);
                }

                input {
                    &[disabled] + span {
                        cursor: not-allowed;

                        &:hover {
                            cursor: not-allowed;

                            &::after {
                                border-color: var(--color-border-strong);
                            }
                        }

                        &::before {
                            background-color: var(--color-icon-disabled);
                            display: none;
                        }

                        &::after {
                            background-color: var(--color-background-verylight);
                        }
                    }

                    &[disabled]:checked + span {
                        &::after {
                            border: solid 1px var(--color-border-strong);
                        }

                        &::before {
                            background-color: var(--color-icon-disabled);
                            display: block;
                            z-index: 1;
                        }
                    }

                    &:checked + span {
                        &::before {
                            background-color: var(--color-form-radio);
                            transform: translateY(-50%) scale(1);
                        }

                        &::after {
                            border: 1px solid var(--color-form-radio);
                        }
                    }

                    &:focus-within + span {
                        .js-user-is-tabbing & {
                            &::after {
                                @include keyboardfocus;

                                border-color: var(--color-form-radio);
                            }
                        }
                    }
                }
            }
        }
    }

    // -----------------------
    // Button styling
    // -----------------------
    &-button {
        --button-background: var(--color-primary);
        --button-background-hover: var(--color-primary-hover);
        --button-border: var(--color-primary);
        --button-txt: var(--color-text-primary-inverse);
        --button-border-hover: var(--color-primary-hover);
        --button-txt-hover: var(--button-txt);
        --button-outline: var(--color-focus-outline);
        --padding-button-block: #{d.rem(13)};
        --padding-button-inline: #{map.get($spacing, s-16)};

        background-color: var(--button-background);
        border: 1px solid var(--button-border);
        border-radius: 4px;
        color: var(--button-txt);
        cursor: pointer;
        display: inline-block;
        max-width: 100%;
        overflow: hidden;
        padding: var(--padding-button-block) var(--padding-button-inline);
        position: relative;
        text-decoration: none;
        text-overflow: ellipsis;
        transition-duration: 250ms;
        transition-property: background-color,
        border-color;
        transition-timing-function: cubic-bezier(0.4, 0, 0.4, 1);
        white-space: nowrap;

        @include text-style('label', 2, m, 1.43);

        &:hover,
        &:focus {
            background-color: var(--button-background-hover);
            border-color: var(--button-border-hover);
            color: var(--button-txt-hover);
        }

        &:focus-visible {
            --color-focus-outline: var(--button-outline);

            @include keyboardfocus;
        }

        &[disabled] {
            --button-background: var(--color-disabled);
            --button-background-hover: var(--button-background);
            --button-border: var(--button-background);
            --button-border-hover: var(--button-border);
            --button-txt: var(--color-text-secondary-transparent);
            --button-txt-hover: var(--button-txt);

            cursor: not-allowed;
        }
    }
}
