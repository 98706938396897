@media print {
    .cmp-top-stories {
        column-gap: var(--grid-gap);
        display: grid;
        grid-template-columns: 2fr 1fr;
        grid-template-rows: min-content 1fr;

        &__header {
            grid-column: 2;
            grid-row: 1;
        }

        &__cta {
            display: none;
        }

        &__featured-story {
            grid-column: 1;
            grid-row: 1 / -1;
            height: 100%;
            margin-block-end: 0;
        }

        &__stories {
            grid-column: 2;
            grid-row: 2;
        }
    }
}
