.cmp-generic-header {
    &--theme-shout {
        --color-focus-outline: var(--color-accent);
        --header-background-color: var(--color-background-inverse);
        --header-text-color: var(--color-text-primary-inverse-transparent);
        --header-title-color: var(--color-text-primary-inverse);
        --header-gradient-mask-rgb-color: var(--color-background-inverse-rgb);

        .cmp-breadcrumb {
            --breadcrumb-color: var(--color-text-primary-inverse);
            --breadcrumb-link-color: var(--color-text-primary-inverse);
            --breadcrumb-link-color-hover: var(--color-text-accent-light);
            --breadcrumb-active-item-opacity: 0.7;
        }

        .button--subtle .cmp-button {
            --button-subtle-text: var(--color-text-primary-inverse);
            --button-subtle-icon-background: var(--color-background);
            --button-subtle-icon-color: var(--color-text-primary);
            --button-subtle-icon-outline: var(--color-focus-outline);
            --button-subtle-text-hover: var(--color-text-accent-light);
            --button-subtle-text-disabled: var(--color-text-primary-inverse-transparent);
            --button-subtle-icon-background-disabled: var(--color-text-primary-inverse-transparent);
        }

        .cmp-header__overlay .button--subtle .cmp-button {
            --button-subtle-icon-background: var(--color-background);
            --button-subtle-icon-color: var(--color-text-primary);
        }

        .button:not(.button--subtle) {
            --button-background: var(--color-background);
            --button-background-hover: var(--color-primary-light);
            --button-border: var(--color-background);
            --button-txt: var(--color-text-primary);
            --button-border-hover: var(--color-primary-light);
            --button-txt-hover: var(--button-txt);

            &[disabled] {
                --button-background: var(--color-disabled);
                --button-background-hover: var(--button-background);
                --button-border: var(--button-background);
                --button-border-hover: var(--button-border);
                --button-txt: var(--color-text-secondary-transparent);
                --button-txt-hover: var(--button-txt);
            }
        }

        .keylink {
            --keylink-text: var(--color-text-primary-inverse);
            --keylink-text-hover: var(--color-text-accent-light);
            --keylink-icon-color: var(--color-text-primary-inverse);
            --keylink-outline: var(--color-focus-outline);
        }

        .tag {
            --tag-background: var(--color-border-transparent-inverse);
            --tag-background-hover: var(--color-tag);

            // Todo: alpha values... ask Jochen or Kristien
            --tag-border: 1px solid transparent;
            --tag-border-hover: 1px solid var(--color-tag);
            --tag-color: var(--color-text-primary-inverse);
            --tag-color-hover: var(--color-text-primary-inverse);
        }

        .generic-header--landing-page & {
            .cmp-generic-header__cta {
                --button-subtle-text: var(--color-text-primary-inverse);
                --button-subtle-icon-background: var(--color-background);
                --button-subtle-icon-color: var(--color-text-primary);
                --button-subtle-icon-outline: var(--color-focus-outline);
                --button-subtle-text-hover: var(--color-text-accent-light);
                --button-subtle-text-disabled: var(--color-text-primary-inverse-transparent);
                --button-subtle-icon-background-disabled: var(--color-text-primary-inverse-transparent);
            }
        }
    }

    &--theme-silent {
        --header-background-color: var(--color-background);
        --header-text-color: var(--color-text-secondary);
        --header-title-color: var(--color-text-primary);
        --header-title-emphasis-color: var(--color-text-title-accent);
        --header-gradient-mask-rgb-color: var(--color-background-rgb);
        --header-image-overlay-color: var(--color-background);
        --header-image-gradient-overlay-color-rgb: 255, 255, 255;

        .image--dark .cmp-image::before,
        .image--darker .cmp-image::before {
            mix-blend-mode: hard-light;
            opacity: 0.7;
        }

        .image--darker .cmp-image::after {
            mix-blend-mode: darken;
        }
    }

    &--theme-whisper {
        --header-background-color: var(--color-primary-light);
        --header-gradient-mask-rgb-color: var(--color-primary-light-rgb);
        --header-image-overlay-color: var(--color-primary-light);
        --header-image-gradient-overlay-color-rgb: 224, 242, 255;

        .image--dark .cmp-image::before,
        .image--darker .cmp-image::before {
            mix-blend-mode: hard-light;
        }

        .image--darker .cmp-image::after {
            mix-blend-mode: darken;
        }

        .tag {
            --tag-background: var(--color-background);
            --tag-border: 1px solid var(--tag-background);
            --tag-color: var(--color-text-primary);
            --tag-color-hover: var(--color-text-link);
            --tag-border-hover: 1px solid var(--color-primary-transparent);
        }
    }
}
