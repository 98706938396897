@use 'sass:map';
@use '@oecd/util-distance' as d;
@use '@oecd/util-focus' as *;
@use '@oecd/foundation/src/site/config/spacing-vars' as *;

.cmp-facet-date-range {
    --date-field-width: #{d.rem(86)};
    --range-thumb-color: var(--color-primary);
    --range-thumb-dimension: #{d.rem(10)};
    --range-track-background: var(--color-border);
    --range-track-dimension: #{d.rem(2)};
    --range-track-min-percentage: 0%;
    --range-track-max-percentage: 100%;
    --range-progress-indicator: linear-gradient(to right, var(--range-track-background) 0%, var(--range-track-background) var(--range-track-min-percentage), var(--range-thumb-color) var(--range-track-min-percentage), var(--range-thumb-color) var(--range-track-max-percentage), var(--range-track-background) var(--range-track-max-percentage), var(--range-track-background) 100%);

    &__range-wrapper {
        min-height: var(--range-thumb-dimension);
        position: relative;

        input[type='range'] {
            appearance: none;
            background: transparent;
            cursor: pointer;
            display: block;
            inset-block-start: 50%;
            inset-inline-start: 0;
            pointer-events: none; // This ensures the track cannot be clicked
            position: absolute;
            transform: translateY(-50%);
            width: 100%;

            &::-webkit-slider-runnable-track {
                background-color: var(--range-track-background);
                border-radius: var(--range-track-dimension);
                height: var(--range-track-dimension);
            }

            &::-moz-range-track {
                background-color: var(--range-track-background);
                border-radius: var(--range-track-dimension);
                height: var(--range-track-dimension);
            }

            &::-webkit-slider-thumb {
                appearance: none;
                background-color: var(--range-thumb-color);
                border-radius: 50%;
                cursor: pointer;
                height: var(--range-thumb-dimension);

                // margin-top = (track height in pixels / 2) - (thumb height in pixels /2)
                margin-block-start: calc((var(--range-track-dimension) / 2) - (var(--range-thumb-dimension) / 2));
                pointer-events: all;
                width: var(--range-thumb-dimension);

                &:hover {
                    box-shadow: 0 0 0 2px var(--range-thumb-color);
                }
            }

            &::-moz-range-thumb {
                appearance: none;
                background-color: var(--range-thumb-color);
                border-radius: 50%;
                cursor: pointer;
                height: var(--range-thumb-dimension);
                pointer-events: all;
                width: var(--range-thumb-dimension);

                &:hover {
                    box-shadow: 0 0 0 2px var(--range-thumb-color);
                }
            }

            &:first-child {
                height: 0;
                z-index: 1;

                &::-webkit-slider-runnable-track {
                    background-color: transparent;
                }

                &::-moz-range-track {
                    background-color: transparent;
                }
            }

            &:last-child {
                z-index: 0;

                &::-webkit-slider-runnable-track {
                    background-image: var(--range-progress-indicator);
                }

                &::-moz-range-track {
                    background-image: var(--range-progress-indicator);
                }
            }
        }
    }

    &__field-wrapper {
        display: flex;
        justify-content: space-between;
        margin-top: map.get($spacing, s-32);
    }

    &__date-field {
        margin-top: map.get($spacing, s-8);
        width: var(--date-field-width);
    }
}
