@use 'sass:map';
@use '@oecd/foundation/src/site/config/spacing-vars' as *;
@use '@oecd/util-a11y' as *;
@use '@oecd/util-breakpoint' as *;
@use '@oecd/util-distance' as d;
@use '@oecd/util-focus' as *;
@use '@oecd/util-text-style' as *;

.cmp-topic-dropdown {
    --topic-dropdown-title-color: var(--color-text-secondary);
    --topic-dropdown-tab-color: var(--color-text-secondary);
    --topic-dropdown-tab-color-active: var(--color-text-link);
    --topic-dropdown-active-tab-icon: var(--icon-chevron-small);
    --topic-dropdown-active-tab-icon-size: #{d.rem(16)};
    --topic-dropdown-link-color: var(--color-text-primary);
    --topic-dropdown-link-color-hover: var(--color-text-link);

    grid-column: 1/-1;

    &__accordion-area {
        @include breakpoint-from(desktop) {
            display: none;
        }

        &__cta {
            margin-block-start: map.get($spacing, s-24);
        }
    }

    &__tab-area {
        display: none;

        @include breakpoint-from(desktop) {
            display: block;
        }
    }

    &__tabs {
        column-gap: var(--grid-gap);
        display: grid;
        grid-template-columns: repeat(12, 1fr);
        grid-template-rows: auto 1fr;

        &-list {
            grid-column: 1 / span 3;
            grid-row: 1;
        }

        &-panels {
            grid-column: 4/-1;
            grid-row: 1 / -1;
        }

        &-cta {
            grid-column: 1 / span 3;
            grid-row: 2;

            .cmp-button {
                margin-block-start: map.get($spacing, s-24);
            }
        }

        .cmp-tabs__tablist {
            display: block;

            // Compensate padding on first tab
            margin-block-start: -#{map.get($spacing, s-4)};
        }

        .cmp-tabs__tab {
            @include text-style(label, 2, m);

            border: 0;
            color: var(--topic-dropdown-tab-color);
            padding-block: map.get($spacing, s-4);
            padding-inline: 0;
            position: relative;

            @media (prefers-reduced-motion: no-preference) {
                transition: color 0.15s linear;
            }

            &:hover,
            &:focus-visible {
                color: var(--topic-dropdown-tab-color-active);
            }

            &--active {
                color: var(--topic-dropdown-tab-color-active);

                &::after {
                    background-color: currentcolor;
                    content: '';
                    display: inline-block;
                    height: var(--topic-dropdown-active-tab-icon-size);
                    margin-inline-start: map.get($spacing, s-8);
                    mask-image: var(--topic-dropdown-active-tab-icon);
                    mask-position: center;
                    mask-repeat: no-repeat;
                    mask-size: contain;
                    vertical-align: middle;
                    width: var(--topic-dropdown-active-tab-icon-size);

                    @media screen and (forced-colors: active) {
                        background: ButtonText;
                    }
                }
            }
        }
    }

    &__title {
        @include text-style(label, 2);

        color: var(--topic-dropdown-title-color);
        margin-block-end: map.get($spacing, s-20);
    }

    &__links {
        @include breakpoint-until(desktop) {
            padding-inline-start: map.get($spacing, s-16);
        }

        &--columns {
            @include breakpoint-from(desktop) {
                column-gap: var(--grid-gap);
                columns: 2;
            }
        }
    }

    &__link {
        break-inside: avoid;

        .cmp-button {
            color: var(--topic-dropdown-link-color);
            display: inline-block;
            padding-block: map.get($spacing, s-8);
            text-decoration: none;

            @media (prefers-reduced-motion: no-preference) {
                transition: color 0.15s linear;
            }

            @include text-style(body, 3);

            @include breakpoint-from(desktop) {
                @include text-style(body, 2, m);

                padding-block: map.get($spacing, s-4);
            }

            &:hover {
                color: var(--topic-dropdown-link-color-hover);
            }

            &:focus-visible {
                @include keyboardfocus;
            }
        }
    }

    &__cta {
        .cmp-button {
            margin-block-start: map.get($spacing, s-24);

            @include breakpoint-until(desktop) {
                margin-inline-start: map.get($spacing, s-16);
            }
        }
    }
}
