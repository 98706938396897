.aem-AuthorLayer-Edit {
    .title--hidden-page-title {
        border: 0;
        clip: initial;
        height: initial;
        margin: initial;
        overflow: hidden;
        padding: 0;
        position: static;
        white-space: initial;
        width: initial;
    }
}
