@use 'sass:map';
@use '@oecd/foundation/src/site/config/spacing-vars' as *;
@use '@oecd/util-a11y' as *;
@use '@oecd/util-breakpoint' as *;
@use '@oecd/util-distance' as d;
@use '@oecd/util-text-style' as *;

@mixin button-style() {
    // Buttons need to be 48px by design
    // Padding top and bottom =  Button height - top and bottom border - lineheigt (48 - 20 - 2 )/2
    --padding-button-block: #{d.rem(13)};
    --padding-button-inline: #{map.get($spacing, s-16)};

    background-color: var(--button-background);
    border: 1px solid var(--button-border);
    border-radius: 4px;
    color: var(--button-txt);
    cursor: pointer;
    display: inline-block;
    font-size: 0;
    max-width: 100%;
    padding: var(--padding-button-block) var(--padding-button-inline);
    text-decoration: none;
    transition-duration: 250ms;
    transition-property: background-color, border-color;
    transition-timing-function: cubic-bezier(0.4, 0, 0.4, 1);

    &:hover,
    &:focus {
        background-color: var(--button-background-hover);
        border-color: var(--button-border-hover);
        color: var(--button-txt-hover);
    }

    &:focus-visible {
        outline: 2px solid var(--button-outline);
        outline-offset: 2px;
    }

    &[disabled] {
        cursor: not-allowed;
    }

    &[hidden] {
        display: none;
    }

    .cmp-button__text {
        // Lineheight = 2Opx otherwise ascenders or descenders are cut off
        @include text-style('label', 2, m, 1.43);

        display: inline-block;
        max-width: 100%;
        overflow: hidden;
        position: relative;
        text-overflow: ellipsis;
        white-space: nowrap;
    }
}

@mixin button-subtle() {
    border-radius: 0;
    color: var(--button-subtle-text);
    display: inline-block;
    max-width: 100%;
    min-height: d.rem(20);
    padding: 0;
    position: relative;
    transition-duration: 250ms;
    transition-property: color;
    transition-timing-function: cubic-bezier(0.4, 0, 0.4, 1);

    &:hover,
    &:focus {
        color: var(--button-subtle-text-hover);
    }

    &:focus {
        outline: none;
    }

    &:focus-visible {
        line-height: 0;
        outline: 2px solid var(--button-subtle-icon-outline);
        outline-offset: 2px;
    }

    &[disabled] {
        color: var(--button-subtle-text-disabled);
        cursor: not-allowed;

        .cmp-button__text {
            &::before {
                background: var(--button-subtle-icon-background-disabled);
            }
        }
    }

    &[hidden] {
        display: none;
    }

    .cmp-button__text {
        display: inline-block;
        max-width: 100%;
        overflow: hidden;
        padding-inline-end: d.rem(28); // Width of the icon + 8;
        position: relative;
        text-overflow: ellipsis;
        white-space: nowrap;

        // line-height equals height of button
        @include text-style('label', 2, b, 1.43);

        &::before {
            background: var(--button-subtle-icon-background);
            border-radius: 50%;
            content: '';
            display: block;
            height: d.rem(20);
            position: absolute;
            right: 0;
            top: 50%;
            transform: translateY(-50%);
            width: d.rem(20);

            @media screen and (forced-colors: active) {
                border: 1px solid;
            }
        }

        &::after {
            background: var(--button-subtle-icon-color);
            content: '';
            display: block;
            height: d.rem(16);
            mask-image: var(--button-subtle-icon);
            mask-position: center;
            mask-repeat: no-repeat;
            mask-size: contain;
            position: absolute;
            right: d.rem(2);
            top: 50%;
            transform: translate(0, -50%);
            width: d.rem(16);

            @media screen and (forced-colors: active) {
                background: LinkText;
            }
        }
    }
}

@mixin button-icon($icon: true, $icon-only: false, $show-copy: false) {
    @if $icon-only {
        --padding-button-inline: #{d.rem(13)};
    } @else if not ($icon-only) {
        @include breakpoint-until(tablet) {
            --padding-button-inline: #{d.rem(13)};
        }
    }

    align-items: center;
    display: inline-flex;
    position: relative;

    &::before {
        background: currentcolor;
        content: '';
        display: inline-block;
        flex-shrink: 0;
        height: d.rem(16);
        mask-image: var($icon);
        mask-position: center;
        mask-repeat: no-repeat;
        mask-size: contain;
        vertical-align: middle;
        width: d.rem(16);

        @if not ($icon-only) {
            @if $show-copy {
                margin-inline-end: #{map.get($spacing, s-8)};
            }

            @include breakpoint-from(tablet) {
                margin-inline-end: #{map.get($spacing, s-8)};
            }
        }
    }

    .cmp-button__text {
        position: relative;

        @if $icon-only {
            @include visually-hidden;
        } @else if not ($icon-only) {
            @if not($show-copy) {
                @include breakpoint-until(tablet) {
                    @include visually-hidden;
                }
            }
        }
    }
}
