@use 'sass:map';
@use '@oecd/foundation/src/site/config/spacing-vars' as *;
@use '@oecd/util-a11y' as *;
@use '@oecd/util-breakpoint' as *;
@use '@oecd/util-distance' as d;
@use '@oecd/util-focus' as *;
@use '@oecd/util-text-style' as *;

.searchbox {
    --search-box-margin-block-end: #{map.get($spacing, s-32)};

    display: grid;
    grid-template-columns: [full-start] var(--grid-offset) [wrapper-start] minmax(0, var(--container-max-width)) [wrapper-end] var(--grid-offset) [full-end];

    // When the searchbox is used inside a content container the gutters need to be reset
    .container-content & {
        --grid-offset: 0;
    }

    &__container {
        column-gap: var(--grid-gap);
        display: grid;
        grid-column: wrapper;
        grid-template-columns: repeat(12, 1fr);
        margin-block-end: var(--search-box-margin-block-end);

        > .cmp-searchbox {
            grid-column: 1/-1;

            @include breakpoint-from(desktop-small) {
                grid-column: 1 / span 5;
            }
        }
    }
}

.cmp-searchbox {
    --searchbox-icon-color: var(--color-text-secondary-transparent);
    --searchbox-icon-dimension: #{d.rem(16)};
    --searchbox-icon-offset: #{map.get($spacing, s-16)};
    --searchbox-submit-button-width: #{d.rem(56)};
    --searchbox-input-padding: #{map.get($spacing, s-24)};
    --searchbox-input-padding-block: #{map.get($spacing, s-16)};
    --searchbox-input-padding-inline-start: #{map.get($spacing, s-16)};
    --searchbox-reset-icon-color: var(--color-icon);
    --searchbox-reset-icon-dimension: #{d.rem(16)};

    border-radius: 4px;
    position: relative;

    &__input[type='search'] {
        padding-block: var(--searchbox-input-padding-block);
        padding-inline: var(--searchbox-submit-button-width) var(--searchbox-input-padding);
        width: 100%;

        &::-webkit-search-clear-button {
            appearance: none;
            display: none;
        }

        &::-webkit-search-clear-button,
        &::-webkit-search-cancel-button {
            appearance: none;
            background-color: var(--searchbox-reset-icon-color);
            cursor: pointer;
            display: inline-block;
            height: var(--searchbox-reset-icon-dimension);
            mask-image: var(--icon-close);
            mask-position: center;
            mask-repeat: no-repeat;
            mask-size: contain;
            width: var(--searchbox-reset-icon-dimension);

            @media screen and (forced-colors: active) {
                background: CanvasText;
            }
        }
    }

    &__button {
        border-radius: 4px 0 0 4px;
        height: 100%;
        inset-block-start: 0;
        inset-inline-start: 0;
        position: absolute;
        width: var(--searchbox-submit-button-width);

        &-txt {
            @include visually-hidden;
        }

        &::before {
            background-color: var(--searchbox-icon-color);
            content: '';
            display: block;
            height: var(--searchbox-icon-dimension);
            inset-block-start: 50%;
            inset-inline-end: var(--searchbox-icon-offset);
            mask-image: var(--icon-search);
            mask-position: center;
            mask-repeat: no-repeat;
            mask-size: contain;
            position: absolute;
            transform: translateY(-50%);
            width: var(--searchbox-icon-dimension);

            @media screen and (forced-colors: active) {
                background: CanvasText;
            }
        }

        &:focus-visible {
            @include keyboardfocus;
        }
    }
}
