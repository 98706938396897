@use 'sass:map';
@use '@oecd/foundation/src/site/config/spacing-vars' as *;
@use '@oecd/util-breakpoint' as *;
@use '@oecd/util-distance' as d;
@use '@oecd/util-focus' as *;
@use '@oecd/util-line-clamp' as *;
@use '@oecd/util-scrollbar' as *;
@use '@oecd/util-text-style' as *;

.cmp-tabs-list {
    --tabs-list-link-border-bottom-color: var(--color-border);
    --tabs-list-link-date-separator-symbol: '\2022';
    --tabs-list-link-text-color: var(--color-text-primary);
    --tabs-list-link-text-color-hover: var(--color-text-link-hover);
    --tabs-list-link-title-icon-background-color: var(--color-background-light);
    --tabs-list-link-title-icon-background-height: #{d.rem(28)};
    --tabs-list-link-title-icon-background-width: #{d.rem(28)};
    --tabs-list-link-title-icon-border-radius: 50%;
    --tabs-list-link-title-icon-color: var(--color-icon);
    --tabs-list-link-title-icon-height: #{d.rem(16)};
    --tabs-list-link-title-icon-width: #{d.rem(16)};
    --tabs-list-column-width: #{d.rem(394)};
    --tabs-list-country-flag-background-color: var(--color-background-light);
    --tabs-list-country-flag-border-color: var(--color-background-inverse);
    --tabs-list-search-icon-color: var(--color-text-secondary-transparent);
    --tabs-list-search-reset-icon-color: var(--color-icon);
    --tabs-list-search-reset-icon-dimension: #{d.rem(16)};

    .cmp-tabs {
        &__tablist {
            display: flex;
            flex-direction: row;
            flex-wrap: nowrap;
            gap: map.get($spacing, s-4);
            list-style: none;
            overflow: auto hidden;
            padding-inline-start: 0;
            scroll-snap-type: x mandatory;

            @include hide-scrollbars;
        }

        &__tab {
            --tabs-list-tab-background-color: var(--color-background);
            --tabs-list-tab-border-color: var(--color-border);
            --tabs-list-tab-border-color-hover: var(--color-primary);
            --tabs-list-tab-text-color: var(--color-text-secondary);
            --tabs-list-tab-text-hover-color: var(--color-text-accent);

            background-color: var(--tabs-list-tab-background-color);
            border: solid 1px var(--tabs-list-tab-border-color);
            border-radius: 4px;
            box-sizing: border-box;
            color: var(--tabs-list-tab-text-color);
            cursor: pointer;
            padding: map.get($spacing, s-8) map.get($spacing, s-16);
            white-space: nowrap;

            @include text-style(label, 2, m);

            @media (prefers-reduced-motion: no-preference) {
                transition: color 0.15s linear, border-color 0.15s linear;
            }

            &:hover {
                --tabs-list-tab-text-color: var(--tabs-list-tab-text-hover-color);
                --tabs-list-tab-border-color: var(--tabs-list-tab-border-color-hover);
            }

            &:focus,
            &:focus-visible {
                outline: none;
            }

            &--active {
                --tabs-list-tab-background-color: var(--color-primary);
                --tabs-list-tab-border-color: var(--tabs-list-tab-background-color);
                --tabs-list-tab-text-color: var(--color-text-primary-inverse);

                &:hover {
                    --tabs-list-tab-text-color: var(--color-text-primary-inverse);
                }
            }
        }
    }

    &__header {
        display: flex;
        flex-direction: column;
        gap: map.get($spacing, s-16);
        margin-block-end: map.get($spacing, s-32);

        @include breakpoint-from(tablet) {
            align-items: end;
            flex-direction: row-reverse;
            justify-content: start;
            margin-block-end: map.get($spacing, s-40);
        }
    }

    &__search-form {
        @include breakpoint-from(tablet) {
            margin-inline-start: auto;
        }
    }

    &__search-input-container {
        position: relative;

        &::before {
            align-items: center;
            background-color: var(--tabs-list-search-icon-color);
            content: '';
            display: block;
            height: d.rem(20);
            inset-block-start: 50%;
            inset-inline-start: map.get($spacing, s-16);
            margin-block-start: d.rem(-10);
            mask-image: var(--icon-search);
            mask-position: center;
            mask-repeat: no-repeat;
            mask-size: contain;
            position: absolute;
            width: d.rem(16);

            @media screen and (forced-colors: active) {
                background: CanvasText;
            }
        }

        input[type='search'] {
            padding: map.get($spacing, s-16);
            padding-inline-start: map.get($spacing, s-48);
            width: 100%;

            &::-webkit-search-clear-button {
                appearance: none;
                display: none;
            }

            &::-webkit-search-clear-button,
            &::-webkit-search-cancel-button {
                appearance: none;
                background-color: var(--tabs-list-search-reset-icon-color);
                cursor: pointer;
                display: inline-block;
                height: var(--tabs-list-search-reset-icon-dimension);
                mask-image: var(--icon-close);
                mask-position: center;
                mask-repeat: no-repeat;
                mask-size: contain;
                width: var(--tabs-list-search-reset-icon-dimension);

                @media screen and (forced-colors: active) {
                    background: CanvasText;
                }
            }

            @include breakpoint-from(desktop-small) {
                min-width: d.rem(395);
                width: auto;
            }
        }
    }

    &__search-message {
        @include text-style(heading, 2);
    }

    &__list {
        column-gap: var(--grid-gap);
        columns: var(--tabs-list-column-width);
    }

    &__item {
        break-inside: avoid;

        &-content {
            border-bottom: 1px solid var(--tabs-list-link-border-bottom-color);
            padding-block: map.get($spacing, s-16);
            position: relative;
        }

        &-link,
        &-date {
            @include text-style(label, 2, m);

            color: var(--tabs-list-link-text-color);
        }

        &-title {
            align-items: center;
            display: flex;
            font-size: 0;
            line-height: 0;
        }

        &-country-flag {
            align-self: center;
            background-color: var(--tabs-list-country-flag-background-color);
            border: 1px solid var(--tabs-list-country-flag-border-color);
            flex: 0 0 auto;
            font-size: 0;
            margin-inline-end: map.get($spacing, s-8);
        }

        &-link {
            display: flex;
            padding-inline-end: map.get($spacing, s-8);
            text-decoration: none;
            word-break: break-word;

            @media (prefers-reduced-motion: no-preference) {
                transition: color 0.15s linear;
            }

            &::before {
                content: '';
                inset: 0;
                position: absolute;
                z-index: 1;
            }

            &:hover {
                color: var(--tabs-list-link-text-color-hover);

                +.link-list__date {
                    color: var(--tabs-list-link-text-color-hover);
                }
            }

            &:focus-visible {
                color: var(--tabs-list-link-text-color-hover);
                outline: none;

                &::before {
                    @include keyboardfocus;
                }
            }
        }

        &-date:not(:empty) {
            &::before {
                content: var(--tabs-list-link-date-separator-symbol);
                margin-inline-end: map.get($spacing, s-4);
            }
        }

        &-icon {
            background-color: var(--tabs-list-link-title-icon-background-color);
            border-radius: var(--tabs-list-link-title-icon-border-radius);
            display: inline-block;
            flex: 0 0 var(--tabs-list-link-title-icon-background-width);
            height: var(--tabs-list-link-title-icon-background-height);
            margin-inline-start: auto;
            position: relative;
            vertical-align: bottom;
            width: var(--tabs-list-link-title-icon-background-width);

            @media screen and (forced-colors: active) {
                border: 1px solid;
            }

            &::after {
                background: var(--tabs-list-link-title-icon-color);
                content: '';
                height: var(--tabs-list-link-title-icon-height);
                inset-block-start: d.rem(6);
                inset-inline-start: d.rem(6);
                mask-image: var(--icon-chevron-small);
                mask-position: center;
                mask-repeat: no-repeat;
                mask-size: contain;
                position: absolute;
                width: var(--tabs-list-link-title-icon-width);

                @media screen and (forced-colors: active) {
                    background: LinkText;
                }
            }
        }
    }

    // In topic list items should be vertically aligned and cannot exceed two lines
    .cmp-topic-list & {
        &__item {
            &-title {
                justify-content: space-between;

                // Min height set on two lines (font-size * lineheight) * 2
                min-height: d.rem(41);
            }

            &-link {
                @include line-clamp(2);

                flex: 1 1 auto;
            }

            &-icon {
                flex: 0 0 auto;
            }
        }
    }


    &__search-message,
    &__search-lists {
        display: none;
    }

    &--search-enabled {
        .cmp-tabs-list__tablist,
        .cmp-tabs-list__tabpanels {
            display: none;
        }

        .cmp-tabs-list__search-message,
        .cmp-tabs-list__search-lists {
            display: block;
        }
    }
}

.js-tabs-list-result-hidden {
    display: none;
}
