@use 'sass:map';
@use '@oecd/foundation/src/site/config/spacing-vars' as *;
@use '@oecd/util-a11y' as *;
@use '@oecd/util-breakpoint' as *;
@use '@oecd/util-distance' as d;
@use '@oecd/util-text-style' as *;

.cmp-dropdown {
    @include breakpoint-from(desktop) {
        column-gap: var(--grid-gap);
        display: grid;
        grid-column: wrapper;
        grid-template-columns: repeat(12, 1fr);
    }

    &__intro {
        @include breakpoint-from(desktop) {
            grid-column: 1 / span 4;
        }

        @include breakpoint-until(desktop) {
            .cmp-button {
                margin-bottom: map.get($spacing, s-32);
            }
        }
    }

    &__content {
        @include breakpoint-from(desktop) {
            grid-column: 5 / span 8;

            > .cmp-container {
                column-gap: var(--grid-gap);
                columns: 2;
            }
        }
    }

    &__title {
        .cmp-title__text {
            @include text-style(heading, 1);

            margin-block-end: map.get($spacing, s-4);
        }

        @include breakpoint-until(desktop) {
            display: none;
        }
    }

    &__cta {
        .cmp-button {
            margin-bottom: map.get($spacing, s-32);
        }
    }

    &__group {
        break-inside: avoid;
        padding-block-end: map.get($spacing, s-32);

        &-title {
            .cmp-title__text {
                color: var(--color-text-secondary);
                margin: 0;
                text-transform: none;

                @include text-style(label, 2, m);
            }
        }

        &-content {
            margin-top: map.get($spacing, s-24);
        }
    }
}
