@use 'swiper/swiper';
@use 'swiper/modules/navigation';
@use '@oecd/util-distance' as d;

// Overrides for list carousel
.swiper {
    overflow: visible;
}

.swiper-slide {
    flex-grow: 1;
    height: unset;
    max-width: d.rem(288);
}

.swiper-button-prev,
.swiper-button-next {
    background-color: var(--color-background-light);
    border-radius: 50%;
    height: d.rem(40);
    inset-inline: 0;
    position: relative;
    width: d.rem(40);

    &::after {
        background: var(--color-icon);
        content: '';
        height: d.rem(16);
        inset-block: d.rem(12);
        inset-inline: d.rem(12);
        mask-image: var(--icon-chevron-small);
        mask-position: center;
        mask-repeat: no-repeat;
        mask-size: contain;
        position: absolute;
        width: d.rem(16);
    }

    @media screen and (forced-colors: active) {
        border: 1px solid;

        &::after {
            background: buttonText;
        }

        &[disabled] {
            &::after {
                background: GrayText;
            }
        }
    }


}

.swiper-button-prev {
    &::after {
        transform: rotate(180deg);
    }
}

