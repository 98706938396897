@use 'sass:map';
@use '@oecd/foundation/src/site/config/spacing-vars' as *;
@use '@oecd/util-breakpoint' as *;
@use '@oecd/util-button' as *;
@use '@oecd/util-distance' as d;
@use '@oecd/util-text-style' as *;

.subscribe-banner {
    container-name: subscribe-banner;
    container-type: inline-size;
}

.cmp-subscribe-banner {
    --subscribe-banner-background: var(--color-primary-light);
    --subscribe-banner-text-color: var(--text-secondary);
    --subscribe-banner-padding-block: #{map.get($spacing, s-24)};
    --subscribe-banner-padding-inline: #{map.get($spacing, s-24)};
    --subscribe-banner-input-background-color: var(--color-background);
    --subscribe-banner-input-border-color: var(--color-form-input);

    background-color: var(--subscribe-banner-background);
    border-radius: 4px;
    display: grid;
    gap: #{map.get($spacing, s-24)} var(--grid-gap);
    grid-template-columns: repeat(12, 1fr);
    padding: var(--subscribe-banner-padding-block) var(--subscribe-banner-padding-inline);

    // On mobile and tablet the subscribe banner needs to bleed to the edges and have no border-radius
    @container subscribe-banner (max-width: 943px) {
        border-radius: 0;
        margin-inline: calc(var(--grid-offset) * -1);
    }

    @container subscribe-banner (min-width: 764px) {
        --subscribe-banner-padding-block: #{map.get($spacing, s-32)};
    }

    @container subscribe-banner (min-width: 944px) {
        --subscribe-banner-padding-block: #{map.get($spacing, s-32)};
    }

    @container subscribe-banner (min-width: 1248px) {
        --subscribe-banner-padding-block: #{map.get($spacing, s-48)};
    }

    &__intro {
        grid-column: 1 /span 12;

        @container subscribe-banner (min-width: 764px) {
            grid-column: 1 /span 10;
        }

        @container subscribe-banner (min-width: 944px) {
            grid-column: 1 /span 6;
            padding-inline-start: map.get($spacing, s-16);
        }

        @container subscribe-banner (min-width: 1248px) {
            padding-inline-start: map.get($spacing, s-32);
        }

        .cmp-title__text {
            @include text-style(heading-hero, 4);

            margin-block-end: 0;
        }

        .cmp-text {
            @include text-style(body, 3);

            color: var(--subscribe-banner-text-color);
            margin-block-start: map.get($spacing, s-8);
        }
    }

    &__form-wrapper {
        align-items: center;
        display: flex;
        grid-column: 1 /span 12;

        @container subscribe-banner (min-width: 764px) {
            grid-column: 1 /span 10;
        }

        @container subscribe-banner (min-width: 944px) {
            grid-column: 7 /span 6;
            padding-inline-end: map.get($spacing, s-16);
        }

        @container subscribe-banner (min-width: 1248px) {
            padding-inline-end: map.get($spacing, s-32);
        }
    }

    &__form {
        border-radius: 4px;
        display: flex;
        width: 100%;

        &:focus-within {
            --subscribe-banner-input-border-color: var(--color-primary);
        }
    }

    input[type='email'] {
        background-color: var(--subscribe-banner-input-background-color);
        border: 1px solid;
        border-color: var(--subscribe-banner-input-border-color);
        border-radius: var(--form-input-border-radius, 4px) 0 0 var(--form-input-border-radius, 4px);
        flex: 1;
        font-size: inherit;
        line-height: 1;
        padding-block: d.rem(14); // Padding of 15px - 1px to account for border
        padding-inline: map.get($spacing, s-24) map.get($spacing, s-16);

        @media (prefers-reduced-motion: no-preference) {
            transition: border-color 0.15s linear;
        }

        &.is-invalid {
            --subscribe-banner-input-border-color: var(--color-error);
        }
    }

    .cmp-button {
        @include button-style;

        --button-background: var(--color-primary);
        --button-background-hover: var(--color-primary-hover);
        --button-border: var(--color-primary);
        --button-txt: var(--color-text-primary-inverse);
        --button-border-hover: var(--color-primary-hover);
        --button-txt-hover: var(--button-txt);
        --button-outline: var(--color-focus-outline);

        border-radius: 0 4px 4px 0;
        height: 100%;
        line-height: 1;
    }
}
