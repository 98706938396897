.cmp-embed {
    --embed-aspect-ratio: initial;

    overflow: hidden;

    iframe {
        aspect-ratio: var(--embed-aspect-ratio);
        background-color: transparent;
        border: 0;
        width: 100%;

        &[src*='youtu.be'],
        &[src*='youtube.com'],
        &[src*='vimeo.com'] {
            --embed-aspect-ratio: 16/9;

            height: auto;
        }
    }
}
