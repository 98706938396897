.aem-AuthorLayer-Edit {
    .report-header .cmp-report-header__cta .cmp-button.cq-placeholder {
        --padding-button-block: 0;
        --button-background: transparent;
        --button-border: transparent;
        --header-cta-margin-block-start: 0;

        transition: none;
    }
}

.aem-AuthorLayer-Preview {
    .report-header .cmp-report-header__cta .cmp-button.cq-placeholder {
        display: none;
    }
}
