@media print {
    .cmp-article-header {
        margin-block-start: 3rem;
        padding-block: 0 0;

        &__breadcrumb {
            display: none;
        }

        &__image {
            grid-column: 1 / span 4 !important;
        }

        &__content {
            grid-column: 5 / span 7 !important;
        }
    }

    .article-header--no-image .cmp-article-header {
        &__content {
            grid-column: 1 / -1 !important;
            margin-inline: 0;
            padding-inline-end: 0 !important;
        }
    }
}
