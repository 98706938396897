@use 'sass:map';
@use 'sass:math';
@use '@oecd/foundation/src/site/config/spacing-vars' as *;
@use '@oecd/util-text-style' as *;

@mixin styled-unordered-list($markerColor: currentColor) {
    ul {
        padding-inline-start: 1.12em;

        > li {
            list-style-type: disc;
            margin-block: map.get($spacing, s-4);

            &::marker {
                color: $markerColor;
            }

            > ul {
                > li {
                    list-style-type: circle;

                    > ul {
                        > li {
                            list-style-type: square;

                            > ul {
                                > li {
                                    margin-inline-start: -0.45em;
                                    padding-inline-start: 1ch;

                                    &::marker {
                                        content: '\25A1';
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

@mixin styled-ordered-list($markerColor: currentColor) {
    ol {
        counter-reset: ol-counter;

        > li {
            counter-increment: ol-counter;
            margin-block: map.get($spacing, s-4);
            padding-left: 2ch;
            position: relative;

            &::before {
                color: $markerColor;
                content: counters(ol-counter, '.') '. ';
                left: 0;
                position: absolute;
            }

            > ol {
                > li {
                    padding-inline-start: 3.5ch;

                    > ol {
                        > li {
                            padding-inline-start: 5ch;

                            > ol {
                                > li {
                                    padding-inline-start: 6.5ch;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}
