@use 'sass:map';
@use '@oecd/foundation/src/site/config/spacing-vars' as *;
@use '@oecd/util-a11y' as *;
@use '@oecd/util-breakpoint' as *;
@use '@oecd/util-distance' as d;
@use '@oecd/util-focus' as *;
@use '@oecd/util-text-style' as *;

.cmp-pagination {
    --pagination-color: var(--color-text-primary);
    --pagination-color-hover: var(--color-text-accent);
    --pagination-margin-block-start: #{map.get($spacing, s-32)};
    --pagination-current-page-background: var(--color-primary-light);
    --pagination-link-dimension: #{d.rem(40)};
    --pagination-control-background: var(--color-background-light);
    --pagination-control-color: var(--color-text-primary);
    --pagination-control-color-hover: var(--color-text-accent);
    --pagination-control-color-disabled: var(--color-text-secondary);
    --pagination-control-icon-size: #{d.rem(16)};
    --pagination-control-icon: var(--icon-chevron-right);

    @include breakpoint-from(desktop) {
        --pagination-margin-block-start: #{map.get($spacing, s-64)};
    }

    color: var(--pagination-color);
    display: flex;
    gap: map.get($spacing, s-24);
    justify-content: center;
    margin-block-start: var(--pagination-margin-block-start);

    &__list {
        align-items: center;
        display: flex;
        flex-wrap: wrap;
        gap: map.get($spacing, s-16);
        justify-content: center;

        @include breakpoint-from(tablet) {
            flex-wrap: nowrap;
            gap: map.get($spacing, s-24);
        }
    }

    &__break {
        a:not([class]) {
            color: var(--pagination-color);
            text-decoration: none;
            text-decoration-thickness: max(1px, 0.0625rem);
            text-underline-offset: 0.15em;
        }

        @include text-style(label, 2, b);

        border-radius: 100%;
        color: var(--pagination-current-page-background);
        display: block;
        height: var(--pagination-link-dimension);
        line-height: var(--pagination-link-dimension);
        text-align: center;
        text-decoration: none;
        width: var(--pagination-link-dimension);
    }

    &__link {
        a {
            @include text-style(label, 2, b);

            border-radius: 100%;
            color: var(--pagination-color);
            cursor: pointer;
            display: block;
            height: var(--pagination-link-dimension);
            line-height: var(--pagination-link-dimension);
            text-align: center;
            text-decoration: none;
            width: var(--pagination-link-dimension);

            @media (prefers-reduced-motion: no-preference) {
                transition-duration: 250ms;
                transition-property: color;
                transition-timing-function: cubic-bezier(0.4, 0, 0.4, 1);
            }

            &:hover,
            &:focus,
            &:focus-visible {
                --pagination-color: var(--pagination-color-hover);
            }

            &[aria-current] {
                background: var(--pagination-current-page-background);
                cursor: pointer;
            }

            &:focus-visible {
                @include keyboardfocus;
            }
        }
    }

    .cmp-pagination__previous,
    .cmp-pagination__next {
        @include breakpoint-until(tablet) {
            display: none;
        }

        a {
            background-color: var(--pagination-control-background);
            border-radius: 100%;
            color: var(--pagination-control-color);
            display: block;
            height: var(--pagination-link-dimension);
            line-height: var(--pagination-link-dimension);
            position: relative;
            text-align: center;
            width: var(--pagination-link-dimension);

            @media (prefers-reduced-motion: no-preference) {
                transition-duration: 250ms;
                transition-property: color;
                transition-timing-function: cubic-bezier(0.4, 0, 0.4, 1);
            }

            &:hover,
            &:focus,
            &:focus-visible {
                --pagination-control-color: var(--pagination-control-color-hover);
            }

            &:focus-visible {
                @include keyboardfocus;
            }

            &[aria-disabled='true'] {
                --pagination-control-color: var(--pagination-control-color-disabled);

                cursor: not-allowed;
            }

            &::before {
                background-color: currentcolor;
                content: '';
                display: block;
                height: var(--pagination-control-icon-size);
                inset-block-start: 50%;
                inset-inline-start: 50%;
                mask-image: var(--pagination-control-icon);
                mask-position: center;
                mask-repeat: no-repeat;
                mask-size: contain;
                position: absolute;
                transform: translate(-50%, -50%);
                width: var(--pagination-control-icon-size);
            }
        }
    }

    .cmp-pagination__previous {
        a {
            &::before {
                transform: translate(-50%, -50%) rotate(-180deg);
            }
        }
    }
}
