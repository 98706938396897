@use 'sass:map';
@use '@oecd/util-a11y' as *;
@use '@oecd/util-distance' as d;

$socials: 'facebook' 'instagram' 'linkedin' 'newsletter' 'podcast' 'x' 'youtube';

.social-link {
    --social-link-background-color: var(--color-background-light);
    --social-link-border: 2px solid var(--color-background);
    --social-link-border-radius: 50%;
    --social-link-color: var(--color-icon-secondary);
    --social-link-color-hover: var(--color-icon-accent);
    --social-link-icon: var(--icon-plus);
    --social-link-icon-size: #{d.rem(16)};
    --social-link-outline: 2px solid var(--color-focus-outline);
    --social-link-padding: #{d.rem(10)};

    line-height: 0;

    a {
        background-color: var(--social-link-background-color);
        border: var(--social-link-border);
        border-radius: var(--social-link-border-radius);
        color: var(--social-link-color);
        display: block;
        height: calc(var(--social-link-icon-size) + 2 * var(--social-link-padding));
        padding: var(--social-link-padding);
        position: relative;
        text-decoration: none;
        width: calc(var(--social-link-icon-size) + 2 * var(--social-link-padding));

        @media (prefers-reduced-motion: no-preference) {
            transition: 0.15s linear color;
        }

        &::before {
            background-color: currentcolor;
            content: '';
            display: inline-block;
            height: var(--social-link-icon-size);
            mask-image: var(--social-link-icon);
            mask-position: center;
            mask-repeat: no-repeat;
            mask-size: contain;
            width: var(--social-link-icon-size);

            @media screen and (forced-colors: active) {
                background-color: LinkText;
            }
        }

        &:hover {
            color: var(--social-link-color-hover);
        }

        &:active,
        &:focus-visible {
            outline: var(--social-link-outline);
        }

        span {
            @include visually-hidden;
        }
    }

    @each $social in $socials {
        &--#{$social} {
            --social-link-icon: var(--icon-#{$social});
        }
    }
}
