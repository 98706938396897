@use 'sass:map';
@use '@oecd/util-distance' as d;
@use '@oecd/util-focus' as *;
@use '@oecd/util-text-style' as *;
@use '@oecd/foundation/src/site/config/spacing-vars' as *;

// Base form styling
label {
    @include text-style('label', 2, m);
}

input {
    &[type='color'],
    &[type='date'],
    &[type='datetime'],
    &[type='datetime-local'],
    &[type='email'],
    &[type='month'],
    &[type='number'],
    &[type='password'],
    &[type='search'],
    &[type='tel'],
    &[type='text'],
    &[type='time'],
    &[type='url'],
    &[type='week'],
    &:not([type]) {
        background-color: var(--color-form-input);
        border: 1px solid var(--color-form-input);
        border-radius: var(--form-input-border-radius, 4px);
        display: block;
        padding: map.get($spacing, s-16);

        @include text-style('form-input');

        @media (prefers-reduced-motion: no-preference) {
            transition: border-color 0.15s linear;
        }

        // Prevent Zoom Issue on touch devices
        @media (hover: none) and (pointer: coarse) {
            @include text-style('form-input-touch', null, null, false, true);
        }

        &:hover {
            border-color: var(--color-primary);
        }

        &:focus {
            border-color: var(--color-primary); // TODO: rescope to more functional color variable
            outline: none;

            .js-user-is-tabbing & {
                @include keyboardfocus;
            }
        }

        &:user-invalid {
            border-color: var(--color-error);
        }

        &:read-only {
            background-color: var(--color-background-light);
            border-color: var(--color-background-light);
            color: var(--color-text-secondary);

            &:hover {
                border-color: var(--color-background-light);
                cursor: default;
            }
        }
    }
}

// Remove IOS default search icon
input[type='search'] {
    appearance: none;
}

textarea {
    background-color: var(--color-form-input);
    border: 1px solid var(--color-form-input);
    border-radius: var(--form-input-border-radius, 4px);
    padding: map.get($spacing, s-16);

    @include text-style('form-input');

    @media (prefers-reduced-motion: no-preference) {
        transition: border-color 0.15s linear;
    }

    @media (hover: none) and (pointer: coarse) {
        @include text-style('form-input-touch', null, null, false, true);
    }

    // Prevent Zoom Issue on touch devices
    @media (hover: none) and (pointer: coarse) {
        @include text-style('form-input-touch', null, null, false, true);
    }

    &:hover {
        border-color: var(--color-primary);
    }

    &:focus {
        border-color: var(--color-primary); // TODO: rescope to more functional color variable
        outline: none;

        .js-user-is-tabbing & {
            @include keyboardfocus;
        }
    }

    &:user-invalid {
        border-color: var(--color-error);
    }

    &:read-only {
        background-color: var(--color-background-light);
        border-color: var(--color-background-light);
        color: var(--color-text-secondary);

        &:hover {
            border-color: var(--color-background-light);
            cursor: default;
        }
    }
}

select {
    --select-arrow: var(--select-border);
    --select-background-color: var(--color-form-input);
    --select-background-image: url('data:image/svg+xml,<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="m11.837 5.837-3.812 3.95-3.813-3.95" stroke="%23101D40" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/></svg>');
    --select-background-image-disabled: url('data:image/svg+xml,<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="m11.837 5.837-3.812 3.95-3.813-3.95" stroke="%23586179" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/></svg>');
    --select-background-image-size: #{d.rem(16)};
    --select-border-color: var(--select-background-color);
    --select-border-color-hover: var(--color-primary);
    --select-border-width: 1px;
    --select-border-radius: 4px;
    --select-padding: #{map.get($spacing, s-16)};
    --select-text-color: var(--color-text-primary);
    --select-text-color-disabled: var(--color-text-placeholder);
    --select-focus: var(--color-focus-outline);

    @include text-style(form-input);

    appearance: none;
    background-color: var(--select-background-color);
    background-image: var(--select-background-image);
    background-position: right var(--select-padding) top 50%, 0 0;
    background-repeat: no-repeat, repeat;
    background-size: var(--select-background-image-size), 100%;
    border-color: var(--select-border-color);
    border-radius: var(--select-border-radius);
    border-style: solid;
    border-width: var(--select-border-width);
    color: var(--select-text-color);
    cursor: pointer;
    display: block;
    margin: 0;
    max-width: 100%;
    outline: none;
    padding: var(--select-padding);
    padding-inline-end: calc((var(--select-padding) * 2) + var(--select-background-image-size));
    width: 100%;

    @media (prefers-reduced-motion: no-preference) {
        transition: border-color 0.15s linear;
    }

    &[multiple] {
        background-position: right var(--select-padding) top var(--select-padding), 0 0;

        option {
            @include text-style(form-input);

            // Prevent Zoom Issue on touch devices
            @media (hover: none) and (pointer: coarse) {
                @include text-style('form-input-touch', null, null, false, true);
            }

            &:checked {
                background: rgba(16, 29, 64, 10%);
            }
        }

        &:focus {
            option {
                &:checked {
                    background: rgba(16, 29, 64, 10%);
                }
            }
        }
    }

    &::-ms-expand {
        display: none;
    }

    &:hover {
        border-color: var(--select-border-color-hover);
    }

    &:focus-visible {
        outline: 2px solid var(--select-focus);
        outline-offset: 2px;
    }

    &:disabled,
    &[aria-disabled='true'] {
        background-image: var(--select-background-image-disabled);
        color: var(--select-text-color-disabled);
        cursor: not-allowed;
    }

    &:disabled:hover,
    &[aria-disabled='true'] {
        border-color: var(--select-border-color);
    }

    option {
        font-weight: normal;
    }
}
