@use 'sass:map';
@use '@oecd/foundation/src/site/config/spacing-vars' as *;
@use '@oecd/util-a11y' as *;
@use '@oecd/util-breakpoint' as *;
@use '@oecd/util-distance' as d;
@use '@oecd/util-scrollbar' as *;
@use '@oecd/util-text-style' as *;

:root {
    --main-navigation-height: #{d.rem(66)};

    @include breakpoint-from(desktop) {
        --main-navigation-height: #{d.rem(80)};
    }
}

.root {
    position: relative;
}

.main-navigation {
    height: var(--main-navigation-height);
    inset-block-start: 0;
    inset-inline-end: 0;
    position: absolute;
    width: 100%;
    z-index: var(--z-index-main-navigation, 700);

    .page--without-header & {
        position: relative;
    }

    &:not(:has(.cmp-main-navigation)):has(.cmp-archive-main-navigation) {
        height: unset;
        position: relative;
    }
}

.cmp-main-navigation {
    --dropdown-menu-padding-block-end: #{map.get($spacing, s-64)};
    --dropdown-menu-padding-block-start: #{map.get($spacing, s-40)};
    --main-navigation-background: transparent;
    --main-navigation-border-bottom-color: var(--color-border-transparent);
    --main-navigation-cta-color: var(--color-text-primary);
    --main-navigation-cta-icon-dimension: #{d.rem(16)};
    --main-navigation-logo-color: var(--color-text-primary);
    --main-navigation-logo-width: #{d.rem(134)};
    --main-navigation-logo-width-mobile: #{d.rem(116)};
    --main-navigation-logo-width-two-logos: #{d.rem(114)};
    --main-navigation-logo-width-two-logos-tablet: #{d.rem(97)};
    --main-navigation-logo-width-two-logos-mobile: #{d.rem(56)};
    --main-navigation-logo-max-height-mobile: #{d.rem(32)};
    --main-navigation-logo-max-height: #{d.rem(64)};
    --main-navigation-inactive-items-opacity: 0.8;
    --main-navigation-current-dropdown-indicator: var(--color-primary);
    --main-navigation-dropdown-background: var(--color-background);
    --main-navigation-dropdown-max-height: #{d.rem(546)}; // 650 - padding 104
    --main-navigation-dropdown-min-height: #{d.rem(300)};

    // Mobile Navigation specific
    --mobile-navigation-max-width: #{d.rem(390)};
    --mobile-navigation-background: var(--color-background);
    --mobile-navigation-border-bottom-color: var(--color-border-transparent);
    --mobile-navigation-logo-color: var(--color-text-primary);
    --mobile-navigation-logo-width: #{d.rem(116)};
    --mobile-navigation-back-btn-size: #{d.rem(24)};
    --mobile-navigation-back-btn-icon-size: #{d.rem(16)};
    --mobile-navigation-back-btn-icon: var(--icon-chevron-right);
    --mobile-navigation-back-btn-icon-color: var(--color-text-primary);
    --mobile-navigation-close-color: var(--color-text-primary);
    --mobile-navigation-panel-background: var(--color-background);
    --mobile-navigation-panel-trigger-color: var(--color-icon);
    --mobile-navigation-panel-trigger-icon-color: var(--color-icon);
    --mobile-navigation-panel-trigger-icon-background-color: var(--color-background-light);
    --mobile-navigation-panel-trigger-icon: var(--icon-chevron-small);
    --mobile-navigation-panel-trigger-icon-size: #{d.rem(16)};
    --mobile-navigation-panel-trigger-icon-background-size: #{d.rem(24)};

    background-color: var(--main-navigation-background);
    border-bottom: solid 1px var(--main-navigation-border-bottom-color);
    border-top: solid 1px transparent;
    display: grid;
    grid-template-columns: [full-start] var(--grid-offset) [wrapper-start] minmax(0, var(--container-max-width)) [wrapper-end] var(--grid-offset) [full-end];

    @media (pointer: fine) {
        transition: background-color 0.15s ease-in-out;
        transition-delay: 0.3s;
    }

    &:focus-within {
        transition-delay: initial;
    }

    &:not(.js-language-popover-is-open .cmp-main-navigation, .js-mobile-menu-is-open .cmp-main-navigation) {
        @media (hover: hover) {
            &:hover {
                --main-navigation-background: var(--color-background);
                --main-navigation-cta-color: var(--color-text-primary);
                --main-navigation-logo-color: var(--color-text-primary);

                .cmp-language-switch {
                    --language-switch-trigger-color: var(--color-text-primary);
                    --language-switch-trigger-btn-background-color: var(--color-text-primary);
                    --language-switch-trigger-btn-color: var(--color-background);
                }
            }
        }

        &:focus-within {
            .cmp-language-switch {
                &__trigger {
                    transition-delay: initial;
                }
            }

            .cmp-main-navigation__menu-trigger,
            .cmp-main-navigation__item,
            .cmp-main-navigation__cta,
            .cmp-main-navigation__logo {
                transition-delay: initial;
            }

            .js-user-is-tabbing & {
                @media (pointer: fine) {
                    --main-navigation-background: var(--color-background);
                    --main-navigation-cta-color: var(--color-text-primary);
                    --main-navigation-logo-color: var(--color-text-primary);

                    .cmp-language-switch {
                        --language-switch-trigger-color: var(--color-text-primary);
                        --language-switch-trigger-btn-background-color: var(--color-text-primary);
                        --language-switch-trigger-btn-color: var(--color-background);
                    }
                }
            }
        }
    }

    .js-user-is-tabbing & {
        .cmp-language-switch {
            &__trigger {
                transition-delay: initial;
            }
        }

        .cmp-main-navigation__menu-trigger,
        .cmp-main-navigation__item,
        .cmp-main-navigation__cta,
        .cmp-main-navigation__logo {
            transition-delay: initial;
        }
    }

    .js-dropdown-menu-is-open & {
        --main-navigation-background: var(--color-background);
        --main-navigation-cta-color: var(--color-text-primary);
        --main-navigation-logo-color: var(--color-text-primary);

        .cmp-language-switch {
            --language-switch-trigger-color: var(--color-text-primary);
            --language-switch-trigger-btn-background-color: var(--color-text-primary);
            --language-switch-trigger-btn-color: var(--color-background);
        }
    }

    &__wrapper {
        align-items: center;
        display: flex;
        grid-column: wrapper;
    }

    &__logo-wrapper {
        align-items: center;
        display: flex;
        gap: map.get($spacing, s-16);
        margin-right: auto;

        .cmp-main-navigation__logo {
            flex: 0 0 auto;

            svg {
                width: var(--main-navigation-logo-width-two-logos-mobile);

                @include breakpoint-from(tablet) {
                    width: var(--main-navigation-logo-width-two-logos-tablet);
                }

                @include breakpoint-from(desktop) {
                    width: var(--main-navigation-logo-width-two-logos);
                }
            }
        }
    }

    &__logo {
        color: var(--main-navigation-logo-color);
        margin-right: auto;
        transition: color 0.15s linear 0.3s;

        &-icon {
            display: block;
            line-height: 0;

            svg {
                height: auto;
                max-height: var(--main-navigation-logo-max-height-mobile);
                width: var(--main-navigation-logo-width-mobile);

                @include breakpoint-from(tablet) {
                    max-height: var(--main-navigation-logo-max-height);
                }

                // On desktop and larger, the logo is larger
                @include breakpoint-from(desktop-small) {
                    width: var(--main-navigation-logo-width);
                }
            }
        }

        &-text {
            @include visually-hidden;
        }

        &:is(a):hover,
        &:is(a):focus {
            --main-navigation-logo-color: var(--color-primary);

            transition-delay: initial;
        }

        &:is(a):focus {
            outline: none;
        }

        &:is(a):focus-visible {
            outline: 2px solid var(--color-focus-outline);
        }
    }

    &__nav {
        @include breakpoint-until(desktop) {
            display: none;
        }

        @include breakpoint-from(desktop) {
            display: flex;
        }
    }

    &__trigger {
        @include breakpoint-from(desktop) {
            display: none;
        }
    }

    &__menu {
        display: flex;
    }

    &__item {
        color: var(--main-navigation-cta-color);
        position: relative;
        text-decoration: none;
        transition: color 0.15s linear 0.3s;

        &:hover {
            --main-navigation-cta-color: var(--color-primary);

            transition-delay: initial;
        }

        &:focus-visible {
            --main-navigation-cta-color: var(--color-primary);

            outline: 2px solid var(--color-focus-outline);

            // Negative value for outline offset other wise focus indicator is cutt off by dropdown
            outline-offset: -2px;
            transition-delay: initial;
        }

        @include breakpoint-from(desktop) {
            @include text-style(label, 2, b, 1);

            display: block;
            padding: map.get($spacing, s-32) map.get($spacing, s-16);
        }

        // Active indicator width needs to be equal to the trigger text hence 100% minus inline padding
        // Left offset is equal to inline padding
        &::before {
            border-bottom: 2px solid var(--main-navigation-current-dropdown-indicator);
            content: '';
            display: block;
            inset-block-end: 0;
            inset-inline-start: map.get($spacing, s-16);
            opacity: 0;
            position: absolute;
            transition: opacity 0.15s linear;
            width: calc(100% - map.get($spacing, s-32));
        }
    }

    &__menu--mixed-types {
        .cmp-main-navigation__dropdown-trigger {
            padding-inline-end: d.rem(28);

            &::after {
                background: currentcolor;
                content: '';
                height: d.rem(16);
                inset-block: 50%;
                inset-inline-end: d.rem(12);
                mask-image: var(--icon-chevron-small);
                mask-position: center;
                mask-repeat: no-repeat;
                mask-size: contain;
                position: absolute;
                transform: translateY(-50%) rotate(90deg);
                transition: transform 0.15s linear;
                width: d.rem(16);
            }

            &[aria-expanded='true'] {
                &::after {
                    transform: translateY(-50%) rotate(-90deg);
                }
            }
        }

        .js-dropdown-is-open {
            .cmp-main-navigation__dropdown-trigger {
                &::after {
                    transform: translateY(-50%) rotate(-90deg);
                }

                &::before {
                    width: calc(100% - #{d.rem(32)});
                }
            }
        }
    }

    &__dropdown-menu {
        background: var(--main-navigation-dropdown-background);
        display: grid;
        grid-template-columns: [full-start] var(--grid-offset) [wrapper-start] minmax(0, var(--container-max-width)) [wrapper-end] var(--grid-offset) [full-end];
        inset-block-start: calc(var(--main-navigation-height) - 1px);
        inset-inline-start: 0;
        min-height: var(--main-navigation-dropdown-min-height);
        opacity: 0;
        padding-block: var(--dropdown-menu-padding-block-start) var(--dropdown-menu-padding-block-end);
        position: absolute;
        transition: visibility 0.19s linear 0.2s, opacity 0.19s linear 0.2s;
        visibility: hidden;
        width: 100%;
        z-index: -1;

        .js-dropdown-menu-is-open & {
            transition: visibility 0.19s linear 0s, opacity 0.19s linear 0s;
        }

        > .cmp-container {
            grid-column: wrapper;

            > .dropdown {
                max-height: var(--main-navigation-dropdown-max-height);
                overflow-y: auto;

                @include custom-scrollbars;
            }
        }
    }

    // Make active indicator visible
    .js-dropdown-is-open .cmp-main-navigation__dropdown-trigger,
    .cmp-main-navigation__dropdown-trigger[aria-expanded='true'] {
        --main-navigation-cta-color: var(--color-primary);

        &::before {
            opacity: 1;
        }
    }

    // Make Active Dropdown visible
    .js-dropdown-is-open .cmp-main-navigation__dropdown-menu,
    .cmp-main-navigation__dropdown-trigger[aria-expanded='true']+.cmp-main-navigation__dropdown-menu {
        opacity: 1;
        transition: opacity 0.15s ease-in-out;
        visibility: visible;
        z-index: var(--z-index-main-navigation-dropdown, 600);
    }

    &__menu-trigger {
        color: var(--main-navigation-cta-color);
        line-height: 0;
        margin-inline-end: -#{map.get($spacing, s-12)};
        padding: map.get($spacing, s-24) map.get($spacing, s-12);
        transition: color 0.15s ease-in-out 0.3s;

        @include breakpoint-from(desktop) {
            display: none;
        }

        &:hover {
            --main-navigation-cta-color: var(--color-primary);
        }

        &:focus-visible {
            --main-navigation-cta-color: var(--color-primary);

            outline: 2px solid var(--color-focus-outline);
        }

        &-icon {
            vertical-align: middle;
        }

        &-text {
            @include visually-hidden;
        }
    }

    &__cta-wrapper {
        align-items: center;
        display: flex;
    }

    &__cta {
        color: var(--main-navigation-cta-color);
        padding: map.get($spacing, s-24) map.get($spacing, s-12);
        text-decoration: none;
        transition: color 0.15s ease-in-out 0.3s;

        &:hover,
        &:focus {
            --main-navigation-cta-color: var(--color-primary);

            transition-delay: initial;
        }

        &:focus-visible {
            outline: 2px solid var(--color-focus-outline);
        }

        &-icon {
            display: block;
            height: var(--main-navigation-cta-icon-dimension);
            overflow: hidden;
            width: var(--main-navigation-cta-icon-dimension);

            svg {
                display: block;
                height: auto;
                width: var(--main-navigation-cta-icon-dimension);
            }
        }

        &-text {
            @include visually-hidden;
        }
    }
}

// backdrop when hovering over menu
.js-main-navigation-hover-backdrop {
    background-color: var(--color-background-backdrop);
    content: '';
    display: block;
    height: 100%;
    inset-block-start: 0;
    inset-inline-start: 0;
    max-height: 0;
    opacity: 0;
    position: absolute;
    transition: opacity 0.19s linear 0.2s, visibility 0.43s linear 0.44s, max-height linear 0.43ms 0.44s;
    visibility: hidden;
    width: 100%;
    will-change: opacity;
    z-index: var(--z-index-main-navigation-hover-backdrop, 650);

    .js-dropdown-menu-is-open & {
        max-height: 100%;
        opacity: 1;
        transition: opacity 0.19s linear 0.2s;
        visibility: visible;
    }
}

// make sure animation is not flickering
.js-user-is-tabbing.js-dropdown-menu-is-open .cmp-main-navigation__menu:focus-within::before {
    min-height: var(--main-navigation-dropdown-min-height);
    opacity: 1;
}

.js-dropdown-menu-is-open {
    .cmp-main-navigation__menu {
        &:hover {
            &::before {
                min-height: var(--main-navigation-dropdown-min-height);
                opacity: 1;
            }
        }
    }
}
