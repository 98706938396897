@use 'sass:map';
@use '@oecd/util-a11y' as *;
@use '@oecd/foundation/src/site/config/spacing-vars' as *;
@use 'typography-vars' as *;
@use '@oecd/util-distance' as d;
@use '@oecd/util-list' as *;
@use '@oecd/util-text-style' as *;

.cmp-table {
    --table-background-color: var(--color-background);
    --table-border-color: var(--color-border);
    --table-caption-background: var(--color-background-inverse);
    --table-caption-text-color: var(--color-text-primary-inverse);
    --table-header-background-color: var(--color-background-light);
    --table-layout: auto;
    --table-list-marker-color: var(--color-text-secondary-transparent);
    --table-section-header-background-color: var(--color-background-inverse);
    --table-section-header-text-color: var(--color-text-primary-inverse);
    --table-shadow-color: var(--table-background-color);
    --table-shadow-size: #{d.rem(32)};
    --table-sub-header-background-color: var(--color-background-extremely-light);
    --table-text-color: var(--color-text-table);
    --table-width: 100%;

    overflow: hidden;
    overflow-wrap: anywhere;
    position: relative;
    width: 100%;

    &.js-has-overflow {
        -webkit-overflow-scrolling: auto;

        .cmp-table__wrapper {
            overflow-x: scroll;
        }
    }

    &.js-has-shadow-left::before,
    &.js-has-shadow-right::after {
        background: radial-gradient(at 0 50%, var(--table-shadow-color), transparent 70%),
            linear-gradient(90deg, var(--table-shadow-color), transparent 50%);
        background-repeat: no-repeat;
        background-size: var(--table-shadow-size) 100%;
        content: '';
        display: block;
        inset-block: 0;
        pointer-events: none;
        position: absolute;
        transition: opacity 0.2s;
        width: var(--table-shadow-size);
        z-index: 1;
    }

    &.js-has-shadow-right::after {
        inset-inline-end: 0;
        transform: rotate(180deg);
    }

    &.js-has-shadow-left::before {
        inset-inline-start: 0;
    }

    table {
        background-color: var(--table-background-color);
        border: none;
        border-collapse: collapse;
        border-spacing: 0;
        table-layout: var(--table-layout);
        width: var(--table-width);
    }

    caption {
        @include text-style('label', 2, b, false, true);

        background: var(--table-caption-background);
        border: 1px solid var(--table-caption-background);
        color: var(--table-caption-text-color);
        padding: map.get($spacing, s-8);
        text-align: left;

        &.sr-only {
            @include visually-hidden;
        }

        &.cell-align-center {
            text-align: center;
        }
    }

    th,
    td {
        background: var(--table-background);
        border: 1px solid var(--table-border-color);
        color: var(--table-text-color);
        padding: map.get($spacing, s-8);
        text-align: left;
        vertical-align: top;

        // Utility classes
        &.cell-nowrap {
            white-space: nowrap;
        }

        &.cell-align-center {
            text-align: center !important;
        }

        &.cell-align-left {
            text-align: left !important;
        }

        &.cell-v-align-center {
            vertical-align: middle !important;
        }

        &.cell-no-bg {
            background: var(--table-background-color) !important;
        }

        &.cell-bg-header {
            background: var(--table-header-background-color) !important;
        }

        &.cell-bg-sub-header {
            background: var(--table-sub-header-background-color) !important;
        }
    }

    th {
        @include text-style('label', 2, b, false, true);

        background-color: var(--table-header-background-color);
        text-align: left;

        &[scope='col'] {
            vertical-align: middle;
        }

        &.table-section-header {
            background-color: var(--table-section-header-background-color);
            border-color: var(--table-section-header-background-color);
            color: var(--table-section-header-text-color);
        }

        &.table-column-header,
        &.table-row-header {
            @include text-style('label', 2, null, false, true);
        }

        &.table-sub-header {
            @include text-style('label', 2, m, false, true);

            background-color: var(--table-sub-header-background-color);
        }

        &.table-sub-header-level-1,
        &.table-sub-header-level-2 {
            @include text-style('label', 2, null, false, true);

            background-color: var(--table-background);
        }

        &.table-sub-header-level-1 {
            padding-left: map.get($spacing, s-24);
        }

        &.table-sub-header-level-2 {
            padding-left: map.get($spacing, s-48);
        }
    }

    td {
        @include text-style('label', 2, null, false, true);

        &:first-child.is-blank {
            border-left: 0;
            border-top: 0;
        }
    }

    // Style lists within table cells
    @include styled-unordered-list(var(--table-list-marker-color));
    @include styled-ordered-list(var(--table-list-marker-color));
}
