@use 'sass:map';
@use '@oecd/foundation/src/site/config/spacing-vars' as *;
@use '@oecd/util-distance' as d;

.chart-with-controls {
    container-name: chart-with-controls;
    container-type: inline-size;
}

.cmp-chart-with-controls {
    --chart-with-controls-background-color: var(--color-background);
    --chart-with-controls-border-color: var(--color-border);
    --chart-with-controls-border-radius: #{d.rem(8)};
    --chart-with-controls-padding: 2px;
    --chart-with-controls-control-min-height: #{d.rem(80)}; // This is the height of the currently implemented chart controls

    background-color: var(--chart-with-controls-background-color);
    border: 1px solid var(--chart-with-controls-border-color);
    border-radius: var(--chart-with-controls-border-radius);
    width: 100%;

    &__controls {
        border-bottom: 1px solid var(--chart-with-controls-border-color);
        margin-block-end: map.get($spacing, s-16);

        .cmp-chart-control {
            padding-block: map.get($spacing, s-8);
        }

        &:not(.cmp-chart-with-controls__controls:has(.cmp-chart-control)) {
            border-bottom: 0;
            margin-block-end: map.get($spacing, s-16);
        }

        .chart-control:has(.cmp-chart-control) {
            min-height: var(--chart-with-controls-control-min-height);
        }

        .chart-control + .chart-control {
            margin-block-start: 0;
        }

        @container chart-with-controls (max-width: 699px) {
            &:has(.cmp-chart-control) {
                padding: map.get($spacing, s-16);
            }

            .chart-control:has(.cmp-chart-control):not(.chart-control:last-child) {
                margin-block-end: map.get($spacing, s-8);
            }
        }

        @container chart-with-controls (min-width: 700px) {
            display: flex;

            .chart-control {
                flex: 1 0 50%;
                max-width: 50%;
                padding: map.get($spacing, s-16);

                &:not(:has(.cmp-chart-control)) {
                    flex: 0 1 auto;
                    max-width: none;
                    padding: 0;

                    + .chart-control {
                        border-left: 0;
                    }
                }
            }

            .chart-control + .chart-control {
                border-left: 1px solid var(--chart-with-controls-border-color);
                margin-block-start: 0;

                &:not(:has(.cmp-chart-control)) {
                    border-left: 0;
                }
            }
        }
    }

    .cmp-chart {
        border: 0;
    }
}
