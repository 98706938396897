@use 'sass:map';
@use '@oecd/foundation/src/site/config/spacing-vars' as *;
@use '@oecd/util-breakpoint' as *;
@use '@oecd/util-distance' as d;
@use '@oecd/util-focus' as *;
@use '@oecd/util-text-style' as *;

.cmp-image {
    --caption-color: var(--color-text-secondary);
    --caption-margin-block-start: #{map.get($spacing, s-8)};

    &__image {
        height: auto;

        @media (prefers-reduced-motion: no-preference) {
            transform: scale(1);
            transition-duration: 0.75s;
            transition-property: transform;
            transition-timing-function: cubic-bezier(0.4, 0, 0.4, 1);
            will-change: transform;
        }
    }

    &__link {
        display: block;
        overflow: hidden;
        position: relative;

        &:hover,
        &:focus {
            .cmp-image__image {
                @media (prefers-reduced-motion: no-preference) {
                    transform: scale(1.025);
                }
            }
        }

        &:focus-visible {
            @include keyboardfocus;
        }
    }

    &__title {
        color: var(--caption-color);
        margin-top: var(--caption-margin-block-start);

        @include text-style(caption, null, null, false, true);
    }
}

// Reduce the width of an image based on the container it is placed in
// See container css for different behaviour
// To DO:
// - PT STORY -> How will we tackle the logo's or more in general images that are not allowed to taken 100% of the parents width
.image {
    &--reduced-width {
        @include breakpoint-until(tablet) {
            margin-inline: auto;
            max-width: d.rem(395); // 395 equals 4 columns on desktop
            width: 100%;
        }
    }

    &--focalpoint {
        $object-positions: (
            'center-bottom': center bottom,
            'center-center': center center,
            'center-top': center top,
            'left-bottom': left bottom,
            'left-center': left center,
            'left-top': left top,
            'right-bottom': right bottom,
            'right-center': right center,
            'right-top': right top,
        );

        @each $class-part, $object-position in $object-positions {
            &-#{$class-part} {
                .cmp-image {
                    &__image {
                        object-position: $object-position !important;
                    }
                }
            }
        }
    }
}
