@use 'sass:map';
@use '@oecd/foundation/src/site/config/spacing-vars' as *;
@use '@oecd/util-a11y' as *;
@use '@oecd/util-distance' as d;
@use '@oecd/util-focus' as *;
@use '@oecd/util-scrollbar' as *;

.cmp-facets-search-combobox {
    --facets-search-combobox-background-color: var(--color-form-input);
    --facets-search-combobox-background-color-active: var(--color-form-input-inverse);
    --facets-search-combobox-border-color: var(--facets-search-combobox-background-color);
    --facets-search-combobox-border-color-active: var(--facets-search-combobox-background-color-active);
    --facets-search-combobox-border-radius: var(--form-input-border-radius, 4px);
    --facets-search-combobox-box-shadow: 0 0 #{d.rem(36)} 0 rgba(0, 0, 0, 10%);
    --facets-search-combobox-dropdown-max-height: #{d.rem(264)};
    --facets-search-combobox-icon-color: var(--color-text-primary);
    --facets-search-combobox-icon-mask-image: url("data:image/svg+xml, %3Csvg%20width=%2716%27%20height=%2716%27%20viewBox=%270%200%2016%2016%27%20fill=%27none%27%20xmlns=%27http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%27%3E%3Cpath%20d=%27m13.369%2014.783.707.707%201.414-1.414-.707-.707-1.414%201.414zm-1.814-4.642a1%201%200%200%200-1.414%201.414l1.414-1.414zM6.77%2011.538A4.769%204.769%200%200%201%202%206.768H0a6.769%206.769%200%200%200%206.769%206.77v-2zM2%206.768A4.769%204.769%200%200%201%206.769%202V0A6.769%206.769%200%200%200%200%206.769h2zM6.769%202a4.769%204.769%200%200%201%204.769%204.769h2A6.769%206.769%200%200%200%206.768%200v2zm4.769%204.769a4.769%204.769%200%200%201-4.77%204.769v2a6.769%206.769%200%200%200%206.77-6.77h-2zm3.245%206.6-3.228-3.228-1.414%201.414%203.228%203.228%201.414-1.414z%27%20fill=%27currentColor%27%2F%3E%3C%2Fsvg%3E"); // stylelint-disable-line string-quotes
    --facets-search-combobox-icon-size-height: #{d.rem(16)};
    --facets-search-combobox-icon-size-width: #{d.rem(16)};
    --facets-search-combobox-outline-color: var(--color-primary);
    --facets-search-combobox-padding: #{map.get($spacing, s-16)};
    --facets-search-combobox-placeholder-color: var(--color-text-primary);

    display: block;
    margin-block-end: map.get($spacing, s-16);
    position: relative;

    form {
        align-items: center;
        border-radius: var(--facets-search-combobox-border-radius);
        display: flex;
        position: relative;

        &:has(input[aria-expanded='true']) {
            z-index: 3;
        }

        &::before {
            background-color: var(--facets-search-combobox-icon-color);
            content: '';
            display: block;
            height: var(--facets-search-combobox-icon-size-height);
            inset-inline-end: var(--facets-search-combobox-padding);
            mask-image: var(--facets-search-combobox-icon-mask-image);
            mask-position: center;
            mask-repeat: no-repeat;
            mask-size: contain;
            position: absolute;
            width: var(--facets-search-combobox-icon-size-width);

            @media screen and (forced-colors: active) {
                background: CanvasText;
            }
        }

        label {
            @include visually-hidden;
        }

        input[type='search'] {
            border-color: var(--facets-search-combobox-border-color);
            padding: var(--facets-search-combobox-padding);
            width: 100%;

            &::placeholder {
                color: var(--facets-search-combobox-placeholder-color);
            }

            &::-webkit-search-clear-button {
                appearance: none;
                display: none;
            }

            &::-webkit-search-clear-button,
            &::-webkit-search-cancel-button {
                appearance: none;
                background-color: var(--searchbox-reset-icon-color);
                cursor: pointer;
                display: inline-block;
                height: var(--searchbox-reset-icon-dimension);
                mask-image: var(--icon-close);
                mask-position: center;
                mask-repeat: no-repeat;
                mask-size: contain;
                width: var(--searchbox-reset-icon-dimension);

                @media screen and (forced-colors: active) {
                    background: CanvasText;
                }
            }
        }
    }

    &__options {
        background-color: var(--facets-search-combobox-background-color-active);
        border-block-start: var(--facets-search-combobox-border-radius) solid var(--facets-search-combobox-background-color-active);
        border-end-end-radius: var(--facets-search-combobox-border-radius);
        border-end-start-radius: var(--facets-search-combobox-border-radius);
        box-shadow: var(--facets-search-combobox-box-shadow);
        display: none;
        margin-block-start: calc(var(--facets-search-combobox-border-radius) * -1);
        max-height: var(--facets-search-combobox-dropdown-max-height);
        overflow-y: auto;
        padding: map.get($spacing, s-16);
        position: absolute;
        width: 100%;
        z-index: 2;

        @include custom-scrollbars;
    }

    &__option {
        + .cmp-facets-search-combobox__option {
            margin-block-start: map.get($spacing, s-16);
        }
    }

    &.cmp-facets-search-combobox--expanded {
        box-shadow: var(--facets-search-combobox-box-shadow);

        input[type='search'] {
            background-color: var(--facets-search-combobox-background-color-active);
            border-color: var(--facets-search-combobox-border-color-active);

            &:focus-visible {
                border-color: var(--facets-search-combobox-outline-color);
            }
        }

        .cmp-facets-search-combobox__options {
            display: block;
        }
    }
}
