@use 'sass:map';
@use 'sass:math';
@use '@oecd/util-breakpoint' as *;
@use '@oecd/foundation/src/site/config/spacing-vars' as *;

.l-grid {
    display: grid;
    grid-template-columns: [full-start] var(--grid-offset) [wrapper-start] minmax(0, var(--container-max-width)) [wrapper-end] var(--grid-offset) [full-end];

    > :is(.cmp-container) {
        column-gap: var(--grid-gap);
        display: grid;
        grid-column: wrapper;
        grid-template-columns: repeat(12, 1fr);
    }

    > :where(.cmp-container)> :where(*) {
        grid-column: 1 / span 12;
    }
}

// Temporary declaration for demo until headers are implemented
main {
    padding-block-end: map.get($spacing, s-64);

    @include breakpoint-from(desktop-small) {
        padding-block-end: map.get($spacing, s-96);
    }
}
