@use 'sass:map';
@use 'colors' as colors;
@use 'theme-config' as themeConfig;

// When a theme opts out of the themed navigation: the navigation always has the background of the page
@if map.get(themeConfig.$theme-config, navigation-themes-opt-out) {
    .cmp-main-navigation {
        --main-navigation-background: var(--color-background);
    }
} @else {
    .cmp-main-navigation {
        .theme-shout:not(.js-dropdown-menu-is-open) & {
            --main-navigation-logo-color: var(--color-background);
            --main-navigation-cta-color: var(--color-icon-inverse);
            --main-navigation-border-bottom-color: var(--color-border-transparent-inverse);

            .cmp-language-switch {
                --language-switch-trigger-color: var(--color-background);
                --language-switch-trigger-btn-background-color: var(--color-background);
                --language-switch-trigger-btn-color: var(--color-text-primary);
            }

            --main-navigation-inactive-items-opacity: 0.4;
        }

        // Dark Themes
        @each $theme-color in colors.$dark-theme-colors {
            .theme-#{$theme-color}:not(.js-dropdown-menu-is-open) & {
                --main-navigation-background: var(--color-theme-#{$theme-color}-background);
                --main-navigation-logo-color: var(--color-text-primary-inverse);
                --main-navigation-cta-color: var(--color-theme-#{$theme-color}-text);
                --main-navigation-border-bottom-color: var(--color-border-transparent-inverse);

                .cmp-language-switch {
                    --language-switch-trigger-color: var(--color-theme-#{$theme-color}-text);
                    --language-switch-trigger-btn-background-color: var(--color-theme-#{$theme-color}-text);
                    --language-switch-trigger-btn-color: var(--color-theme-#{$theme-color}-background);
                }

                --main-navigation-inactive-items-opacity: 0.4;
            }
        }


        // Light Themes
        @each $theme-color in colors.$light-theme-colors {
            .theme-#{$theme-color}:not(.js-dropdown-menu-is-open, .js-language-popover-is-open) & {
                --main-navigation-background: var(--color-theme-#{$theme-color}-background);
                --main-navigation-logo-color: var(--color-text-primary);
                --main-navigation-cta-color: var(--color-theme-#{$theme-color}-text);
                --main-navigation-border-bottom-color: var(--color-border-transparent);

                .cmp-language-switch {
                    --language-switch-trigger-color: var(--color-theme-#{$theme-color}-text);
                    --language-switch-trigger-btn-background-color: var(--color-theme-#{$theme-color}-text);
                    --language-switch-trigger-btn-color: var(--color-theme-#{$theme-color}-background);
                }

                --main-navigation-inactive-items-opacity: 0.8;
            }
        }
    }
}
