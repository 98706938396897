@use 'sass:map';
@use '@oecd/foundation/src/site/config/spacing-vars' as *;
@use '@oecd/util-breakpoint' as *;
@use '@oecd/util-scrollbar' as *;

// The report container has divergent behaviour
// From mobile until desktop-small it behaves like a narrow container cfr Article Template
// From desktop-small onwards it behaves as a two column container

.cmp-report-content-container {
    grid-column: wrapper;

    @include breakpoint-from(desktop-small) {
        column-gap: var(--grid-gap);
        display: grid;
        grid-template-columns: repeat(12, 1fr);
        height: 100%;
        position: relative;
    }

    &__navigation {
        --report-navigation-sticky-offset: calc(var(--content-language-picker-height, 0px) + var(--chart-control-bar-height, 0px) + var(--jumplinks-height, 0px) + var(--secondary-navigation-height, 0px) + #{map.get($spacing, s-32)});

        display: none;

        @include breakpoint-from(desktop-small) {
            display: block;
            grid-column: 1 / span 4;
            padding: 4px; // Padding to compensate for negative margin on the report navigation

            @supports (position: sticky) or (position: -webkit-sticky) {
                inset-block-start: var(--report-navigation-sticky-offset);
                max-height: calc(100vh - var(--report-navigation-sticky-offset));
                overflow-y: auto;
                position: sticky;

                @include custom-scrollbars;

                @media (prefers-reduced-motion: no-preference) {
                    transition: inset-block-start 0.15s linear;
                }

                .js-is-scrolling-down & {
                    --report-navigation-sticky-offset: calc(var(--content-language-picker-height, 0px) + var(--chart-control-bar-height, 0px) + var(--jumplinks-height, 0px) + #{map.get($spacing, s-32)});
                }

                .js-is-scrolling-up & {
                    --report-navigation-sticky-offset: calc(var(--secondary-navigation-height, 0px) + #{map.get($spacing, s-32)});
                }
            }
        }
    }

    &__content {
        --layout-spacing-default: var(--layout-spacing-prose);

        @include breakpoint-between(mobile-large,desktop-small) {
            > .container > .cmp-container {
                column-gap: var(--grid-gap);
                display: grid;
                grid-template-columns: repeat(12, 1fr);

                > *:not(.chart-control-bar) {
                    grid-column: 2 / span 10;
                }

                > .image:not(.image--reduced-width) {
                    margin-inline: 0;

                    > * {
                        grid-column: 1 / span 12;
                    }
                }
            }
        }

        @include breakpoint-from(desktop-small) {
            grid-column: 5 / span 8;

            > .container > .cmp-container {
                column-gap: var(--grid-gap);
                display: grid;
                grid-template-columns: repeat(8, 1fr);

                > *:not(.image, .embed, .list, .teaser, .chart, .featured-card, .highlight-container, .descriptive-asset, .two-column-container) {
                    grid-column: 1 / span 6;
                }

                > .image,
                > .embed,
                > .list,
                > .teaser,
                > .chart,
                > .featured-card,
                > .highlight-container,
                > .descriptive-asset,
                > .two-column-container {
                    grid-column: 1 / span 8;
                }

                > .highlight-container--content-wide {
                    grid-column: 1 / span 6;
                }

                > .cmp-form-container {
                    grid-column: 1 / span 4;
                }

                > .image:not(.image--reduced-width) {
                    margin-inline: 0;

                    > * {
                        grid-column: 1 / span 12;
                    }
                }

                > .image--reduced-width {
                    grid-column: 2 / span 4;
                }
            }
        }
    }
}
