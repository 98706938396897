@use 'sass:map';
@use 'sass:math';
@use '@oecd/util-breakpoint' as *;
@use '@oecd/util-distance' as d;
@use 'spacing-vars' as *;

:root {
    --scrollbar-width: 0;
    --viewport-width: calc(100vw - var(--scrollbar-width));
    --container-max-width: #{d.rem(1248)};
    --grid-gap: #{map.get($spacing, s-24)};

    @include breakpoint-from(tablet) {
        --grid-gap: #{map.get($spacing, s-16)};
    }

    @include breakpoint-from(desktop-small) {
        --grid-gap: #{map.get($spacing, s-24)};
    }

    @include breakpoint-from(desktop) {
        --grid-gap: #{map.get($spacing, s-32)};
    }

    --grid-offset: #{map.get($spacing, s-24)};

    @include breakpoint-from(desktop) { // 1248px + guttersize * 2
        --grid-offset: 1fr;
    }

    // Layout spacing
    --layout-spacing-default: #{map.get($spacing, s-48)}; // 48px to be used for spacing between "rows"
    --layout-spacing-prose: #{map.get($spacing, s-32)}; // 32px to be used for spacing between "rows"

    @include breakpoint-from(tablet) {
        --layout-spacing-default: #{map.get($spacing, s-80)};
        --layout-spacing-prose: #{map.get($spacing, s-64)};
    }

    // content offset
    --content-offset: #{map.get($spacing, s-48)};

    @include breakpoint-from(tablet) {
        --content-offset: #{map.get($spacing, s-80)};
    }
}

.page--without-header {
    @include breakpoint-from(tablet) {
        --content-offset: #{map.get($spacing, s-64)};
    }
}
