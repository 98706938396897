@use 'sass:map';
@use '@oecd/foundation/src/site/config/spacing-vars' as *;
@use '@oecd/util-a11y' as *;
@use '@oecd/util-breakpoint' as *;
@use '@oecd/util-distance' as d;
@use '@oecd/util-scrollbar' as *;
@use '@oecd/util-text-style' as *;

.cmp-overlay {
    --overlay-border-radius: #{d.rem(4)};
    --overlay-background: var(--color-background);
    --overlay-max-width: #{d.rem(716)};
    --overlay-margin: #{map.get($spacing, s-48)};
    --overlay-close-btn-color: var(--color-icon-secondary);
    --overlay-close-btn-color-hover: var(--color-icon-accent);
    --overlay-close-btn-icon: var(--icon-close);
    --overlay-close-btn-icon-size: #{d.rem(16)};
    --overlay-close-btn-outline-color: var(--color-focus-outline);
    --overlay-close-btn-padding: #{map.get($spacing, s-24)};
    --overlay-gradient-cover-height: #{map.get($spacing, s-48)};
    --overlay-gradient-shadow-height: #{map.get($spacing, s-24)};
    --overlay-background-rgb: 255, 255, 255;
    --overlay-shadow-rgb: 16, 29, 64;
    --overlay-gradient-cover: rgb(var(--overlay-background-rgb)); // stylelint-disable-line color-function-notation
    --overlay-gradient-cover-transparent: rgba(var(--overlay-background-rgb), 0);
    --overlay-gradient-shadow: rgba(var(--overlay-shadow-rgb), 0.1);
    --overlay-gradient-shadow-transparent: rgba(var(--overlay-shadow-rgb), 0);

    background-color: var(--overlay-background);
    border: solid 1px var(--color-border);
    color: var(--color-text-secondary);
    display: flex;
    flex-direction: column;
    width: 100%;

    @include breakpoint-until(tablet) {
        height: 100vh;
    }

    @include breakpoint-from(tablet) {
        border-radius: var(--overlay-border-radius);
        max-height: calc(100vh - var(--overlay-margin));
        width: var(--overlay-max-width);
    }

    &__header {
        align-items: center;
        border-bottom: 1px solid var(--color-border);
        display: flex;
        justify-content: flex-end;
        padding-inline-start: map.get($spacing, s-24);
    }

    &__title {
        flex: 1 1 auto;
        margin: 0;
        padding-block: map.get($spacing, s-24);
        text-transform: none;

        @include text-style('body', 2);
    }

    &__close-btn {
        color: var(--color-icon);
        flex: 0 0 auto;
        padding: var(--overlay-close-btn-padding) var(--overlay-close-btn-padding);
        position: relative;

        @media (prefers-reduced-motion: no-preference) {
            transition-duration: 250ms;
            transition-property: color;
            transition-timing-function: cubic-bezier(0.4, 0, 0.4, 1);
        }

        &:hover {
            color: var(--overlay-close-btn-color-hover);
        }

        &:focus {
            outline: none;
        }

        &:focus-visible {
            color: var(--overlay-close-btn-color-hover);
            outline: 2px solid var(--overlay-close-btn-outline-color);
        }

        &::before {
            background-color: currentcolor;
            content: '';
            display: inline-block;
            height: var(--overlay-close-btn-icon-size);
            mask-image: var(--overlay-close-btn-icon);
            mask-position: center;
            mask-repeat: no-repeat;
            mask-size: contain;
            width: var(--overlay-close-btn-icon-size);

            @media screen and (forced-colors: active) {
                background: ButtonText;
            }
        }

        &-txt {
            @include visually-hidden;
        }
    }

    &__content {
        // Set a shadow on the overlay content when the user scrollsShadow Cover TOP */
        // First background lies on top of the shadow and has the same color as the modal background
        // It has background attachment local so it scrolls along with the content
        // Second background image is the actual shadow
        // CSS magic lies in the background attachment scroll
        background:
        linear-gradient(to bottom, var(--overlay-gradient-cover) 0%, var(--overlay-gradient-cover-transparent) 100%),
        linear-gradient(to bottom, var(--overlay-gradient-shadow) 0%, rgba(var(--overlay-shadow-rgb), 0%) 100%),
        linear-gradient(to top, var(--overlay-gradient-cover) 0%, var(--overlay-gradient-cover-transparent) 100%) center bottom,
        linear-gradient(to top, var(--overlay-gradient-shadow) 0%, rgba(var(--overlay-shadow-rgb), 0%) 100%) center bottom;
        background-attachment: local, scroll, local, scroll;
        background-repeat: no-repeat;
        background-size: 100% var(--overlay-gradient-cover-height), 100% var(--overlay-gradient-shadow-height), 100% var(--overlay-gradient-cover-height), 100% var(--overlay-gradient-shadow-height);
        flex: 1;
        min-height: 0;
        overflow-y: auto;
        padding-inline: map.get($spacing, s-24);

        // Scrollbar gutter auto otherwise there is a scroll gradient artefact
        @include custom-scrollbars($scrollbar-gutter: auto);

        > .cmp-container {
            padding-block: map.get($spacing, s-24) map.get($spacing, s-40);

            > :where(* + *) {
                margin-block-start: map.get($spacing, s-32);
            }

            > :where(oecd-control + oecd-control) { /* stylelint-disable-line selector-type-no-unknown */
                margin-block-start: map.get($spacing, s-24);
            }

            // Reset top margin for all components following a title or title and link component
            > :where(.title + *) {
                margin-block-start: 0;
            }
        }
    }

    &__actions {
        align-items: center;
        border-top: 1px solid var(--color-border);
        display: flex;
        flex-direction: row-reverse;
        gap: map.get($spacing, s-8);
        padding-block: map.get($spacing, s-8);
        padding-inline: map.get($spacing, s-24);
    }
}

.overlay {
    // This is needed to style the XPF in the author
    @at-root div#{&} {
        align-items: center;
        background-color: rgba(16, 29, 64, 70%);
        display: flex;
        flex-direction: column;
        justify-content: center;
        min-height: 100vh;
    }

    @at-root dialog#{&} {
        overflow: hidden;

        @include breakpoint-until(tablet) {
            height: 100vh;
            max-height: 100%;
            max-width: 100%;
            width: 100%;
        }
    }

    &::backdrop {
        background-color: var(--color-background-backdrop);
    }
}
