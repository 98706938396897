@use '@oecd/util-breakpoint' as *;

.cmp-separator {
    &__horizontal-rule {
        border-bottom: 0;
        border-left: 0;
        border-right: 0;
        border-top: 1px solid var(--color-divider);
        margin: 0;

        .separator--full-width & {
            margin-inline: calc(var(--grid-offset) * -1);

            @include breakpoint-from(desktop) {
                margin-left: 50%;
                padding-inline: 0;
                transform: translate3d(-50%, 0, 0);
                width: 100vw;
            }

            .container-content--narrow & {
                @include breakpoint-from(mobile-large) {
                    margin-left: 50%;
                    padding-inline: 0;
                    transform: translate3d(-50%, 0, 0);
                    width: 100vw;
                }
            }
        }
    }
}
