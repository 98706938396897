@use '@oecd/util-breakpoint' as *;

@media print {
    .person-container {
        .person {
            &:nth-child(odd) {
                @include breakpoint-from(mobile-large) {
                    grid-column: 1/ span 6;
                }

                @include breakpoint-from(desktop) {
                    grid-column: 1/ span 6;
                }
            }

            &:nth-child(even) {
                @include breakpoint-from(mobile-large) {
                    grid-column: 6/ span 6;
                }

                @include breakpoint-from(desktop) {
                    grid-column: 6/ span 6;
                }
            }
        }
    }
}
