@media print {
    .cmp-accordion {
        &__icon {
            display: none;
        }

        &__panel--hidden {
            display: block;
        }
    }
}
