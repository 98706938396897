@use 'sass:map';
@use '@oecd/foundation/src/site/config/spacing-vars' as *;
@use '@oecd/util-breakpoint' as *;
@use '@oecd/util-distance' as d;
@use '@oecd/util-focus' as *;
@use '@oecd/util-text-style' as *;
@use 'theme-config' as themeConfig;

.featured-card {
    container-name: featured-card;
    container-type: inline-size;
}

.cmp-featured-card {
    --featured-card-background-color: var(--color-background);
    --featured-card-border-color: var(--color-border);
    --featured-card-border-color-hover: var(--color-primary);
    --featured-card-separator-symbol: '\2022';
    --featured-card-metadata-text-color: var(--color-text-secondary);
    --featured-card-tag-text-color: var(--color-text-secondary);
    --featured-card-tag-background-color: var(--color-tag-inverse);
    --featured-card-link-text-color: var(--color-text-primary);
    --featured-card-title-text-color-hover: var(--color-text-accent);
    --featured-card-image-aspect-ratio: 4/3;
    --featured-card-image-hover-opacity: 0.65;

    background: var(--featured-card-background-color);
    border: 1px solid var(--featured-card-border-color);
    border-radius: 4px;
    position: relative;

    @media (prefers-reduced-motion: no-preference) {
        transition: 0.15s linear border-color;
    }

    &:hover,
    &:focus-within {
        border-color: var(--featured-card-border-color-hover);

        .cmp-featured-card__visual {
            opacity: var(--featured-card-image-hover-opacity);
        }
    }

    @container featured-card (min-width: 608px) {
        &:has(.cmp-image) {
            display: grid;
            grid-template-columns: d.rem(280) 1fr;
            min-height: d.rem(280);
        }
    }

    &__visual {
        @media (prefers-reduced-motion: no-preference) {
            transition: 0.15s cubic-bezier(0.4, 0, 0.4, 1) opacity;
            will-change: opacity;
        }

        .cmp-image {
            aspect-ratio: var(--featured-card-image-aspect-ratio);
            height: 100%;
            overflow: hidden;
            position: relative;

            @container featured-card (min-width: 608px) {
                aspect-ratio: initial;
            }

            &__image {
                border-radius: 4px 4px 0 0;
                height: 100%;
                object-fit: cover;
                object-position: center center;
                position: absolute;
                width: 100%;

                @container featured-card (min-width: 608px) {
                    aspect-ratio: initial;
                    border-radius: 4px 0 0 4px;
                }
            }
        }
    }

    &__content {
        display: flex;
        flex-direction: column;
        min-width: 0;
        padding: map.get($spacing, s-24);
    }

    &__header {
        display: flex;
        flex-direction: column-reverse;
    }

    &__link {
        @include text-style(card-title);

        @include breakpoint-from(tablet) {
            @include text-style(heading, 3);
        }

        color: var(--featured-card-link-text-color);
        text-decoration: none;

        @media (prefers-reduced-motion: no-preference) {
            transition: 0.15s linear color;
        }

        &::before {
            content: '';
            inset: 0;
            position: absolute;
            z-index: 1;
        }

        &:hover {
            color: var(--featured-card-title-text-color-hover);
        }

        &:focus-visible {
            color: var(--featured-card-title-text-color-hover);
            outline: none;

            &::before {
                @include keyboardfocus;
            }
        }

        + .cmp-featured-card__tags {
            margin-block-end: map.get($spacing, s-12);
        }
    }

    &__tags {
        display: flex;
        flex-wrap: wrap;
        gap: map.get($spacing, s-4);

        .tag {
            --tag-background: var(--featured-card-tag-background-color);
            --tag-border: var(--featured-card-tag-background-color);
            --tag-color: var(--featured-card-tag-text-color);
        }
    }

    &__meta-data {
        @include text-style(label, 3, b);

        color: var(--featured-card-metadata-text-color);
        display: flex;
        margin-block-start: auto;
        padding-block-start: map.get($spacing, s-24);

        > *:not(:first-child)::before {
            content: var(--featured-card-separator-symbol);
            display: inline-block;
            margin-inline: map.get($spacing, s-8);
        }
    }

    &__date {
        flex: 1 0 auto;
    }

}

.featured-card { // stylelint-disable-line no-duplicate-selectors
    @if map.get(themeConfig.$theme-imagery, featured-card-image) {
        &.has-ripple {
            .cmp-featured-card {
                --featured-card-background-color: var(--color-primary-light);

                &__content {
                    overflow: hidden;
                    position: relative;

                    &::before {
                        background-image: url('#{map.get(themeConfig.$theme-imagery, featured-card-image)}');
                        background-position: center center;
                        background-repeat: no-repeat;
                        background-size: contain;
                        bottom: d.rem(24);
                        content: '';
                        height: d.rem(340);
                        opacity: 0.2;
                        position: absolute;
                        right: d.rem(24);
                        transform: translate(50%, 50%);
                        width: d.rem(340);
                        z-index: 1;
                    }

                    > * {
                        position: relative;
                        z-index: 2;
                    }
                }
            }
        }
    }
}
