@use 'sass:map';
@use '@oecd/foundation/src/site/config/spacing-vars' as *;
@use 'typography-vars' as *;
@use '@oecd/util-a11y' as *;
@use '@oecd/util-breakpoint' as *;
@use '@oecd/util-distance' as d;
@use '@oecd/util-focus' as *;
@use '@oecd/util-text-style' as *;

.cmp-archive-footer {
    --archive-footer-background-color: var(--color-background-light);
    --archive-footer-closure-border-color: var(--color-border-transparent);
    --archive-footer-logo-color: var(--color-background-inverse);
    --archive-viewport-width: var(--viewport-width, 100dvw);

    background-color: var(--archive-footer-background-color);
    display: grid;
    grid-template-columns: [full-start] var(--grid-offset) [container-start] minmax(0, var(--container-max-width)) [container-end] var(--grid-offset) [full-end];
    padding: map.get($spacing, s-32) 0 map.get($spacing, s-32);

    &__container {
        column-gap: var(--grid-gap);
        display: grid;
        grid-column: container;
        grid-template-columns: repeat(12, 1fr);
    }

    &__brand {
        grid-column: 1 / span 12;

        @include breakpoint-from(desktop) {
            grid-column: 1 / span 3;
        }

        &-txt {
            @include visually-hidden;
        }

        .cmp-text {
            @include text-style(label, 3);
        }
    }

    &__logo {
        color: var(--archive-footer-logo-color);
        display: block;
        height: auto;
        margin-block-end: map.get($spacing, s-16);
        width: d.rem(132);
    }

    &__closure {
        display: flex;
        flex-direction: column;
        gap: map.get($spacing, s-16);
        grid-column: 1 / span 12;
        margin-block-start: map.get($spacing, s-48);
        padding-block-start: map.get($spacing, s-24);
        position: relative;

        @include breakpoint-from(tablet) {
            align-items: center;
            flex-direction: row;
        }

        &::before {
            border-top: 1px solid var(--archive-footer-closure-border-color);
            content: '';
            display: block;
            height: 1px;
            min-width: 100%;
            position: absolute;
            top: 0;

            @include breakpoint-from(tablet) {
                margin-left: calc(((var(--archive-footer-viewport-width) - 100%) / 2) * -1);
                margin-right: calc(((var(--archive-footer-viewport-width) - 100%) / 2) * -1);
                width: var(--footer-viewport-width);
            }
        }
    }

    .utility-links {
        .cmp-container {
            display: flex;
            gap: map.get($spacing, s-16);
            justify-content: center;
        }

        &__title {
            @include visually-hidden;
        }
    }

    .utility-link {
        a {
            @include text-style(label, 3);

            color: var(--color-text-secondary);
            font-weight: $font-weight-normal;
            text-decoration: none;

            @media (prefers-reduced-motion: no-preference) {
                transition-duration: 250ms;
                transition-property: color;
                transition-timing-function: cubic-bezier(0.4, 0, 0.4, 1);
            }

            &:hover,
            &:focus {
                color: var(--color-text-link-hover);
            }

            &:focus-visible {
                @include keyboardfocus;
            }
        }
    }
}
