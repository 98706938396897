@use 'sass:map';
@use '@oecd/foundation/src/site/config/spacing-vars' as *;
@use '@oecd/util-a11y' as *;
@use '@oecd/util-breakpoint' as *;
@use '@oecd/util-distance' as d;
@use '@oecd/util-focus' as *;
@use 'theme-config' as themeConfig;

.cmp-main-navigation {
    --secondary-navigation-background-color: var(--color-background-inverse);
    --secondary-navigation-logo-color: #fff;
    --secondary-navigation-logo-width: #{d.rem(325)};
    --secondary-navigation-logo-width-mobile: #{d.rem(283)};
    --secondary-navigation-logo-max-height: #{d.rem(46)};
    --secondary-navigation-min-height: #{d.rem(80)};
    --secondary-navigation-decoration-offset: #{d.rem(104)};

    &:has(.cmp-main-navigation__secondary-header) {
        border-top: solid 1px var(--secondary-navigation-background-color);
    }

    &__secondary-header {
        background-color: var(--secondary-navigation-background-color);
        display: grid;
        grid-column: 1 / -1;
        grid-template-columns: [full-start] var(--grid-offset) [wrapper-start] minmax(0, var(--container-max-width)) [wrapper-end] var(--grid-offset) [full-end];
        min-height: var(--secondary-navigation-min-height);

        &__wrapper {
            align-items: center;
            display: flex;
            grid-column: wrapper;
            position: relative;
        }

        &__decoration {
            height: 100%;
            inset-inline-end: calc(var(--grid-offset) * -1);
            max-width: #{d.rem(500)};
            overflow: hidden;
            position: absolute;
            width: 40%;
            z-index: 1;

            @include breakpoint-from(desktop) {
                inset-inline-end: calc(var(--secondary-navigation-decoration-offset) * -1);
            }

            @if map.get(themeConfig.$theme-imagery, secondary-header-background-image) {
                &::before {
                    background-image: url('#{map.get(themeConfig.$theme-imagery, secondary-header-background-image)}');
                    background-position: left center;
                    background-repeat: no-repeat;
                    background-size: cover;
                    content: '';
                    height: d.rem(300);
                    inset-block-start: 50%;
                    inset-inline-start: 0;
                    position: absolute;
                    transform: translate(0, -50%);
                    width: d.rem(500);
                    z-index: 1;

                    @include breakpoint-until(tablet) {
                        opacity: 0.7;
                    }
                }
            }
        }

        &__logo {
            color: var(--secondary-navigation-logo-color);
            flex: 0 0 auto;
            margin-right: auto;
            position: relative;
            transition: color 0.15s linear 0.3s;
            z-index: 2;

            &-icon {
                display: block;
                line-height: 0;

                svg {
                    height: auto;
                    max-height: var(--secondary-navigation-logo-max-height);
                    width: var(--secondary-navigation-logo-width-mobile);

                    // On desktop and larger, the logo is larger
                    @include breakpoint-from(desktop-small) {
                        width: var(--secondary-navigation-logo-width);
                    }
                }
            }

            &-text {
                @include visually-hidden;
            }

            &:is(a):hover,
            &:is(a):focus {
                --secondary-navigation-logo-color: var(--color-accent);

                transition-delay: initial;
            }

            &:focus {
                outline: none;
            }

            &:is(a):focus-visible {
                outline: 2px solid var(--color-accent);
            }
        }
    }
}
