@use 'sass:map';
@use '@oecd/foundation/src/site/config/spacing-vars' as *;
@use '@oecd/util-breakpoint' as *;
@use '@oecd/util-distance' as d;

.container-content {
    // Margin between two content containers
    & + & {
        margin-block-start: var(--layout-spacing-default);
    }

    // Reset top margin if content container follows an empty content-container
    &:not(:has([class*='cmp-'])) + & {
        margin-block-start: 0;
    }

    &:has(> .cmp-container):not(:has(> .cmp-container *[class^='cmp'])) + & {
        margin-block-start: 0;
    }

    // Reset top margin if content container is empty
    &:has(> .cmp-container):not(:has(> .cmp-container *[class^='cmp'])) {
        margin-block-start: 0;
    }

    // Vertical flow within a content container
    > :where(.cmp-container) > :where(* + *) {
        margin-block-start: var(--layout-spacing-default);
    }

    // Reset top margin for all components following a title or title and link component
    > :where(.cmp-container) > :where(.title + *, .title-and-link + *) {
        margin-block-start: 0;
    }

    // Reset top margin when container component follows other container component
    > :where(.cmp-container) > :where(.container + .container:not(.highlight-container)) {
        margin-block-start: 0;
    }

    // Reset top margin when container and separator components follow each other
    > :where(.cmp-container) > :where(.container + .separator, .separator + .container) {
        margin-block-start: 0;
    }

    // Reset top margin of element that follows an empty element
    > :where(.cmp-container) > :where(*:not(:has([class*='cmp-'])) + * ) {
        margin-block-start: 0;
    }

    // Make sure empty content language picker placed in content container does not take up space
    // And position sticky is accounted for
    > :where(.cmp-container) > :where(* + .content-language-picker) {
        margin-block-start: 0;

        .cmp-content-language-picker {
            margin-block-start: var(--layout-spacing-default);
        }
    }

    // Placement of a normal image directly placed inside an "one column" container
    > :where(.cmp-container) > .image:not(.image--reduced-width) {
        // bleed until tablet
        @include breakpoint-until(tablet) {
            margin-inline: calc(var(--grid-offset) * -1);
        }

        // Take up 10/12 columns from tablet and 8/12 from desktop
        @include breakpoint-from(tablet) {
            display: grid;
            gap: var(--grid-gap);
            grid-template-columns: repeat(12, 1fr);
        }

        > * {
            @include breakpoint-from(tablet) {
                grid-column: 2 / span 10;
                margin-inline: 0;
            }

            @include breakpoint-from(desktop) {
                grid-column: 3 / span 8;
            }
        }
    }

    // Placement of a Reduced witdh image directly placed inside an "one column" container
    > :where(.cmp-container) > .image--reduced-width {
        @include breakpoint-from(tablet) {
            display: grid;
            gap: var(--grid-gap);
            grid-template-columns: repeat(12, 1fr);
        }

        > * {
            @include breakpoint-until(tablet) {
                margin-inline: auto;
                max-width: d.rem(400);
            }

            @include breakpoint-from(tablet) {
                grid-column: 4 / span 6;
                margin-inline: 0;
            }

            @include breakpoint-from(desktop) {
                grid-column: 5 / span 4;
            }
        }
    }

    &--narrow {
        --layout-spacing-default: var(--layout-spacing-prose);
        --button-spacing: #{map.get($spacing, s-24)};

        > .cmp-container {
            @include breakpoint-from(mobile-large) {
                column-gap: var(--grid-gap);
                display: grid;
                grid-template-columns: repeat(12, 1fr);
            }
        }

        > :where(.cmp-container) {
            > * {
                grid-column: 1 / -1;
            }

            > .archived-page-navigation,
            > .button,
            > .chart-control,
            > .clickable-tags,
            > .footnote,
            > .highlight-container--content-wide,
            > .list,
            > .text,
            > .title,
            > .separator {
                @include breakpoint-from(mobile-large) {
                    grid-column: 2 / span 10;
                }

                @include breakpoint-from(desktop-small) {
                    grid-column: 3 / span 8;
                }

                @include breakpoint-from(desktop) {
                    grid-column: 4 / span 6;
                }
            }

            > .image {
                grid-column: 1 / span 12;
            }

            > .chart,
            > .descriptive-asset,
            > .embed,
            > .highlight-container:not(.highlight-container--content-wide),
            > .two-column-container {
                @include breakpoint-from(mobile-large) {
                    grid-column: 2 / span 10;
                }

                @include breakpoint-from(desktop) {
                    grid-column: 3 / span 8;
                }
            }
        }

        // Button following on text component can only have a spacing of 24px
        // Button following on button component can only have a spacing of 24px
        > :where(.cmp-container) > :where(.text + .button, .button + .button) {
            margin-block-start: var(--button-spacing);
        }
    }
}
