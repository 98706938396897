@use 'sass:map';
@use '@oecd/foundation/src/site/config/spacing-vars' as *;
@use '@oecd/util-distance' as d;

.cmp-chart {
    --chart-background-color: var(--color-background);
    --chart-border-color: var(--color-border);
    --chart-border-radius: #{d.rem(8)};
    --chart-height: #{d.rem(400)};
    --chart-padding: 2px;
    --chart-button-inset: #{map.get($spacing, s-32)};

    background-color: var(--chart-background-color);
    border: 1px solid var(--chart-border-color);
    border-radius: var(--chart-border-radius);
    padding: var(--chart-padding);
    width: 100%;

    &--large {
        --chart-height: #{d.rem(500)};
    }

    &--small {
        --chart-height: #{d.rem(300)};
        --chart-button-inset-start: #{d.rem(18)};
    }

    @at-root .container-themable.theme-subtle & {
        --chart-border-color: transparent;
    }

    &__wrapper {
        height: var(--chart-height);
        position: relative;
    }

    &__button:has(.cmp-button) {
        margin-block: map.get($spacing, s-8) map.get($spacing, s-24);
        margin-inline: var(--chart-button-inset);
    }
}
