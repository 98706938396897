@use '@oecd/util-breakpoint' as *;

.aem-AuthorLayer-Edit {
    .cmp-title-link__cta {
        @include breakpoint-from(tablet) {
            line-height: inherit;
            text-align: right;
            width: 100%;
        }
    }
}
