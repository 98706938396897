@media print {
    $cmp-person-container-item-min-width: 288px;
    $cmp-person-container-max-width: $cmp-person-container-item-min-width - 1;

    .cmp-person-container {
        container-name: person-grid;
        container-type: inline-size;
    }

    .cmp-person-container > .container > .cmp-container {
        @container person-grid (max-width: #{$cmp-person-container-max-width}) {
            .person + .person {
                margin-block-start: var(--grid-gap);
            }
        }

        @container person-grid (min-width: #{$cmp-person-container-item-min-width}) {
            display: grid;
            gap: var(--grid-gap);
            grid-template-columns: repeat(auto-fill, minmax($cmp-person-container-item-min-width, 1fr));
        }
    }
}

