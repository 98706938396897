@use '@oecd/util-distance' as d;
@use 'colors' as colors;

@media print {
    .js-list-carousel {
        page-break-inside: avoid;
    }

    .cmp-list {
        &--grid-layout.cmp-list--info-card-rendition {
            grid-template-columns: repeat(auto-fit, minmax(d.rem(288), 1fr)) !important;

            .cmp-two-column-container & {
                grid-template-columns: repeat(auto-fit, minmax(d.rem(288), 1fr));
            }
        }
    }

    .cmp-list__carousel-controls {
        display: none;
    }

    .info-card {
        page-break-inside: avoid !important;
    }

    .card,
    .info-card,
    .data-card {
        page-break-inside: avoid;
    }

    .card {
        @each $theme-color in (colors.$theme-colors) {
            &--#{$theme-color}-theme {
                --list-card-image-background-color: transparent;
                --list-card-tag-background-color: var(--color-print-background);
                --list-card-metadata-text-color: var(--color-text-print-default);
                --list-card-tag-text-color: var(--color-text-print-default);
                --list-card-title-text-color: var(--color-text-print-dark);
                --list-card-title-highlight-color: var(--color-text-print-dark);
            }
        }
    }
}
