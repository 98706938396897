@use 'sass:map';
@use '@oecd/foundation/src/site/config/spacing-vars' as *;
@use '@oecd/util-breakpoint' as *;
@use '@oecd/util-scrollbar' as *;

.cmp-chart-miniatures-container {
    .cmp-container {
        display: grid;
        grid-gap: map.get($spacing, s-16);

        @include breakpoint-until(tablet) {
            grid-auto-flow: column;
            overflow-x: scroll;
            padding: map.get($spacing, s-32) var(--grid-gap);
            scroll-snap-type: x mandatory;
            width: calc(100vw - var(--grid-gap));

            @include hide-scrollbars;
        }

        @include breakpoint-from(tablet) {
            grid-gap: var(--grid-gap);
            grid-template-columns: repeat(3, 1fr);
        }

        @include breakpoint-from(desktop) {
            grid-template-columns: repeat(4, 1fr);
        }
    }

    .chart {
        @include breakpoint-from(tablet) {
            min-width: 0; // Prevent chart from growing bigger if there is a large CTA inside of it
        }

        @include breakpoint-until(tablet) {
            min-width: 75vw;
            scroll-snap-align: start;
        }
    }
}
