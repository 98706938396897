@use 'sass:map';
@use '@oecd/foundation/src/site/config/spacing-vars' as *;
@use '@oecd/util-breakpoint' as *;
@use '@oecd/util-text-style' as *;

.cmp-highlight-container {
    --highlight-container-background: var(--color-primary-light);
    --highlight-container-background-subtle: var(--color-background-light);
    --highlight-container-border-color: transparent;
    --highlight-container-border-color-subtle: var(--color-border-strong);
    --highlight-container-padding: #{map.get($spacing, s-24)};
    --highlight-container-component-spacing: #{map.get($spacing, s-24)};

    background-color: var(--highlight-container-background);
    border: 1px solid var(--highlight-container-border-color);
    border-radius: 4px;
    padding: var(--highlight-container-padding);

    @include breakpoint-from(desktop) {
        --highlight-container-padding: #{map.get($spacing, s-32)};
    }

    // Vertical flow within a content container
    > :where(.cmp-container) > :where(* + *) {
        margin-block-start: var(--highlight-container-component-spacing);
    }

    // Reset top margin for all components following a title
    > :where(.cmp-container) > :where(.title + *) {
        margin-block-start: 0;
    }

    .cmp-text {
        @include text-style(label, 2);
    }

    &.highlight-container--subtle {
        background-color: var(--highlight-container-background-subtle);
        border: 1px solid var(--highlight-container-border-color-subtle);
    }
}
