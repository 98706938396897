@use 'sass:map';
@use '@oecd/foundation/src/site/config/spacing-vars' as *;
@use '@oecd/util-a11y' as *;
@use '@oecd/util-breakpoint' as *;
@use '@oecd/util-distance' as d;
@use '@oecd/util-focus' as *;
@use '@oecd/util-text-style' as *;

// 1384px =
//   1248px (container-max-width) +
//   2 * (
//       16px (margin between content & share) +
//       36px (share button width) +
//       16px (offset to right of window)
//   )
$share-sticky-container-query: 1384px;

.share-sticky-container {
    container-name: share-sticky;
    container-type: inline-size;
    position: relative;

    @container share-sticky (min-width: #{$share-sticky-container-query}) {
        > .cmp-container {
            margin-block-start: var(--content-offset);
        }
    }

    // If the themable container is the first component in the page content
    // and there ARE NO social share buttons the themable container
    // has no visual margin on all breakpoints
    &:not(.share-sticky-container:has(.cmp-share__container)) {
        .cmp-share + .container-content > .cmp-container {
            > .container-themable:is(:first-child) {
                margin-block-start: calc(var(--content-offset) * -1);
            }
        }
    }

    // If the themable container is the first component in the page content
    // and there ARE social share buttons the themable container
    // has no visual margin on as from the point the share buttons are sticky
    .cmp-share + .container-content > .cmp-container {
        > .container-themable:is(:first-child) {
            @container share-sticky (min-width: #{$share-sticky-container-query}) {
                margin-block-start: calc(var(--content-offset) * -1);
            }
        }
    }

    // If the top stories is the first component in the page content
    // and there are no social share buttons
    .page--without-header & {
        &:not(.share-sticky-container:has(.cmp-share__container)) {
            .cmp-share + .container-content > .cmp-container {
                > .top-stories:is(:first-child),
                > .title--hidden-page-title + .top-stories {
                    .cmp-featured-top-story {
                        @container featured-top-story (max-width: 599px) {
                            margin-block-start: calc(var(--content-offset) * -1);
                        }
                    }
                }
            }
        }
    }
}

.cmp-share {
    --share-button-background-color: var(--color-background-light);
    --share-button-border: 2px solid var(--color-background);
    --share-button-border-radius: 50%;
    --share-button-color: var(--color-icon-secondary);
    --share-button-color-hover: var(--color-icon-accent);
    --share-button-icon: var(--icon-plus);
    --share-button-icon-size: #{d.rem(16)};
    --share-button-inline-end-offset: #{d.rem(16)};
    --share-button-outline: 2px solid var(--color-focus-outline);
    --share-button-size: #{d.rem(36)};
    --share-sticky-offset: calc(var(--content-language-picker-height, 0px) + var(--chart-control-bar-height, 0px) + var(--jumplinks-height, 0px) + #{map.get($spacing, s-32)});
    --share-margin-block-start: var(--content-offset);
    --share-margin-block-end: 0;

    display: grid;
    grid-template-columns: [full-start] var(--grid-offset) [wrapper-start] minmax(0, var(--container-max-width)) [wrapper-end] var(--grid-offset) [full-end];
    margin-block: var(--share-margin-block-start) var(--share-margin-block-end);
    z-index: var(--z-index-share, 450);

    &:has(.cmp-share__container) {
        // When the social share is active
        // the distance between the share buttons and the previous content
        // needs to be 24px and the distance between the buttons and the
        // next content needs to be `content-offset` value
        --share-margin-block-start: #{map.get($spacing, s-24)};
        --share-margin-block-end: var(--content-offset);

        @container share-sticky (min-width: #{$share-sticky-container-query}) {
            --share-margin-block-start: 0;
            --share-margin-block-end: 0;
        }
    }

    @container share-sticky (min-width: #{$share-sticky-container-query}) {
        display: block;
        inset-block-start: 0;
        inset-inline-end: var(--share-button-inline-end-offset);
        margin-block: 0 0;
        position: absolute;

        @supports (position: sticky) or (position: -webkit-sticky) {
            height: 100%;
        }
    }

    &__container {
        align-items: center;
        display: flex;
        flex-direction: row;
        grid-column: wrapper;

        @container share-sticky (min-width: #{$share-sticky-container-query}) {
            flex-direction: column;
            padding-block-end: 0;

            @supports (position: sticky) or (position: -webkit-sticky) {
                inset-block-start: var(--share-sticky-offset);
                margin-block-start: var(--content-offset);
                padding-block-end: map.get($spacing, s-16);
                position: sticky;

                @media (prefers-reduced-motion: no-preference) {
                    transition: inset-block-start 0.15s linear;
                }

                .js-is-scrolling-down & {
                    --share-sticky-offset: calc(var(--content-language-picker-height, 0px) + var(--chart-control-bar-height, 0px) + var(--jumplinks-height, 0px) + #{map.get($spacing, s-32)});
                }

                .js-is-scrolling-up & {
                    --share-sticky-offset: calc(var(--secondary-navigation-height, 0px) + #{map.get($spacing, s-32)});
                }
            }
        }
    }

    &__title {
        @include visually-hidden;
    }

    &__buttons {
        align-items: center;
        display: flex;
        flex-direction: row;
        gap: map.get($spacing, s-16);

        @container share-sticky (min-width: #{$share-sticky-container-query}) {
            flex-direction: column;
        }
    }

    &__button {
        align-items: center;
        background-color: var(--share-button-background-color);
        border: var(--share-button-border);
        border-radius: var(--share-button-border-radius);
        color: var(--share-button-color);
        cursor: pointer;
        display: flex;
        height: var(--share-button-size);
        justify-content: center;
        text-decoration: none;
        width: var(--share-button-size);

        @media (prefers-reduced-motion: no-preference) {
            transition: 0.15s linear color;
        }

        &:hover {
            color: var(--share-button-color-hover);
        }

        &:active,
        &:focus-visible {
            outline: var(--share-button-outline);
        }

        &-icon {
            align-items: center;
            display: flex;
            height: var(--share-button-icon-size);
            justify-content: center;
            overflow: hidden;
            width: var(--share-button-icon-size);

            svg {
                display: inline-block;
                max-height: var(--share-button-icon-size);
                max-width: var(--share-button-icon-size);
            }
        }

        &-text {
            @include visually-hidden;
        }

        &:focus-visible {
            @include keyboardfocus;
        }
    }

    &--narrow {
        @include breakpoint-from(mobile-large) {
            .cmp-share {
                &__container {
                    column-gap: var(--grid-gap);
                    display: grid;
                    grid-template-columns: repeat(12, 1fr);
                }

                &__buttons {
                    grid-column: 2 / span 10;
                }
            }
        }

        @container share-sticky (min-width: #{$share-sticky-container-query}) {
            .cmp-share__container {
                display: flex;
            }
        }
    }
}
