@use 'sass:map';
@use '@oecd/foundation/src/site/config/spacing-vars' as *;
@use '@oecd/util-breakpoint' as *;
@use '@oecd/util-distance' as d;
@use '@oecd/util-text-style' as *;

.cmp-language-switch {
    --language-switch-popover-background-color: var(--color-background);

    // Language switch trigger
    --language-switch-trigger-background-color: transparent;
    --language-switch-trigger-color: var(--color-text-primary);
    --language-switch-trigger-color-hover: var(--color-primary);
    --language-switch-trigger-background-color-hover: transparent;
    --language-switch-box-shadow: 0 0 36px 0 rgba(0, 0, 0, 10%);

    // Language switch trigger styled as a button
    --language-switch-trigger-btn-background-color: var(--color-text-primary);
    --language-switch-trigger-btn-background-color-hover: var(--color-primary);
    --language-switch-trigger-btn-color: var(--color-background);
    --language-switch-trigger-btn-color-hover: var(--color-background);

    // Language switch popover links
    --language-switch-link-color: var(--color-text-secondary);
    --language-switch-link-color-active: var(--color-primary);
    --language-switch-link-color-hover: var(--color-primary);

    position: relative;

    &__trigger {
        align-items: center;
        background-color: var(--language-switch-trigger-background-color);
        border-radius: 4px;
        color: var(--language-switch-trigger-color);
        display: flex;
        margin-inline: map.get($spacing, s-4);
        padding: map.get($spacing, s-6) map.get($spacing, s-8);

        @media (prefers-reduced-motion: no-preference) {
            transition: background-color 0.15s linear 0.3s, color 0.15s linear 0.3s;
        }

        @media screen and (forced-colors: active) {
            border: 1px solid;
        }

        &:hover {
            background: var(--language-switch-trigger-background-color-hover);
            color: var(--language-switch-trigger-color-hover);
            transition-delay: initial;
        }

        &:focus-visible {
            outline: 2px solid var(--color-focus-outline);
        }

        // When the popover is open the trigger must look like a button
        @include breakpoint-until(tablet) {
            .js-language-popover-is-open & {
                background-color: var(--language-switch-trigger-btn-background-color);
                color: var(--language-switch-trigger-btn-color);
            }
        }

        // From tablet onwards the trigger must look like a button
        @include breakpoint-from(tablet) {
            background-color: var(--language-switch-trigger-btn-background-color);
            color: var(--language-switch-trigger-btn-color);

            &:hover {
                background-color: var(--language-switch-trigger-btn-background-color-hover);
                color: var(--language-switch-trigger-btn-color-hover);
            }

            &:focus-visible {
                outline-offset: 2px;
            }
        }

        @include breakpoint-from(desktop) {
            margin-inline-end: 0;
        }

        &-text {
            @include text-style(label, 2, m, 1);

            @at-root abbr#{&} {
                border: none;
                text-decoration: none;
                text-transform: uppercase;

                @include breakpoint-from(tablet) {
                    display: none;
                }
            }

            @at-root span#{&} {
                @include breakpoint-until(tablet) {
                    display: none;
                }
            }
        }

        &-icon {
            display: inline-block;
            margin-inline-end: map.get($spacing, s-4);
        }
    }

    &__dialog {
        background-color: var(--language-switch-popover-background-color);
        border-radius: 4px;
        box-shadow: var(--language-switch-box-shadow);
        inset-block: inherit;
        inset-inline-start: 50%;
        min-width: d.rem(152);
        padding-inline: map.get($spacing, s-16);
        transform: translate(-50%, map.get($spacing, s-8));
    }

    &__list-item {
        &:not(:first-child) {
            border-top: 1px solid var(--color-border);
        }
    }

    &__language {
        display: block;
        padding-block: map.get($spacing, s-16);

        &[aria-current] {
            color: var(--language-switch-link-color-active);

            @include text-style(label, 2, b, 1);

            &:hover {
                cursor: default;
            }
        }

        @at-root a#{&} {
            color: var(--language-switch-link-color);
            text-decoration: none;

            @include text-style(label, 2, m, 1);

            &:hover {
                color: var(--language-switch-link-color-hover);
            }

            &:focus-visible {
                outline: 2px solid var(--color-focus-outline);
            }
        }


        &--not-available {
            color: var(--language-switch-link-color);

            @include text-style(label, 3);
        }
    }
}

.js-language-switch-backdrop {
    background-color: var(--color-background-backdrop);
    display: none;
    height: 100%;
    inset-block-start: 0;
    inset-inline-start: 0;
    opacity: 0;
    position: fixed;
    transition: opacity 0.15s linear;
    width: 100%;
    will-change: opacity;
    z-index: var(--z-index-language-switch-backdrop, 25);
}

.js-language-popover-is-open {
    .js-language-switch-backdrop {
        display: block;
        opacity: 1;
    }

    .cmp-main-navigation {
        transition-delay: initial;
    }

    .cmp-main-navigation__logo,
    .cmp-main-navigation__item,
    .cmp-main-navigation__cta,
    .cmp-main-navigation__menu-trigger {
        opacity: var(--main-navigation-inactive-items-opacity);
        pointer-events: none;
        transition-delay: initial;
    }
}
