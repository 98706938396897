// == Variable font Noto SANS DISPLAY
// At the moment only latin and latin extended are added to the project V20
// cyrillic and greek are not included
//  https://fonts.googleapis.com/css2?family=Noto+Sans+Display:ital,wght@0,100..900;1,100..900

/* latin */
@font-face {
    font-display: swap;
    font-family: 'Noto Sans Display VF';
    font-stretch: 100%;
    font-style: normal;
    font-weight: 100 900;
    src: url('./resources/fonts/noto-sans-display/vf/noto-sans-display-latin.woff2') format('woff2');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
    font-display: swap;
    font-family: 'Noto Sans Display VF';
    font-stretch: 100%;
    font-style: italic;
    font-weight: 100 900;
    src: url('./resources/fonts/noto-sans-display/vf/noto-sans-display-italic-latin.woff2') format('woff2');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

/* latin-ext */
@font-face {
    font-display: swap;
    font-family: 'Noto Sans Display VF';
    font-stretch: 100%;
    font-style: italic;
    font-weight: 100 900;
    src: url('./resources/fonts/noto-sans-display/vf/noto-sans-display-italic-latin-ext.woff2') format('woff2');
    unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

/* latin-ext */
@font-face {
    font-family: 'Noto Sans Display VF';
    font-stretch: 100%;
    font-style: normal;
    font-weight: 100 900;
    src: url('./resources/fonts/noto-sans-display/vf/noto-sans-display-latin-ext.woff2') format('woff2');
    unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

// == Static font Noto SANS DISPLAY
// At the moment only latin and latin extended are added to the project V20
// cyrillic and greek are not included
// https://fonts.googleapis.com/css2?family=Noto+Sans+Display:ital,wght@0,300;0,800;0,900;1,300;1,800;1,900&display=swap

/* latin-ext */
@font-face {
    font-display: swap;
    font-family: 'Noto Sans Display';
    font-stretch: 100%;
    font-style: italic;
    font-weight: 300;
    src: url('./resources/fonts/noto-sans-display/static/noto-sans-display-italic-300-latin-ext.woff2') format('woff2');
    unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

/* latin */
@font-face {
    font-display: swap;
    font-family: 'Noto Sans Display';
    font-stretch: 100%;
    font-style: italic;
    font-weight: 300;
    src: url('./resources/fonts/noto-sans-display/static/noto-sans-display-italic-300-latin.woff2') format('woff2');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

/* latin-ext */
@font-face {
    font-display: swap;
    font-family: 'Noto Sans Display';
    font-stretch: 100%;
    font-style: italic;
    font-weight: 800;
    src: url('./resources/fonts/noto-sans-display/static/noto-sans-display-italic-800-latin-ext.woff2') format('woff2');
    unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

/* latin */
@font-face {
    font-display: swap;
    font-family: 'Noto Sans Display';
    font-stretch: 100%;
    font-style: italic;
    font-weight: 800;
    src: url('./resources/fonts/noto-sans-display/static/noto-sans-display-italic-800-latin.woff2') format('woff2');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

/* latin-ext */
@font-face {
    font-display: swap;
    font-family: 'Noto Sans Display';
    font-stretch: 100%;
    font-style: italic;
    font-weight: 900;
    src: url('./resources/fonts/noto-sans-display/static/noto-sans-display-italic-900-latin-ext.woff2') format('woff2');
    unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

/* latin */
@font-face {
    font-display: swap;
    font-family: 'Noto Sans Display';
    font-stretch: 100%;
    font-style: italic;
    font-weight: 900;
    src: url('./resources/fonts/noto-sans-display/static/noto-sans-display-italic-900-latin.woff2') format('woff2');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

/* latin-ext */
@font-face {
    font-display: swap;
    font-family: 'Noto Sans Display';
    font-stretch: 100%;
    font-style: normal;
    font-weight: 300;
    src: url('./resources/fonts/noto-sans-display/static/noto-sans-display-300-latin-ext.woff2') format('woff2');
    unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

/* latin */
@font-face {
    font-display: swap;
    font-family: 'Noto Sans Display';
    font-stretch: 100%;
    font-style: normal;
    font-weight: 300;
    src: url('./resources/fonts/noto-sans-display/static/noto-sans-display-300-latin.woff2') format('woff2');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

/* latin-ext */
@font-face {
    font-display: swap;
    font-family: 'Noto Sans Display';
    font-stretch: 100%;
    font-style: normal;
    font-weight: 800;
    src: url('./resources/fonts/noto-sans-display/static/noto-sans-display-800-latin-ext.woff2') format('woff2');
    unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

/* latin */
@font-face {
    font-display: swap;
    font-family: 'Noto Sans Display';
    font-stretch: 100%;
    font-style: normal;
    font-weight: 800;
    src: url('./resources/fonts/noto-sans-display/static/noto-sans-display-800-latin.woff2') format('woff2');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

/* latin-ext */
@font-face {
    font-display: swap;
    font-family: 'Noto Sans Display';
    font-stretch: 100%;
    font-style: normal;
    font-weight: 900;
    src: url('./resources/fonts/noto-sans-display/static/noto-sans-display-900-latin-ext.woff2') format('woff2');
    unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

/* latin */
@font-face {
    font-display: swap;
    font-family: 'Noto Sans Display';
    font-stretch: 100%;
    font-style: normal;
    font-weight: 900;
    src: url('./resources/fonts/noto-sans-display/static/noto-sans-display-900-latin.woff2') format('woff2');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

// == Static font Noto SANS
// At the moment only latin and latin extended are added to the project V20
// cyrillic and greek are not included
// https://fonts.googleapis.com/css2?family=Noto+Sans+Display:ital,wght@0,300;0,800;0,900;1,300;1,800;1,900&display=swap
@font-face {
    font-display: swap;
    font-family: 'Noto Sans';
    font-style: italic;
    font-weight: 300;
    src: url('./resources/fonts/noto-sans/noto-sans-italic-300-latin-ext.woff2') format('woff2');
    unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

/* latin */
@font-face {
    font-display: swap;
    font-family: 'Noto Sans';
    font-style: italic;
    font-weight: 300;
    src: url('./resources/fonts/noto-sans/noto-sans-italic-300-latin.woff2') format('woff2');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

/* latin-ext */
@font-face {
    font-display: swap;
    font-family: 'Noto Sans';
    font-style: italic;
    font-weight: 400;
    src: url('./resources/fonts/noto-sans/noto-sans-italic-400-latin-ext.woff2') format('woff2');
    unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

/* latin */
@font-face {
    font-display: swap;
    font-family: 'Noto Sans';
    font-style: italic;
    font-weight: 400;
    src: url('./resources/fonts/noto-sans/noto-sans-italic-400-latin.woff2') format('woff2');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

/* latin-ext */
@font-face {
    font-display: swap;
    font-family: 'Noto Sans';
    font-style: italic;
    font-weight: 500;
    src: url('./resources/fonts/noto-sans/noto-sans-italic-500-latin-ext.woff2') format('woff2');
    unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

/* latin */
@font-face {
    font-display: swap;
    font-family: 'Noto Sans';
    font-style: italic;
    font-weight: 500;
    src: url('./resources/fonts/noto-sans/noto-sans-italic-500-latin.woff2') format('woff2');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

/* latin-ext */
@font-face {
    font-display: swap;
    font-family: 'Noto Sans';
    font-style: italic;
    font-weight: 700;
    src: url('./resources/fonts/noto-sans/noto-sans-italic-700-latin-ext.woff2') format('woff2');
    unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

/* latin */
@font-face {
    font-display: swap;
    font-family: 'Noto Sans';
    font-style: italic;
    font-weight: 700;
    src: url('./resources/fonts/noto-sans/noto-sans-italic-700-latin.woff2') format('woff2');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}


/* latin-ext */
@font-face {
    font-display: swap;
    font-family: 'Noto Sans';
    font-style: normal;
    font-weight: 300;
    src: url('./resources/fonts/noto-sans/noto-sans-300-latin-ext.woff2') format('woff2');
    unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

/* latin */
@font-face {
    font-display: swap;
    font-family: 'Noto Sans';
    font-style: normal;
    font-weight: 300;
    src: url('./resources/fonts/noto-sans/noto-sans-300-latin.woff2') format('woff2');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

/* latin-ext */
@font-face {
    font-display: swap;
    font-family: 'Noto Sans';
    font-style: normal;
    font-weight: 400;
    src: url('./resources/fonts/noto-sans/noto-sans-400-latin-ext.woff2') format('woff2');
    unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

/* latin */
@font-face {
    font-display: swap;
    font-family: 'Noto Sans';
    font-style: normal;
    font-weight: 400;
    src: url('./resources/fonts/noto-sans/noto-sans-400-latin.woff2') format('woff2');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

/* latin-ext */
@font-face {
    font-display: swap;
    font-family: 'Noto Sans';
    font-style: normal;
    font-weight: 500;
    src: url('./resources/fonts/noto-sans/noto-sans-500-latin-ext.woff2') format('woff2');
    unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

/* latin */
@font-face {
    font-display: swap;
    font-family: 'Noto Sans';
    font-style: normal;
    font-weight: 500;
    src: url('./resources/fonts/noto-sans/noto-sans-500-latin.woff2') format('woff2');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

/* latin-ext */
@font-face {
    font-display: swap;
    font-family: 'Noto Sans';
    font-style: normal;
    font-weight: 700;
    src: url('./resources/fonts/noto-sans/noto-sans-700-latin-ext.woff2') format('woff2');
    unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

/* latin */
@font-face {
    font-display: swap;
    font-family: 'Noto Sans';
    font-style: normal;
    font-weight: 700;
    src: url('./resources/fonts/noto-sans/noto-sans-700-latin.woff2') format('woff2');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
