@use 'sass:map';
@use '@oecd/foundation/src/site/config/spacing-vars' as *;
@use '@oecd/util-breakpoint' as *;
@use '@oecd/util-button' as *;
@use '@oecd/util-distance' as d;
@use '@oecd/util-text-style' as *;

.cmp-event-header {
    --navigation-height: var(--main-navigation-height, #{d.rem(64)});

    // Colors
    --header-background-color: var(--color-background);
    --header-text-color: var(--color-text-secondary);
    --header-title-color: var(--color-text-primary);
    --header-title-highlight-color: var(--color-text-title-accent);
    --header-description-color: var(--header-text-color);

    // Minimum height
    --header-min-height: #{d.rem(480)};

    // Header visual aspect ratio
    --header-visual-aspect-ratio: 2/1;

    // Distance
    --header-breadcrumb-margin-block-start: #{map.get($spacing, s-16)};
    --header-breadcrumb-margin-block-end: #{map.get($spacing, s-32)};
    --header-description-margin-block-start: #{map.get($spacing, s-16)};
    --header-tags-margin-block-start: #{map.get($spacing, s-16)};
    --header-cta-margin-block-start: #{map.get($spacing, s-24)};
    --header-keylinks-padding-block-start: #{map.get($spacing, s-32)};
    --header-prefix-margin-block-end: #{map.get($spacing, s-8)};

    background-color: var(--header-background-color);
    display: grid;
    grid-template-columns: [full-start] var(--grid-offset) [container-start] minmax(0, var(--container-max-width)) [container-end] var(--grid-offset) [full-end];

    @include breakpoint-from(desktop) {
        --header-cta-margin-block-start: #{map.get($spacing, s-32)};
        --header-description-margin-block-start: #{map.get($spacing, s-24)};
        --navigation-height: var(--main-navigation-height, #{d.rem(80)});
    }

    &__container {
        grid-column: container;

        @include breakpoint-from(tablet) {
            column-gap: var(--grid-gap);
            display: grid;
            grid-template-columns: repeat(12, 1fr);
            grid-template-rows: min-content;
            min-height: var(--header-min-height);
        }
    }

    &__breadcrumb {
        grid-column: 1 / span 12;
        grid-row: 1;
        margin-block: var(--header-breadcrumb-margin-block-start) var(--header-breadcrumb-margin-block-end);
        padding-block-start: var(--navigation-height); // Set padding top to account for main navigation
    }

    &__content-area {
        padding-block-end: map.get($spacing, s-32);

        @include breakpoint-from(tablet) {
            grid-column: 1 / span 10;
        }

        @include breakpoint-from(desktop-small) {
            grid-column: 1 / span 8;
        }

        @include breakpoint-from(desktop) {
            padding-block-end: map.get($spacing, s-64);
        }
    }

    &__content {
        container-name: event-header-details;
        container-type: inline-size;
    }

    &__title {
        @include text-style(heading-hero, 3, false, false, true);

        @include breakpoint-from(desktop-small) {
            @include text-style(heading-hero, 2, false, false, true);
        }

        color: var(--header-title-color);

        b,
        strong {
            color: var(--header-title-highlight-color);
            font-weight: inherit;
        }
    }

    &__description {
        color: var(--header-description-color);
        margin-block-start: var(--header-description-margin-block-start);

        @include text-style(body, 2);

        @include breakpoint-from(desktop-small) {
            @include text-style(body, 1);
        }
    }

    &__tags {
        margin-block-start: var(--header-tags-margin-block-start);

        @include breakpoint-from(desktop) {
            --header-tags-margin-block-start: #{map.get($spacing, s-24)};
        }
    }

    &__event-details {
        display: grid;
        gap: map.get($spacing, s-8) map.get($spacing, s-32);
        grid-template-columns: repeat(6, 1fr);
        margin-block-start: map.get($spacing, s-24);

        @include breakpoint-from(desktop) {
            margin-block-start: map.get($spacing, s-32);
        }

        &-field {
            grid-column: span 3;

            @container event-header-details (min-width: 500px) {
                grid-column: span 2;
            }
        }

        dt {
            color: var(--color-text-primary);

            @include text-style(label, 2, b);
        }

        dd {
            color: var(--color-text-primary-transparent);

            @include text-style(label, 2);
        }
    }

    &__cta {
        --button-background: var(--color-primary);
        --button-background-hover: var(--color-primary-hover);
        --button-border: var(--color-primary);
        --button-txt: var(--color-text-primary-inverse);
        --button-border-hover: var(--color-primary-hover);
        --button-txt-hover: var(--button-txt);
        --button-outline: var(--color-focus-outline);

        width: 100%;

        .cmp-button {
            display: inline-block;
            margin-block-start: var(--header-cta-margin-block-start);

            @include button-style;

            &.cmp-button-download {
                @include button-icon(--icon-external, $icon-only: false, $show-copy: true);

                &[download] {
                    &::before {
                        mask-image: var(--icon-download);
                    }
                }
            }
        }
    }

    &__visual {
        display: none;
        position: relative;

        .image {
            height: 100%;
            left: 0;
            position: absolute;
            top: 0;
            width: 100%;
            z-index: 1;
        }

        .cmp-image {
            height: 100%;
            position: absolute;
            width: 100%;

            &__image {
                height: 100%;
                object-fit: cover;
                object-position: center center;
                width: 100%;
            }
        }
    }

    &:has(.cmp-image) {
        .cmp-event-header {
            &__container {
                @include breakpoint-from(tablet) {
                    padding-block-end: map.get($spacing, s-32);
                }

                @include breakpoint-from(desktop) {
                    padding-block-end: map.get($spacing, s-64);
                }
            }

            // Remove the padding because it needs to align to the image
            &__content-area {
                @include breakpoint-from(tablet) {
                    grid-column: 1 / span 6;
                    padding-block-end: 0;
                }
            }

            &__visual {
                aspect-ratio: var(--header-visual-aspect-ratio);
                display: block;

                @include breakpoint-from(tablet) {
                    --header-visual-aspect-ratio: 4/5;

                    align-self: start;
                    grid-column: 7 / span 6;
                }

                @include breakpoint-from(desktop-small) {
                    --header-visual-aspect-ratio: 5/4;
                }

                @include breakpoint-until(tablet) {
                    // Ensure Image components bleed on small screens
                    margin-inline: calc(var(--grid-offset) * -1);
                }
            }
        }
    }
}
