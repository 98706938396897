@use 'sass:map';
@use '@oecd/foundation/src/site/config/spacing-vars' as *;
@use '@oecd/util-a11y' as *;
@use '@oecd/util-breakpoint' as *;
@use '@oecd/util-text-style' as *;

.report-navigation-mobile {
    --report-navigation-mobile-sticky-offset: var(--content-language-picker-height, 0px); /* stylelint-disable-line  length-zero-no-unit */

    display: grid;
    grid-template-columns: [full-start] var(--grid-offset) [container-start] minmax(0, var(--container-max-width)) [container-end] var(--grid-offset) [full-end];
    position: relative;
    z-index: var(--z-index-report-navigation-mobile, 500);

    @supports (position: sticky) or (position: -webkit-sticky) {
        inset-block-start: var(--report-navigation-mobile-sticky-offset);
        position: sticky;

        // While scrolling up
        .js-is-scrolling-up & {
            --report-navigation-mobile-sticky-offset: 0;

            position: relative;
        }
    }
}

.cmp-report-navigation-mobile {
    --report-navigation-mobile-background-color: var(--color-background);
    --report-navigation-mobile-color: var(--color-text-primary);
    --report-navigation-mobile-cta-color: var(--report-navigation-mobile-color);

    grid-column: container;

    @include breakpoint-from(desktop-small) {
        display: none;
    }

    &::after {
        background-color: var(--report-navigation-mobile-background-color);
        border-block-end: 1px solid var(--color-border-transparent);
        content: '';
        display: block;
        height: 100%;
        inset-block-end: 0;
        inset-inline-start: 0;
        position: absolute;
        width: 100vw;
        z-index: -1;
    }

    &__wrapper {
        align-items: center;
        column-gap: map.get($spacing, s-32);
        display: flex;
        justify-content: space-between;
    }

    &__label {
        @include text-style(label, 2, b);

        color: var(--report-navigation-mobile-color);
    }

    &__menu-trigger {
        color: var(--report-navigation-mobile-cta-color);
        line-height: 0;
        margin-inline-end: -#{map.get($spacing, s-12)};
        padding: map.get($spacing, s-24) map.get($spacing, s-12);
        transition: color 0.15s ease-in-out 0.3s;

        &:hover {
            --report-navigation-mobile-cta-color: var(--color-primary);
        }

        &:focus-visible {
            --report-navigation-mobile-cta-color: var(--color-primary);

            outline: 2px solid var(--color-focus-outline);
        }

        &-icon {
            vertical-align: middle;
        }

        &-text {
            @include visually-hidden;
        }
    }
}

.js-mobile-report-navigation-is-open {
    // Hide these elements otherwise the root container is slightly scrollable
    .cmp-page__skiptomaincontent,
    .cmp-page__back-to-top-button {
        display: none;
    }

    // Restore fixed elements back to normal flow position when menu is open
    .content-language-picker,
    .report-navigation-mobile,
    .secondary-navigation {
        position: relative;
    }

    // Make the root container scrollable instead of the body
    // See https://www.jayfreestone.com/writing/locking-body-scroll-ios/
    .root.container {
        height: 100%;

        // Allow the main content to be scrolled,
        // so we can adjust the scroll position with JS.
        overflow: auto;
        position: fixed;
        width: 100%;
    }
}
