@use 'sass:map';
@use 'sass:math';
@use '@oecd/util-breakpoint' as *;
@use '@oecd/util-distance' as d;
@use '@oecd/foundation/src/site/config/spacing-vars' as *;

.cmp-kappa-full-report-reference {
    --prose-negative-spacing: calc(var(--layout-spacing-prose) - #{map.get($spacing, s-24)});

    // All components except title components have prose spacing 32px
    > :where(*:not(.title)) {
        margin-block-end: var(--layout-spacing-prose);
    }

    // Last item of this coomponent cannot have a bottom margin because it is allready inside a component
    // otherwise the margin between components is not according to design
    > :where(*:last-child) {
        margin-block-end: 0;
    }

    // Button following on text component can only have a spacing of 24px
    // Button following on button component can only have a spacing of 24px
    // So we need to have a negative margin top that equals the prose spacing minus 24px
    > :where(.text + .button, .button + .button) {
        margin-block-start: calc(var(--prose-negative-spacing) * -1);
    }

    @include breakpoint-until(tablet) {
        // Ensure Image components bleed on small screens
        > :where(.image:not(.image--reduced-width)) {
            margin-inline: calc(var(--grid-offset) * -1);

            // Ensure image caption does not stick to edge
            .cmp-image__title {
                padding-inline: var(--grid-offset);
            }
        }

        // Ensure embeds bleed on small screens
        > :where(.embed) {
            margin-inline: calc(var(--grid-offset) * -1);
        }
    }

    // Two column container starts from desktop small
    .column-container--two-col-6-6 & {
        @include breakpoint-until(desktop-small) {
            .image--reduced-width {
                margin-inline: auto;
                max-width: d.rem(395); // 395 equals 4 columns on desktop
            }
        }

        @include breakpoint-from(desktop-small) {
            .image--reduced-width {
                column-gap: var(--grid-gap);
                display: grid;
                grid-template-columns: repeat(6, 1fr);

                > * {
                    grid-column: 2 / span 4;
                }
            }

            // Text title and button are not allowed to go the end of the column width per design
            // Converted to direct child selector otherwise styles will leak into composite components
            > .text,
            > .button,
            > .title {
                max-width: calc(100% - var(--grid-gap));
            }
        }
    }

    .column-container--two-col-4-8 &,
    .column-container--two-col-8-4 {
        @include breakpoint-from(tablet) {
            column-gap: var(--grid-gap);
            display: grid;
            grid-template-columns: repeat(8, 1fr);

            > *:not(.image, .embed, .list, .teaser, .chart, .chart-with-controls, .descriptive-asset, .featured-card, .highlight-container, .kappa-full-report-reference, .two-column-container) {
                grid-column: 1 / span 7;
            }

            > .image,
            > .descriptive-asset,
            > .embed,
            > .list,
            > .teaser,
            > .highlight-container,
            > .chart,
            > .chart-with-controls,
            > .two-column-container,
            > .featured-card {
                grid-column: 1 / span 8;
            }

            > .highlight-container--content-wide {
                grid-column: 1 / span 7;
            }

            > .cmp-form-container {
                grid-column: 1 / span 5;
            }

            > .image--reduced-width {
                grid-column: 2 / span 4;
            }
        }

        @include breakpoint-from(desktop-small) {
            > *:not(.image, .embed, .descriptive-asset, .list, .teaser, .chart, .chart-with-controls, .featured-card, .highlight-container, .two-column-container) {
                grid-column: 1 / span 6;
            }

            > .highlight-container--content-wide {
                grid-column: 1 / span 6;
            }

            > .cmp-form-container {
                grid-column: 1 / span 4;
            }
        }
    }
}
