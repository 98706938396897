@use 'sass:map';
@use '@oecd/foundation/src/site/config/spacing-vars' as *;
@use '@oecd/util-breakpoint' as *;
@use '@oecd/util-distance' as d;
@use '@oecd/util-text-style' as *;

.cmp-person {
    --person-padding-block: #{map.get($spacing, s-16)};
    --person-padding-inline: #{map.get($spacing, s-16)};
    --person-image-aspect-ratio: 1/1;
    --person-image-border-radius: 50%;
    --person-image-width: #{d.rem(110)};
    --person-name-color: var(--color-text-primary);
    --person-job-title-margin-block-start: #{map.get($spacing, s-8)};
    --person-cta-margin-block-start: #{map.get($spacing, s-8)};

    &:has(.image--rectangular) {
        --person-image-aspect-ratio: 3/4;
        --person-image-border-radius: #{d.rem(4)};
        --person-image-width: #{d.rem(150)};
    }

    align-items: top;
    display: flex;
    flex-direction: row-reverse;
    line-height: 0;

    &__content {
        display: flex;
        flex: 1;
        flex-direction: column;
        justify-content: center;
        padding: var(--person-padding-block) var(--person-padding-inline);
        width: 0;
    }

    &__visual {
        width: var(--person-image-width);

        .cmp-image {
            aspect-ratio: var(--person-image-aspect-ratio);

            img {
                background-position: center;
                background-size: cover;
                border-radius: var(--person-image-border-radius);
                height: 100%;
                object-fit: cover;
                width: 100%;
            }
        }

        .person__fallback-image {
            aspect-ratio: var(--person-image-aspect-ratio);
            background-color: var(--color-background-light);
            border-radius: var(--person-image-border-radius);
            width: var(--person-image-width);
        }
    }

    .jobtitle {
        .cmp-text {
            @include text-style(label, 3);

            margin-block-start: var(--person-job-title-margin-block-start);

            a {
                display: inline-block;
                padding-block: map.get($spacing, s-8);
                position: relative;

                & ~ a {
                    padding-block-start: 0;
                }
            }
        }
    }

    .name {
        .cmp-text {
            @include text-style(body, 2, b);

            color: var(--person-name-color);
        }
    }

    .cmp-button {
        margin-block-start: var(--person-cta-margin-block-start);
    }
}

// Styling of parent container for person components in PT article.
.person-container {
    &:has(.cmp-person) {
        margin-block-start: map.get($spacing, s-32);

        @include breakpoint-from(tablet) {
            margin-block-start: map.get($spacing, s-48);
        }
    }

    .cmp-container {
        @include breakpoint-until(mobile-large) {
            .person + .person {
                margin-block-start: var(--grid-gap);
            }
        }

        @include breakpoint-from(mobile-large) {
            display: grid;
            gap: map.get($spacing, s-16) var(--grid-gap);
            grid-template-columns: repeat(12, 1fr);
        }
    }

    .person {
        &:nth-child(odd) {
            @include breakpoint-from(mobile-large) {
                grid-column: 2/ span 5;
            }

            @include breakpoint-from(desktop) {
                grid-column: 3/ span 4;
            }
        }

        &:nth-child(even) {
            @include breakpoint-from(mobile-large) {
                grid-column: 7/ span 5;
            }

            @include breakpoint-from(desktop) {
                grid-column: 7/ span 4;
            }
        }
    }

    .cmp-person {
        --person-image-width: #{d.rem(50)};
        --person-padding-block: #{map.get($spacing, s-4)};
        --person-job-title-margin-block-start: #{map.get($spacing, s-4)};

        &:has(.image--rectangular) {
            --person-image-aspect-ratio: 1/1;
            --person-image-border-radius: #{d.rem(4)};
            --person-image-width: #{d.rem(95)};
        }
    }

    .name {
        .cmp-text {
            @include text-style(body, 4, b);
        }
    }
}
