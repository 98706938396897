@use 'sass:map';
@use 'sass:math';
@use '@oecd/foundation/src/site/config/spacing-vars' as *;
@use '@oecd/util-breakpoint' as *;
@use '@oecd/util-distance' as d;
@use '@oecd/util-focus' as *;
@use '@oecd/util-text-style' as *;

.top-stories {
    container-name: top-stories;
    container-type: inline-size;
}

.cmp-top-stories {
    display: flex;
    flex-direction: column;

    @include breakpoint-from(desktop-small) {
        column-gap: var(--grid-gap);
        display: grid;
        grid-template-columns: 2fr 1fr;
        grid-template-rows: min-content 1fr;
    }

    @container top-stories (max-width: 599px) {
        margin-block-start: 0;
    }

    &__header {
        align-items: center;
        display: flex;
        flex-wrap: wrap;
        margin-block-end: map.get($spacing, s-24);
        order: 2;

        @include breakpoint-from(desktop-small) {
            grid-column: 2;
            grid-row: 1;
        }
    }

    &__title {
        margin-inline-end: auto;
        padding-inline-end: map.get($spacing, s-16);

        .cmp-title__text {
            @include text-style(heading, 2);

            margin-block-end: 0;

            @include breakpoint-from(tablet) {
                @include text-style(heading, 3);
            }
        }
    }

    &__cta {
        .cmp-button {
            margin-block-start: map.get($spacing, s-8);
        }
    }

    &__featured-story {
        height: 100%;
        order: 1;
        position: relative;

        .featured-top-story {
            height: 100%;
        }

        @container top-stories (max-width: 599px) {
            margin-block-end: map.get($spacing, s-40);
        }

        @container top-stories (min-width: 600px) {
            margin-block-end: map.get($spacing, s-16);
        }

        @container top-stories (min-width: 929px) {
            margin-block-end: 0;
        }

        @include breakpoint-from(desktop-tablet) {
            grid-column: 1;
            grid-row: 1 / -1;
        }
    }

    &__stories {
        order: 3;

        @include breakpoint-from(desktop-tablet) {
            grid-column: 2;
            grid-row: 2;
        }
    }
}
