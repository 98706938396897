@use 'sass:map';
@use 'colors' as colors;

.card {
    @each $theme-color in colors.$theme-colors {
        &--#{$theme-color}-theme {
            --list-card-border-color: none;
            --list-card-border-color-hover: none;
            --list-card-content-background-color: var(--color-theme-#{$theme-color}-background);
            --list-card-content-background-color-hover: var(--color-theme-#{$theme-color}-background-hover);
            --list-card-image-background-color: transparent;
            --list-card-metadata-text-color: var(--color-theme-#{$theme-color}-text);
            --list-card-tag-text-color: var(--color-theme-#{$theme-color}-text);
            --list-card-title-text-color: var(--color-theme-#{$theme-color}-text);
            --list-card-title-text-color-hover: var(--color-theme-#{$theme-color}-text);
            --list-card-title-highlight-color: var(--color-theme-#{$theme-color}-text-accent);

            &:hover {
                background-color: var(--list-card-content-background-color-hover);
            }
        }
    }

    // Light Themes
    @each $theme-color in colors.$light-theme-colors {
        &--#{$theme-color}-theme {
            --list-card-tag-background-color: var(--color-border-transparent);
        }
    }

    // Dark Themes
    @each $theme-color in colors.$dark-theme-colors {
        &--#{$theme-color}-theme {
            --list-card-tag-background-color: var(--color-border-transparent-inverse);
        }
    }
}
