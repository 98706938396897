@use 'sass:map';
@use '@oecd/foundation/src/site/config/spacing-vars' as *;
@use '@oecd/util-distance' as d;
@use '@oecd/util-text-style' as *;

.key-links {
    &__title {
        color: var(--header-text-color);
        margin-block-end: #{map.get($spacing, s-8)};

        @include text-style(label, 3);
    }
}

.keylink {
    --keylink-text: var(--color-text-primary);
    --keylink-text-hover: var(--color-text-link-hover);
    --keylink-icon: var(--icon-chevron-small);
    --keylink-icon-color: var(--color-text-primary);
    --keylink-outline: var(--color-focus-outline);

    &:hover,
    &:focus {
        color: var(--keylink-text-hover);
    }

    border-radius: 0;
    color: var(--keylink-text);
    display: inline-block;
    max-width: 100%;
    min-height: d.rem(20);
    padding: 0;
    position: relative;

    @media (prefers-reduced-motion: no-preference) {
        transition-duration: 250ms;
        transition-property: color;
        transition-timing-function: cubic-bezier(0.4, 0, 0.4, 1);
    }

    &:focus {
        outline: none;
    }

    &:focus-visible {
        line-height: 0;
        outline: 2px solid var(--keylink-outline);
        outline-offset: 2px;
    }

    &__text {
        display: inline-block;
        max-width: 100%;
        overflow: hidden;
        padding-inline-end: d.rem(24); // Width of the icon + 8;
        position: relative;
        text-overflow: ellipsis;
        white-space: nowrap;

        // line-height equals height of button
        @include text-style('label', 2, b, 1.43);

        &::after {
            background: var(--keylink-icon-color);
            content: '';
            display: block;
            height: d.rem(16);
            mask-image: var(--keylink-icon);
            mask-position: center;
            mask-repeat: no-repeat;
            mask-size: contain;
            position: absolute;
            right: 0;
            top: d.rem(3);
            width: d.rem(16);

            @media screen and (forced-colors: active) {
                background: LinkText;
            }
        }
    }
}
