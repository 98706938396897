@use 'sass:map';
@use '@oecd/foundation/src/site/config/spacing-vars' as *;
@use '@oecd/util-a11y' as *;
@use '@oecd/util-breakpoint' as *;
@use '@oecd/util-distance' as d;
@use '@oecd/util-focus' as *;
@use '@oecd/util-text-style' as *;

.content-language-picker {
    --content-language-picker-sticky-offset: 0;

    display: grid;
    grid-template-columns: [full-start] var(--grid-offset) [wrapper-start] minmax(0, var(--container-max-width)) [wrapper-end] var(--grid-offset) [full-end];
    pointer-events: none;
    position: relative;
    z-index: var(--z-index-content-language-picker, 550);

    @supports (position: sticky) or (position: -webkit-sticky) {
        inset-block-start: var(--content-language-picker-sticky-offset);
        position: sticky;

        // While scrolling up
        .js-is-scrolling-up & {
            position: relative;
        }
    }

    // Ensure margins are correct should it be placed inside a container-content
    .container-content > :where(.cmp-container) > * + & {
        inset-block-start: calc(var(--layout-spacing-default) * -1);
    }

    // In the narrow variant used on PT Article
    // the borders on the Content Language Picker should only bleed,
    // take up the entire screen when it is sticky
    &--narrow:not(.js-is-stuck) {
        @include breakpoint-from(mobile-large) {
            .cmp-content-language-picker {
                &__content {
                    &::before {
                        margin-inline-start: initial;
                        transform: translate3d(0, 0, 0);
                        width: 100%;
                    }
                }
            }
        }
    }
}

.cmp-content-language-picker {
    --content-language-picker-background-color: var(--color-background);
    --content-language-picker-color: var(--color-text-secondary);
    --content-language-picker-border-color: var(--color-border);
    --content-language-picker-active-language-background: var(--color-primary-light);

    // Language switch trigger
    --content-language-picker-trigger-background-color: transparent;
    --content-language-picker-trigger-color: var(--color-text-primary);
    --content-language-picker-trigger-color-hover: var(--color-primary);
    --content-language-picker-trigger-background-color-hover: transparent;
    --content-language-picker-box-shadow: 0 0 36px 0 rgba(0, 0, 0, 10%);
    --content-language-picker-trigger-border-color: var(--color-border);

    grid-column: wrapper;
    pointer-events: all;

    // Used on PT article where it should align with the article header
    .content-language-picker--narrow & {
        @include breakpoint-from(mobile-large) {
            column-gap: var(--grid-gap);
            display: grid;
            grid-template-columns: repeat(12, 1fr);
        }

        .cmp-content-language-picker {
            &__wrapper {
                @include breakpoint-from(mobile-large) {
                    grid-column: 2 / span 10;
                }

                @include breakpoint-from(desktop) {
                    grid-column: 3 / span 8;
                }
            }
        }
    }

    &__wrapper {
        container-name: content-language-picker;
        container-type: inline-size;
        position: relative;
    }

    &__content {
        display: flex;
        flex-wrap: wrap;
        padding-block: map.get($spacing, s-16);

        &::before {
            background-color: var(--content-language-picker-background-color);
            border-bottom: 1px solid var(--content-language-picker-border-color);
            border-top: 1px solid var(--content-language-picker-border-color);
            content: '';
            display: block;
            height: 100%;
            inset-block-start: 0;
            margin-inline-start: 50%;
            padding-inline: 0;
            position: absolute;
            transform: translate3d(-50%, 0, 0);
            width: 100vw;
            z-index: -1;
        }

        // When the Content Language Picker is sticky and smaller than 700px
        // only the actions should be visible if there are actions configured
        .js-is-stuck:not(.js-has-no-actions) & {
            @container content-language-picker (max-width: 699px) {
                // 14px = margin top of cta's + 2px of borders
                transform: translateY(calc((var(--content-language-picker-languages-height) + 14px ) * -1));
                transition: transform 0.3s linear;
            }
        }

        @container content-language-picker (min-width: 700px) {
            align-items: center;
            flex-wrap: nowrap;
        }
    }

    &__languages {
        @include text-style(label, 2, m);

        align-items: center;
        display: flex;
        flex: 1 0 auto;

        @container content-language-picker (min-width: 700px) {
            min-height: d.rem(48);
            width: d.rem(1);
        }
    }

    &__languages-title {
        color: var(--content-language-picker-color);
        display: inline-block;

        @container content-language-picker (max-width: 699px) {
            flex: 1 0 auto;
            margin-inline-end: auto;
            width: d.rem(1);
        }

        @container content-language-picker (min-width: 700px) {
            flex: 0 0 auto;
            margin-inline-end: map.get($spacing, s-8);
        }
    }

    &__available-language {
        color: var(--content-language-picker-color);
        display: inline-block;
        padding-block: map.get($spacing, s-4);
        position: relative;
        text-decoration: none;

        @at-root a#{&} {
            @media (prefers-reduced-motion: no-preference) {
                transition: color 0.15s linear;
            }

            &::before {
                content: '';
                display: inline-block;
                height: auto;
                inset-block: -24px;
                inset-inline: 0;
                position: absolute;
                width: 100%;
            }

            &:hover {
                --content-language-picker-color: var(--color-primary);
            }

            &:focus-visible {
                outline: none;

                &::before {
                    @include keyboardfocus;
                }
            }
        }


        &[aria-current] {
            --content-language-picker-color: var(--color-text-primary);

            background: var(--content-language-picker-active-language-background);
            border-radius: 4px;
            padding-inline: map.get($spacing, s-8);

            @media screen and (forced-colors: active) {
                border: 1px solid;
            }
        }
    }

    &__cta {
        align-items: center;
        display: flex;
        flex: 1 0 auto;
        justify-content: flex-end;
    }

    &__trigger {
        align-items: center;
        background-color: var(--content-language-picker-trigger-background-color);
        border: 1px solid var(--content-language-picker-trigger-border-color);
        border-radius: 4px;
        color: var(--content-language-picker-trigger-color);
        display: flex;
        margin-inline-start: map.get($spacing, s-4);
        padding: map.get($spacing, s-6) map.get($spacing, s-8);

        // The overlay trigger should only be visible on larger screens
        // when there are to many languages to fit on one line
        @include breakpoint-from(desktop-small) {
            .cmp-content-language-picker:not(.js-content-language-picker-overlay-mode) & {
                display: none;
            }
        }

        @media (prefers-reduced-motion: no-preference) {
            transition: background-color 0.15s linear, color 0.15s linear;
        }

        &:hover {
            background: var(--content-language-picker-trigger-background-color-hover);
            color: var(--content-language-picker-trigger-color-hover);
            transition-delay: initial;
        }

        &:focus-visible {
            outline: 2px solid var(--color-focus-outline);
            outline-offset: 2px;
        }

        &-text {
            @include text-style(label, 3, m, 1);
        }

        &-icon {
            display: inline-block;
            margin-inline-end: map.get($spacing, s-4);
        }
    }

    &__actions {
        display: flex;
        gap: map.get($spacing, s-8);
        margin-inline-start: auto;

        @container content-language-picker (max-width: 699px) {
            flex: 1 0 auto;
            justify-content: space-between;
            width: 100%;

            .cmp-content-language-picker__languages + & {
                margin-block-start: map.get($spacing, s-12);
            }
        }

        @container content-language-picker (min-width: 700px) {
            flex: 0 0 auto;
            justify-content: flex-end;
        }
    }

    &__download {
        .cmp-button {
            align-items: center;
            display: inline-flex;
            position: relative;

            &::before {
                background: currentcolor;
                content: '';
                display: inline-block;
                height: d.rem(16);
                margin-inline-end: #{map.get($spacing, s-8)};
                mask-image: var(--icon-download);
                mask-position: center;
                mask-repeat: no-repeat;
                mask-size: contain;
                vertical-align: middle;
                width: d.rem(16);

                @media screen and (forced-colors: active) {
                    background: LinkText;
                }
            }

            .cmp-button__text {
                position: relative;
            }
        }
    }

    &__available-languages {
        flex: 1 0 auto;
        margin-inline-end: auto;
        width: d.rem(1);

        @include breakpoint-until(desktop-small) {
            display: none;
        }

        // Visibiliity hidden is applied instead of display:none
        // otherwise the element has no overflow, thus triggering the javascript
        @include breakpoint-from(desktop-small) {
            .js-content-language-picker-overlay-mode & {
                visibility: hidden;
            }
        }

        ul {
            align-items: center;
            display: flex;
            gap: map.get($spacing, s-16);
            margin-inline-start: map.get($spacing, s-16);
        }
    }

    &__radios {
        padding-block: map.get($spacing, s-24) map.get($spacing, s-40);
    }

    &__radio {
        &-input {
            @include visually-hidden;

            &:checked + .cmp-content-language-picker__radio-label {
                color: var(--color-text-primary);

                &::before {
                    background-color: var(--color-form-radio);
                    transform: translateY(-50%) scale(1);
                }

                &::after {
                    border: 2px solid var(--color-form-radio);
                }
            }

            &:focus-within + .cmp-content-language-picker__radio-label {
                .js-user-is-tabbing & {
                    &::after {
                        @include keyboardfocus;

                        border-color: var(--color-form-radio);
                    }
                }

            }
        }

        &-label {
            @include text-style(body, 3);

            color: var(--color-text-secondary);
            display: block;
            padding-block: map.get($spacing, s-8);
            padding-inline-end: map.get($spacing, s-32);
            position: relative;

            &:hover {
                cursor: pointer;

                &::after {
                    border-color: var(--color-form-radio);
                }
            }

            &::before {
                background-color: transparent;
                border-radius: 100%;
                content: '';
                height: d.rem(12);
                inset-block-start: 50%;
                inset-inline-end: d.rem(6);
                position: absolute;
                transform: translateY(-50%) scale(0.5);
                transition: transform  0.3s ease-in-out;
                width: d.rem(12);
            }

            &::after {
                border: solid 1px var(--color-border-strong);
                border-radius: 100%;
                content: '';
                height: d.rem(24);
                inset-block-start: 50%;
                inset-inline-end: 0;
                position: absolute;
                transform: translateY(-50%);
                width: d.rem(24);
            }
        }
    }
}

// Prevent double border when Content Language Picker follow a configured secondary navigation
.secondary-navigation:has(.cmp-secondary-navigation) + .content-language-picker {
    .cmp-content-language-picker {
        &__content {
            &::before {
                border-top: 1px solid transparent;
            }
        }
    }
}
