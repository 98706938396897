@use 'sass:map';
@use '@oecd/foundation/src/site/config/spacing-vars' as *;

.js-sticky-scroll-interaction {
    --sticky-target-scroll-offset-down: calc(var(--content-language-picker-height, 0px) + var(--chart-control-bar-height, 0px) + var(--jumplinks-height, 0px) + var(--report-navigation-mobile-height, 0px) + #{map.get($spacing, s-32)});
    --sticky-target-scroll-offset-up: calc(var(--secondary-navigation-height, 0px) + #{map.get($spacing, s-32)});

    [id]:not([id='top']):target {
        scroll-margin-block-start: var(--sticky-target-scroll-offset-down);
    }
}
