@use 'sass:map';
@use '@oecd/foundation/src/site/config/spacing-vars' as *;
@use '@oecd/util-breakpoint' as *;
@use '@oecd/util-button' as *;
@use '@oecd/util-distance' as d;
@use '@oecd/util-text-style' as *;

.teaser {
    container-name: teaser;
    container-type: inline-size;
    isolation: isolate;

    // Let the teaser take up the space of the entire column
    // if it is placed in a column container with equal height modifier
    // and it is the only component placed in this container
    .column-container--equal-height & {
        &:only-child {
            flex: 1 0 auto;

            .cmp-teaser {
                height: 100%;
            }
        }
    }
}

.cmp-teaser {
    --teaser-background-color: var(--color-primary-light);
    --teaser-background-gradient-rgb: var(--color-background-inverse-rgb);
    --teaser-border-radius: 4px;
    --teaser-image-overlay-color: var(--color-primary);
    --teaser-image-gradient-overlay-color-rgb: var(--color-background-inverse-rgb);
    --teaser-info-icon-background: var(--color-primary);
    --teaser-info-icon-color: var(--color-icon-inverse);
    --teaser-padding-block-end: #{map.get($spacing, s-24)};
    --teaser-padding-block-start: #{map.get($spacing, s-24)};
    --teaser-padding-inline-end: #{map.get($spacing, s-24)};
    --teaser-padding-inline-start: #{map.get($spacing, s-24)};

    background-color: var(--teaser-background-color);
    border-radius: var(--teaser-border-radius);
    position: relative;

    &__content {
        padding: var(--teaser-padding-block-start) var(--teaser-padding-inline-end) var(--teaser-padding-block-end) var(--teaser-padding-inline-start);

        @container teaser (min-width: 609px) {
            align-items: center;
            column-gap: map.get($spacing, s-24);
            display: grid;
            grid-template-areas:
                'pretitle button'
                'title button'
                'description button';
            grid-template-columns: repeat(2, auto);
        }
    }

    &__visual {
        display: none;
    }

    .pretitle {
        @container teaser (min-width: 609px) {
            grid-area: pretitle;
        }

        .cmp-text {
            margin-block-end: map.get($spacing, s-8);

            @include text-style(label, 2);
        }
    }

    .description {
        @container teaser (min-width: 609px) {
            grid-area: description;
        }

        .cmp-text {
            @include text-style(label, 2);
        }
    }

    .title {
        @container teaser (min-width: 609px) {
            grid-area: title;
        }

        .cmp-title__text {
            margin-block-end: map.get($spacing, s-8);

            @include text-style(label, 2, b);
        }
    }

    .button {
        @container teaser (min-width: 609px) {
            grid-area: button;
            margin-inline-start: auto;
        }
    }

    .cmp-button {
        @include button-style;

        --button-background: var(--color-primary);
        --button-background-hover: var(--color-primary-hover);
        --button-border: var(--color-primary);
        --button-txt: var(--color-text-primary-inverse);
        --button-border-hover: var(--color-primary-hover);
        --button-txt-hover: var(--button-txt);
        --button-outline: var(--color-focus-outline);

        margin-block-start: map.get($spacing, s-24);

        @container teaser (min-width: 609px) {
            margin-block-start: 0;
        }

        &.cmp-button-download {
            @include button-icon(--icon-external, $icon-only: false, $show-copy: true);

            &[download] {
                &::before {
                    mask-image: var(--icon-download);
                }
            }
        }
    }

    /////////////////
    // Information //
    /////////////////
    &--information {
        .cmp-teaser {
            --teaser-padding-block-end: #{map.get($spacing, s-12)};
            --teaser-padding-block-start: #{map.get($spacing, s-12)};
            --teaser-padding-inline-end: #{map.get($spacing, s-12)};
            --teaser-padding-inline-start: #{map.get($spacing, s-12)};

            @container teaser (min-width: 609px) {
                --teaser-padding-inline-end: #{map.get($spacing, s-16)};
                --teaser-padding-inline-start: #{d.rem(60)};
            }

            &__content {
                position: relative;

                @container teaser (min-width: 609px) {
                    align-items: center;
                    display: flex;
                    flex-direction: row;
                    gap: map.get($spacing, s-16);
                }

                &::after {
                    background: var(--teaser-info-icon-color);
                    content: '';
                    display: block;
                    height: d.rem(16);
                    mask-image: var(--icon-information);
                    mask-position: center;
                    mask-repeat: no-repeat;
                    mask-size: contain;
                    position: absolute;
                    right: calc(var(--teaser-padding-inline-end) + d.rem(2));
                    top: calc(var(--teaser-padding-block-start) + d.rem(2));
                    width: d.rem(16);

                    @container teaser (min-width: 609px) {
                        left: calc(map.get($spacing, s-24) + d.rem(2));
                        position: absolute;
                        right: 0;
                        top: cal(var(--teaser-padding-block-start) + d.rem(12));
                    }
                }

                &::before {
                    background: var(--teaser-info-icon-background);
                    border-radius: 100%;
                    content: '';
                    display: block;
                    height: d.rem(20);
                    position: absolute;
                    right: var(--teaser-padding-inline-end);
                    top: var(--teaser-padding-block-start);
                    width: d.rem(20);

                    @container teaser (min-width: 609px) {
                        left: map.get($spacing, s-24);
                        position: absolute;
                        right: 0;
                        top: cal(var(--teaser-padding-block-start) + d.rem(10));
                    }
                }
            }

            .pretitle {
                display: none;
            }

            .description .cmp-text {
                @include text-style(label, 3);

                @container teaser (min-width: 609px) {
                    @include text-style(label, 2);
                }
            }

            .title .cmp-title__text {
                @include text-style(label, 3, b);

                @container teaser (min-width: 609px) {
                    margin-block-end: 0;

                    @include text-style(label, 2, b);
                }
            }

            .button {
                @container teaser (min-width: 609px) {
                    margin-inline-start: auto;
                }
            }

            .cmp-button {
                margin-block-start: map.get($spacing, s-16);

                @container teaser (min-width: 609px) {
                    margin-block-start: 0;
                }
            }
        }
    }

    //////////////////////////////
    // Background Image & Image //
    //////////////////////////////
    &--background-image,
    &--image {
        .cmp-teaser {
            --teaser-background-color: var(--color-background-inverse);
            --teaser-border-radius: 0;

            overflow: hidden;

            @container teaser (min-width: 391px) {
                --teaser-border-radius: 4px;
                --teaser-padding-block-end: #{map.get($spacing, s-40)};
                --teaser-padding-block-start: #{map.get($spacing, s-40)};
                --teaser-padding-inline-end: #{map.get($spacing, s-40)};
                --teaser-padding-inline-start: #{map.get($spacing, s-40)};
            }

            @container teaser (min-width: 609px) {
                --teaser-padding-block-end: #{map.get($spacing, s-56)};
                --teaser-padding-block-start: #{map.get($spacing, s-56)};
                --teaser-padding-inline-end: #{map.get($spacing, s-56)};
                --teaser-padding-inline-start: #{map.get($spacing, s-56)};
            }

            @include breakpoint-until(mobile-large) {
                // Ensure teaser components bleed on small screens when image is displayed beneath content
                margin-inline: calc(var(--grid-offset) * -1);

                @container teaser (min-width: 391px) {
                    margin-inline: 0;
                }
            }

            &__content {
                position: relative;
                z-index: 5;

                @container teaser (min-width: 391px) {
                    width: calc(50% + var(--teaser-padding-inline-end));
                }

                @container teaser (min-width: 609px) {
                    display: block;
                }
            }

            .pretitle .cmp-text {
                color: var(--color-text-primary-inverse);
                margin-block-end: map.get($spacing, s-6);

                @include text-style(body, 3);

                @container teaser (min-width: 609px) {
                    margin-block-end: map.get($spacing, s-8);

                    @include text-style(body, 2);
                }
            }

            .title .cmp-title__text {
                color: var(--color-text-primary-inverse);
                margin-block-end: 0;

                @include text-style(heading-hero, 4);

                @container teaser (min-width: 391px) {
                    @include text-style(heading, 3);
                }

                @container teaser (min-width: 609px) {
                    @include text-style(heading-hero, 3);
                }
            }

            .description .cmp-text {
                color: var(--color-text-primary-inverse-transparent);
                margin-block-start: map.get($spacing, s-16);

                @include text-style(body, 4);

                @container teaser (min-width: 391px) {
                    @include text-style(body, 3);
                }

                @container teaser (min-width: 609px) {
                    margin-block-start: map.get($spacing, s-8);

                    @include text-style(body, 2);
                }
            }

            .image {
                @container teaser (min-width: 391px) {
                    &,
                    .cmp-image {
                        height: 100%;
                    }

                    img {
                        height: 100%;
                        object-fit: cover;
                        object-position: right bottom;
                    }
                }

                &--dark,
                &--darker {
                    .cmp-image {
                        position: relative;

                        &::before {
                            background-color: var(--teaser-image-overlay-color);
                            content: '';
                            height: 100%;
                            inset-block-start: 0;
                            inset-inline-start: 0;
                            mix-blend-mode: multiply;
                            opacity: 0.9;
                            position: absolute;
                            width: 100%;
                            z-index: 2;
                        }

                        &__image {
                            filter: saturate(0);
                            isolation: isolate;
                            position: relative;
                            z-index: 1;
                        }
                    }
                }

                &--darker {
                    .cmp-image {
                        &::after {
                            background: radial-gradient(214.38% 50% at 50% 50%, rgba(var(--teaser-image-gradient-overlay-color-rgb), 0) 0%, rgba(var(--teaser-image-gradient-overlay-color-rgb), 1) 100%);
                            content: '';
                            height: 100%;
                            inset-block-start: 0;
                            inset-inline-start: 0;
                            mix-blend-mode: multiply;
                            opacity: 0.8;
                            position: absolute;
                            width: 100%;
                            z-index: 3;
                        }
                    }
                }
            }

            .cmp-button {
                --button-background: var(--color-background);
                --button-background-hover: var(--color-primary-light);
                --button-border: var(--color-background);
                --button-txt: var(--color-text-primary);
                --button-border-hover: var(--color-primary-light);
                --button-txt-hover: var(--button-txt);
                --button-outline: var(--color-accent);

                margin-block-start: map.get($spacing, s-24);

                @container teaser (min-width: 391px) and (max-width: 608px) {
                    --button-background: transparent;
                    --button-background-hover: transparent;
                    --button-border: transparent;
                    --button-border-hover: transparent;
                    --button-subtle-text: var(--color-text-primary-inverse);
                    --button-subtle-icon: var(--icon-chevron-small);
                    --button-subtle-icon-background: var(--color-background);
                    --button-subtle-icon-color: var(--color-text-primary);
                    --button-subtle-icon-outline: var(--color-focus-outline-inverse);
                    --button-subtle-text-hover: var(--color-text-accent-light);

                    &:not(.cmp-button-download) {
                        @include button-subtle;
                    }

                    &.cmp-button-download {
                        --button-background: transparent;
                        --button-background-hover: transparent;
                        --button-border: var(--color-background);
                        --button-txt: var(--color-text-primary-inverse);
                        --button-border-hover: var(--color-primary-light);
                        --button-txt-hover: var(--color-text-accent-light);
                    }
                }

                @container teaser (min-width: 609px) {
                    margin-block-start: map.get($spacing, s-24);
                }
            }

            &__visual {
                display: block;
                overflow: hidden;

                img {
                    display: block;
                }
            }
        }
    }

    &--background-image {
        .cmp-teaser {
            &__visual {
                margin-block-start: -(map.get($spacing, s-72));
                position: relative;
                width: 100%;

                @container teaser (min-width: 391px) {
                    height: 100%;
                    margin-block-start: 0;
                    position: absolute;
                    right: 0;
                    top: 0;
                    width: 78%;
                }

                &::after {
                    background-image: linear-gradient(to bottom, rgba(var(--teaser-background-gradient-rgb), 100%), rgba(var(--teaser-background-gradient-rgb), 0%));
                    content: '';
                    height: 53%;
                    left: 0;
                    position: absolute;
                    right: 0;
                    top: 0;
                    z-index: 4;

                    @container teaser (min-width: 391px) {
                        background-image: linear-gradient(to right, rgba(var(--teaser-background-gradient-rgb), 100%), rgba(var(--teaser-background-gradient-rgb), 0%));
                        content: '';
                        height: auto;
                        inset: 0 auto 0 0;
                        position: absolute;
                        width: 79%;
                        z-index: 4;
                    }
                }
            }
        }
    }

    &--image {
        .cmp-teaser {
            @container teaser (min-width: 391px) {
                --teaser-padding-inline-end: 0;

                display: grid;
                grid-template-columns: repeat(2, 1fr);
            }

            &__content {
                @container teaser (min-width: 391px) and (max-width: 608px) {
                    display: flex;
                    flex-direction: column;
                    min-width: 1px;
                    width: auto;
                }
            }

            .button {
                @container teaser (min-width: 391px) and (max-width: 608px) {
                    margin-block-start: auto;
                }
            }

            @container teaser (min-width: 391px) and (max-width: 608px) {
                .cmp-image__image {
                    height: auto;
                    width: 100%;
                }
            }

            &__visual {
                @container teaser (min-width: 391px) {
                    position: relative;
                }

                &::after {
                    @container teaser (min-width: 391px) {
                        background-image: linear-gradient(to right, rgba(var(--teaser-background-gradient-rgb), 100%), rgba(var(--teaser-background-gradient-rgb), 0%));
                        content: '';
                        inset: 0 auto 0 0;
                        position: absolute;
                        width: 22%;
                        z-index: 4;
                    }
                }
            }
        }
    }
}
