@use 'sass:map';
@use '@oecd/foundation/src/site/config/spacing-vars' as *;
@use '@oecd/util-breakpoint' as *;
@use '@oecd/util-button' as *;
@use '@oecd/util-distance' as d;
@use '@oecd/util-text-style' as *;

@media print {
    .cmp-generic-header {
        padding-block-start: 3rem;

        &__container {
            column-gap: var(--grid-gap);
            display: grid;
            grid-template-columns: repeat(12, 1fr);
            grid-template-rows: min-content;
            min-height: none;
        }

        &__breadcrumb {
            display: none;
        }

        &__content-area {
            align-items: flex-start;
            display: flex;
            flex-direction: column;
            height: 100%;
            justify-content: flex-end;
            padding-block-end: map.get($spacing, s-32) !important;
            padding-inline-end: map.get($spacing, s-32);
        }

        &__prefix {
            @include text-style(body, 1);
        }

        &__description {
            @include text-style(body, 1);
        }

        .cmp-generic-header__cta {
            display: none;
        }

        &__overlay {
            display: none;
        }

        &__tags {
            --header-tags-margin-block-start: #{map.get($spacing, s-24)};
        }

        .theme-shout &,
        .theme-whisper & {
            --header-background-color: var(--color-print-background);
            --header-text-color: var(--color-text-print-normal);
            --header-title-color: var(--color-text-print-dark);

            .keylink {
                --keylink-text: var(--color-text-print-normal);
                --keylink-icon-color: var(--color-text-print-normal);
            }

            .tag {
                --tag-border: 1px solid var(--color-text-print-normal);
                --tag-background: var(--color-print-background);
                --tag-color: var(--color-text-print-normal);
            }
        }
    }

    .generic-header {
        ///////////////////////////////////////
        // Default and with Background image //
        //////////////////////////////////////
        &:not(.has-featured-image) {
            .cmp-generic-header {
                --header-min-height: none;

                &__content-area {
                    grid-column: 1 / span 12;
                }

                &__visual {
                    display: none;
                }
            }
        }


        ////////////////////
        // Featured image //
        ////////////////////
        &.has-featured-image {
            .cmp-generic-header {
                --header-min-height: none;

                &__content-area {
                    grid-column: 1 / span 6;
                    padding-block-end: 0;
                }

                &__container {
                    padding-block-end: map.get($spacing, s-64);
                }

                &__visual {
                    --header-visual-aspect-ratio: 5/4;

                    align-self: start;
                    aspect-ratio: var(--header-visual-aspect-ratio);
                    grid-column: 7 / span 6;
                }
            }
        }

        //////////////////////
        // Background image //
        //////////////////////
        &.has-background-image {
            .cmp-generic-header {
                --header-min-height: none;

                &::before {
                    display: none !important;
                }

                &__container {
                    &::before {
                        display: none !important;
                    }
                }
            }

            //////////////////
            // Landing Page //
            //////////////////
            &--landing-page {
                .cmp-generic-header {
                    &__description {
                        @include text-style(subheading, 1);
                    }
                }
            }
        }
    }
}
