@use 'sass:map';
@use 'sass:math';
@use '@oecd/util-breakpoint' as *;

@media print {
    .cmp-two-column-container {
        .column-container--two-col-6-6 &,
        .column-container--two-col-4-8 &,
        .column-container--two-col-8-4 & {
            .column-container__container {
                > .cmp-container > *:last-child {
                    margin-block-end: var(--layout-spacing-prose);
                }
            }
        }
    }
}
