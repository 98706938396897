@use 'sass:map';
@use '@oecd/foundation/src/site/config/spacing-vars' as *;
@use '@oecd/util-distance' as d;
@use '@oecd/util-line-clamp' as *;
@use '@oecd/util-text-style' as *;

.cmp-list {
    &--card-rendition {
        .data-card {
            --list-card-border-color: var(--color-primary-light);
            --list-card-content-background-color: var(--color-primary-light);
            --list-card-min-height: #{d.rem(219)};
            --list-card-tag-background-color: var(--color-background);
            --list-card-title-margin-block-start: #{map.get($spacing, s-16)};

            &__description {
                // Increase lineheight so descenders don't get cut off
                @include text-style(label, 2, null, 1.25);

                color: var(--list-card-description-text-color);
                margin-block: map.get($spacing, s-8) map.get($spacing, s-24);

                // Set line clamp to 3
                @include line-clamp(3);
            }
        }
    }
}
