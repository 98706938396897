@use 'colors' as colors;

.cmp-report-header {
    // Light Themes
    @each $theme in colors.$light-theme-colors {
        &--theme-#{$theme} {
            --report-header-background-color: var(--color-theme-#{$theme}-background);
            --report-header-text-color: var(--color-theme-#{$theme}-text);
            --report-header-title-color: var(--color-theme-#{$theme}-text);
            --report-header-title-highlight-color: var(--color-theme-#{$theme}-text-accent);

            .cmp-breadcrumb {
                --breadcrumb-color: var(--color-theme-#{$theme}-text);
                --breadcrumb-link-color: var(--color-theme-#{$theme}-text);
                --breadcrumb-link-color-hover: var(--color-theme-#{$theme}-text-accent);
                --breadcrumb-focus-outline-color: var(--color-theme-#{$theme}-text-accent);
            }

            .cmp-report-header__tags .tag {
                --tag-background: var(--color-border-transparent);
                --tag-border: 1px solid transparent;
                --tag-color: var(--color-theme-#{$theme}-text);
            }

            .cmp-report-header__overlay-trigger .button--subtle .cmp-button {
                --button-subtle-icon-outline: var(--color-theme-#{$theme}-text-accent);
            }
        }
    }

    // Dark Themes
    @each $theme in colors.$dark-theme-colors {
        &--theme-#{$theme} {
            --report-header-background-color: var(--color-theme-#{$theme}-background);
            --report-header-text-color: var(--color-theme-#{$theme}-text);
            --report-header-title-color: var(--color-theme-#{$theme}-text);
            --report-header-title-highlight-color: var(--color-theme-#{$theme}-text-accent);

            .cmp-breadcrumb {
                --breadcrumb-color: var(--color-theme-#{$theme}-text);
                --breadcrumb-link-color: var(--color-theme-#{$theme}-text);
                --breadcrumb-link-color-hover: var(--color-theme-#{$theme}-text-accent);
                --breadcrumb-focus-outline-color: var(--color-theme-#{$theme}-text-accent);
            }

            .cmp-report-header__tags .tag {
                --tag-background: var(--color-border-transparent-inverse);
                --tag-border: 1px solid transparent;
                --tag-color: var(--color-theme-#{$theme}-text);
            }

            .cmp-report-header__overlay-trigger .button--subtle .cmp-button {
                --button-subtle-icon-outline: var(--color-theme-#{$theme}-text-accent);
            }
        }
    }
}
