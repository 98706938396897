@use 'sass:map';
@use '@oecd/foundation/src/site/config/spacing-vars' as *;
@use '@oecd/util-a11y' as *;
@use '@oecd/util-breakpoint' as *;
@use '@oecd/util-distance' as d;

.cmp-assets-list {
    &--link-rendition-column-layout {
        @include breakpoint-from(tablet) {
            display: grid;
            gap: 0 var(--grid-gap);
            grid-template-columns: repeat(auto-fill, minmax(d.rem(394), 1fr));

            .cmp-two-column-container & {
                grid-template-columns: repeat(auto-fit, minmax(d.rem(228), 1fr));
            }
        }

        @include breakpoint-from(desktop-small) {
            .cmp-two-column-container & {
                grid-template-columns: repeat(auto-fit, minmax(d.rem(288), 1fr));
            }
        }
    }

    &__sr-message {
        @include visually-hidden;
    }
}
