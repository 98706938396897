@use 'sass:map';
@use '@oecd/foundation/src/site/config/spacing-vars' as *;
@use '@oecd/util-breakpoint' as *;
@use '@oecd/util-button' as *;
@use '@oecd/util-external-link' as *;
@use '@oecd/util-text-style' as *;

.search-result-list-item {
    --search-results-item-link-color: var(--color-text-primary);
    --search-results-item-color: var(--color-text-secondary);
    --search-results-item-padding-block: #{map.get($spacing, s-24)};
    --search-results-item-padding-inline-end: 0;
    --search-results-item-border-color: var(--color-border);
    --search-results-item-meta-separator-symbol: '\2022';

    border-bottom: 1px solid var(--search-results-item-border-color);
    color: var(--search-results-item-color);
    padding-block: var(--search-results-item-padding-block);
    padding-inline-end: var(--search-results-item-padding-inline-end);

    @include breakpoint-from(desktop) {
        --search-results-item-padding-block: #{map.get($spacing, s-32)};
        --search-results-item-padding-inline-end: #{map.get($spacing, s-64)};
    }

    &__title {
        @include text-style(body, 4, b);

        a {
            color: var(--search-results-item-link-color);
            display: inline-block;
            text-decoration: none;
        }
    }

    &__meta {
        @include text-style(label, 3);

        margin-block-start: map.get($spacing, s-4);

        > *:not(:first-child)::before {
            content: var(--search-results-item-meta-separator-symbol);
            padding-inline: map.get($spacing, s-8);
        }
    }

    &__snippet {
        @include text-style(label, 2);

        margin-block-start: map.get($spacing, s-8);
    }

    &--is-external {
        .search-result-list-item__title a {
            @include external-link-icon;

            --external-link-icon-color: var(--search-results-item-link-color);
            --external-link-icon-inset-inline-start: calc(var(--external-link-icon-size) / 2);

            padding-inline-start: map.get($spacing, s-20);
        }
    }
}
