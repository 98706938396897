@use 'sass:map';
@use '@oecd/foundation/src/site/config/spacing-vars' as *;
@use '@oecd/util-distance' as d;
@use '@oecd/util-focus' as *;
@use '@oecd/util-text-style' as *;

.cmp-dropdown-link {
    --dropdown-link-image-background-color: var(--color-primary-light);
    --dropdown-link-image-size: #{d.rem(40)};
    --dropdown-link-svg-size: #{d.rem(20)};
    --dropdown-link-color: var(--color-text-primary);
    --dropdown-link-color-hover: var(--color-text-link);
    --dropdown-tagline-color: var(--color-text-secondary);

    // overwrite Core component style
    .image {
        margin: 0;
    }

    &__image {
        background-color: var(--dropdown-link-image-background-color);
        border-radius: 4px;
        height: var(--dropdown-link-image-size);
        inset-block-start: 0;
        inset-inline-start: 0;
        position: absolute;
        width: var(--dropdown-link-image-size);

        .cmp-image {
            height: var(--dropdown-link-image-size);
            width: var(--dropdown-link-image-size);
        }

        img {
            border-radius: 4px;
            height: 100%;
            object-fit: cover;
            width: 100%;

            &[src$='svg'] {
                height: var(--dropdown-link-svg-size);
                inset-block-start: 50%;
                inset-inline-start: 50%;
                position: absolute;
                transform: translate(-50%, -50%);
                width: var(--dropdown-link-svg-size);
            }
        }
    }

    &__link {
        .cmp-button {
            @include text-style(body, 2, m);

            color: var(--dropdown-link-color);
            display: inline-block;
            text-decoration: none;

            &:hover {
                color: var(--dropdown-link-color-hover);
            }

            &:focus-visible {
                @include keyboardfocus;
            }
        }
    }

    &__tagline {
        .cmp-text {
            @include text-style(label, 3);

            color: var(--dropdown-tagline-color);
        }
    }

    &--default {
        .cmp-dropdown-link__link {
            .cmp-button {
                padding-block: map.get($spacing, s-4);
            }
        }
    }

    &--visual,
    &--titletagline {
        position: relative;

        .cmp-dropdown-link__link {
            .cmp-button {
                @include text-style(label, 2, b);

                &:focus {
                    outline: none;
                }

                &:focus-visible {
                    outline: none;

                    &::before {
                        outline: 2px solid var(--color-focus-outline);
                        outline-offset: 2px;
                    }
                }

                &::before {
                    content: '';
                    display: block;
                    height: 100%;
                    inset-block-start: 0;
                    inset-inline-start: 0;
                    position: absolute;
                    width: 100%;
                }
            }

        }
    }

    &--visual {
        min-height: var(--dropdown-link-image-size);
        padding-inline-start: calc(var(--dropdown-link-image-size) + #{map.get($spacing, s-8)});
    }
}

.dropdown-link + .dropdown-link {
    .cmp-dropdown-link {
        margin-block-start: map.get($spacing, s-4);

        &--visual,
        &--titletagline {
            margin-block-start: map.get($spacing, s-16);
        }
    }
}
