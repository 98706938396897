// stylelint-disable number-max-precision

@use 'sass:map';
@use '@oecd/util-distance' as d;

/* !*****************************************************
Freak Flags, Copyright ©2023 Michael P. Cohen. Freak flags is licenced under the MIT licence.
For complete information visit: www.freakflagsprite.com
******************************************************/

.country-flag {
    background-image: url('resources/images/country-flags.png');
    background-repeat: no-repeat;
    background-size: 100% 49494%;
    box-sizing: content-box;
    display: inline-block;
    height: d.rem(13);
    overflow: hidden;
    position: relative;
    vertical-align: middle;
    width: d.rem(22);
}

.country-flag-CH,
.country-flag-NP {
    box-shadow: none !important;
}

.country-flag-DZ {
    background-position: center 0.2287%;
}

.country-flag-AO {
    background-position: center 0.4524%;
}

.country-flag-BJ {
    background-position: center 0.6721%;
}

.country-flag-BW {
    background-position: center 0.8958%;
}

.country-flag-BF {
    background-position: center 1.1162%;
}

.country-flag-BI {
    background-position: center 1.3379%;
}

.country-flag-CM {
    background-position: center 1.5589%;
}

.country-flag-CV {
    background-position: center 1.7805%;
}

.country-flag-CF {
    background-position: center 2.0047%;
}

.country-flag-TD {
    background-position: center 2.2247%;
}

.country-flag-CD {
    background-position: left 2.4467%;
}

.country-flag-DJ {
    background-position: left 2.6674%;
}

.country-flag-EG {
    background-position: center 2.8931%;
}

.country-flag-GQ {
    background-position: center 3.1125%;
}

.country-flag-ER {
    background-position: left 3.3325%;
}

.country-flag-ET {
    background-position: center 3.5542%;
}

.country-flag-GA {
    background-position: center 3.7759%;
}

.country-flag-GM {
    background-position: center 4.0015%;
}

.country-flag-GH {
    background-position: center 4.2229%;
}

.country-flag-GN {
    background-position: center 4.441%;
}

.country-flag-GW {
    background-position: left 4.66663%;
}

.country-flag-CI {
    background-position: center 4.8844%;
}

.country-flag-KE {
    background-position: center 5.1061%;
}

.country-flag-LS {
    background-position: center 5.3298%;
}

.country-flag-LR {
    background-position: left 5.5495%;
}

.country-flag-LY {
    background-position: center 5.7712%;
}

.country-flag-MG {
    background-position: center 5.994%;
}

.country-flag-MW {
    background-position: center 6.2156%;
}

.country-flag-ML {
    background-position: center 6.4363%;
}

.country-flag-MR {
    background-position: center 6.658%;
}

.country-flag-MU {
    background-position: center 6.8805%;
}

.country-flag-YT {
    background-position: center 7.1038%;
}

.country-flag-MA {
    background-position: center 7.3231%;
}

.country-flag-MZ {
    background-position: left 7.5448%;
}

.country-flag-NA {
    background-position: left 7.7661%;
}

.country-flag-NE {
    background-position: center 7.98937%;
}

.country-flag-NG {
    background-position: center 8.2099%;
}

.country-flag-CG {
    background-position: center 8.4316%;
}

.country-flag-RE {
    background-position: center 8.6533%;
}

.country-flag-RW {
    background-position: right 8.875%;
}

.country-flag-SH {
    background-position: center 9.0967%;
}

.country-flag-ST {
    background-position: center 9.32237%;
}

.country-flag-SN {
    background-position: center 9.5426%;
}

.country-flag-SC {
    background-position: left 9.7628%;
}

.country-flag-SL {
    background-position: center 9.9845%;
}

.country-flag-SO {
    background-position: center 10.2052%;
}

.country-flag-ZA {
    background-position: left 10.4269%;
}

.country-flag-SS {
    background-position: left 10.6486%;
}

.country-flag-SD {
    background-position: center 10.8703%;
}

.country-flag-SR {
    background-position: center 11.0945%;
}

.country-flag-SZ {
    background-position: center 11.3135%;
}

.country-flag-TG {
    background-position: left 11.5354%;
}

.country-flag-TN {
    background-position: center 11.7593%;
}

.country-flag-UG {
    background-position: center 11.9799%;
}

.country-flag-TZ {
    background-position: center 12.2005%;
}

.country-flag-EH {
    background-position: center 12.4222%;
}

.country-flag-YE {
    background-position: center 12.644%;
}

.country-flag-ZM {
    background-position: center 12.8664%;
}

.country-flag-ZW {
    background-position: left 13.0873%;
}

.country-flag-AI {
    background-position: center 13.309%;
}

.country-flag-AG {
    background-position: center 13.5307%;
}

.country-flag-AR {
    background-position: center 13.7524%;
}

.country-flag-AW {
    background-position: left 13.9741%;
}

.country-flag-BS {
    background-position: left 14.1958%;
}

.country-flag-BB {
    background-position: center 14.4175%;
}

.country-flag-BQ {
    background-position: center 14.6415%;
}

.country-flag-BZ {
    background-position: center 14.8609%;
}

.country-flag-BM {
    background-position: center 15.0826%;
}

.country-flag-BO {
    background-position: center 15.306%;
}

.country-flag-VG {
    background-position: center 15.528%;
}

.country-flag-BR {
    background-position: center 15.7496%;
}

.country-flag-CA {
    background-position: center 15.9694%;
}

.country-flag-KY {
    background-position: center 16.1911%;
}

.country-flag-CL {
    background-position: left 16.4128%;
}

.country-flag-CO {
    background-position: left 16.6345%;
}

.country-flag-KM {
    background-position: center 16.8562%;
}

.country-flag-CR {
    background-position: center 17.0779%;
}

.country-flag-CU {
    background-position: left 17.2996%;
}

.country-flag-CW {
    background-position: center 17.5213%;
}

.country-flag-DM {
    background-position: center 17.743%;
}

.country-flag-DO {
    background-position: center 17.968%;
}

.country-flag-EC {
    background-position: center 18.1864%;
}

.country-flag-SV {
    background-position: center 18.4081%;
}

.country-flag-FK {
    background-position: center 18.6298%;
}

.country-flag-GF {
    background-position: center 18.8515%;
}

.country-flag-GL {
    background-position: left 19.0732%;
}

.country-flag-GD {
    background-position: center 19.2987%;
}

.country-flag-GP {
    background-position: center 19.518%;
}

.country-flag-GT {
    background-position: center 19.7383%;
}

.country-flag-GY {
    background-position: center 19.96%;
}

.country-flag-HT {
    background-position: center 20.1817%;
}

.country-flag-HN {
    background-position: center 20.4034%;
}

.country-flag-JM {
    background-position: center 20.6241%;
}

.country-flag-MQ {
    background-position: center 20.8468%;
}

.country-flag-MX {
    background-position: center 21.0685%;
}

.country-flag-MS {
    background-position: center 21.2902%;
}

.country-flag-NI {
    background-position: center 21.5119%;
}

.country-flag-PA {
    background-position: center 21.7336%;
}

.country-flag-PY {
    background-position: center 21.9553%;
}

.country-flag-PE {
    background-position: center 22.177%;
}

.country-flag-PR {
    background-position: left 22.4002%;
}

.country-flag-BL {
    background-position: center 22.6204%;
}

.country-flag-KN {
    background-position: center 22.8421%;
}

.country-flag-LC {
    background-position: center 23.0638%;
}

.country-flag-PM {
    background-position: center 23.2855%;
}

.country-flag-VC {
    background-position: center 23.5072%;
}

.country-flag-SX {
    background-position: left 23.732%;
}

.country-flag-TT {
    background-position: center 23.9506%;
}

.country-flag-TC {
    background-position: center 24.1723%;
}

.country-flag-US {
    background-position: center 24.392%;
}

.country-flag-VI {
    background-position: center 24.6157%;
}

.country-flag-UY {
    background-position: left 24.8374%;
}

.country-flag-VE {
    background-position: center 25.0591%;
}

.country-flag-AB {
    background-position: center 25.279%;
}

.country-flag-AF {
    background-position: center 25.5025%;
}

.country-flag-AZ {
    background-position: center 25.7242%;
}

.country-flag-BD {
    background-position: center 25.9459%;
}

.country-flag-BT {
    background-position: center 26.1676%;
}

.country-flag-BN {
    background-position: center 26.3885%;
}

.country-flag-KH {
    background-position: center 26.611%;
}

.country-flag-CN {
    background-position: left 26.8327%;
}

.country-flag-GE {
    background-position: center 27.0544%;
}

.country-flag-HK {
    background-position: center 27.2761%;
}

.country-flag-IN {
    background-position: center 27.4978%;
}

.country-flag-ID {
    background-position: center 27.7195%;
}

.country-flag-JP {
    background-position: center 27.9412%;
}

.country-flag-KZ {
    background-position: center 28.1615%;
}

.country-flag-LA {
    background-position: center 28.3846%;
}

.country-flag-MO {
    background-position: center 28.6063%;
}

.country-flag-MY {
    background-position: center 28.829%;
}

.country-flag-MV {
    background-position: center 29.0497%;
}

.country-flag-MN {
    background-position: left 29.2714%;
}

.country-flag-MM {
    background-position: center 29.4931%;
}

.country-flag-NP {
    background-position: left 29.7148%;
}

.country-flag-KP {
    background-position: left 29.9365%;
}

.country-flag-MP {
    background-position: center 30.1582%;
}

.country-flag-PW {
    background-position: center 30.3799%;
}

.country-flag-PG {
    background-position: center 30.6016%;
}

.country-flag-PH {
    background-position: left 30.8233%;
}

.country-flag-SG {
    background-position: left 31.045%;
}

.country-flag-KR {
    background-position: center 31.2667%;
}

.country-flag-LK {
    background-position: right 31.4884%;
}

.country-flag-TW {
    background-position: left 31.7101%;
}

.country-flag-TJ {
    background-position: center 31.9318%;
}

.country-flag-TH {
    background-position: center 32.1535%;
}

.country-flag-TL {
    background-position: left 32.3752%;
}

.country-flag-TM {
    background-position: center 32.5969%;
}

.country-flag-VN {
    background-position: center 32.8186%;
}

.country-flag-AX {
    background-position: center 33.0403%;
}

.country-flag-AL {
    background-position: center 33.25975%;
}

.country-flag-AD {
    background-position: center 33.4837%;
}

.country-flag-AM {
    background-position: center 33.7054%;
}

.country-flag-AT {
    background-position: center 33.9271%;
}

.country-flag-BY {
    background-position: left 34.1488%;
}

.country-flag-BE {
    background-position: center 34.3705%;
}

.country-flag-BA {
    background-position: center 34.5922%;
}

.country-flag-BG {
    background-position: center 34.8139%;
}

.country-flag-HR {
    background-position: center 35.0356%;
}

.country-flag-CY {
    background-position: center 35.2555%;
}

.country-flag-CZ {
    background-position: left 35.479%;
}

.country-flag-DK {
    background-position: center 35.7007%;
}

.country-flag-EE {
    background-position: center 35.9224%;
}

.country-flag-FO {
    background-position: center 36.1441%;
}

.country-flag-FI {
    background-position: center 36.3658%;
}

.country-flag-FR {
    background-position: center 36.5875%;
}

.country-flag-DE {
    background-position: center 36.8092%;
}

.country-flag-GI {
    background-position: center 37.0309%;
}

.country-flag-GR {
    background-position: left 37.2526%;
}

.country-flag-GG {
    background-position: center 37.4743%;
}

.country-flag-HU {
    background-position: center 37.696%;
}

.country-flag-IS {
    background-position: center 37.9177%;
}

.country-flag-IE {
    background-position: center 38.1394%;
}

.country-flag-IM {
    background-position: center 38.3611%;
}

.country-flag-IT {
    background-position: center 38.5828%;
}

.country-flag-JE {
    background-position: center 38.8045%;
}

.country-flag-XK {
    background-position: center 39.0262%;
}

.country-flag-LV {
    background-position: center 39.2479%;
}

.country-flag-LI {
    background-position: left 39.4696%;
}

.country-flag-LT {
    background-position: center 39.6913%;
}

.country-flag-LU {
    background-position: center 39.913%;
}

.country-flag-MT {
    background-position: left 40.1347%;
}

.country-flag-MD {
    background-position: center 40.3564%;
}

.country-flag-MC {
    background-position: center 40.5781%;
}

.country-flag-ME {
    background-position: center 40.7998%;
}

.country-flag-NL {
    background-position: center 41.0215%;
}

.country-flag-MK {
    background-position: center 41.2432%;
}

.country-flag-NO {
    background-position: center 41.4649%;
}

.country-flag-PL {
    background-position: center 41.6866%;
}

.country-flag-PT {
    background-position: center 41.9083%;
}

.country-flag-RO {
    background-position: center 42.13%;
}

.country-flag-RU {
    background-position: center 42.3517%;
}

.country-flag-SM {
    background-position: center 42.5734%;
}

.country-flag-RS {
    background-position: center 42.7951%;
}

.country-flag-SK {
    background-position: center 43.0168%;
}

.country-flag-SI {
    background-position: center 43.2385%;
}

.country-flag-ES {
    background-position: left 43.4602%;
}

.country-flag-SE {
    background-position: center 43.6819%;
}

.country-flag-CH {
    background-position: center 43.9036%;
}

.country-flag-TR {
    background-position: center 44.1253%;
}

.country-flag-UA {
    background-position: center 44.347%;
}

.country-flag-GB {
    background-position: center 44.5687%;
}

.country-flag-VA {
    background-position: right 44.7904%;
}

.country-flag-BH {
    background-position: center 45.0121%;
}

.country-flag-IR {
    background-position: center 45.2338%;
}

.country-flag-IQ {
    background-position: center 45.4555%;
}

.country-flag-IL {
    background-position: center 45.6772%;
}

.country-flag-KW {
    background-position: left 45.897%;
}

.country-flag-JO {
    background-position: left 46.1206%;
}

.country-flag-KG {
    background-position: center 46.3423%;
}

.country-flag-LB {
    background-position: center 46.561%;
}

.country-flag-OM {
    background-position: left 46.7857%;
}

.country-flag-PK {
    background-position: center 47.0074%;
}

.country-flag-PS {
    background-position: center 47.2291%;
}

.country-flag-QA {
    background-position: center 47.4508%;
}

.country-flag-SA {
    background-position: center 47.6725%;
}

.country-flag-SY {
    background-position: center 47.8942%;
}

.country-flag-AE {
    background-position: center 48.1159%;
}

.country-flag-UZ {
    background-position: left 48.3376%;
}

.country-flag-AS {
    background-position: right 48.5593%;
}

.country-flag-AU {
    background-position: center 48.781%;
}

.country-flag-CX {
    background-position: center 49.002%;
}

.country-flag-CC {
    background-position: center 49.2244%;
}

.country-flag-CK {
    background-position: center 49.4445%;
}

.country-flag-FJ {
    background-position: center 49.6678%;
}

.country-flag-PF {
    background-position: center 49.8895%;
}

.country-flag-GU {
    background-position: center 50.1112%;
}

.country-flag-KI {
    background-position: center 50.3329%;
}

.country-flag-MH {
    background-position: left 50.5546%;
}

.country-flag-FM {
    background-position: center 50.7763%;
}

.country-flag-NC {
    background-position: center 50.998%;
}

.country-flag-NZ {
    background-position: center 51.2197%;
}

.country-flag-NR {
    background-position: left 51.4414%;
}

.country-flag-NU {
    background-position: center 51.6631%;
}

.country-flag-NF {
    background-position: center 51.8848%;
}

.country-flag-WS {
    background-position: left 52.1065%;
}

.country-flag-SB {
    background-position: left 52.3282%;
}

.country-flag-TK {
    background-position: center 52.5499%;
}

.country-flag-TO {
    background-position: left 52.7716%;
}

.country-flag-TV {
    background-position: center 52.9933%;
}

.country-flag-VU {
    background-position: left 53.215%;
}

.country-flag-WF {
    background-position: center 53.4385%;
}

.country-flag-AQ {
    background-position: center 53.6584%;
}

.country-flag-EU {
    background-position: center 53.875%;
}

.country-flag-UN {
    background-position: center 54.099%;
}
