@use 'sass:math';
@use '@oecd/util-a11y' as *;
@use '@oecd/util-distance' as d;
@use '@oecd/util-focus' as *;

#top {
    @include visually-hidden;
}

.cmp-page__back-to-top-button {
    $offset: d.rem(16);

    --back-to-top-button-background-color: var(--color-primary);
    --back-to-top-button-block-start-offset: calc(100dvh - var(--back-to-top-button-icon-size) - #{$offset});
    --back-to-top-button-border-radius: 50%;
    --back-to-top-button-icon-color: var(--color-icon-inverse);
    --back-to-top-button-icon-size: #{d.rem(36)};
    --back-to-top-button-inline-end-offset: #{$offset};
    --back-to-top-button-zindex: 9999;

    height: 100%;
    inset-block-start: 0;
    inset-inline-end: var(--back-to-top-button-inline-end-offset);
    pointer-events: none;
    position: absolute;

    &::before {
        content: '';
        display: block;
        height: 100dvh;
    }

    &-link {
        align-items: center;
        background-color: var(--back-to-top-button-background-color);
        border-radius: var(--back-to-top-button-border-radius);
        color: var(--back-to-top-button-icon-color);
        cursor: pointer;
        display: flex;
        inset-block-start: var(--back-to-top-button-block-start-offset);
        justify-content: center;
        pointer-events: initial;
        position: sticky;
        z-index: var(--back-to-top-button-zindex);

        @media screen and (forced-colors: active) {
            border: 1px solid;
        }

        &::before {
            background: currentcolor;
            content: '';
            display: block;
            height: var(--back-to-top-button-icon-size);
            mask-image: var(--icon-back-to-top);
            mask-position: center;
            mask-repeat: no-repeat;
            mask-size: contain;
            vertical-align: middle;
            width: var(--back-to-top-button-icon-size);

            @media screen and (forced-colors: active) {
                background: LinkText;
            }
        }

        &:focus-visible {
            @include keyboardfocus;
        }

        span {
            @include visually-hidden;
        }
    }
}
