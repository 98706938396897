@use 'sass:map';
@use '@oecd/foundation/src/site/config/spacing-vars' as *;

oecd-control { /* stylelint-disable-line */
    display: block;
}

.chart-control + .chart-control {
    margin-block-start: map.get($spacing, s-24);
}

.container-content > :where(.cmp-container) > :where(.chart-control + .chart-control) {
    --layout-spacing-default: #{map.get($spacing, s-24)};
}
