@use 'sass:map';
@use '@oecd/foundation/src/site/config/spacing-vars' as *;
@use '@oecd/util-a11y' as *;
@use '@oecd/util-distance' as d;
@use '@oecd/util-text-style' as *;

.cmp-page__skiptomaincontent {
    --skiplink-container-bg-color: var(--color-background);
    --skiplink-container-border-bottom: 1px solid var(--color-border-transparent);
    --skiplink-text-bg-color: transparent;
    --skiplink-text-color: var(--color-text-link);
    --skiplink-text-margin-block: #{map.get($spacing, s-24)};
    --skiplink-text-outline: 2px solid var(--color-focus-outline);
    --skiplink-text-outline-offset: #{d.rem(2)};
    --skiplink-text-text-decoration: underline;

    @include visually-hidden-focusable;

    background-color: var(--skiplink-container-bg);
    border-bottom: var(--skiplink-container-border-bottom);

    &-container {
        grid-column: wrapper;
    }

    &-link {
        @include text-style(label, 2, m);
        @include visually-hidden-focusable;

        background-color: var(--skiplink-text-bg-color);
        color: var(--skiplink-text-color);
        display: inline-block;
        margin-block: var(--skiplink-text-margin-block);
        outline: var(--skiplink-text-outline);
        outline-offset: var(--skiplink-text-outline-offset);
        text-decoration: var(--skiplink-text-text-decoration);
    }
}
