@use 'sass:map';
@use '@oecd/foundation/src/site/config/spacing-vars' as *;
@use '@oecd/util-a11y' as *;
@use '@oecd/util-breakpoint' as *;
@use '@oecd/util-button' as *;
@use '@oecd/util-distance' as d;

.chart-control-bar {
    --chart-control-bar-sticky-offset: var(--content-language-picker-height, 0px); /* stylelint-disable-line */

    display: grid;
    grid-template-columns: [full-start] var(--grid-offset) [container-start] minmax(0, var(--container-max-width)) [container-end] var(--grid-offset) [full-end];
    pointer-events: none;
    position: relative;
    z-index: var(--z-index-chart-control-bar, 500);

    @supports (position: sticky) or (position: -webkit-sticky) {
        inset-block-start: var(--chart-control-bar-sticky-offset);
        position: sticky;

        // While scrolling up
        .js-is-scrolling-up & {
            --chart-control-bar-sticky-offset: 0;

            position: relative;
        }
    }
}

.cmp-chart-control-bar {
    --chart-control-bar-background-color: var(--color-background);
    --chart-control-dashboard-btn-icon: var(--icon-gear);
    --chart-control-download-btn-icon: var(--icon-download);
    --chart-control-overlay-max-width: #{d.rem(470)};

    grid-column: container;
    pointer-events: all;

    &::after {
        background-color: var(--chart-control-bar-background-color);
        border-block-end: 1px solid var(--color-border-transparent);
        content: '';
        display: block;
        height: 100%;
        inset-block-end: 0;
        inset-inline-start: 0;
        position: absolute;
        width: 100vw;
        z-index: -1;
    }

    &__wrapper {
        align-items: center;
        column-gap: map.get($spacing, s-8);
        display: flex;
        justify-content: space-between;
        margin-block: map.get($spacing, s-8);

        @include breakpoint-from(tablet) {
            column-gap: map.get($spacing, s-32);
        }
    }

    &__dropdown {
        flex: 1;

        @include breakpoint-from(desktop-small) {
            max-width: d.rem(600);
            min-width: d.rem(300);
        }
    }

    &__buttons {
        column-gap: map.get($spacing, s-8);
        display: flex;
        justify-content: end;

        @include breakpoint-from(desktop-small) {
            flex: 1;
        }
    }

    &__overlay {
        .cmp-overlay {
            --overlay-max-width: var(--chart-control-overlay-max-width);
        }
    }

    &__download {
        .cmp-button {
            @include button-icon(--chart-control-download-btn-icon);

            @media screen and (forced-colors: active) {
                &::before {
                    background: LinkText;
                }
            }
        }
    }

    &__controls {
        .cmp-button {
            @include button-icon(--chart-control-dashboard-btn-icon);

            @media screen and (forced-colors: active) {
                &::before {
                    background: ButtonText;
                }
            }
        }
    }
}
