@use 'sass:map';
@use '@oecd/foundation/src/site/config/spacing-vars' as *;
@use '@oecd/util-a11y' as *;
@use '@oecd/util-breakpoint' as *;
@use '@oecd/util-distance' as d;
@use '@oecd/util-scrollbar' as *;
@use '@oecd/util-text-style' as *;

.mobile-navigation {
    background-color: var(--mobile-navigation-background);
    height: 100vh;
    margin: 0;
    max-height: 100%;
    max-width: 100%;
    width: 100%;

    @include breakpoint-from(tablet) {
        background: transparent;
        max-width: var(--mobile-navigation-max-width);
    }

    &__wrapper {
        background-color: var(--mobile-navigation-background);
        display: flex;
        flex-direction: column;
        height: 100%;
        overflow: hidden;

        @include breakpoint-until(tablet) {
            animation-delay: normal;
            animation-duration: 0;
            animation-name: fadeMobileMenuIn;
            animation-timing-function: linear;
        }

        @include breakpoint-from(tablet) {
            animation-duration: 0;
            animation-name: slideMobileMenuInFromLeft;
            animation-timing-function: ease-in-out;
        }

        @media (prefers-reduced-motion: no-preference) {
            animation-duration: 0.3s;
        }
    }

    &[open] {
        display: flex;
        flex-direction: column;
        visibility: visible;
        z-index: 1;
    }

    &__header {
        align-items: center;
        border-bottom: solid 1px var(--mobile-navigation-border-bottom-color);
        display: flex;
        flex: 0 0 auto;
        justify-content: space-between;
        padding-inline: map.get($spacing, s-24);
    }

    &__logo-wrapper {
        align-items: center;
        display: flex;
        gap: map.get($spacing, s-12);
        margin-right: auto;

        .mobile-navigation__logo {
            flex: 0 0 auto;

            svg {
                max-height: var(--main-navigation-logo-max-height-mobile);
                width: var(--main-navigation-logo-width-two-logos-mobile);

                @include breakpoint-from(tablet) {
                    max-height: var(--main-navigation-logo-max-height);
                    width: var(--main-navigation-logo-width-two-logos-tablet);
                }
            }
        }
    }

    &__logo {
        color: var(--mobile-navigation-logo-color);

        @media (prefers-reduced-motion: no-preference) {
            transition: 0.15s linear color;
        }

        &-icon {
            display: block;
            line-height: 0;

            svg {
                height: auto;
                max-height: var(--main-navigation-logo-max-height-mobile);
                width: var(--mobile-navigation-logo-width);
            }
        }

        &-text {
            @include visually-hidden;
        }

        &:hover {
            --mobile-navigation-logo-color: var(--color-primary);
        }

        &:focus {
            outline: none;
        }

        &:focus-visible {
            .js-user-is-tabbing & {
                --mobile-navigation-logo-color: var(--color-primary);

                outline: 2px solid var(--color-focus-outline);
                outline-offset: 2px;
            }
        }

        .js-first-level-open & {
            display: none;
        }
    }

    &__current {
        display: none;

        @include text-style(body, 3);

        .js-first-level-open & {
            display: block;
        }
    }

    &__back {
        background-color: var(--color-background-light);
        border-radius: 100%;
        color: var(--mobile-navigation-back-btn-icon-color);
        display: none;
        height: var(--mobile-navigation-back-btn-size);
        justify-self: flex-start;
        position: relative;
        text-align: center;
        width: var(--mobile-navigation-back-btn-size);

        @media (prefers-reduced-motion: no-preference) {
            transition: 0.15s linear color;
        }

        @media screen and (forced-colors: active) {
            border: 1px solid;
        }

        &:hover {
            --mobile-navigation-back-btn-icon-color: var(--color-primary);
        }

        &:focus-visible {
            --mobile-navigation-back-btn-icon-color: var(--color-primary);

            outline: 2px solid var(--color-focus-outline);
        }

        &::before {
            background-color: currentcolor;
            content: '';
            display: block;
            height: var(--mobile-navigation-back-btn-icon-size);
            inset-block-start: 50%;
            inset-inline-start: 50%;
            mask-image: var(--mobile-navigation-back-btn-icon);
            mask-position: center;
            mask-repeat: no-repeat;
            mask-size: contain;
            position: absolute;
            transform: translate(-50%, -50%) rotate(-180deg);
            width: var(--mobile-navigation-back-btn-icon-size);

            @media screen and (forced-colors: active) {
                background: ButtonText;
            }
        }

        .js-first-level-open & {
            display: block;
        }

        &-text {
            @include visually-hidden;
        }
    }

    &__close {
        color: var(--mobile-navigation-close-color);
        line-height: 0;
        margin-inline-end: -#{map.get($spacing, s-12)};
        padding: map.get($spacing, s-24) map.get($spacing, s-12);

        @media (prefers-reduced-motion: no-preference) {
            transition: 0.15s linear color;
        }

        &--mobile {
            @include breakpoint-from(tablet) {
                visibility: hidden;
            }
        }

        &--tablet {
            color: var(--color-icon-inverse);
            display: none;

            @include breakpoint-from(tablet) {
                display: block;
                inset-block-start: 0;
                inset-inline-end: var(--grid-offset);
                position: fixed;
            }
        }

        @include breakpoint-from(desktop) {
            display: none;
        }

        &:hover {
            --mobile-navigation-close-color: var(--color-primary);
        }

        &:focus {
            --mobile-navigation-close-color: var(--color-primary);
        }

        &:focus-visible {
            outline: 2px solid var(--color-focus-outline);
        }

        &-icon {
            vertical-align: middle;
        }

        &-text {
            @include visually-hidden;
        }
    }

    &__body {
        flex: 1;
        min-height: 0;
        position: relative;

        // This causes a positioning context for the panels
        transform: translateZ(0);

        @media (prefers-reduced-motion: no-preference) {
            transition: transform 0.5s ease-in-out;
        }

        .js-first-level-open & {
            transform: translate3d(-100%, 0, 0);
        }
    }

    &__menu {
        overflow-y: auto;
        padding: map.get($spacing, s-24);

        @include custom-scrollbars;

        &:focus-visible {
            .js-user-is-tabbing & {
                outline: 2px solid var(--color-focus-outline);
                outline-offset: -2px;
            }
        }
    }

    &__item {
        color: var(--mobile-navigation-panel-trigger-color);
        display: block;
        padding-block: map.get($spacing, s-8);
        padding-inline-end: map.get($spacing, s-32);
        position: relative;
        text-align: left;
        text-decoration: none;
        width: 100%;

        @media (prefers-reduced-motion: no-preference) {
            transition: 0.15s linear color;
        }

        &:hover {
            --mobile-navigation-panel-trigger-color: var(--color-text-link);
        }

        &:focus-visible {
            outline: 2px solid var(--color-focus-outline);
            outline-offset: 2px;
        }

        @include text-style(body, 3);
    }

    &__panel-trigger {
        &-icon {
            background-color: var(--mobile-navigation-panel-trigger-icon-background-color);
            border-radius: 100%;
            display: block;
            height: var(--mobile-navigation-panel-trigger-icon-background-size);
            inset-block-start: map.get($spacing, s-8);
            inset-inline-end: 0;
            position: absolute;
            width: var(--mobile-navigation-panel-trigger-icon-background-size);

            &::before {
                background-color: var(--mobile-navigation-panel-trigger-icon-color);
                content: '';
                display: block;
                height: var(--mobile-navigation-panel-trigger-icon-size);
                inset-block-start: 50%;
                inset-inline-start: 50%;
                mask-image: var(--mobile-navigation-panel-trigger-icon);
                mask-position: center;
                mask-repeat: no-repeat;
                mask-size: contain;
                position: absolute;
                transform: translate(-50%, -50%);
                width: var(--mobile-navigation-panel-trigger-icon-size);

                @media screen and (forced-colors: active) {
                    background: ButtonText;
                }
            }
        }
    }

    &__panel {
        backface-visibility: hidden;
        background: var(--mobile-navigation-panel-background);
        display: none;
        height: 100%;
        inset-block-start: 0;
        inset-inline-start: 100%;
        overflow-y: auto;
        padding: map.get($spacing, s-24) map.get($spacing, s-24);
        position: fixed;
        width: 100%;
        z-index: 2;

        @include custom-scrollbars;

        // Adjust width otherwise custom-scrollbar is not noticable due to overlay
        @supports selector(::-webkit-scrollbar) {
            width: calc(100% - (var(--scrollbar-width-legacy) / 2));
        }

        &--active {
            display: block;
        }

        &:focus-visible {
            .js-user-is-tabbing & {
                --mobile-navigation-logo-color: var(--color-primary);

                outline: 2px solid var(--color-focus-outline);
                outline-offset: -2px;
            }
        }
    }

    &::backdrop {
        background-color: var(--color-background-backdrop);
    }
}

// Hide elements in header when menu is open
.js-mobile-menu-is-open {
    .cmp-main-navigation__menu-trigger,
    .cmp-main-navigation__cta-wrapper,
    .language-switch {
        opacity: 0;
    }
}

@keyframes fadeMobileMenuIn {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

@keyframes slideMobileMenuInFromLeft {
    0% {
        transform: translateX(-100%);
    }

    100% {
        transform: translateX(0);
    }
}
