@use 'sass:map';
@use '@oecd/foundation/src/site/config/spacing-vars' as *;
@use '@oecd/util-a11y' as *;
@use '@oecd/util-breakpoint' as *;
@use '@oecd/util-distance' as d;
@use '@oecd/util-focus' as *;
@use '@oecd/util-text-style' as *;

.cmp-report-navigation {
    --report-navigation-divider: var(--color-divider);
    --report-navigation-icon-size: #{d.rem(16)};
    --report-navigation-link-color: var(--color-text-primary);
    --report-navigation-link-color-active: var(--color-text-link-active);
    --report-navigation-link-color-hover: var(--color-text-link-hover);
    --report-navigation-link-padding-block: #{map.get($spacing, s-8)};
    --report-navigation-trigger-icon-background: var(--color-background-light);
    --report-navigation-trigger-icon-closed: var(--icon-plus);
    --report-navigation-trigger-icon-color: var(--color-icon);
    --report-navigation-trigger-icon-open: var(--icon-minus);
    --report-navigation-trigger-icon-size: #{d.rem(28)};
    --report-navigation-chapter-inset: #{map.get($spacing, s-32)};

    @include breakpoint-from(desktop-small) {
        --report-navigation-chapter-inset: #{map.get($spacing, s-40)};
    }

    a {
        color: var(--report-navigation-link-color);
        display: block;
        padding-block: var(--report-navigation-link-padding-block);
        text-decoration: none;

        &:focus-visible,
        &:hover {
            color: var(--report-navigation-link-color-hover);
        }

        &:focus-visible {
            @include keyboardfocus;
        }

        &[aria-current] {
            color: var(--report-navigation-link-color-active);
        }

        >* {
            pointer-events: none;
        }
    }

    &__page {
        &-header {
            align-items: center;
            display: flex;
            gap: map.get($spacing, s-16);
            justify-content: space-between;

            &:has(button[aria-expanded='false']) {
                border-bottom: 1px solid var(--report-navigation-divider);
            }
        }

        &-link {
            @include text-style(body, 4, b);

            flex: 1 1 auto;

            .js-report-navigation-toggle-menu & {
                --report-navigation-link-padding-block: #{map.get($spacing, s-16)};
            }
        }

        button {
            flex: 0 0 auto;
        }

        &.js-report-navigation-toggle-menu~.cmp-report-navigation__page:not(.js-report-navigation-toggle-menu) .cmp-report-navigation__page-header {
            border-bottom: 1px solid var(--report-navigation-divider);

            .cmp-report-navigation__page-link {
                --report-navigation-link-padding-block: #{map.get($spacing, s-16)};
            }

        }
    }

    &__page-chapters {
        padding-inline-start: var(--report-navigation-chapter-inset);
        width: 100%;

        @at-root ol#{&} {
            list-style: none;
        }
    }

    &__chapter {
        a {
            --report-navigation-link-padding-block: #{map.get($spacing, s-12)};

            @include text-style(label, 2, m);
        }

        &:not(.js-report-navigation-toggle-menu) {
            border-bottom: 1px solid var(--report-navigation-divider);
        }
    }

    &__chapter-header {
        align-items: center;
        display: flex;
        gap: map.get($spacing, s-16);
        justify-content: space-between;

        a {
            flex: 1 1 auto;
        }

        button {
            flex: 0 0 auto;
        }

        &:has(button[aria-expanded='false']) {
            border-bottom: 1px solid var(--report-navigation-divider);
        }
    }

    &__chapter-item {
        padding-inline-start: var(--report-navigation-chapter-inset);

        a {
            --report-navigation-link-padding-block: #{map.get($spacing, s-12)};

            @include text-style(label, 2, m);

            border-bottom: 1px solid var(--report-navigation-divider);
        }
    }

    &__button {
        background-color: var(--report-navigation-trigger-icon-background);
        border: 1px solid var(--report-navigation-trigger-icon-background);
        border-radius: 100%;
        height: var(--report-navigation-trigger-icon-size);
        position: relative;
        width: var(--report-navigation-trigger-icon-size);

        &:hover,
        &:focus {
            cursor: pointer;
        }

        &:focus-visible {
            @include keyboardfocus;
        }

        &::before {
            background-color: var(--report-navigation-trigger-icon-color);
            content: '';
            display: block;
            height: var(--report-navigation-icon-size);
            left: 50%;
            mask-image: var(--report-navigation-trigger-icon-closed);
            mask-position: center;
            mask-repeat: no-repeat;
            mask-size: contain;
            position: absolute;
            top: 50%;
            transform: translate(-50%, -50%);
            width: var(--report-navigation-icon-size);

            @media screen and (forced-colors: active) {
                background: ButtonText;
            }
        }

        &[aria-expanded='true']::before {
            mask-image: var(--report-navigation-trigger-icon-open);
        }
    }
}

// Specific styling for when the Report Navigation is embedded in a Report Content Container sidebar
.cmp-report-content-container__navigation {
    .report-navigation {
        @include breakpoint-from(desktop-small) {
            @supports (position: sticky) or (position: -webkit-sticky) {
                height: 100%;
                position: relative;
            }
        }
    }

    .cmp-report-navigation {
        @include breakpoint-from(desktop-small) {
            // 4px trick to make sure outlines are visible inside menu
            margin: -4px;
            padding: 4px;
            padding-block-end: map.get($spacing, s-32);
            padding-inline-end: map.get($spacing, s-32);
        }
    }
}
