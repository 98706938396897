@use 'sass:map';
@use '@oecd/foundation/src/site/config/spacing-vars' as *;
@use '@oecd/util-distance' as d;
@use '@oecd/util-focus' as *;
@use '@oecd/util-text-style' as *;

///////////////////
// TAGS
//////////////////
.tag {
    --tag-padding-inline: #{map.get($spacing, s-12)};
    --tag-padding-block: #{map.get($spacing, s-4)};
    --tag-border-radius: #{d.rem(14)};
    --tag-background: var(--color-primary-light);
    --tag-background-hover: var(--tag-background);
    --tag-border: 1px solid var(--tag-background);
    --tag-color: var(--color-text-primary);
    --tag-color-hover: var(--color-text-link);
    --tag-border-hover: 1px solid var(--tag-color-hover);

    background: var(--tag-background);
    border: var(--tag-border);
    border-radius: var(--tag-border-radius);
    color: var(--tag-color);
    display: inline-block;
    max-width: 100%;
    overflow: hidden;

    // -1px to account for border
    padding: calc(var(--tag-padding-block) - 1px) calc(var(--tag-padding-inline) - 1px);
    text-overflow: ellipsis;
    white-space: nowrap;

    @include text-style(label, 2, m, 1.4);

    @at-root a#{&},
    button#{&} {
        --tag-border-radius: #{map.get($spacing, s-4)};

        text-decoration: none;

        @media (prefers-reduced-motion: no-preference) {
            transition: 0.15s linear border-color, 0.15s linear color;
        }

        &:hover,
        &:focus {
            background-color: var(--tag-background-hover);
            border: var(--tag-border-hover);
            color: var(--tag-color-hover);
        }

        &:focus-visible {
            @include keyboardfocus;
        }
    }

    &--small {
        @include text-style(label, 3, b);
    }
}

