@use '@oecd/util-focus' as *;
@use 'typography-vars' as *;

:root {
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
}

html {
    @media (prefers-reduced-motion: no-preference) {
        scroll-behavior: smooth;
    }

    &.js-disable-smooth-scroll {
        scroll-behavior: auto;
    }
}

body {
    background: var(--color-background);
    color: var(--color-text-secondary);
    font-family: var(--font-stack-base, sans-serif);
    line-height: 1.5;
    overflow-x: clip;
    position: relative;
}

b,
strong {
    font-weight: $font-weight-bold;
}

em,
i {
    font-style: italic;
}

sub,
sup {
    font-size: 0.65em;
}

sup {
    vertical-align: super;
}

sub {
    vertical-align: sub;
}

/* Baseline for default links */
a:not([class]) {
    color: var(--color-text-link);
    text-decoration: underline;

    /* Relatively sized thickness and offset */
    text-decoration-thickness: max(1px, 0.0625rem);
    text-underline-offset: 0.15em;

    @media (prefers-reduced-motion: no-preference) {
        transition: color 0.15s linear;
    }

    @media (pointer: fine) {
        &:hover,
        &:focus {
            color: var(--color-text-link-hover);
            text-decoration-skip: none;
            text-decoration-skip-ink: none;
            text-decoration-thickness: max(3px, 0.1875rem, 0.12em);
        }
    }

    &:focus-visible {
        @include keyboardfocus;

        text-decoration-thickness: max(1px, 0.0625rem);
    }
}
