@use 'sass:map';
@use '@oecd/util-breakpoint' as *;
@use '@oecd/foundation/src/site/config/spacing-vars' as *;

@media print {
    // Force background to be printed
    * {
        color-adjust: exact !important;
        -webkit-print-color-adjust: exact !important;
    }

    @page {
        margin: 1.5cm;
    }

    @page :first {
        margin-top: 0;
    }

    .u-hide-from-print {
        display: none !important;
    }

    :root {
        --text-secondary: var(--color-text-print-normal);
        --text-primary: var(--color-text-print-dark);
    }

    // Structure
    .container-content {
        &--narrow {
            > :where(.cmp-container) {
                >* {
                    grid-column: 1 / -1 !important;
                }
            }
        }
    }
}
