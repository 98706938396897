@use 'sass:map';
@use '@oecd/foundation/src/site/config/spacing-vars' as *;
@use '@oecd/util-breakpoint' as *;
@use '@oecd/util-button' as *;
@use '@oecd/util-distance' as d;
@use '@oecd/util-text-style' as *;

@media print {
    .cmp-event-header {
        padding-block-start: 3rem;

        &__container {
            column-gap: var(--grid-gap);
            display: grid;
            grid-template-columns: repeat(12, 1fr);
            grid-template-rows: min-content;
            min-height: auto;
        }

        &__breadcrumb {
            display: none;
        }

        &__content-area {
            grid-column: 1 / span 8;
        }

        &__description {
            @include text-style(body, 1);
        }

        &__tags {
            --header-tags-margin-block-start: #{map.get($spacing, s-24)};
        }

        &__event-details {
            margin-block-start: map.get($spacing, s-32);

            &-field {
                grid-column: span 3;

                @container event-header-details (min-width: 500px) {
                    grid-column: span 2;
                }
            }
        }

        &:has(.cmp-image) {
            .cmp-event-header {
                &__container {
                    padding-block-end: map.get($spacing, s-32);
                }

                &__content-area {
                    grid-column: 1 / span 6;
                }

                &__visual {
                    --header-visual-aspect-ratio: 5/4;

                    align-self: start;
                    grid-column: 7 / span 6;
                }
            }
        }
    }
}
