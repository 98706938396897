@use 'sass:map';
@use '@oecd/foundation/src/site/config/spacing-vars' as *;
@use '@oecd/util-breakpoint' as *;
@use '@oecd/util-distance' as d;
@use '@oecd/util-focus' as *;
@use '@oecd/util-text-style' as *;

.cmp-list {
    &--grid-layout.cmp-list--link-rendition {
        display: block;
    }

    &--link-rendition-column-layout.cmp-list--link-rendition {
        @include breakpoint-from(tablet) {
            display: grid;
            gap: 0 var(--grid-gap);
            grid-template-columns: repeat(auto-fill, minmax(d.rem(394), 1fr));

            .cmp-two-column-container & {
                grid-template-columns: repeat(auto-fit, minmax(d.rem(228), 1fr));
            }
        }

        @include breakpoint-from(desktop-small) {
            .cmp-two-column-container & {
                grid-template-columns: repeat(auto-fit, minmax(d.rem(288), 1fr));
            }
        }
    }

    .link-list {
        --list-link-border-bottom-color: var(--color-border);
        --list-link-date-separator-symbol: '\2022';
        --list-link-text-color: var(--color-text-primary);
        --list-link-meta-color: var(--color-text-secondary);
        --list-link-text-color-hover: var(--color-text-link-hover);
        --list-link-title-icon-background-color: var(--color-background-light);
        --list-link-title-icon-background-height: #{d.rem(28)};
        --list-link-title-icon-background-width: #{d.rem(28)};
        --list-link-title-icon-border-radius: 50%;
        --list-link-title-icon-color: var(--color-icon);
        --list-link-title-icon-height: #{d.rem(16)};
        --list-link-title-icon-width: #{d.rem(16)};

        height: 100%;
        width: 100%;

        &__content {
            border-bottom: 1px solid var(--list-link-border-bottom-color);
            height: 100%;
            padding-block: map.get($spacing, s-16);
            padding-inline-end: calc(#{map.get($spacing, s-16)} + var(--list-link-title-icon-background-width));
            position: relative;
        }

        &__date {
            @include text-style(label, 2);

            color: var(--list-link-meta-color);
            display: inline-block;
        }

        &__title {
            @include text-style(label, 2, m);

            color: var(--list-link-text-color);
        }

        &__title-link {
            color: inherit;
            padding-inline-end: map.get($spacing, s-4);
            text-decoration: none;

            @media (prefers-reduced-motion: no-preference) {
                transition: 0.15s linear color;
            }

            &::before {
                content: '';
                inset: 0;
                position: absolute;
                z-index: 1;
            }

            &:hover {
                color: var(--list-link-text-color-hover);
            }

            &:focus-visible {
                color: var(--list-link-text-color-hover);
                outline: none;

                &::before {
                    @include keyboardfocus;
                }
            }
        }

        &__date:not(:empty) {
            @media (prefers-reduced-motion: no-preference) {
                transition: 0.15s linear color;
            }

            &::before {
                content: var(--list-link-date-separator-symbol);
                margin-inline-end: map.get($spacing, s-4);
            }
        }

        &__title-icon {
            background-color: var(--list-link-title-icon-background-color);
            border-radius: var(--list-link-title-icon-border-radius);
            display: inline-block;
            height: var(--list-link-title-icon-background-height);
            inset-block-start: 50%;
            inset-inline-end: 0;
            position: absolute;
            transform: translateY(-50%);
            width: var(--list-link-title-icon-background-width);

            @media screen and (forced-colors: active) {
                border: 1px solid;
            }

            &::after {
                background: var(--list-link-title-icon-color);
                content: '';
                height: var(--list-link-title-icon-height);
                inset-block-start: d.rem(6);
                inset-inline-start: d.rem(6);
                mask-image: var(--icon-chevron-small);
                mask-position: center;
                mask-repeat: no-repeat;
                mask-size: contain;
                position: absolute;
                width: var(--list-link-title-icon-width);

                @media screen and (forced-colors: active) {
                    background: canvasText;
                }
            }
        }
    }
}
