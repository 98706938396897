@use '@oecd/util-distance' as d;

.aem-AuthorLayer-Edit {
    .cmp-footer {
        .social-links,
        .utility-links {
            .cmp-container {
                align-items: center;

                .new {
                    margin-block: 0;
                    min-width: d.rem(200);
                }
            }
        }

        .utility-links {
            .cq-placeholder.cmp-button {
                min-width: d.rem(120);
            }
        }

        .cmp-doormat__content {
            break-inside: avoid;
        }

        .doormat-block + .new {
            column-span: all;
        }
    }
}
