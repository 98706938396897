@use '@oecd/util-distance' as d;

@mixin hide-scrollbars() {
    scrollbar-width: none;  /* Firefox */

    &::-webkit-scrollbar { /* Chrome, Safari and Opera */
        display: none;
    }
}

@mixin custom-scrollbars($thumb-color: #{var(--color-scroll-thumb)},$track-color:#{var(--color-scroll-track)},$scrollbar-color-track-hover-legacy:#{var(--color-scroll-track)},$scrollbar-gutter:stable) {
    --scrollbar-color-thumb: #{$thumb-color};
    --scrollbar-color-track: #{$track-color};
    --scrollbar-color-track-hover-legacy: #{$scrollbar-color-track-hover-legacy};
    --scrollbar-width: auto;
    --scrollbar-width-legacy: #{d.rem(10)};

    scrollbar-gutter: $scrollbar-gutter;

    @media screen and not (forced-colors: active) {
        /* Legacy browsers with `::-webkit-scrollbar-*` support */
        @supports selector(::-webkit-scrollbar) {
            &::-webkit-scrollbar-thumb {
                background: var(--scrollbar-color-thumb);
                border-radius: 9999px;
            }

            &::-webkit-scrollbar-track {
                // background: var(--scrollbar-color-track);
                background: transparent;
                border-radius: 9999px;
            }

            &::-webkit-scrollbar-track:hover {
                background: var(--scrollbar-color-track-hover-legacy);
            }

            &::-webkit-scrollbar {
                max-height: var(--scrollbar-width-legacy);
                max-width: var(--scrollbar-width-legacy);
            }

            &:hover::-webkit-scrollbar-thumb {
                background: var(--scrollbar-color-thumb);
            }

            &:hover {
                /* stylelint-disable-next-line */
                --fix: ; /* This custom property invalidates styles on hover, thereby enforcing a style recomputation. This is needed to work around a bug in Safari. */
            }
        }
    }
}
