@use 'sass:map';
@use '@oecd/foundation/src/site/config/spacing-vars' as *;
@use '@oecd/util-a11y' as *;
@use '@oecd/util-distance' as d;
@use '@oecd/util-focus' as *;
@use '@oecd/util-scrollbar' as *;
@use '@oecd/util-text-style' as *;

.secondary-navigation {
    display: grid;
    grid-template-columns: [full-start] var(--grid-offset) [container-start] minmax(0, var(--container-max-width)) [container-end] var(--grid-offset) [full-end];
    pointer-events: none;
    position: relative;
    z-index: var(--z-index-secondary-navigation, 575);

    @supports (position: sticky) or (position: -webkit-sticky) {
        inset-block-start: 0;
        position: sticky;

        // While scrolling down
        .js-is-scrolling-down & {
            position: relative;
        }
    }
}

.cmp-secondary-navigation {
    $focus-offset: 2px;

    --secondary-navigation-background-color: var(--color-background);
    --secondary-navigation-link-border-color-active: var(--color-text-link);
    --secondary-navigation-link-text-color-active: var(--color-text-link);
    --secondary-navigation-link-text-color-default: var(--color-text-primary);
    --secondary-navigation-link-text-color-hover: var(--color-text-link);
    --scroll-button-width: #{d.rem(40)};
    --scroll-button-color: var(--color-text-primary);
    --scroll-button-color-hover: var(--color-text-link);
    --scroll-icon: var(--icon-chevron-right);
    --scroll-icon-size: #{d.rem(16)};

    @include text-style(label, 2, b);

    grid-column: container;
    pointer-events: all;
    position: relative;

    &::after {
        background-color: var(--secondary-navigation-background-color);
        border-block-end: 1px solid var(--color-border-transparent);
        content: '';
        display: block;
        height: 100%;
        inset-block-end: $focus-offset;
        inset-inline-start: 50%;
        margin-inline-start: -50vw;
        position: absolute;
        width: 100vw;
        z-index: -1;
    }

    ul {
        display: flex;
        flex-direction: row;
        gap: map.get($spacing, s-32);
        overflow: auto hidden;
        scroll-behavior: smooth;
        scroll-padding-inline: var(--scroll-button-width);
        scroll-snap-type: x mandatory;
        width: 100%;

        @include hide-scrollbars;
    }

    li {
        flex: 0 0 auto;
        padding: $focus-offset;
        scroll-padding-inline-end: map.get($spacing, s-32);
        scroll-snap-align: start;
    }

    a {
        color: var(--secondary-navigation-link-text-color-default);
        cursor: default;
        display: block;
        padding: map.get($spacing, s-20) $focus-offset;
        text-decoration: none;

        @media (prefers-reduced-motion: no-preference) {
            transition: 0.15s linear color;
        }

        &:focus-visible,
        &:hover {
            color: var(--secondary-navigation-link-text-color-hover);
            text-decoration: none;

            &:not(.active) {
                cursor: pointer;
            }
        }

        &:focus-visible {
            outline: $focus-offset solid var(--secondary-navigation-link-border-color-active);
            outline-offset: 0;
        }

        &.active {
            border-bottom: 2px solid var(--secondary-navigation-link-border-color-active);
            color: var(--secondary-navigation-link-text-color-active);
        }
    }

    &__control {
        color: var(--scroll-button-color);
        height: calc(100% - #{$focus-offset} - 1px);
        inset-block-start: 0;
        position: absolute;
        width: var(--scroll-button-width);

        &:hover {
            color: var(--scroll-button-color-hover);
        }

        &:focus-visible {
            @include keyboardfocus;

            color: var(--scroll-button-color-hover);
        }

        &:not(.js-shown) {
            display: none;
        }

        // Set the gradient
        &::before {
            content: '';
            height: 100%;
            inset-block-start: 0;
            pointer-events: none;
            position: absolute;
            width: calc(var(--scroll-button-width) * 1.15);
        }

        // Set the icon
        &::after {
            background-color: currentcolor;
            content: '';
            display: inline-block;
            height: var(--scroll-icon-size);
            inset-block-start: 50%;
            inset-inline-start: 50%;
            mask-image: var(--scroll-icon);
            mask-position: center;
            mask-repeat: no-repeat;
            mask-size: contain;
            position: absolute;
            transform: translate(-50%, -50%);
            width: var(--scroll-icon-size);
        }

        &-text {
            @include visually-hidden;
        }

        &--next {
            inset-inline-end: calc(((var(--scroll-button-width) - var(--scroll-icon-size)) / 2 ) * -1);

            &::before {
                background-image: linear-gradient(to left, var(--color-background) 0%, var(--color-background) 34%, rgba(var(--color-background-rgb), 0%) 100%);
                inset-inline-end: calc((var(--scroll-button-width) - var(--scroll-icon-size)) / 2);
            }
        }

        &--prev {
            inset-inline-start: calc(((var(--scroll-button-width) - var(--scroll-icon-size)) / 2 ) * -1);

            &::after {
                transform: translate(-50%, -50%) rotate(-180deg);
            }

            &::before {
                background-image: linear-gradient(to right, var(--color-background) 0%, var(--color-background) 34%, rgba(var(--color-background-rgb), 0%) 100%);
                inset-inline-start: calc((var(--scroll-button-width) - var(--scroll-icon-size)) / 2);
            }
        }
    }
}
