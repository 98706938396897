@use '@oecd/util-breakpoint' as *;

.aem-AuthorLayer-Edit {
    .container-content--narrow,
    .person-container {
        > .cmp-container {
            > .new.cq-Editable-dom {
                @include breakpoint-from(mobile-large) {
                    grid-column: 2 / span 10;
                }

                @include breakpoint-from(desktop-small) {
                    grid-column: 3 / span 8;
                }

                @include breakpoint-from(desktop) {
                    grid-column: 4 / span 6;
                }
            }
        }
    }
}
