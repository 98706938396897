@use 'sass:map';
@use '@oecd/foundation/src/site/config/spacing-vars' as *;
@use 'typography-vars' as *;
@use '@oecd/util-breakpoint' as *;
@use '@oecd/util-distance' as d;
@use '@oecd/util-text-style' as *;
@use 'theme-config' as themeConfig;

.testimonial {
    container-name: testimonial;
    container-type: inline-size;
}

.cmp-testimonial {
    --testimonial-background-color: var(--color-background-inverse);
    --testimonial-text-color: var(--color-text-primary-inverse);
    --testimonial-job-title-color: var(--color-text-primary-inverse-transparent);
    --testimonial-min-height: #{d.rem(272)};
    --testimonial-min-height-without-image: #{d.rem(285)};
    --testimonial-min-height-in-carousel: #{d.rem(350)};
    --testimonial-visual-aspect-ratio: 7/4;
    --testimonial-decoration-height: #{d.rem(285)};
    --testimonial-decoration-width: #{d.rem(299)};

    background-color: var(--testimonial-background-color);
    color: var(--testimonial-text-color);

    @include breakpoint-until(tablet) {
        // Ensure Image components bleed on small screens
        margin-inline: calc(var(--grid-offset) * -1);
    }

    &__content {
        padding: map.get($spacing, s-24);

        // Calc 1ch needed to align content with left hanging quote
        padding-inline-start: calc(#{map.get($spacing, s-24)} + 1ch);

        // Text style included here in order to align the content to the hanging quote
        // otherwise 1ch unit is not the same
        @include text-style(heading, 2);

        .cmp-button {
            --button-subtle-text: var(--color-text-primary-inverse);
            --button-subtle-icon-background: var(--color-background);
            --button-subtle-icon-color: var(--color-text-primary);
            --button-subtle-icon-outline: var(--color-focus-outline-inverse);
            --button-subtle-text-hover: var(--color-text-title-accent-light);

            &[disabled] {
                --button-background: var(--color-disabled);
                --button-background-hover: var(--button-background);
                --button-border: var(--button-background);
                --button-border-hover: var(--button-border);
                --button-txt: var(--color-text-secondary-transparent);
                --button-txt-hover: var(--button-txt);
            }

            &.cmp-button-download {
                --button-background: transparent;
                --button-background-hover: transparent;
                --button-border: var(--color-background);
                --button-txt: var(--color-text-primary-inverse);
                --button-border-hover: var(--color-primary-light);
                --button-txt-hover: var(--color-text-accent-light);
                --button-outline: var(--color-focus-outline-inverse);
            }

            // Margin is applied on cmp-button class
            // That way it is only applicable if the link is rendered
            margin-block-start: map.get($spacing, s-56);
        }

        // Set minimum height on testimonials in OTB carousel to prevent layout shift.
        // Align testimonial action link to bottom of content like on other viewports.
        .cmp-testimonial-carousel & {
            display: flex;
            flex-direction: column;
            min-height: var(--testimonial-min-height-in-carousel);

            @container testimonial (min-width: 716px) {
                --testimonial-min-height-in-carousel: #{d.rem(404)};
            }

            @container testimonial (min-width: 944px) {
                --testimonial-min-height-in-carousel: #{d.rem(600)};
            }
        }
    }

    &__quote {
        position: relative;
        z-index: 2;

        &-quote {
            @include text-style(heading, 2, false, false, true);

            &::before {
                content: '“';
                margin-inline-start: -1ch;
                position: absolute;
            }

            &::after {
                content: '”';
                margin-inline-start: map.get($spacing, s-4);
                position: absolute;
            }
        }

        &-source {
            margin-block-start: map.get($spacing, s-16);

            @include text-style(label, 2, b, false, true);
        }

        &-jobtitle {
            color: var(--testimonial-job-title-color);
            display: block;
            font-weight: $font-weight-normal;
        }
    }

    &__visual {
        line-height: 0;
    }

    .cmp-image {
        aspect-ratio: var(--testimonial-visual-aspect-ratio);
        overflow: hidden;
        position: relative;

        img {
            height: 100%;
            object-fit: cover;
            object-position: center center;
            position: absolute;
            width: 100%;
        }
    }

    &__link {
        // Set minimum height on testimonials in OTB carousel to prevent layout shift.
        // Align testimonial action link to bottom of content like on other viewports.
        .cmp-testimonial-carousel & {
            margin-top: auto;
        }
    }

    // Testimonial with decorative image
    @if map.get(themeConfig.$theme-imagery, testimonial-background-image) {
        .testimonial--has-decoration & {
            &__content {
                overflow: hidden;
                position: relative;

                &::before {
                    background-image: url('#{map.get(themeConfig.$theme-imagery, testimonial-background-image)}');
                    background-position: center center;
                    background-repeat: no-repeat;
                    background-size: contain;
                    content: '';
                    height: var(--testimonial-decoration-height);
                    inset-block-end: d.rem(0);
                    inset-inline-end: d.rem(0);
                    opacity: 0.4;
                    position: absolute;
                    transform: translate(42%, 42%);
                    width: var(--testimonial-decoration-width);
                    z-index: 1;
                }
            }
        }

        // Background placement when there is no image configured
        &:not(:has(.cmp-image__image)) {
            .testimonial--has-decoration & {
                overflow: hidden;
                position: relative;

                &::before {
                    background-image: url('#{map.get(themeConfig.$theme-imagery, testimonial-background-image)}');
                    background-position: center center;
                    background-repeat: no-repeat;
                    background-size: contain;
                    content: '';
                    height: var(--testimonial-decoration-height);
                    inset-block-end: d.rem(0);
                    inset-inline-end: d.rem(0);
                    opacity: 0.4;
                    position: absolute;
                    transform: translate(42%, 42%);
                    width: var(--testimonial-decoration-width);
                    z-index: 1;
                }

                .cmp-testimonial__content {
                    &::before {
                        display: none;
                    }
                }
            }
        }
    }
}

@container testimonial (min-width: 716px) {
    .cmp-testimonial {
        column-gap: var(--grid-gap);
        display: grid;
        grid-template-columns: repeat(12, 1fr);

        // Override margin set in mobile media query.
        margin-inline: 0;
        min-height: var(--testimonial-min-height);

        &__content {
            display: flex;
            flex-direction: column;
            grid-column: 1 / span 6;
        }

        &__link {
            margin-top: auto;
        }

        &__visual {
            grid-column: 7 / span 6;
            height: 100%;
            position: relative;
            width: 100%;
        }

        // Override content width when there is no image configured
        // Visual has been made narrow in order to be able to edit the component
        &:not(:has(.cmp-image__image)) {
            min-height: var(--testimonial-min-height-without-image);

            .cmp-testimonial__content {
                grid-column: 1 / span 9;
            }

            .cmp-testimonial__visual {
                grid-column: 10 / span 3;
            }
        }

        .cmp-image {
            --testimonial-visual-aspect-ratio: initial;

            height: 100%;
            position: absolute;
            width: 100%;
        }

        // Testimonial with decorative image
        @if map.get(themeConfig.$theme-imagery, testimonial-background-image) {
            .testimonial--has-decoration & {
                &__content {
                    margin-right: calc(var(--grid-gap) * -1);
                    padding-inline-end: calc(#{map.get($spacing, s-24)} + var(--grid-gap));
                }
            }
        }
    }
}

@container testimonial (min-width: 944px) {
    .cmp-testimonial {
        --testimonial-min-height: #{d.rem(404)};
        --testimonial-decoration-height: #{d.rem(475)};
        --testimonial-decoration-width: #{d.rem(499)};

        @include text-style(heading, 1, null, false, true);

        &__content {
            padding: map.get($spacing, s-48);
            padding-inline-start: calc(#{map.get($spacing, s-48)} + 1ch);
        }

        &__quote {
            @include text-style(heading, 1, null, false, true);

            &-quote {
                @include text-style(heading, 1, null, false, true);

                > p {
                    margin-block-end: map.get($spacing, s-8);

                    &:last-child {
                        display: inline;
                    }
                }
            }
        }

        // Testimonial with decorative image
        @if map.get(themeConfig.$theme-imagery, testimonial-background-image) {
            .testimonial--has-decoration & {
                &__content {
                    margin-right: calc(var(--grid-gap) * -1);
                    padding-inline-end: calc(#{map.get($spacing, s-48)} + var(--grid-gap));
                }
            }
        }
    }
}

// Adjust padding.
@container testimonial (min-width: 1200px) {
    .cmp-testimonial {
        --testimonial-min-height: #{d.rem(536)};

        &__content {
            padding: map.get($spacing, s-64);
            padding-inline-start: calc(#{map.get($spacing, s-64)} + 1ch);
        }

        @if map.get(themeConfig.$theme-imagery, testimonial-background-image) {
            .testimonial--has-decoration & {
                &__content {
                    margin-right: calc(var(--grid-gap) * -1);
                    padding-inline-end: calc(#{map.get($spacing, s-64)} + var(--grid-gap));
                }
            }
        }
    }
}
