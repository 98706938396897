.cmp-testimonial-carousel {
    &.container-themable:not(.theme-highlight) {
        padding-block: 0;
    }

    .cmp-carousel {
        @at-root .container-themable#{&} {
            grid-column: wrapper;
        }
    }
}
