@use 'sass:map';
@use '@oecd/foundation/src/site/config/spacing-vars' as *;

.cmp-clickable-tags {
    &__tags {
        display: flex;
        flex-wrap: wrap;
        gap: map.get($spacing, s-4);
    }
}
