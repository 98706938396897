.aem-AuthorLayer-Edit {
    .cmp-report-navigation-buttons .cmp-button.cq-placeholder {
        min-width: 120px;
    }
}

.aem-AuthorLayer-Preview {
    .cmp-report-navigation-buttons .cmp-button.cq-placeholder {
        display: none;
    }
}
