.aem-AuthorLayer-Edit {
    .featured-navigation-item {
        &__tagline {
            display: block;
            width: 100%;

            &:not(:has(.cq-placeholder.cmp-text)) {
                margin-block-end: auto;

                .cmp-text {
                    margin-block-end: 0;
                }
            }
        }

        &__link {
            width: 100%;
        }

        &__content {
            width: 100%;
        }
    }

    .featured-topics-container {
        .cmp-container {
            > * {
                &.new.newpar {
                    column-span: all;
                }
            }
        }
    }
}
