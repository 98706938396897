@use 'sass:map';
@use '@oecd/util-breakpoint' as *;

.cmp-three-column-container {
    // -------------------------------
    // Vertical flow
    // -------------------------------
    .column-container__container {
        // Allow components to take up the entire height of a column
        > .cmp-container {
            display: flex;
            flex-direction: column;
            height: 100%;
        }

        // Last item of the last column cannot have a bottom margin
        // otherwise the margin between components is not according to design
        &:last-child {
            > :where(.cmp-container)> *:where(:last-child) {
                margin-block-end: 0;
            }
        }

        @include breakpoint-from(tablet) {
            // Last item of the column cannot have a bottom margin
            // otherwise the margin between components is not according to design
            &> :where(.cmp-container)> :where(*:last-child) {
                margin-block-end: 0;
            }
        }

        // Ensure Image components bleed on small screens
        @include breakpoint-until(tablet) {
            > :where(.cmp-container) > :where(.image:not(.image--reduced-width)) {
                margin-inline: calc(var(--grid-offset) * -1);
            }
        }
    }
}
