@use 'sass:map';
@use 'sass:math';
@use '@oecd/foundation/src/site/config/spacing-vars' as *;
@use '@oecd/util-a11y' as *;
@use '@oecd/util-breakpoint' as *;
@use '@oecd/util-button' as *;
@use '@oecd/util-distance' as d;
@use '@oecd/util-focus' as *;
@use '@oecd/util-scrollbar' as *;
@use '@oecd/util-text-style' as *;

$search-widescreen-adjustment-mediaquery: 1384px;

.cmp-facet-rail {
    --facet-rail-bg-color: var(--color-background);
    --facet-rail-close-icon: var(--icon-close-small);
    --facet-rail-content-bg-color: var(--color-background);
    --facet-rail-footer-bg-color: var(--color-background);
    --facet-rail-header-bg-color: var(--color-background);
    --facet-rail-header-border-color: var(--color-border);
    --facet-rail-icon-size: #{d.rem(16)};
    --facet-rail-item-divider-color: #dee5ed;
    --facet-rail-slider-icon: var(--icon-slider);
    --facet-rail-max-width-from-desktop: #{d.rem(385)};
    --facet-rail-trigger-hover-color: var(--color-primary);
    --overlay-gradient-cover-height: #{map.get($spacing, s-48)};
    --overlay-gradient-shadow-height: #{map.get($spacing, s-24)};
    --overlay-background-rgb: 255, 255, 255;
    --overlay-shadow-rgb: 16, 29, 64;
    --overlay-gradient-cover: rgb(var(--overlay-background-rgb)); // stylelint-disable-line color-function-notation
    --overlay-gradient-cover-transparent: rgba(var(--overlay-background-rgb), 0);
    --overlay-gradient-shadow: rgba(var(--overlay-shadow-rgb), 0.1);
    --overlay-gradient-shadow-transparent: rgba(var(--overlay-shadow-rgb), 0);

    @include breakpoint-from(desktop-small) {
        --facet-rail-bg-color: var(--color-background-verylight);
        --facet-rail-content-bg-color: var(--color-background-verylight);
        --facet-rail-header-bg-color: var(--color-background-verylight);
    }

    background-color: var(--facet-rail-bg-color);

    @include breakpoint-from(desktop-small) {
        border-radius: 0 4px 4px 0;
        margin-inline-start: calc(var(--grid-offset) * -1);
        max-width: var(--facet-rail-max-width-from-desktop);
    }

    @include breakpoint-from(desktop) {
        margin-inline-start: clamp(-64px, calc(((100vw - 1248px) / 2 ) * -1), 0vw);
    }

    @media (min-width: $search-widescreen-adjustment-mediaquery) {
        border-radius: 4px;
        margin-inline-end: calc(var(--grid-gap) * -1);
    }

    &__toggle-mobile,
    &__footer {
        @include breakpoint-from(desktop-small) {
            display: none;
        }
    }

    &__toggle-mobile {
        &--header {
            align-items: center;
            border-bottom: 1px solid var(--facet-rail-item-divider-color);
            display: flex;
            justify-content: space-between;
            margin-inline: calc(var(--grid-offset) * -1);
            padding-inline: map.get($spacing, s-24);

            &-title {
                @include text-style(label, 2, b);
            }

            &-button {
                align-items: center;
                color: var(--color-text-primary);
                display: flex;
                justify-content: space-between;
                padding: map.get($spacing, s-16) map.get($spacing, s-16);

                &::after {
                    background-color: currentcolor;
                    content: '';
                    display: block;
                    height: var(--facet-rail-icon-size);
                    inset-block-start: 50%;
                    inset-inline-start: 50%;
                    mask-image: var(--facet-rail-slider-icon);
                    mask-position: center;
                    mask-repeat: no-repeat;
                    mask-size: contain;
                    width: var(--facet-rail-icon-size);
                }

                span {
                    @include visually-hidden;
                }

                &:hover,
                &:focus {
                    color: var(--facet-rail-trigger-hover-color);
                }

                &:focus-visible {
                    @include keyboardfocus;
                }
            }
        }
    }

    &__header {
        align-items: center;
        border-bottom: 1px solid var(--facet-rail-header-border-color);
        display: flex;
        padding: map.get($spacing, s-16) map.get($spacing, s-24);

        &-title {
            @include text-style(heading, 3);
        }

        &-button {
            flex: 0 0 auto;
            margin-inline-start: auto;

            .cmp-button {
                @include button-icon(--facet-rail-close-icon, $icon-only: false, $show-copy: true);
            }

            &--hidden {
                visibility: hidden;
            }
        }
    }

    &__desktop {
        display: none;

        @include breakpoint-from(desktop-small) {
            display: block;
        }
    }

    &__content {
        padding: 0 map.get($spacing, s-24);

        @include breakpoint-from(desktop-ultra) {
            padding-inline: map.get($spacing, s-40);
        }

        .cmp-facet-rail-mobile-dialog & {
            background:
            linear-gradient(to bottom, var(--overlay-gradient-cover) 0%, var(--overlay-gradient-cover-transparent) 100%),
            linear-gradient(to bottom, var(--overlay-gradient-shadow) 0%, rgba(var(--overlay-shadow-rgb), 0%) 100%),
            linear-gradient(to top, var(--overlay-gradient-cover) 0%, var(--overlay-gradient-cover-transparent) 100%) center bottom,
            linear-gradient(to top, var(--overlay-gradient-shadow) 0%, rgba(var(--overlay-shadow-rgb), 0%) 100%) center bottom;
            background-attachment: local, scroll, local, scroll;
            background-repeat: no-repeat;
            background-size: 100% var(--overlay-gradient-cover-height), 100% var(--overlay-gradient-shadow-height), 100% var(--overlay-gradient-cover-height), 100% var(--overlay-gradient-shadow-height);
            flex: 1;
            min-height: 0;
            overflow-y: auto;
            padding: map.get($spacing, s-8) map.get($spacing, s-24) map.get($spacing, s-40);

            // Scrollbar gutter auto otherwise there is a scroll gradient artefact
            @include custom-scrollbars;

            // Adjust width otherwise custom-scrollbar is not noticable due to overlay
            @supports selector(::-webkit-scrollbar) {
                width: calc(100% - (var(--scrollbar-width-legacy) / 2));
            }
        }
    }

    &__footer {
        background-color: var(--facet-rail-footer-bg-color);
        display: flex;
        justify-content: flex-end;
        padding: map.get($spacing, s-8) map.get($spacing, s-24);

        @include breakpoint-from(desktop-small) {
            display: none;
        }

        .cmp-facet-rail-mobile-dialog & {
            border-top: 1px solid var(--color-border);
        }
    }
}
