@use 'sass:map';
@use '@oecd/foundation/src/site/config/spacing-vars' as *;
@use '@oecd/util-distance' as d;
@use '@oecd/util-focus' as *;
@use '@oecd/util-text-style' as *;

.footnote {
    --layout-spacing-default: #{map.get($spacing, s-16)};
}

.cmp-footnote {
    --footnote-max-width: #{d.rem(608)};
    --footnote-number-color: var(--color-text-secondary-transparent);
    --footnote-text-color: var(--color-text-secondary);
    --footnote-backlink-color: var(--color-text-link);
    --footnote-backlink-color-hover: var(--color-text-link);

    align-items: flex-start;
    display: flex;
    max-width: var(--footnote-max-width);

    @include text-style(body, 4);

    &__number {
        color: var(--footnote-number-color);
        flex: 0 0 d.rem(40);
    }

    &__text {
        color: var(--footnote-text-color);
        overflow-wrap: anywhere;

        p {
            display: inline;
        }

    }

    &__back-link {
        color: var(--footnote-backlink-color);
        text-decoration: underline;

        /* Relatively sized thickness and offset */
        text-decoration-thickness: max(1px, 0.0625rem);
        text-underline-offset: 0.15em;

        @media (prefers-reduced-motion: no-preference) {
            transition: color 0.15s linear;
        }

        @media (pointer: fine) {
            &:hover,
            &:focus {
                color: var(--footnote-backlink-color-hover);
                text-decoration-skip: none;
                text-decoration-skip-ink: none;
                text-decoration-thickness: max(3px, 0.1875rem, 0.12em);
            }
        }

        &:focus-visible {
            @include keyboardfocus;

            text-decoration-thickness: max(1px, 0.0625rem);
        }
    }
}

.js-footnote-target[id]:target {
    &,
    .js-sticky-scroll-interaction & {
        scroll-margin-block-start: var(--sticky-target-scroll-offset-up, #{map.get($spacing, s-32)});
    }
}
