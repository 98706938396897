@use 'sass:map';
@use '@oecd/foundation/src/site/config/spacing-vars' as *;

@media print {
    .chart {
        page-break-inside: avoid;
    }

    .cmp-chart {
        max-width: 100%;

        &__wrapper {
            max-width: 100%;
        }
    }

    .cmp-chart-miniatures-container {
        .cmp-container {
            grid-auto-flow: row;
            grid-template-columns: repeat(3, 1fr);
            overflow-x: inherit;
            width: 100%;
        }

        .chart {
            min-width: inherit;
        }
    }
}
