.cmp-image-carousel {
    .cmp-image {
        aspect-ratio: 21/9;
        position: relative;

        .cmp-image__image,
        .cmp-image__link {
            height: 100%;
            object-fit: contain;
            width: 100%;
        }
    }

    &.container-themable:not(.theme-highlight) {
        padding-block: 0;
    }

    .cmp-carousel {
        @at-root .container-themable#{&} {
            grid-column: wrapper;
        }
    }
}
