@use '@oecd/util-distance' as d;

@media print {
    .cmp-tabs-list {
        --tabs-list-column-width: #{d.rem(300)};

        &__search-form {
            display: none;
        }
    }
}
