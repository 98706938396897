@use 'sass:map';
@use '@oecd/util-breakpoint' as *;
@use '@oecd/util-distance' as d;
@use '@oecd/util-focus' as *;
@use '@oecd/util-text-style' as *;
@use '@oecd/foundation/src/site/config/spacing-vars' as *;

.cmp-accordion {
    --accordion-trigger-text: var(--color-text-primary);
    --accordion-trigger-text-hover: var(--color-text-accent);
    --accordion-trigger-icon-background: var(--color-background-light);
    --accordion-trigger-icon-color: var(--color-icon);
    --accordion-divider: var(--color-divider);
    --accordion-trigger-icon-size: #{d.rem(28)};
    --accordion-icon-size: #{d.rem(16)};

    &__item {
        border-bottom: 1px solid var(--accordion-divider);
    }

    &__button {
        color: var(--accordion-trigger-text);
        display: block;
        padding-block: map.get($spacing, s-24);
        position: relative;
        width: 100%;

        @include breakpoint-from(desktop-small) {
            padding-block: map.get($spacing, s-32);
        }

        @media (prefers-reduced-motion: no-preference) {
            transition-duration: 250ms;
            transition-property: color;
            transition-timing-function: cubic-bezier(0.4, 0, 0.4, 1);
        }

        &:hover,
        &:focus {
            cursor: pointer;
        }

        &:hover {
            color: var(--accordion-trigger-text-hover);
        }

        &:focus-visible {
            outline: none;

            .cmp-accordion__title {
                @include keyboardfocus;
            }
        }

        .js-sticky-scroll-interaction & {
            scroll-margin-block-start: var(--sticky-target-scroll-offset-down);
        }
    }

    &__icon {
        background: var(--accordion-trigger-icon-background);
        border: 1px solid var(--accordion-trigger-icon-background);
        border-radius: 100%;
        display: block;
        height: var(--accordion-trigger-icon-size);
        inset-block-start: 50%;
        inset-inline-end: 0;
        position: absolute;
        transform: translateY(-50%);
        width: var(--accordion-trigger-icon-size);

        &::before {
            background-color: var(--accordion-trigger-icon-color);
            content: '';
            display: block;
            height: var(--accordion-icon-size);
            inset-block-start: 50%;
            inset-inline-start: 50%;
            mask-image: var(--icon-plus);
            mask-position: center;
            mask-repeat: no-repeat;
            mask-size: contain;
            position: absolute;
            transform: translate(-50%, -50%);
            width: var(--accordion-icon-size);

            .cmp-accordion__button--expanded & {
                mask-image: var(--icon-minus);
            }

            @media screen and (forced-colors: active) {
                background: ButtonText;
            }
        }
    }

    &__title {
        @include text-style('body', 4, b);

        @include breakpoint-from(desktop-small) {
            @include text-style('body', 2, b, null, true);
        }

        display: block;
        padding-inline-end: map.get($spacing, s-32);
        position: relative; // This is needed so in Safari the outline is completely visible
    }

    &__panel {
        padding-block-end: map.get($spacing, s-32);

        @include breakpoint-from(desktop-small) {
            padding-block-end: map.get($spacing, s-48);
            padding-inline-end: map.get($spacing, s-48);
        }
    }

    .accordion-item > .cmp-container {
        > * + * {
            margin-top: map.get($spacing, s-24);
        }
    }

    // Accordion used in navigation dropdown
    .accordion--navigation-dropdown & {
        --accordion-trigger-text: var(--color-text-secondary);
        --accordion-trigger-text-hover: var(--color-text-primary);
        --accordion-divider: transparent;
        --accordion-trigger-icon-size: #{d.rem(24)};

        &__button {
            padding-block: map.get($spacing, s-12);

            &--expanded {
                color: var(--accordion-trigger-text-hover);

                .cmp-accordion__icon {
                    &::before {
                        mask-image: var(--icon-chevron-right);
                        transform: translate(-50%, -50%) rotate(-90deg);
                    }
                }
            }
        }

        &__title {
            @include text-style('label', 2, m);
        }

        &__icon {
            transform: translateY(-50%);

            &::before {
                mask-image: var(--icon-chevron-right);
                transform: translate(-50%, -50%) rotate(90deg);
            }
        }

        &__panel {
            padding-block-end: map.get($spacing, s-16);

            @include breakpoint-from(desktop-small) {
                padding-block-end: map.get($spacing, s-16);
                padding-inline-end: 0;
            }
        }
    }
}
