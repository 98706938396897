@use 'sass:map';
@use '@oecd/foundation/src/site/config/spacing-vars' as *;
@use '@oecd/util-a11y' as *;
@use '@oecd/util-breakpoint' as *;
@use '@oecd/util-distance' as d;

.searchbox-dialog {
    --searchbox-dialog-margin-block-start: #{map.get($spacing, s-16)};

    @at-root dialog#{&} {
        height: 100%;
        margin: auto;
        margin-block-start: var(--searchbox-dialog-margin-block-start);
        max-height: calc(100% - #{map.get($spacing, s-32)});
        max-width: calc(100% - #{map.get($spacing, s-32)});
        min-height: d.rem(70);
        overflow: hidden;
        width: 100%;

        @include breakpoint-from(tablet) {
            max-width: d.rem(600);
        }

        @include breakpoint-from(desktop-small) {
            --searchbox-dialog-margin-block-start: var(--main-navigation-height, map.get($spacing, s-80));
        }
    }

    &::backdrop {
        background-color: var(--color-background-backdrop);
    }

    &__close {
        background-color: var(--color-background);
        color: var(--color-icon);
        inset-block-start: map.get($spacing, s-20);
        inset-inline-start: map.get($spacing, s-20);
        line-height: 0;
        margin: d.rem(6) 0 map.get($spacing, s-4) map.get($spacing, s-12);
        padding: map.get($spacing, s-16) map.get($spacing, s-8);
        position: fixed;

        @media (prefers-reduced-motion: no-preference) {
            transition: 0.15s linear color;
        }

        @include breakpoint-from(tablet) {
            background-color: transparent;
            color: var(--color-icon-inverse);
            inset-block-start: 0;
            inset-inline: auto 0;
            margin: 0;
            padding: map.get($spacing, s-24);
        }

        &:hover {
            --mobile-navigation-close-color: var(--color-primary);
        }

        &:focus {
            --mobile-navigation-close-color: var(--color-primary);
        }

        &:focus-visible {
            outline: 2px solid var(--color-focus-outline);
        }

        &-icon {
            vertical-align: middle;

            &--mobile {
                @include breakpoint-from(tablet) {
                    display: none;
                }
            }

            &--tablet {
                display: none;

                @include breakpoint-from(tablet) {
                    display: block;
                }
            }
        }

        &-text {
            @include visually-hidden;
        }
    }

    oecd-searchbox { // stylelint-disable-line selector-type-no-unknown
        --font-family: var(--font-stack-base);
        --dropdown-max-height: calc(100dvh - var(--form-input-height) - #{map.get($spacing, s-32)} - var(--searchbox-dialog-margin-block-start));
    }
}

html.js-searchbox-dialog-is-open {
    // Hiding the Main Navigation menu to improve readability.
    .cmp-main-navigation {
        @include breakpoint-from(tablet) {
            visibility: hidden;
        }
    }
}
