@use 'sass:map';
@use '@oecd/foundation/src/site/config/spacing-vars' as *;
@use '@oecd/util-breakpoint' as *;
@use '@oecd/util-distance' as d;
@use '@oecd/util-focus' as *;
@use '@oecd/util-line-clamp' as *;
@use '@oecd/util-text-style' as *;

.cmp-list {
    &--grid-layout.cmp-list--info-card-rendition {
        grid-template-columns: repeat(auto-fit, minmax(d.rem(394), 1fr));

        @include breakpoint-until(mobile-large) {
            grid-template-columns: 1fr;
        }

        @include breakpoint-between(mobile-large, desktop) {
            grid-template-columns: repeat(auto-fit, minmax(d.rem(340), 1fr));
        }

        .cmp-two-column-container & {
            grid-template-columns: repeat(auto-fit, minmax(d.rem(288), 1fr));
        }
    }

    &--info-card-rendition {
        --list-info-card-background-color: var(--color-background);
        --list-info-card-border-color: var(--color-border);
        --list-info-card-border-color-hover: var(--color-primary);
        --list-info-card-title-text-color: var(--color-text-primary);
        --list-info-card-text-color: var(--color-text-secondary);
        --list-info-card-cta-text-color: var(--color-text-primary);
        --list-info-card-cta-text-color-hover: var(--color-text-link);
        --list-info-card-cta-icon-color: var(--color-background);
        --list-info-card-cta-icon-background-color: var(--color-primary);

        .info-card {
            background-color: var(--list-info-card-background-color);
            border: 1px solid var(--list-info-card-border-color);
            border-radius: 4px;
            display: flex;
            flex-direction: column;
            height: 100%;
            padding: map.get($spacing, s-24);
            position: relative;

            @media (prefers-reduced-motion: no-preference) {
                transition: 0.15s linear border-color;
            }

            &:hover {
                border-color: var(--list-info-card-border-color-hover);

                .info-card__cta {
                    color: var(--list-info-card-cta-text-color-hover);
                }
            }

            &:focus-within {
                border-color: var(--list-info-card-border-color-hover);
            }

            &__content {
                flex-grow: 1;
            }

            &__title {
                // Increase lineheight so descenders don't get cut off
                @include text-style(body, 4, b, 1.25);
            }

            &__title-link {
                color: var(--list-info-card-title-text-color);
                text-decoration: none;

                // Set line clamp to 4
                @include line-clamp(4);

                &::before {
                    content: '';
                    inset: 0;
                    position: absolute;
                    z-index: 1;
                }

                &:focus-visible {
                    color: var(--list-info-card-cta-text-color-hover);
                    outline: none;

                    &::before {
                        @include keyboardfocus;
                    }
                }

            }

            &__description {
                @include text-style(label, 2, null, 1.25);

                color: var(--list-info-card-text-color);
                margin-block: map.get($spacing, s-8);

                // Set line clamp to 3
                @include line-clamp(3);
            }

            &__cta {
                @include text-style(label, 2, b);

                color: var(--list-info-card-cta-text-color);
                margin-block-start: map.get($spacing, s-32);

                @media (prefers-reduced-motion: no-preference) {
                    transition: 0.15s linear color;
                }
            }

            &__cta-icon {
                background-color: var(--list-info-card-cta-icon-background-color);
                border-radius: 50%;
                display: inline-block;
                height: d.rem(20);
                margin-inline-start: map.get($spacing, s-8);
                position: relative;
                vertical-align: bottom;
                width: d.rem(20);

                @media screen and (forced-colors: active) {
                    border: 1px solid;
                }

                &::after {
                    background: var(--list-info-card-cta-icon-color);
                    content: '';
                    height: d.rem(16);
                    left: d.rem(2);
                    mask-image: var(--icon-chevron-small);
                    mask-position: center;
                    mask-repeat: no-repeat;
                    mask-size: contain;
                    position: absolute;
                    top: d.rem(2);
                    width: d.rem(16);

                    @media screen and (forced-colors: active) {
                        background: canvasText;
                    }
                }
            }
        }
    }
}
