@use 'sass:map';
@use '@oecd/foundation/src/site/config/spacing-vars' as *;
@use '@oecd/util-a11y' as *;
@use '@oecd/util-breakpoint' as *;
@use '@oecd/util-distance' as d;

.cmp-facet-rail-mobile-dialog {
    --facet-rail-mobile-dialog-max-width: #{d.rem(390)};
    --facet-rail-mobile-dialog-background: var(--color-background);
    --facet-rail-mobile-dialog-border-bottom-color: var(--color-border-transparent);
    --facet-rail-mobile-dialog-close-color: var(--color-text-primary);

    background-color: var(--facet-rail-mobile-dialog-background);
    display: none;
    height: 100vh;
    margin: 0;
    max-height: 100%;
    max-width: 100%;
    width: 100%;

    @include breakpoint-from(tablet) {
        background: transparent;
        max-width: var(--facet-rail-mobile-dialog-max-width);
    }

    &__wrapper {
        background-color: var(--facet-rail-mobile-dialog-background);
        display: flex;
        flex-direction: column;
        height: 100%;
        overflow: hidden;

        @include breakpoint-until(tablet) {
            animation-delay: normal;
            animation-duration: 0;
            animation-name: fadeFacetRailMobileMenuIn;
            animation-timing-function: linear;
        }

        @include breakpoint-from(tablet) {
            animation-duration: 0;
            animation-name: slideFacetRailMobileMenuInFromLeft;
            animation-timing-function: ease-in-out;
        }

        @media (prefers-reduced-motion: no-preference) {
            animation-duration: 0.3s;
        }
    }

    &[open] {
        display: flex;
        flex-direction: column;
        visibility: visible;
        z-index: 1;

        @include breakpoint-from(desktop-small) {
            display: none;
        }
    }

    &__header {
        align-items: center;
        border-bottom: solid 1px var(--facet-rail-mobile-dialog-border-bottom-color);
        display: flex;
        flex: 0 0 auto;
        justify-content: space-between;
        padding-inline: map.get($spacing, s-24);
    }

    &::backdrop {
        background-color: var(--color-background-backdrop);
    }
}

@keyframes fadeFacetRailMobileMenuIn {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

@keyframes slideFacetRailMobileMenuInFromLeft {
    0% {
        transform: translateX(-100%);
    }

    100% {
        transform: translateX(0);
    }
}

// Workaround to prevent browser to crash when the Facet Rail Modal is open
// and at the same time the window has been resized to a larger size
// without the Facet Rail Modal being closed first.
.js-mobile-facet-rail-is-open {
    .share-sticky-container {
        container-type: normal;
    }
}
