@use 'sass:map';
@use 'sass:math';
@use '@oecd/foundation/src/site/config/spacing-vars' as *;
@use '@oecd/util-breakpoint' as *;
@use '@oecd/util-distance' as d;
@use '@oecd/util-focus' as *;
@use '@oecd/util-text-style' as *;

.featured-top-story {
    container-name: featured-top-story;
    container-type: inline-size;
}

.cmp-featured-top-story {
    --featured-top-story-background: var(--color-background-inverse);
    --featured-top-story-pretitle: var(--color-text-primary-inverse-transparent);
    --featured-top-story-link-color: var(--color-text-primary-inverse);
    --featured-top-story-link-color-hover: var(--color-text-accent-light);
    --featured-top-story-cta-text-color: var(--color-text-primary-inverse);
    --featured-top-story-cta-text-color-hover: var(--color-text-accent-light);
    --featured-top-story-cta-icon-dimension: #{d.rem(20)};
    --featured-top-story-cta-svg-dimension: #{d.rem(16)};
    --featured-top-story-cta-padding-inline-end: calc(var(--featured-top-story-cta-icon-dimension) + #{map.get($spacing, s-8 )});
    --featured-top-story-description-color: var(--color-text-primary-inverse);
    --featured-top-story-description-color-hover: var(--color-text-accent-light);
    --featured-top-story-metadata-color: var(--color-text-primary-inverse-transparent);
    --featured-top-story-metadata-separator-symbol: '\2022';


    @container featured-top-story (max-width: 599px) {
        margin-inline: calc(var(--grid-offset) * -1);
    }

    @container featured-top-story (min-width: 600px) {
        height: 100%;
        min-height: d.rem(400);

        @include breakpoint-between(tablet, desktop-small) {
            min-height: d.rem(440);
        }

        &:has(.cmp-image) {
            column-gap: var(--grid-offset);
            display: grid;
            grid-template-columns: repeat(12, 1fr);
        }

        .cmp-featured-top-story {
            &__body {
                grid-column: 1 / span 6;
            }

            &__visual {
                grid-column: 7 / span 6;

                .cmp-image {
                    aspect-ratio: initial;
                    height: 100%;
                    margin-inline-start: calc(var(--grid-offset) * -1);
                }
            }
        }
    }

    &:hover {
        .cmp-featured-top-story__description {
            color: var(--featured-top-story-description-color-hover);
        }

        .cmp-featured-top-story__cta-text {
            color: var(--featured-top-story-cta-text-color-hover);
        }
    }

    &__body {
        align-items: start;
        background-color: var(--featured-top-story-background);
        display: flex;
        flex-direction: column;
        height: 100%;
        padding-block: map.get($spacing, s-24) map.get($spacing, s-32);
        padding-inline: map.get($spacing, s-24);

        @include breakpoint-until(tablet) {
            padding-block: map.get($spacing, s-32);
            padding-inline: map.get($spacing, s-32) map.get($spacing, s-40);
        }
    }

    &__pretitle {
        @include text-style(label, 3, b);

        @include breakpoint-from(tablet) {
            @include text-style(label, 2, b);
        }

        color: var(--featured-top-story-pretitle);
        display: block;
        margin-block-end: map.get($spacing, s-4);
        text-transform: uppercase;
    }

    &__link {
        @include text-style(heading, 2);

        @include breakpoint-from(tablet) {
            @include text-style(heading, 1);
        }

        color: var(--featured-top-story-link-color);
        display: block;
        text-decoration: none;

        @media (prefers-reduced-motion: no-preference) {
            transition-duration: 250ms;
            transition-property: color;
            transition-timing-function: cubic-bezier(0.4, 0, 0.4, 1);
        }

        &:hover {
            color: var(--featured-top-story-cta-text-color-hover);
        }

        &:focus {
            color: var(--featured-top-story-cta-text-color-hover);
            outline: none;
        }

        &:focus-visible {
            color: var(--featured-top-story-cta-text-color-hover);

            &::before {
                @include keyboardfocus;
            }

            ~ .cmp-featured-top-story__cta-text {
                outline: 2px solid var(--color-focus-outline-inverse);
                outline-offset: 2px;
            }
        }

        &::before {
            content: '';
            inset: 0;
            position: absolute;
            z-index: 1;
        }
    }

    &__description {
        @include text-style(body, 4);

        @include breakpoint-from(tablet) {
            @include text-style(body, 3);
        }

        color: var(--featured-top-story-description-color);
        margin-block-start: map.get($spacing, s-8);

        @media (prefers-reduced-motion: no-preference) {
            transition-duration: 250ms;
            transition-property: color;
            transition-timing-function: cubic-bezier(0.4, 0, 0.4, 1);
        }
    }

    &__metadata {
        @include text-style(label, 3, b);

        align-items: center;
        color: var(--featured-top-story-metadata-color);
        display: flex;
        gap: map.get($spacing, s-8);
        margin-block-start: auto;
        padding-block-start: map.get($spacing, s-24);

        @include breakpoint-from(tablet) {
            padding-block-start: map.get($spacing, s-32);
        }

        > * + * {
            &::before {
                content: var(--featured-top-story-metadata-separator-symbol);
                padding-right: map.get($spacing, s-8);
            }
        }
    }

    &__cta-text {
        @include text-style(label, 2, b);

        color: var(--featured-top-story-cta-text-color);
        display: inline-block;
        margin-block-start: map.get($spacing, s-16);
        min-height: var(--featured-top-story-cta-icon-dimension);
        padding-inline-end: var(--featured-top-story-cta-padding-inline-end);
        position: relative;
        vertical-align: middle;

        &::before {
            background: #fff;
            border-radius: 100%;
            content: '';
            display: block;
            height: var(--featured-top-story-cta-icon-dimension);
            inset-block-start: 50%;
            inset-inline-end: 0;
            position: absolute;
            transform: translate(0, -50%);
            width: var(--featured-top-story-cta-icon-dimension);

            @media screen and (forced-colors: active) {
                border: 1px solid;
            }
        }

        &::after {
            background: var(--featured-top-story-background);
            content: '';
            display: block;
            height: d.rem(16);
            inset-block-start: 50%;
            inset-inline-end: calc((var(--featured-top-story-cta-icon-dimension) - var(--featured-top-story-cta-svg-dimension)) / 2);
            mask-image: var(--icon-chevron-small);
            mask-position: center;
            mask-repeat: no-repeat;
            mask-size: contain;
            position: absolute;
            transform: translate(0, -50%);
            width: d.rem(16);

            @media screen and (forced-colors: active) {
                background: CanvasText;
            }
        }

        @media (prefers-reduced-motion: no-preference) {
            transition-duration: 250ms;
            transition-property: color;
            transition-timing-function: cubic-bezier(0.4, 0, 0.4, 1);
        }
    }

    &__visual {
        .cmp-image {
            aspect-ratio: 3/2;
            line-height: 0;
            position: relative;

            &__image {
                background-color: var(--featured-top-story-background);
                height: 100%;
                object-fit: cover;
                object-position: center center;
                position: absolute;
                width: 100%;
            }
        }
    }
}
