@use 'sass:map';
@use '@oecd/foundation/src/site/config/spacing-vars' as *;
@use '@oecd/util-breakpoint' as *;
@use '@oecd/util-distance' as d;
@use '@oecd/util-external-link' as *;
@use '@oecd/util-focus' as *;
@use '@oecd/util-line-clamp' as *;
@use '@oecd/util-text-style' as *;

.cmp-list {
    &--grid-layout {
        display: grid;
        gap: map.get($spacing, s-32);
        grid-template-columns: repeat(auto-fit, d.rem(288));
    }

    &:not(.swiper-initialized) {
        .cmp-list__carousel-controls {
            display: none;
        }
    }

    &__carousel-controls {
        display: flex;
        gap: map.get($spacing, s-4);
        height: d.rem(50);
        justify-content: flex-end;
        margin-block-start: map.get($spacing, s-32);

        // Hide navigation on touch devices
        // Show navigation on devices that don't have touch events
        @media (hover: none) and (pointer: coarse) {
            @include breakpoint-until(mobile-large) {
                display: none;
            }
        }
    }

    &--card-rendition {
        --list-card-border-color: var(--color-border);
        --list-card-border-color-hover: var(--color-primary);
        --list-card-content-background-color: var(--color-background);
        --list-card-content-background-color-hover: var(--color-background);
        --list-card-date-separator-symbol: '\2022';
        --list-card-description-text-color: var(--color-text-secondary);
        --list-card-image-aspect-ratio: 7/5;
        --list-card-image-background-color: var(--color-background-light);
        --list-card-image-hover-opacity: 0.65;
        --list-card-metadata-text-color: var(--color-text-secondary);
        --list-card-min-height: #{d.rem(401)};
        --list-card-tag-text-color: var(--color-text-secondary);
        --list-card-tag-background-color: var(--color-background-light);
        --list-card-title-margin-block-start: #{map.get($spacing, s-12)};
        --list-card-title-text-color: var(--color-text-primary);
        --list-card-title-text-color-hover: var(--color-text-accent);
        --list-card-title-highlight-color: var(--color-text-title-accent);

        .card,
        .data-card {
            background-color: var(--list-card-content-background-color);
            border: 1px solid var(--list-card-border-color);
            border-radius: 4px;
            display: flex;
            flex-direction: column;
            height: 100%;
            min-height: var(--list-card-min-height);
            position: relative;
            text-decoration: none;

            @media (prefers-reduced-motion: no-preference) {
                transition: 0.15s linear border-color;
            }

            &:hover {
                border-color: var(--list-card-border-color-hover);
            }

            &__image {
                background-color: var(--list-card-image-background-color);
                border-top-left-radius: 4px;
                border-top-right-radius: 4px;
                overflow: hidden;

                @media (prefers-reduced-motion: no-preference) {
                    transition: 0.15s cubic-bezier(0.4, 0, 0.4, 1) opacity;
                    will-change: opacity;
                }

                .cmp-image {
                    aspect-ratio: var(--list-card-image-aspect-ratio);
                    overflow: hidden;
                    position: relative;

                    img {
                        height: 100%;
                        object-fit: cover;
                        object-position: center center;
                        position: absolute;
                        width: 100%;
                    }
                }
            }

            &__content {
                flex-grow: 1;
                margin: map.get($spacing, s-24);
            }

            &__tags {
                display: flex;
                flex-wrap: wrap;
                gap: map.get($spacing, s-4);

                .tag {
                    --tag-background: var(--list-card-tag-background-color);
                    --tag-border: var(--list-card-tag-background-color);
                    --tag-color: var(--list-card-tag-text-color);
                }
            }

            &__title {
                margin-block-start: var(--list-card-title-margin-block-start);
            }

            &__title-link {
                // Increase lineheight so descenders don't get cut off
                @include text-style(card-title, null, null, 1.25);

                color: var(--list-card-title-text-color);
                text-decoration: none;

                // Set line clamp to 4
                @include line-clamp(4);

                @media (prefers-reduced-motion: no-preference) {
                    transition: 0.15s linear color;
                }

                &::before {
                    content: '';
                    inset: 0;
                    position: absolute;
                    z-index: 1;
                }

                &:hover {
                    color: var(--list-card-title-text-color-hover);
                }

                &:focus-visible {
                    color: var(--list-card-title-text-color-hover);
                    outline: none;

                    &::before {
                        @include keyboardfocus;
                    }
                }

                // Reset font style in case highlighted title is used on other PT than PT report.
                b,
                strong {
                    font-size: inherit;
                }
            }

            &__metadata {
                @include text-style(label, 3, b);

                color: var(--list-card-metadata-text-color);
                display: flex;
                margin: map.get($spacing, s-24);

                > div:not(:first-child)::before {
                    content: var(--list-card-date-separator-symbol);
                    margin-inline: map.get($spacing, s-8);
                }
            }

            &--is-external {
                .card,
                .data-card {
                    &__metadata {
                        @include external-link-icon;

                        --external-link-icon-color: var(--list-card-title-text-color);
                        --external-link-icon-inset-block-end: calc((var(--external-link-icon-size) / 2) * -1);
                        --external-link-icon-inset-block-start: auto;
                        --external-link-icon-inset-inline-end: calc((var(--external-link-icon-size) / 2) * -1);
                        --external-link-icon-inset-inline-start: auto;

                        padding-inline-end: map.get($spacing, s-20);
                    }
                }
            }
        }

        .card {
            &:focus-within,
            &:hover {
                .card__image {
                    opacity: var(--list-card-image-hover-opacity);
                }
            }

            &.report-full-report-page,
            &.report-summary-page {
                .card__image {
                    border-radius: 0 0 4px 4px;
                    order: 3;
                }

                // Color styling for highlighted title.
                .card__title {
                    b,
                    strong {
                        color: var(--list-card-title-highlight-color);
                    }
                }
            }
        }
    }
}
