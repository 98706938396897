@use 'sass:map';
@use '@oecd/foundation/src/site/config/spacing-vars' as *;
@use '@oecd/util-a11y' as *;
@use '@oecd/util-distance' as d;
@use '@oecd/util-focus' as *;
@use '@oecd/util-text-style' as *;

.cmp-carousel {
    --carousel-indicator-active-background-color: var(--color-background-inverse);
    --carousel-indicator-active-color: var(--color-text-primary-inverse);
    --carousel-indicator-background-color: var(--color-background);
    --carousel-indicator-border-radius: 50%;
    --carousel-indicator-color: var(--color-text-primary);
    --carousel-indicator-focus-outline-color: var(--color-text-link-hover);
    --carousel-indicator-hover-color: var(--color-text-link-hover);
    --carousel-indicator-size: #{d.rem(40)};
    --carousel-control-background-color: var(--color-background-light);
    --carousel-control-border-radius: 50%;
    --carousel-control-color: var(--color-text-primary);
    --carousel-control-color-hover: var(--color-icon-accent);
    --carousel-control-icon: var(--icon-play);
    --carousel-control-icon-size: #{d.rem(16)};
    --carousel-control-size: #{d.rem(40)};

    .cmp-carousel {
        counter-reset: testimonial-carousel;

        @at-root .container-themable#{&} {
            grid-column: wrapper;
        }

        &__content {
            display: flex;
            flex-wrap: wrap;
        }

        &__item {
            flex-basis: 100%;
            flex-grow: 1;
            flex-shrink: 0;
        }

        &__actions {
            flex-basis: var(--carousel-control-size);
            flex-grow: 0;
            flex-shrink: 0;
            order: 3;
        }

        &__action {
            background: var(--carousel-control-background-color);
            border-radius: var(--carousel-control-border-radius);
            cursor: pointer;
            display: inline-block;
            height: var(--carousel-control-size);
            margin-block-start: map.get($spacing, s-32);
            position: relative;
            width: var(--carousel-control-size);

            // Carousel controls need to have a different background color when placed on a subtle or highlight themed background.
            @at-root .theme-highlight#{&},
            .theme-subtle & {
                --carousel-control-background-color: var(--color-background);
            }

            &-icon {
                background-color: var(--carousel-control-color);
                display: inline-block;
                height: var(--carousel-control-icon-size);
                inset-block-start: 50%;
                inset-inline-start: 50%;
                mask-image: var(--carousel-control-icon);
                mask-position: center;
                mask-repeat: no-repeat;
                mask-size: contain;
                position: absolute;
                transform: translate(-50%, -50%);
                vertical-align: middle;
                width: var(--carousel-control-icon-size);

                @media (prefers-reduced-motion: no-preference) {
                    transition: background-color 0.15s linear;
                }
            }

            &-text {
                @include visually-hidden;
            }

            &:hover,
            &:focus {
                .cmp-carousel__action-icon {
                    --carousel-control-color: var(--carousel-control-color-hover);
                }
            }

            &:focus-visible {
                @include keyboardfocus;
            }

            &--pause {
                .cmp-carousel__action-icon {
                    --carousel-control-icon: var(--icon-pause);
                }
            }

            &--play {
                .cmp-carousel__action-icon {
                    --carousel-control-icon: var(--icon-play);
                }
            }

            &--disabled,
            &--next,
            &--previous {
                display: none;
            }
        }

        &__indicators {
            flex-grow: 1;
            gap: map.get($spacing, s-8);
            justify-content: flex-start;
            margin-block-start: map.get($spacing, s-32);

            // Hide indicators container if there's only 1 element inside.
            &:has(.cmp-carousel__indicator:only-child) {
                display: none;
            }
        }

        &__indicator {
            background-color: var(--carousel-indicator-background-color);
            border-radius: var(--carousel-indicator-border-radius);
            color: var(--carousel-indicator-color);
            counter-increment: testimonial-carousel;
            cursor: pointer;
            height: var(--carousel-indicator-size);
            margin: 0;
            width: var(--carousel-indicator-size);

            // Fallback for previous :has()-alternative.
            &:only-child {
                display: none;
            }

            &::before {
                content: counter(testimonial-carousel);
                inset-block-start: 50%;
                inset-inline-start: 50%;
                position: absolute;
                text-indent: initial;
                transform: translate(-50%, -50%);

                @include text-style(label, 2, b);
            }

            &:hover {
                --carousel-indicator-color: var(--carousel-indicator-hover-color);
            }

            &:focus-visible {
                outline: 2px solid var(--carousel-indicator-focus-outline-color);
                outline-offset: 1px;
            }

            &--active {
                --carousel-indicator-background-color: var(--carousel-indicator-active-background-color);

                @media screen and (forced-colors: active) {
                    border: 1px solid;
                }

                &,
                &:hover {
                    --carousel-indicator-color: var(--carousel-indicator-active-color);
                }
            }
        }
    }
}
