@use 'sass:map';
@use '@oecd/foundation/src/site/config/spacing-vars' as *;
@use '@oecd/util-breakpoint' as *;
@use '@oecd/util-button' as *;
@use '@oecd/util-distance' as d;
@use '@oecd/util-scrollbar' as *;
@use '@oecd/util-text-style' as *;

.descriptive-asset {
    container-name: descriptive-asset;
    container-type: inline-size;
}

.cmp-descriptive-asset {
    --descriptive-asset-actions-width: #{d.rem(60)}; // 44 + 16 = width of 1 button
    --descriptive-asset-background-color: var(--color-background);
    --descriptive-asset-border: none;
    --descriptive-asset-border-radius: #{d.rem(8)};
    --descriptive-asset-description-color: var(--color-text-secondary);
    --descriptive-asset-download-btn-icon: var(--icon-download);
    --descriptive-asset-expand-btn-icon: var(--icon-expand);
    --descriptive-asset-flow: #{map.get($spacing, s-24)};
    --descriptive-asset-notes-background: var(--color-primary-light);
    --descriptive-asset-notes-padding: #{map.get($spacing, s-24)};
    --descriptive-asset-notes-flow: #{map.get($spacing, s-24)};
    --descriptive-asset-padding: 0;
    --descriptive-asset-title-color: var(--color-text-primary);

    &--with-border {
        --descriptive-asset-padding: #{map.get($spacing, s-24)};
        --descriptive-asset-border: 1px solid var(--color-border);

        @include breakpoint-from(desktop-small) {
            --descriptive-asset-padding: #{map.get($spacing, s-40)};
        }
    }

    &.js-in-fullscreen-mode {
        --descriptive-asset-border-radius: 0;
        --descriptive-asset-padding: #{map.get($spacing, s-24)};

        overflow-y: auto; // needed for full screen experience

        @include custom-scrollbars;

        .cmp-image__image {
            display: block;
            height: revert-layer;
            margin: 0 auto;
            max-width: 100%;
            object-fit: contain;
            object-position: top;
            width: auto;
        }
    }

    .cmp-highlight-container & {
        --descriptive-asset-padding: #{map.get($spacing, s-24)};

        @include breakpoint-from(desktop-small) {
            --descriptive-asset-padding: #{map.get($spacing, s-24)};
        }

        --descriptive-asset-flow: #{map.get($spacing, s-16)};
        --descriptive-asset-notes-padding: #{map.get($spacing, s-16)};
        --descriptive-asset-notes-flow: #{map.get($spacing, s-16)};
    }

    @container descriptive-asset (max-width: 500px) {
        --descriptive-asset-actions-width: #{d.rem(60)};
    }

    align-content: start;
    align-items: start;
    background-color: var(--descriptive-asset-background-color);
    border: var(--descriptive-asset-border);
    border-radius: var(--descriptive-asset-border-radius);
    display: flex;
    flex-wrap: wrap;
    padding: var(--descriptive-asset-padding);

    &:has(.cmp-descriptive-asset__download):has(.cmp-descriptive-asset__expand) {
        --descriptive-asset-actions-width: #{d.rem(120)};

        @container descriptive-asset (max-width: 500px) {
            --descriptive-asset-actions-width: #{d.rem(60)};
        }
    }

    &:not(:has(.cmp-descriptive-asset__actions)) {
        --descriptive-asset-actions-width: 0;

        .cmp-descriptive-asset__header {
            max-width: 100%;
        }
    }

    &__header {
        flex: 1 0 auto;
        max-width: calc(100% - var(--descriptive-asset-actions-width));


        .cmp-title__text {
            @include text-style(body, 2, b);

            color: var(--descriptive-asset-title-color);
            margin: 0;
        }

        .cmp-text {
            @include text-style(label, 2);

            color: var(--descriptive-asset-description-color);
            margin-block-start: map.get($spacing, s-4);
        }
    }

    &__actions {
        display: flex;
        gap: d.rem(16);
        padding-inline-start: map.get($spacing, s-16);

        @container descriptive-asset (max-width: 500px) {
            flex: 0 0 var(--descriptive-asset-actions-width);
            flex-wrap: wrap;
        }
    }

    &__download {
        .cmp-button {
            @include button-icon(--descriptive-asset-download-btn-icon, true);

            @media screen and (forced-colors: active) {
                &::before {
                    background: LinkText;
                }
            }
        }
    }

    &__expand {
        .cmp-button {
            @include button-icon(--descriptive-asset-expand-btn-icon, true);

            @media screen and (forced-colors: active) {
                &::before {
                    background: ButtonText;
                }
            }

            .js-in-fullscreen-mode & {
                --descriptive-asset-expand-btn-icon: var(--icon-close);
            }
        }
    }

    &__content {
        flex: 0 0 auto;
        width: 100%;


        .cmp-image,
        .cmp-embed,
        .cmp-table,
        .cmp-button,
        .cmp-text {
            margin-block-start: var(--descriptive-asset-flow);
        }

        .cmp-image__image {
            display: block;
            margin: 0 auto;
            max-width: 100%;
            width: auto;
        }

        .cmp-text {
            @include text-style(label, 3);

            background: var(--descriptive-asset-notes-background);
            padding: var(--descriptive-asset-notes-padding);

            h3,
            h4,
            h5,
            h6 {
                @include text-style(label, 3, b);

                text-transform: none;

                + * {
                    margin-block-start: map.get($spacing, s-8);
                }
            }

            p,
            ol,
            ul {
                + h3,
                + h4,
                + h5,
                + h6 {
                    margin-block-start: var(--descriptive-asset-notes-flow);
                }
            }
        }
    }
}
