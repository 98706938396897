@media print {
    .main-navigation {
        height: auto;
        position: relative;
    }

    .cmp-main-navigation {
        --main-navigation-background: var(--color-background) !important;
        --main-navigation-logo-color: var(--color-text-print-dark) !important;
        --main-navigation-border-bottom-color: var(--color-print-background);

        &__wrapper {
            > *:not(.cmp-main-navigation__logo) {
                visibility: hidden;
            }
        }
    }
}
