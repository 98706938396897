@use 'sass:map';
@use '@oecd/foundation/src/site/config/spacing-vars' as *;
@use 'typography-vars' as *;
@use '@oecd/util-breakpoint' as *;
@use '@oecd/util-list' as *;
@use '@oecd/util-table' as *;
@use '@oecd/util-text-style' as *;

.cmp-text {
    --blockquote-border-width: #{map.get($spacing, s-4)};
    --blockquote-padding-inline-start: #{map.get($spacing, s-32)};
    --blockquote-border-color: var(--color-border-transparent);
    --color-list-marker: var(--color-text-secondary-transparent);
    --statement-txt-color: var(--color-text-primary);
    --statement-txt-color-accent: var(--color-text-title-accent);
    --table-border-color: var(--color-border);
    --table-header-background-color: var(--color-background-light);
    --table-text-color: var(--color-text-table);
    --table-caption-background: var(--color-background-inverse);
    --table-caption-color: var(--color-text-primary-inverse);

    @include text-style('body', 4, null, false, true);

    @include breakpoint-from(desktop-small) {
        @include text-style('body', 3, null, false, true);
    }

    // Style lists within text component
    @include styled-unordered-list(var(--color-list-marker));
    @include styled-ordered-list(var(--color-list-marker));

    // Margin between elements inside a text component
    > * + * {
        margin-top: map.get($spacing, s-16);
    }

    @include default-table-styling;

    overflow-wrap: anywhere;

    blockquote {
        border-inline-start: solid var(--blockquote-border-width) var(--color-border-transparent);
        padding-inline-start: calc(var(--blockquote-padding-inline-start) - var(--blockquote-border-width));

        .text--statement & {
            border: 0;
            padding-inline-start: 0;
        }
    }
}

.text--statement {
    // From mobile until tablet statement text needs 80px above and beneath
    // Default margins are 32px -> 48px needed to be added
    // From tablet 144px above and beneath
    // Default margins are 80px -> 64px needed to be added
    --additional-padding: #{map.get($spacing, s-48)};
    --total-padding: #{map.get($spacing, s-80)};

    @include breakpoint-from(tablet) {
        --additional-padding: #{map.get($spacing, s-64)};
        --total-padding: #{map.get($spacing, s-144)};
    }

    @include breakpoint-from(tablet) {
        display: grid;
        gap: var(--grid-gap);
        grid-template-columns: repeat(12, 1fr);
    }

    .cmp-text {
        color: var(--statement-txt-color);
        padding-block: var(--additional-padding) var(--additional-padding);
        text-align: center;

        @include text-style('subheading', 2);

        @include breakpoint-from(tablet) {
            grid-column: 2 / span 10;
        }

        @include breakpoint-from(desktop-small) {
            @include text-style('subheading', 1, null, false, true);
        }

        @include breakpoint-from(desktop) {
            grid-column: 3 / span 8;
        }

        b,
        strong {
            color: var(--statement-txt-color-accent);
            font-weight: $font-weight-light;
        }
    }

    &:first-child:not(.container-themable > .cmp-container .text--statement:first-child) {
        .cmp-text {
            padding-block-start: var(--total-padding);
        }
    }

    &:last-child:not(.container-themable > .cmp-container .text--statement:last-child) {
        .cmp-text {
            padding-block-end: var(--total-padding);
        }
    }
}
