.aem-AuthorLayer-Edit {
    .generic-header:not(.generic-header--landing-page) .cmp-generic-header__cta .cmp-button.cq-placeholder {
        --padding-button-block: 0;
        --button-background: transparent;
        --button-border: transparent;
        --header-cta-margin-block-start: 0;

        transition: none;
    }
}

.aem-AuthorLayer-Preview {
    .generic-header .cmp-generic-header__cta .cmp-button.cq-placeholder {
        display: none;
    }
}
