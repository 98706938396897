@use 'sass:map';
@use '@oecd/foundation/src/site/config/spacing-vars' as *;
@use '@oecd/util-breakpoint' as *;
@use '@oecd/util-button' as *;
@use '@oecd/util-distance' as d;
@use '@oecd/util-text-style' as *;

.cmp-generic-header {
    --navigation-height: var(--main-navigation-height, #{d.rem(64)});

    // Colors
    --header-background-color: var(--color-background);
    --header-text-color: var(--color-text-secondary);
    --header-title-color: var(--color-text-primary);
    --header-title-highlight-color: var(--color-text-title-accent);
    --header-description-color: var(--header-text-color);

    // Minimum height
    --header-min-height: #{d.rem(480)};

    // Header visual aspect ratio
    --header-visual-aspect-ratio: 2/1;

    // Background gradient masks
    --header-gradient-mask-rgb-color: var(--color-background-rgb);
    --header-background-top-mask: linear-gradient(180deg, rgba(var(--header-gradient-mask-rgb-color), 1), rgba(var(--header-gradient-mask-rgb-color), 0));
    --header-background-top-mask-height: #{d.rem(176)};
    --header-background-mask: linear-gradient(to right, rgba(var(--header-gradient-mask-rgb-color), 1) 53%, rgba(var(--header-gradient-mask-rgb-color), 0) 100%);
    --header-background-mask-mobile: linear-gradient(to bottom, rgba(var(--header-gradient-mask-rgb-color), 1), rgba(var(--header-gradient-mask-rgb-color), 0));
    --header-background-mask-mobile-height: #{d.rem(80)};

    // Distance
    --header-breadcrumb-margin-block-start: #{map.get($spacing, s-16)};
    --header-breadcrumb-margin-block-end: #{map.get($spacing, s-32)};
    --header-description-margin-block-start: #{map.get($spacing, s-16)};
    --header-tags-margin-block-start: #{map.get($spacing, s-16)};
    --header-cta-margin-block-start: #{map.get($spacing, s-24)};
    --header-keylinks-padding-block-start: #{map.get($spacing, s-32)};
    --header-prefix-margin-block-end: #{map.get($spacing, s-8)};

    // Visual
    --header-image-overlay-color: var(--color-primary);
    --header-image-gradient-overlay-color-rgb: var(--color-background-inverse-rgb);


    background-color: var(--header-background-color);
    display: grid;
    grid-template-columns: [full-start] var(--grid-offset) [container-start] minmax(0, var(--container-max-width)) [container-end] var(--grid-offset) [full-end];

    @include breakpoint-from(desktop) {
        --navigation-height: var(--main-navigation-height, #{d.rem(80)});
        --header-description-margin-block-start: #{map.get($spacing, s-24)};
    }

    &__container {
        grid-column: container;

        @include breakpoint-from(tablet) {
            column-gap: var(--grid-gap);
            display: grid;
            grid-template-columns: repeat(12, 1fr);
            grid-template-rows: min-content;
            min-height: var(--header-min-height);
        }
    }

    &__breadcrumb {
        grid-column: 1 / span 12;
        grid-row: 1;
        margin-block: var(--header-breadcrumb-margin-block-start) var(--header-breadcrumb-margin-block-end);

        // Set padding top to account for main navigation
        padding-block-start: var(--navigation-height);
    }

    &__content-area {
        padding-block-end: map.get($spacing, s-32);
        padding-inline-end: map.get($spacing, s-32);

        @include breakpoint-from(tablet) {
            align-items: flex-start;
            display: flex;
            flex-direction: column;
            height: 100%;
            justify-content: flex-end;
        }

        @include breakpoint-from(desktop) {
            padding-block-end: map.get($spacing, s-64);
            padding-inline-end: map.get($spacing, s-64);
        }
    }

    &__prefix {
        color: var(--header-text-color);
        display: inline-block;
        margin-block-end: var(--header-prefix-margin-block-end);

        @include text-style(body, 2);

        @include breakpoint-from(desktop-small) {
            @include text-style(body, 1);
        }
    }

    &__title {
        color: var(--header-title-color);

        b,
        strong {
            color: var(--header-title-highlight-color);
            font-weight: inherit;
        }
    }

    &__description {
        color: var(--header-description-color);
        margin-block-start: var(--header-description-margin-block-start);

        @include text-style(body, 2);

        @include breakpoint-from(desktop-small) {
            @include text-style(body, 1);
        }
    }

    @at-root .generic-header:not(.generic-header--landing-page) {
        .cmp-generic-header__cta {
            --button-background: var(--color-primary);
            --button-background-hover: var(--color-primary-hover);
            --button-border: var(--color-primary);
            --button-txt: var(--color-text-primary-inverse);
            --button-border-hover: var(--color-primary-hover);
            --button-txt-hover: var(--button-txt);
            --button-outline: var(--color-focus-outline);

            width: 100%;

            &[disabled] {
                --button-background: var(--color-disabled);
                --button-background-hover: var(--button-background);
                --button-border: var(--button-background);
                --button-border-hover: var(--button-border);
                --button-txt: var(--color-text-secondary-transparent);
                --button-txt-hover: var(--button-txt);
            }

            .cmp-button {
                display: inline-block;
                margin-block-start: var(--header-cta-margin-block-start);

                @include button-style;

                &.cmp-button-download {
                    @include button-icon(--icon-external, $icon-only: false, $show-copy: true);

                    &[download] {
                        &::before {
                            mask-image: var(--icon-download);
                        }
                    }
                }
            }
        }
    }

    &__overlay {
        margin-block-start: var(--header-cta-margin-block-start);

        .button--subtle .cmp-button {
            --button-subtle-icon: var(--icon-information);
        }
    }

    &__tags {
        margin-block-start: var(--header-tags-margin-block-start);

        @include breakpoint-from(desktop) {
            --header-tags-margin-block-start: #{map.get($spacing, s-24)};
        }
    }

    &__key-links {
        margin-block-start: auto;
        padding-block-start: var(--header-keylinks-padding-block-start);
    }

    &__visual {
        .image {
            height: 100%;
            left: 0;
            position: absolute;
            top: 0;
            width: 100%;
            z-index: 1;

            &--dark,
            &--darker {
                .cmp-image__image {
                    filter: saturate(0);
                    isolation: isolate;
                    position: relative;
                    z-index: 1;
                }

                .cmp-image {
                    position: relative;

                    &::before {
                        background-color: var(--header-image-overlay-color);
                        content: '';
                        height: 100%;
                        inset-block-start: 0;
                        inset-inline-start: 0;
                        mix-blend-mode: multiply;
                        opacity: 0.9;
                        position: absolute;
                        width: 100%;
                        z-index: 2;
                    }
                }
            }

            &--darker {
                .cmp-image {
                    &::after {
                        background: radial-gradient(214.38% 50% at 50% 50%, rgba(var(--header-image-gradient-overlay-color-rgb), 0)0%, rgba(var(--header-image-gradient-overlay-color-rgb), 1) 100%);
                        content: '';
                        height: 100%;
                        inset-block-start: 0;
                        inset-inline-start: 0;
                        mix-blend-mode: multiply;
                        opacity: 0.8;
                        position: absolute;
                        width: 100%;
                        z-index: 3;
                    }
                }
            }

        }

        .cmp-image {
            height: 100%;
            position: absolute;
            width: 100%;

            &__image {
                height: 100%;
                object-fit: cover;
                object-position: center center;
                width: 100%;
            }
        }
    }
}

.generic-header {
    /////////////
    // Default //
    /////////////
    &:not(.has-featured-image, .has-background-image) {
        .cmp-generic-header {
            --header-min-height: #{d.rem(480)};

            @include breakpoint-from(desktop) {
                --header-min-height: #{d.rem(536)};
            }

            &__content-area {
                @include breakpoint-from(tablet) {
                    grid-column: 1 / span 10;
                }

                @include breakpoint-from(desktop-small) {
                    grid-column: 1 / span 8;
                }
            }

            &__visual {
                display: none;
            }
        }
    }

    ////////////////////
    // Featured image //
    ////////////////////
    &.has-featured-image {
        .cmp-generic-header {
            // Remove the padding because it needs to align to the image
            &__content-area {
                @include breakpoint-from(tablet) {
                    grid-column: 1 / span 6;
                    padding-block-end: 0;
                }
            }

            &__container {
                @include breakpoint-from(tablet) {
                    padding-block-end: map.get($spacing, s-32);
                }

                @include breakpoint-from(desktop) {
                    padding-block-end: map.get($spacing, s-64);
                }
            }

            &__visual {
                aspect-ratio: var(--header-visual-aspect-ratio);
                position: relative;

                @include breakpoint-from(tablet) {
                    --header-visual-aspect-ratio: 4/5;

                    align-self: start;
                    grid-column: 7 / span 6;
                }

                @include breakpoint-from(desktop-small) {
                    --header-visual-aspect-ratio: 5/4;
                }

                @include breakpoint-until(tablet) {
                    // Ensure Image components bleed on small screens
                    margin-inline: calc(var(--grid-offset) * -1);
                }
            }
        }
    }

    //////////////////////
    // Background image //
    //////////////////////
    &.has-background-image {
        .cmp-generic-header {
            @include breakpoint-from(tablet) {
                --header-min-height: #{d.rem(536)};
            }

            position: relative;

            &__breadcrumb {
                z-index: 3;
            }

            // TOP Gradient
            @include breakpoint-from(tablet) {
                &::before {
                    background-image: var(--header-background-top-mask);
                    content: '';
                    display: block;
                    height: var(--header-background-top-mask-height);
                    left: 0;
                    position: absolute;
                    top: 0;
                    width: 100%;
                    z-index: 3;
                }
            }

            &__container {
                @include breakpoint-from(tablet) {
                    &::before {
                        background-image: var(--header-background-mask);
                        content: '';
                        grid-column: 1 / span 10;
                        grid-row: 1 / span 2;
                        margin-inline-start: calc(var(--grid-offset) * -1);
                        position: relative;
                        z-index: 3;
                    }
                }

                @include breakpoint-from(desktop) {
                    &::before {
                        grid-column: 5 / span 5;
                        grid-row: 1 / span 2;
                        margin-inline-start: 0;
                    }
                }
            }

            // Remove the padding because it needs to align to the image
            &__content-area {
                padding-block-end: 0;
                position: relative;
                z-index: 3;

                @include breakpoint-from(tablet) {
                    grid-column: 1 / span 6;
                    grid-row: 2;
                    padding-block-end: map.get($spacing, s-32);
                }

                @include breakpoint-from(desktop) {
                    padding-block-end: map.get($spacing, s-64);
                }

            }

            &__visual {
                --header-visual-aspect-ratio: 6/5;

                aspect-ratio: var(--header-visual-aspect-ratio);
                position: relative;
                z-index: 2;

                @include breakpoint-until(tablet) {
                    margin-block-start: -#{map.get($spacing, s-24)};

                    // Ensure Image components bleed on small screens
                    margin-inline: calc(var(--grid-offset) * -1);

                    &::before {
                        background-image: var(--header-background-mask-mobile);
                        content: '';
                        display: block;
                        height: var(--header-background-mask-mobile-height);
                        left: 0;
                        position: absolute;
                        top: 0;
                        width: 100%;
                        z-index: 2;
                    }
                }

                @include breakpoint-from(tablet) {
                    --header-visual-aspect-ratio: initial;

                    grid-column: 5 / span 8;
                    grid-row: 1 / span 2;
                    margin-inline-end: calc(var(--grid-offset) * -1);
                }

                @include breakpoint-from(desktop) {
                    grid-column: 5 / span 8;

                    // Pull background image completely to right side
                    margin-inline-end: calc(((100vw - d.rem(1248)) / 2) * -1);
                }
            }

        }
    }

    //////////////////
    // Landing Page //
    //////////////////
    &--landing-page {
        .cmp-generic-header {
            --header-description-color: var(--color-text-accent);

            &__description {
                @include text-style(subheading, 2);

                @include breakpoint-from(desktop-small) {
                    @include text-style(subheading, 1);
                }
            }

            // style cta as a subtle button
            .cmp-generic-header__cta {
                --button-subtle-text: var(--color-text-primary);
                --button-subtle-text-disabled: var(--color-text-secondary-transparent);
                --button-subtle-icon-background: var(--color-primary);
                --button-subtle-icon-background-disabled: var(--color-text-secondary-transparent);
                --button-subtle-icon-color: var(--color-text-primary-inverse);
                --button-subtle-icon-outline: var(--color-focus-outline);
                --button-subtle-text-hover: var(--color-text-link-hover);
                --button-subtle-icon: var(--icon-chevron-small);

                width: 100%;

                .cmp-button {
                    display: inline-block;
                    margin-block-start: var(--header-cta-margin-block-start);

                    &:not(.cmp-button-download) {
                        @include button-subtle;
                    }

                    &.cmp-button-download {
                        --button-background: transparent;
                        --button-background-hover: transparent;
                        --button-border: var(--color-background);
                        --button-txt: var(--color-text-primary-inverse);
                        --button-border-hover: var(--color-primary-light);
                        --button-txt-hover: var(--color-text-accent-light);

                        @include button-style;
                        @include button-icon(--icon-external, $icon-only: false, $show-copy: true);

                        &[download] {
                            &::before {
                                mask-image: var(--icon-download);
                            }
                        }
                    }
                }
            }
        }
    }

    //////////////////
    // Country page //
    //////////////////
    &--country-page {
        &,
        &.has-background-image {
            .cmp-generic-header {
                @include breakpoint-from(desktop) {
                    --header-min-height: #{d.rem(748)};
                }
            }
        }
    }
}
