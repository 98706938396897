@use 'sass:map';
@use 'sass:math';
@use 'typography-vars' as *;
@use 'text-styles-config' as textStyleConfig;

@mixin font-size($size: 16) {
    font-size: math.div($size, 16) * 1rem;
}

/*
| The `text-style` mixin applies typography styles based on the provided `$text-style` and `$text-size`.
|
| Parameters:
| - $text-style: The name of the text style (e.g., 'label', 'body', 'caps').
| - $text-size: The size key within the style (e.g., 1, 2, 3).
| - $text-variant: (Optional) The variant key within the size (e.g., 'b', 'm').
| - $line-height: (Optional) Override the line-height value.
| - $dont-set-font-family: (Optional) If true, the font-family is not set.
|
| How It Works:
| - Retrieves the style configuration from the `$text-styles` map, from the theme-specific text-styles-config.scss.
|
| Usage Examples:
|
| // Applying 'label' style with size 2 and variant 'b' (bold)
| .element-label-bold {
|     @include text-style(label, 2, b); // Applies 'label' style, size 2, bold variant
| }
|
| // Applying 'form-input' style without setting font-family
| .form-input-no-family {
|     @include text-style(form-input, 1, false, false, true);
| }
*/

@mixin text-style($text-style, $text-size: false, $text-variant: false, $line-height: false, $dont-set-font-family: false) {
    $text-styles: textStyleConfig.$text-styles;

    // Check if the provided text style exists in the map
    $style: map.get($text-styles, $text-style);

    @if $style {
        @if not $dont-set-font-family { // Conditionally set the font-family
            @if map.get($style, font-family) {
                font-family: var(#{map.get($style, font-family)}, sans-serif);
            }

            @if map.get($style, variable-font-family) {
                @supports (font-variation-settings: 'wdth' 450) {
                    font-family: var(#{map.get($style, variable-font-family)}, sans-serif);
                }
            }
        }

        // Initialize font-weight with the style's default
        $font-weight: map.get($style, font-weight);

        // Check if a specific text size is provided and exists in the map
        @if $text-size and map.get(map.get($style, sizes), $text-size) {
            $size-data: map.get(map.get($style, sizes), $text-size);

            @if map.get($size-data, size) {
                @include font-size(map.get($size-data, size));
            }

            @if map.get($size-data, spacing) {
                letter-spacing: map.get($size-data, spacing);
            }

            // Apply line-height, allowing override
            line-height: if($line-height == false, map.get($size-data, line-height), $line-height);

            // Override font-weight if text-variant is specified
            @if $text-variant and map.get($size-data, variants) and map.get(map.get($size-data, variants), $text-variant) {
                $variant-data: map.get(map.get($size-data, variants), $text-variant);

                @if map.get($variant-data, font-weight) {
                    $font-weight: map.get($variant-data, font-weight);
                }
            } @else if map.get($size-data, font-weight) { // If no variant is specified, use the size-specific font-weight
                $font-weight: map.get($size-data, font-weight);
            }
        } @else {
            // Apply default font-size, letter-spacing, and line-height from the style map
            @if map.get($style, size) {
                @include font-size(map.get($style, size));
            }

            @if map.get($style, spacing) {
                letter-spacing: map.get($style, spacing);
            }

            line-height: if($line-height == false, map.get($style, line-height), $line-height);
        }

        // Set font-weight once based on the computed value
        @if $font-weight {
            font-weight: $font-weight;
        }

        @if map.get($style, text-transform) {
            text-transform: map.get($style, text-transform);
        }
    }
}
