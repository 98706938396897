@use 'sass:map';
@use '@oecd/foundation/src/site/config/spacing-vars' as *;
@use '@oecd/util-breakpoint' as *;
@use '@oecd/util-distance' as d;
@use '@oecd/util-line-clamp' as *;
@use '@oecd/util-text-style' as *;

.cmp-number-citation {
    --number-citation-background-color: var(--color-background);
    --number-citation-header-margin: #{map.get($spacing, s-48)};
    --number-citation-padding: #{map.get($spacing, s-24)};
    --number-citation-number-color: var(--color-text-link);

    background-color: var(--number-citation-background-color);
    padding: var(--number-citation-padding);

    @include breakpoint-from(tablet) {
        display: flex;
        flex-direction: column;
        height: 100%;
    }

    @include breakpoint-from(desktop-small) {
        --number-citation-header-margin: #{map.get($spacing, s-96)};
        --number-citation-padding: #{map.get($spacing, s-32)};
    }

    &__number,
    &__label,
    &__description {
        overflow: hidden;
    }

    &__number {
        .cmp-text {
            @include text-style(heading-hero, 2, null, 1.25);

            color: var(--number-citation-number-color);

            @include breakpoint-from(desktop-small) {
                @include text-style(heading-hero, 1, null, 1.25);
            }

            > p {
                // Set line clamp to 3
                @include line-clamp(3);
            }
        }
    }

    &__label {
        .cmp-text {
            @include text-style(body, 2);

            color: var(--color-text-primary);

            @include breakpoint-from(desktop-small) {
                @include text-style(body, 1);
            }
        }
    }

    &__description {
        @include breakpoint-from(tablet) {
            margin-block-start: auto;
        }

        .cmp-text {
            color: var(--color-text-secondary);
            margin-block-start: var(--number-citation-header-margin);
        }
    }
}

.column-container {
    &__container {
        // Number Citation following on Number Citation component can only have a spacing of 24px
        // So we need to have a negative margin top that equals the prose spacing minus 24px
        > :where(.cmp-container) > :where(.number-citation + .number-citation) {
            margin-block-start: calc(var(--prose-negative-spacing) * -1);
        }
    }
}

// Number citation inside a three column container can grow as high as there is available space
// If there are only number citations in a three column container this will result in an equal height
.cmp-three-column-container {
    .number-citation {
        flex: 1 0 auto;

        .cmp-number-citation {
            height: 100%;
        }
    }
}
