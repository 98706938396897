@use 'sass:map';
@use 'sass:math';
@use '@oecd/util-breakpoint' as *;
@use '@oecd/util-distance' as d;

.cmp-two-column-container {
    .column-container__container {
        @include breakpoint-until(tablet) {
            // Ensure Image components bleed on small screens
            > :where(.cmp-container) > :where(.image:not(.image--reduced-width)) {
                margin-inline: calc(var(--grid-offset) * -1);

                // Ensure image caption does not stick to edge
                .cmp-image__title {
                    padding-inline: var(--grid-offset);
                }
            }

            // Ensure embeds bleed on small screens
            > :where(.cmp-container) > :where(.embed) {
                margin-inline: calc(var(--grid-offset) * -1);
            }
        }
    }

    // Two column container starts from desktop small
    .column-container--two-col-6-6 > & {
        > .column-container__container {
            @include breakpoint-until(desktop-small) {
                // Last item of the second col cannot have a bottom margin when the columns stack
                // otherwise the margin between components is not according to design
                &:nth-child(2) > :where(.cmp-container) > :where(*:last-child) {
                    margin-block-end: 0;
                }

                .image--reduced-width {
                    margin-inline: auto;
                    max-width: d.rem(395); // 395 equals 4 columns on desktop
                }
            }

            @include breakpoint-from(desktop-small) {
                > .cmp-container {
                    .image--reduced-width {
                        column-gap: var(--grid-gap);
                        display: grid;
                        grid-template-columns: repeat(6, 1fr);

                        > * {
                            grid-column: 2 / span 4;
                        }
                    }

                    // Text title and button are not allowed to go the end of the column width per design
                    // Converted to direct child selector otherwise styles will leak into composite components
                    > .text,
                    > .button,
                    > .title {
                        max-width: calc(100% - var(--grid-gap));
                    }
                }

                // Last item of the column cannot have a bottom margin
                // otherwise the margin between components is not according to design
                > :where(.cmp-container) > :where(*:last-child) {
                    margin-block-end: 0;
                }
            }
        }
    }

    .column-container--two-col-4-8 > &,
    .column-container--two-col-8-4 > & {
        > .column-container__container {
            @include breakpoint-until(tablet) {
                // Last item of the second col cannot have a bottom margin when the columns stack
                // otherwise the margin between components is not according to design
                &:nth-child(2) > :where(.cmp-container) > :where(*:last-child) {
                    margin-block-end: 0;
                }
            }

            @include breakpoint-from(tablet) {
                // Last item of the column cannot have a bottom margin
                // otherwise the margin between components is not according to design
                > :where(.cmp-container) > :where(*:last-child) {
                    margin-block-end: 0;
                }
            }
        }
    }

    .column-container--two-col-4-8 > & {
        > .column-container__container {
            // Layout of components within smallest column
            &:first-child {
                // Text title and button are not allowed to go the end of the column width per design
                @include breakpoint-from(tablet) {
                    > .text,
                    > .button,
                    > .title {
                        max-width: calc(100% - var(--grid-gap));
                    }
                }
            }

            // Layout of components within largest column
            &:last-child {
                @include breakpoint-from(tablet) {
                    > .cmp-container {
                        column-gap: var(--grid-gap);
                        display: grid;
                        grid-template-columns: repeat(8, 1fr);

                        > *:not(.image, .embed, .list, .teaser, .chart, .chart-with-controls, .descriptive-asset, .featured-card, .kappa-full-report-reference) {
                            grid-column: 1 / span 7;
                        }

                        > .image,
                        > .embed,
                        > .list,
                        > .teaser,
                        > .chart,
                        > .chart-with-controls,
                        > .descriptive-asset,
                        > .featured-card {
                            grid-column: 1 / span 8;
                        }

                        > .cmp-form-container {
                            grid-column: 1 / span 5;
                        }

                        > .image--reduced-width {
                            grid-column: 2 / span 4;
                        }

                        > .kappa-full-report-reference {
                            grid-column: 1 / -1;
                        }
                    }
                }

                @include breakpoint-from(desktop-small) {
                    > .cmp-container {
                        > *:not(.image, .embed, .list, .teaser, .chart, .chart-with-controls, .descriptive-asset, .featured-card, .kappa-full-report-reference) {
                            grid-column: 1 / span 6;
                        }

                        > .cmp-form-container {
                            grid-column: 1 / span 4;
                        }
                    }
                }
            }
        }
    }

    .column-container--two-col-8-4 > & {
        // Layout of components within largest column
        > .column-container__container {
            &:first-child {
                @include breakpoint-from(tablet) {
                    > .cmp-container {
                        column-gap: var(--grid-gap);
                        display: grid;
                        grid-template-columns: repeat(8, 1fr);

                        > *:not(.image, .embed, .list, .teaser, .chart, .chart-with-controls, .descriptive-asset, .featured-card, .kappa-full-report-reference) {
                            grid-column: 1 / span 7;
                        }

                        > .image,
                        > .embed,
                        > .list,
                        > .teaser,
                        > .chart,
                        > .chart-with-controls,
                        > .descriptive-asset,
                        > .featured-card {
                            grid-column: 1 / span 8;
                        }

                        > .image--reduced-width {
                            grid-column: 2 / span 4;
                        }

                        > .cmp-form-container {
                            grid-column: 1 / span 5;
                        }

                        > .kappa-full-report-reference {
                            grid-column: 1 / -1;
                        }
                    }
                }

                @include breakpoint-from(desktop-small) {
                    > .cmp-container {
                        > *:not(.image, .embed, .list, .teaser, .chart, .chart-with-controls, .descriptive-asset, .featured-card, .kappa-full-report-reference) {
                            grid-column: 1 / span 6;
                        }

                        > .image--reduced-width {
                            grid-column: 2 / span 4;
                        }

                        > .cmp-form-container {
                            grid-column: 1 / span 4;
                        }
                    }
                }
            }
        }
    }
}
