@use '@oecd/util-a11y' as *;
@use '@oecd/util-distance' as d;

@mixin loading-spinner {
    --loading-spinner-border-color: var(--color-background-light);
    --loading-spinner-border-color-active: var(--color-primary);
    --loading-spinner-border-size: 3px;
    --loading-spinner-size: #{d.rem(30)};
    --loading-spinner-time: 1.5s;

    position: relative;

    &::before {
        animation: var(--loading-spinner-time) linear infinite loading-spinner;
        border: var(--loading-spinner-border-size) solid var(--loading-spinner-border-color);
        border-bottom-color: var(--loading-spinner-border-color-active);
        border-radius: 50%;
        content: '';
        height: var(--loading-spinner-size);
        inset-block-start: 50%;
        inset-inline-start: 50%;
        position: absolute;
        transform: translate3d(-50%, -50%, 0);
        transform-origin: center;
        width: var(--loading-spinner-size);
        will-change: transform;
    }

    &-text {
        @include visually-hidden;
    }

    @keyframes loading-spinner {
        0% {
            transform: translate3d(-50%, -50%, 0) rotate(0deg);
        }

        100% {
            transform: translate3d(-50%, -50%, 0) rotate(360deg);
        }
    }
}
