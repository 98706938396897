@use 'sass:map';
@use '@oecd/foundation/src/site/config/spacing-vars' as *;
@use '@oecd/util-a11y' as *;
@use '@oecd/util-breakpoint' as *;
@use '@oecd/util-distance' as d;
@use '@oecd/util-scrollbar' as *;

.cmp-report-navigation-mobile-dialog {
    --report-navigation-mobile-dialog-max-width: #{d.rem(390)};
    --report-navigation-mobile-dialog-background: var(--color-background);
    --report-navigation-mobile-dialog-border-bottom-color: var(--color-border-transparent);
    --report-navigation-mobile-dialog-close-color: var(--color-text-primary);

    background-color: var(--report-navigation-mobile-dialog-background);
    height: 100vh;
    margin: 0;
    max-height: 100%;
    max-width: 100%;
    overflow: auto;
    width: 100%;

    @include breakpoint-from(tablet) {
        background: transparent;
    }

    &__wrapper {
        background-color: var(--report-navigation-mobile-dialog-background);
        display: flex;
        flex-direction: column;
        height: 100%;
        overflow: hidden;

        @supports (height: 100dvh) {
            height: 100dvh;
            max-height: 100dvh;
        }

        @include breakpoint-until(tablet) {
            animation-delay: normal;
            animation-duration: 0;
            animation-name: fadeReportNavigationMobileMenuIn;
            animation-timing-function: linear;
        }

        @include breakpoint-from(tablet) {
            animation-duration: 0;
            animation-name: slideReportNavigationMobileMenuInFromLeft;
            animation-timing-function: ease-in-out;
            max-width: var(--report-navigation-mobile-dialog-max-width);
        }

        @media (prefers-reduced-motion: no-preference) {
            animation-duration: 0.3s;
        }
    }

    &[open] {
        display: flex;
        flex-direction: column;
        visibility: visible;
        z-index: 1;
    }

    &__header {
        align-items: center;
        border-bottom: solid 1px var(--report-navigation-mobile-dialog-border-bottom-color);
        display: flex;
        flex: 0 0 auto;
        justify-content: space-between;
        padding-inline: map.get($spacing, s-24);
    }

    &__close {
        color: var(--report-navigation-mobile-dialog-close-color);
        line-height: 0;
        margin-inline-end: -#{map.get($spacing, s-12)};
        padding: map.get($spacing, s-24) map.get($spacing, s-12);

        @media (prefers-reduced-motion: no-preference) {
            transition: 0.15s linear color;
        }

        &--mobile {
            @include breakpoint-from(tablet) {
                visibility: hidden;
            }
        }

        &--tablet {
            color: var(--color-icon-inverse);
            display: none;

            @include breakpoint-from(tablet) {
                display: block;
                inset-block-start: 0;
                inset-inline-end: var(--grid-offset);
                position: fixed;
            }
        }

        @include breakpoint-from(desktop) {
            display: none;
        }

        &:hover {
            --report-navigation-mobile-dialog-close-color: var(--color-primary);
        }

        &:focus {
            --report-navigation-mobile-dialog-close-color: var(--color-primary);
        }

        &:focus-visible {
            outline: 2px solid var(--color-focus-outline);
        }

        &-icon {
            vertical-align: middle;
        }

        &-text {
            @include visually-hidden;
        }
    }

    &__body {
        flex: 1;
        min-height: 0;
        overflow-y: auto;
        padding: map.get($spacing, s-24);

        @include custom-scrollbars;

        // Adjust width otherwise custom-scrollbar is not noticable due to overlay
        @supports selector(::-webkit-scrollbar) {
            width: calc(100% - (var(--scrollbar-width-legacy) / 2));
        }
    }

    &::backdrop {
        background-color: var(--color-background-backdrop);
    }
}

// Hide elements in header when menu is open
// .js-mobile-report-navigation-is-open {
//     .cmp-main-navigation__menu-trigger,
//     .cmp-main-navigation__cta-wrapper,
//     .language-switch {
//         opacity: 0;
//     }
// }

@keyframes fadeReportNavigationMobileMenuIn {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

@keyframes slideReportNavigationMobileMenuInFromLeft {
    0% {
        transform: translateX(-100%);
    }

    100% {
        transform: translateX(0);
    }
}
