@use '@oecd/util-distance' as d;

@mixin external-link-icon() {
    --external-link-icon: var(--icon-external);
    --external-link-icon-color: var(--color-text-primary);
    --external-link-icon-inset-block-end: auto;
    --external-link-icon-inset-block-start: 50%;
    --external-link-icon-inset-inline-end: auto;
    --external-link-icon-inset-inline-start: 50%;
    --external-link-icon-size: #{d.rem(16)};
    --external-link-icon-transform: translate(-50%, -50%);

    position: relative;

    &::before {
        background-color: var(--external-link-icon-color);
        content: '';
        display: block;
        height: var(--external-link-icon-size);
        inset-block: var(--external-link-icon-inset-block-start) var(--external-link-icon-inset-block-end);
        inset-inline: var(--external-link-icon-inset-inline-start) var(--external-link-icon-inset-inline-end);
        mask-image: var(--external-link-icon);
        mask-position: center;
        mask-repeat: no-repeat;
        mask-size: contain;
        position: absolute;
        transform: var(--external-link-icon-transform);
        width: var(--external-link-icon-size);

        @media screen and (forced-colors: active) {
            background: ButtonText;
        }
    }
}
