@use 'sass:map';
@use '@oecd/foundation/src/site/config/spacing-vars' as *;
@use '@oecd/util-breakpoint' as *;
@use '@oecd/util-button' as *;
@use '@oecd/util-distance' as d;
@use '@oecd/util-focus' as *;
@use '@oecd/util-text-style' as *;

.cmp-report-header {
    --navigation-height: var(--main-navigation-height, #{d.rem(66)});

    // Colors
    --report-header-background-color: var(--color-background);
    --report-header-text-color: var(--color-text-secondary);
    --report-header-text-opacity: 0.7;
    --report-header-title-color: var(--color-text-primary);
    --report-header-title-highlight-color: var(--color-text-title-accent);

    // Minimum height
    --report-header-min-height: auto;

    // Header visual aspect ratio
    --report-header-visual-aspect-ratio: auto;
    --report-header-visual-aspect-ratio-mobile: 3/2;
    --report-header-image-max-height: #{d.rem(600)};

    // Distance
    --report-header-breadcrumb-margin-block-start: #{map.get($spacing, s-16)};
    --report-header-breadcrumb-margin-block-end: #{map.get($spacing, s-32)};
    --report-header-description-margin-block-start: #{map.get($spacing, s-16)};
    --report-header-doormat-gap: #{map.get($spacing, s-16)};
    --report-header-footer-gap: #{map.get($spacing, s-16)};
    --report-header-tags-margin-block-start: #{map.get($spacing, s-16)};
    --report-header-cta-margin-block-start: #{map.get($spacing, s-24)};
    --report-header-keylinks-padding-block-start: #{map.get($spacing, s-32)};
    --report-header-prefix-margin-block-end: #{map.get($spacing, s-8)};

    // Publisher Logo Height
    --report-header-publisher-logo-height: #{d.rem(16)};
    --report-header-publisher-logo-small-height: #{d.rem(16)};
    --report-header-publisher-logo-medium-height: #{d.rem(32)};
    --report-header-publisher-logo-large-height: #{d.rem(48)};

    // List separator symbol
    --report-header-list-separator-symbol: '\2022';

    background-color: var(--report-header-background-color);
    display: grid;
    grid-template-columns: [full-start] var(--grid-offset) [container-start] minmax(0, var(--container-max-width)) [container-end] var(--grid-offset) [full-end];

    @include breakpoint-from(desktop) {
        --report-header-description-margin-block-start: #{map.get($spacing, s-24)};
        --navigation-height: var(--main-navigation-height, #{d.rem(80)});
    }

    &__container {
        grid-column: container;
        min-height: var(--report-header-min-height);

        // Set padding top to account for main navigation
        padding-block-start: var(--navigation-height);

        @include breakpoint-from(tablet) {
            --report-header-min-height: #{d.rem(480)};

            column-gap: var(--grid-gap);
            display: grid;
            grid-template-columns: repeat(12, 1fr);
        }

        @include breakpoint-from(desktop) {
            --report-header-min-height: #{d.rem(600)};
        }
    }

    &__breadcrumb {
        margin-block: var(--report-header-breadcrumb-margin-block-start) var(--report-header-breadcrumb-margin-block-end);
    }

    &__content-area {
        padding-block-end: map.get($spacing, s-32);

        @include breakpoint-from(tablet) {
            align-items: flex-start;
            display: flex;
            flex-direction: column;
            grid-column: 1 / span 10;
            padding-inline-end: map.get($spacing, s-32);
        }

        @include breakpoint-from(desktop-small) {
            grid-column: 1 / span 10;
        }

        @include breakpoint-from(desktop) {
            padding-block-end: map.get($spacing, s-64);
            padding-inline-end: map.get($spacing, s-64);
        }
    }

    &__content {
        & + .cmp-report-header__doormat,
        & + .cmp-report-header__footer {
            padding-block-start: map.get($spacing, s-40);

            @include breakpoint-from(tablet) {
                margin-block-start: auto;
            }
        }
    }

    &__prefix {
        color: var(--report-header-text-color);
        display: inline-block;
        margin-block-end: var(--report-header-prefix-margin-block-end);
        opacity: var(--report-header-text-opacity);

        @include text-style(body, 2);

        @include breakpoint-from(desktop-small) {
            @include text-style(body, 1);
        }
    }

    &__title {
        @include text-style(heading-hero, 3, false, false, true);

        @include breakpoint-from(desktop-small) {
            @include text-style(heading-hero, 2, false, false, true);
        }

        color: var(--report-header-title-color);

        b,
        strong {
            color: var(--report-header-title-highlight-color);
            font-weight: inherit;
        }
    }

    &__description {
        color: var(--report-header-text-color);
        margin-block-start: var(--report-header-description-margin-block-start);
        opacity: var(--report-header-text-opacity);

        @include text-style(lead, 2);

        @include breakpoint-from(desktop-small) {
            @include text-style(lead, 1);
        }
    }

    &__overlay-trigger {
        margin-block-start: var(--report-header-cta-margin-block-start);

        .button--subtle .cmp-button {
            --button-subtle-icon: var(--icon-information);
            --button-subtle-icon-background: var(--report-header-title-color);
            --button-subtle-icon-color: var(--report-header-background-color);
            --button-subtle-icon-outline: var(--color-focus-outline);
            --button-subtle-text: var(--report-header-title-color);
            --button-subtle-text-hover: var(--report-header-title-highlight-color);
        }
    }

    &__overlay {
        .cmp-overlay {
            --overlay-max-width: #{d.rem(502)};
        }

        &-content {
            & > .cmp-container {
                padding-block: map.get($spacing, s-24) map.get($spacing, s-32);
            }
        }

        &-field {
            select {
                margin-block-end: map.get($spacing, s-16);
            }

            & + .cmp-report-header__overlay-field {
                margin-block-start: map.get($spacing, s-24);
            }
        }

        &-field-label {
            @include text-style(label, 2, b);

            color: var(--color-text-primary);
            margin-block-end: map.get($spacing, s-8);

            @at-root label#{&} {
                @include text-style(label, 2, m);

                color: var(--color-text-secondary);
                display: block;
            }
        }

        &-field-content {
            @include text-style(body, 3);

            color: var(--color-text-secondary);

            a:not(.tag) {
                @include text-style(body, 4);
            }
        }
    }

    &__tags {
        margin-block-start: var(--report-header-tags-margin-block-start);

        @include breakpoint-from(desktop) {
            --report-header-tags-margin-block-start: #{map.get($spacing, s-24)};
        }
    }

    &__doormat,
    &__footer {
        @include text-style(label, 2, m);

        display: flex;
        gap: var(--report-header-doormat-gap);
    }

    &__doormat {
        color: var(--report-header-title-color);

        & + .cmp-report-header__footer {
            margin-block-start: var(--report-header-footer-gap);
        }
    }

    &__series {
        --color-focus-outline: var(--report-header-title-highlight-color);

        color: var(--report-header-title-color);

        & + .cmp-report-header__date {
            &::before {
                content: var(--report-header-list-separator-symbol);
                display: inline-block;
                margin-inline-end: var(--report-header-doormat-gap);
            }
        }

        a {
            color: var(--report-header-title-color);

            &:hover {
                color: var(--report-header-title-highlight-color);
            }

            &:focus-visible {
                color: var(--report-header-title-highlight-color);
                outline: none;

                @include keyboardfocus;
            }
        }
    }

    &__footer {
        align-items: center;
    }

    &__publisher-logo {
        color: var(--report-header-title-color);
        display: inline-flex;

        svg {
            height: var(--report-header-publisher-height);
            width: auto;
        }

        &--small {
            --report-header-publisher-height: var(--report-header-publisher-logo-small-height);
        }

        &--medium {
            --report-header-publisher-height: var(--report-header-publisher-logo-medium-height);
        }

        &--large {
            --report-header-publisher-height: var(--report-header-publisher-logo-large-height);
        }
    }

    &__publisher-disclaimer {
        --color-focus-outline: var(--report-header-title-highlight-color);

        color: var(--report-header-text-color);
        opacity: var(--report-header-text-opacity);

        a {
            color: var(--report-header-title-color);

            &:hover {
                color: var(--report-header-title-highlight-color);
            }

            &:focus-visible {
                color: var(--report-header-title-highlight-color);
                outline: none;

                @include keyboardfocus;
            }
        }
    }

    &__visual {
        position: relative;

        @include breakpoint-from(tablet) {
            grid-column: 11 / span 2;
            height: 100%;
        }

        .image {
            display: flex;
            flex-direction: column;
            height: 100%;
            justify-content: center;
            left: 0;
            position: absolute;
            top: 0;
            width: 100%;
        }

        .cmp-image {
            height: 100%;
            max-height: var(--report-header-image-max-height);
            position: relative;
            width: 100%;

            &__image {
                height: 100%;
                object-fit: cover;
                object-position: center center;
                width: 100%;
            }
        }
    }

    //////////////////////////////////////////
    // Specific styles if there is an image //
    /////////////////////////////////////////
    &:has(.cmp-image) {
        @include breakpoint-from(tablet) {
            --report-header-min-height: #{d.rem(536)};
        }

        .cmp-report-header {
            &__breadcrumb {
                @include breakpoint-from(tablet) {
                    margin-inline-end: calc(#{map.get($spacing, s-32)} * -1);
                }

                @include breakpoint-from(desktop) {
                    margin-inline-end: calc(#{map.get($spacing, s-64)} * -1);
                }
            }

            &__content-area {
                @include breakpoint-from(tablet) {
                    grid-column: 1 / span 7;
                }
            }

            &__visual {
                aspect-ratio: var(--report-header-visual-aspect-ratio-mobile);

                @include breakpoint-until(tablet) {
                    // Ensure Image components bleed on small screens
                    margin-inline: calc(var(--grid-offset) * -1);
                }

                @include breakpoint-from(tablet) {
                    aspect-ratio: var(--report-header-visual-aspect-ratio);
                    grid-column: 8 / span 5;
                    margin-inline-end: calc(var(--grid-offset) * -1);
                }

                @include breakpoint-from(desktop-small) {
                    grid-column: 8 / span 5;
                }

                @include breakpoint-from(desktop) {
                    margin-inline-end: calc(((100vw - 1248px) / 2) * -1);
                }

                $container-max-width-with-offset-for-image: calc(#{d.rem(1248)} + (#{map.get($spacing, s-96)} * 2));

                @media (min-width: $container-max-width-with-offset-for-image) {
                    margin-inline-end: calc(#{map.get($spacing, s-96)} * -1);
                }
            }
        }
    }
}
