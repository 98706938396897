@media print {
    .cmp-report-content-container {
        &__navigation {
            display: none;
        }

        &__content {
            grid-column: 1 / -1 !important;

            >.container>.cmp-container {
                >* {
                    grid-column: 1 / -1 !important;
                }
            }
        }
    }
}
