@use 'sass:map';
@use '@oecd/foundation/src/site/config/spacing-vars' as *;
@use '@oecd/util-breakpoint' as *;
@use '@oecd/util-distance' as d;
@use '@oecd/util-focus' as *;
@use '@oecd/util-text-style' as *;

.cmp-breadcrumb {
    --breadcrumb-color: var(--color-text-primary);
    --breadcrumb-link-color: var(--color-text-primary);
    --breadcrumb-link-color-hover: var(--color-text-link-hover);
    --breadcrumb-focus-outline-color: var(--color-focus-outline);
    --breadcrumb-active-item-opacity: 0.7;

    color: var(--breadcrumb-color);

    &__list {
        position: relative;
        width: 100%;
    }

    &__item {
        span {
            @include text-style(label, 2, b);
        }

        &-link {
            color: var(--breadcrumb-link-color);
            text-decoration: none;

            @media (prefers-reduced-motion: no-preference) {
                transition: color 0.15s linear;
            }

            @media (pointer: fine) {
                &:hover,
                &:focus {
                    color: var(--breadcrumb-link-color-hover);

                }
            }

            &:focus-visible {
                outline: none;

                span {
                    --color-focus-outline: var(--breadcrumb-focus-outline-color);

                    @include keyboardfocus;
                }
            }
        }
    }

    @include breakpoint-until(tablet) {
        // Target the second last list item.
        &__item:nth-last-child(2) {
            .cmp-breadcrumb__item-link {
                display: inline-block;
                padding-left: d.rem(16);
                position: relative;

                span {
                    opacity: var(--breadcrumb-active-item-opacity);

                }

                &::before {
                    background: var(--breadcrumb-color);
                    content: '';
                    display: block;
                    height: d.rem(16);
                    left: 0;
                    mask-image: var(--icon-chevron-small);
                    mask-position: center;
                    mask-repeat: no-repeat;
                    mask-size: contain;
                    position: absolute;
                    top: d.rem(4);
                    transform: rotate(-180deg);
                    width: d.rem(16);
                }
            }
        }

        // Hide all the other breadcrumb items
        &__item:not(li:nth-last-child(2)) {
            display: none;
        }
    }

    @include breakpoint-from(tablet) {
        &__item {
            &:not(:last-child) {
                margin-right: #{map.get($spacing, s-8)};
                position: relative;

                &::after {
                    background: var(--breadcrumb-color);
                    content: '';
                    display: inline-block;
                    height: d.rem(16);
                    margin-left: d.rem(4);
                    mask-image: var(--icon-chevron-small);
                    mask-position: center;
                    mask-repeat: no-repeat;
                    mask-size: contain;
                    vertical-align: middle;
                    width: d.rem(16);

                    @media screen and (forced-colors: active) {
                        background: CanvasText;
                    }
                }
            }

            &--active {
                opacity: var(--breadcrumb-active-item-opacity);
            }
        }
    }
}
