@use 'sass:map';

$ds-color-primitives: (
        neutral-800: #3d3d3d,
        neutral-500: #586179,
        neutral-500-a70: rgb(88, 97, 121, 70%),
        neutral-300: #c2cbd6,
        neutral-250: #dee3e9,
        neutral-200: #e8edf2,
        neutral-150: #f0f4f8,
        neutral-150-a40: rgba(240, 244, 248, 40%),
        neutral-100: #f6f7f9,
        brand-800: #101d40,
        brand-800-rgb: '16, 29, 64',
        brand-800-a70: rgba(16, 29, 64, 70%),
        brand-800-a15: rgba(16, 29, 64, 15%),
        brand-800-a10: rgba(16, 29, 64, 10%),
        brand-700: #203e69,
        brand-500: #0660ef,
        brand-450: #156df9,
        brand-450-a50: rgba(21, 109, 249, 50%),
        brand-200: #c5e1ff,
        brand-150: #e0f2ff,
        brand-150-rgb: '224, 242, 255',
        black: #000,
        white: #fff,
        white-rgb: '255, 255, 255',
        white-a70: rgba(255, 255, 255, 70%),
        white-a30: rgba(255, 255, 255, 30%),
        white-a15: rgba(255, 255, 255, 15%),
        c-red: #ba1212,
        c-cyan: #45deff,
);
$core-colors: (
        background: map.get($ds-color-primitives, white),
        background-rgb: map.get($ds-color-primitives, white-rgb),
        background-inverse: map.get($ds-color-primitives, brand-800),
        background-inverse-rgb: map.get($ds-color-primitives, brand-800-rgb),
        background-light: map.get($ds-color-primitives, neutral-150),
        background-verylight: map.get($ds-color-primitives, neutral-100),
        background-extremely-light: map.get($ds-color-primitives, neutral-150-a40),
        primary: map.get($ds-color-primitives, brand-450),
        primary-hover: map.get($ds-color-primitives, brand-500),
        primary-transparent: map.get($ds-color-primitives, brand-450-a50),
        primary-light: map.get($ds-color-primitives, brand-150),
        primary-light-rgb: map.get($ds-color-primitives, brand-150-rgb),
        accent: map.get($ds-color-primitives, c-cyan),
        border: map.get($ds-color-primitives, neutral-250),
        border-strong: map.get($ds-color-primitives, neutral-300),
        border-transparent: map.get($ds-color-primitives, brand-800-a15),
        border-transparent-inverse: map.get($ds-color-primitives, white-a15),
        divider: map.get($ds-color-primitives, neutral-250),
        section-divider: map.get($ds-color-primitives, neutral-250),
        tag: map.get($ds-color-primitives, white-a30),
        tag-inverse: map.get($ds-color-primitives, brand-800-a10),
        form-input: map.get($ds-color-primitives, neutral-200),
        form-input-inverse: map.get($ds-color-primitives, white),
        form-radio: map.get($ds-color-primitives, brand-450),
        icon: map.get($ds-color-primitives, brand-800),
        icon-inverse: map.get($ds-color-primitives, white),
        icon-accent: map.get($ds-color-primitives, brand-450),
        icon-disabled: map.get($ds-color-primitives, neutral-500),
        icon-secondary: map.get($ds-color-primitives, neutral-500),
        focus-outline: map.get($ds-color-primitives, brand-500),
        focus-outline-inverse: map.get($ds-color-primitives, c-cyan),
        disabled: map.get($ds-color-primitives, neutral-250),
        error: map.get($ds-color-primitives, c-red),
        overlay-shadow: map.get($ds-color-primitives, brand-800-a10),
        background-backdrop: map.get($ds-color-primitives, brand-800-a70),
        print-background: map.get($ds-color-primitives, neutral-150),
        print-background-dark: map.get($ds-color-primitives, neutral-200),
        scroll-thumb: map.get($ds-color-primitives, neutral-500),
        scroll-track: map.get($ds-color-primitives, neutral-300)
);
$core-text-colors: (
        primary: map.get($ds-color-primitives, brand-800),
        primary-transparent: map.get($ds-color-primitives, brand-800-a70),
        primary-inverse: map.get($ds-color-primitives, white),
        primary-inverse-transparent: map.get($ds-color-primitives, white-a70),
        secondary: map.get($ds-color-primitives, neutral-500),
        secondary-transparent: map.get($ds-color-primitives, neutral-500-a70),
        title-accent: map.get($ds-color-primitives, brand-450),
        title-accent-light: map.get($ds-color-primitives, brand-150),
        placeholder: map.get($ds-color-primitives, neutral-500),
        helper: map.get($ds-color-primitives, neutral-500),
        link: map.get($ds-color-primitives, brand-500),
        link-hover: map.get($ds-color-primitives, brand-500),
        link-active: map.get($ds-color-primitives, brand-450),
        link-inverse: map.get($ds-color-primitives, c-cyan),
        link-inverse-hover: map.get($ds-color-primitives, c-cyan),
        accent: map.get($ds-color-primitives, brand-450),
        accent-light: map.get($ds-color-primitives, brand-150),
        table: map.get($ds-color-primitives, black),
        print-dark: map.get($ds-color-primitives, black),
        print-normal: map.get($ds-color-primitives, neutral-800)
);
$theme-colors-values: (
        berry-background: #3a062d,
        berry-background-hover: #23061c,
        berry-text: #fff,
        berry-text-accent: #ff75af,
        burgundy-background: #3a0c12,
        burgundy-background-hover: #56252b,
        burgundy-text: #fff,
        burgundy-text-accent: #ed672d,
        forest-accent: #c1ef71,
        forest-background: #183f15,
        forest-background-hover: #162e14,
        forest-text: #fff,
        grass-background: #f2ffe5,
        grass-background-hover: #ebfdd8,
        grass-text: #20310e,
        grass-text-accent: #3a8415,
        lilac-accent: #c63963,
        lilac-background: #fceefb,
        lilac-background-hover: #f8e2f6,
        lilac-text: #3a062d,
        ocean-background: #001c37,
        ocean-background-hover: #02274b,
        ocean-text: #fff,
        ocean-text-accent: #48a7ff,
        orange-background: #ffddbd,
        orange-background-hover: #ffe7d1,
        orange-text: #4e1c1c,
        orange-text-accent: #b13b10,
        plum-background: #591c37,
        plum-background-hover: #3e1426,
        plum-text: #fff,
        plum-text-accent: #ff667a,
        sky-background: #d7edfe,
        sky-background-hover: #c3e1f8,
        sky-text-accent: #1162d4,
        sky-text: #0b2742,
        turquoise-background: #00292e,
        turquoise-background-hover: #07363c,
        turquoise-text: #f4fbfb,
        turquoise-text-accent: #71e0d8,
        yellow-background: #ffd15c,
        yellow-background-hover: #ffdf8f,
        yellow-text: #441800,
        yellow-text-accent: #ba1212,
);

:root {
    // Core Colors
    @each $key, $value in $core-colors {
        --color-#{"" + $key}: #{$value};
    }

    // Core text colors
    @each $key, $value in $core-text-colors {
        --color-text-#{"" + $key}: #{$value};
    }

    // Theme colors
    @each $key, $value in $theme-colors-values {
        --color-theme-#{"" + $key}: #{$value};
    }
}

$dark-theme-colors: 'berry', 'burgundy', 'forest', 'ocean', 'plum', 'turquoise';
$light-theme-colors: 'grass', 'lilac', 'orange', 'sky', 'yellow';
$theme-colors: 'berry', 'burgundy', 'forest', 'grass', 'lilac', 'ocean', 'orange', 'plum', 'sky', 'turquoise', 'yellow';

::backdrop {
    --color-background-backdrop: #{map.get($ds-color-primitives, brand-800-a70)};
}
