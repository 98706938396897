@use 'sass:map';
@use '@oecd/foundation/src/site/config/spacing-vars' as *;
@use '@oecd/util-breakpoint' as *;
@use '@oecd/util-distance' as d;
@use '@oecd/util-text-style' as *;

// DEFAULT style should be with image
// modifier .article-header--no-image used as:
// - default style class for PT article (image style not editable by author)
// - an image style option for PT leadership bio (image style editable by author)

.cmp-article-header {
    --article-header-breadcrumb-margin-block: #{map.get($spacing, s-16)};
    --article-header-breadcrumb-text-color: var(--color-text-primary);
    --article-header-date-text-color: var(--color-text-primary);
    --article-header-image-aspect-ratio: 1;
    --article-header-prefix-text-color: var(--color-text-primary);
    --article-header-title-text-color: var(--color-text-primary);
    --article-header-title-description-color: var(--color-text-secondary);
    --navigation-height: var(--main-navigation-height, #{d.rem(64)});

    display: grid;
    grid-template-columns: [full-start] var(--grid-offset) [container-start] minmax(0, var(--container-max-width)) [container-end] var(--grid-offset) [full-end];
    padding-block: var(--navigation-height) 0;

    @include breakpoint-from(mobile-large) {
        padding-block-end: map.get($spacing, s-32);
    }

    @include breakpoint-from(desktop) {
        --navigation-height: var(--main-navigation-height, #{d.rem(80)});

        padding-block-end: map.get($spacing, s-64);
    }

    &__container {
        grid-column: container;
    }

    &__breadcrumb {
        margin-block: var(--article-header-breadcrumb-margin-block) map.get($spacing, s-32);
    }

    &__wrapper {
        @include breakpoint-from(mobile-large) {
            column-gap: var(--grid-gap);
            display: grid;
            grid-template-columns: repeat(12, 1fr);
            grid-template-rows: min-content, min-content;
        }
    }

    &__image {
        margin-inline: calc(var(--grid-offset) * -1);

        @include breakpoint-from(mobile-large) {
            grid-column: 2 / span 4;
            grid-row: 1 / -1;
            margin-inline: 0;
        }

        @include breakpoint-from(desktop-large) {
            grid-column: 3 / span 4;
        }

        .cmp-image {
            aspect-ratio: var(--article-header-image-aspect-ratio);
            overflow: hidden;

            img {
                height: 100%;
                object-fit: cover;
                object-position: center center;
                width: 100%;
            }
        }
    }

    &__content {
        @include breakpoint-from(mobile-large) {
            grid-column: 6 / span 6;
            grid-row: 1 / -1;
            margin-inline: 0;
        }

        @include breakpoint-from(desktop-large) {
            grid-column: 7 / span 4;
        }
    }

    &__prefix {
        @include text-style(body, 1);

        color: var(--article-header-prefix-text-color);
        margin-block-end: map.get($spacing, s-8);
    }

    &__title {
        @include text-style(heading-hero, 3, false, false, true);

        @include breakpoint-from(desktop-small) {
            @include text-style(heading-hero, 2, false, false, true);
        }

        color: var(--article-header-title-text-color);
        margin-block-end: map.get($spacing, s-24);
    }

    &__description {
        @include text-style(body, 2);

        color: var(--article-header-title-description-color);
        margin-block-end: map.get($spacing, s-24);

        @include breakpoint-from(mobile-large) {
            flex-grow: 1;
        }

        @include breakpoint-from(desktop-small) {
            @include text-style(body, 1);
        }
    }

    &__metadata {
        align-items: center;
        display: flex;
        flex-wrap: wrap;
        gap: map.get($spacing, s-16);
        line-height: 0;
        margin-block: map.get($spacing, s-16) map.get($spacing, s-32);

        @include breakpoint-from(mobile-large) {
            margin-block-end: 0;
        }
    }

    &__date {
        @include text-style(label, 2, m);

        color: var(--article-header-date-text-color);
    }

    .article-header--no-image & {
        .cmp-article-header {
            &__image {
                display: none;
            }

            &__content {
                margin-inline: 0;

                @include breakpoint-from(mobile-large) {
                    grid-column: 2 / span 10;
                    grid-row: 1 / -1;
                    padding-inline-end: map.get($spacing, s-32);
                }

                @include breakpoint-from(desktop-small) {
                    grid-column: 2 / span 10;
                    padding-inline-end: map.get($spacing, s-64);
                }

                @include breakpoint-from(desktop) {
                    grid-column: 3 / span 8;
                }
            }
        }
    }
}
