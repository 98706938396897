@use 'sass:map';
@use '@oecd/foundation/src/site/config/spacing-vars' as *;
@use '@oecd/util-a11y' as *;
@use '@oecd/util-button' as *;

.cmp-button {
    .button--primary &,
    .button--secondary & {
        @include button-style;
    }

    .button--primary & {
        --button-background: var(--color-primary);
        --button-background-hover: var(--color-primary-hover);
        --button-border: var(--color-primary);
        --button-txt: var(--color-text-primary-inverse);
        --button-border-hover: var(--color-primary-hover);
        --button-txt-hover: var(--button-txt);
        --button-outline: var(--color-focus-outline);

        &[disabled] {
            --button-background: var(--color-disabled);
            --button-background-hover: var(--button-background);
            --button-border: var(--button-background);
            --button-border-hover: var(--button-border);
            --button-txt: var(--color-text-secondary-transparent);
            --button-txt-hover: var(--button-txt);
        }

        &.cmp-button-download {
            @include button-icon(--icon-external, $icon-only: false, $show-copy: true);

            &[download] {
                &::before {
                    mask-image: var(--icon-download);
                }
            }
        }
    }

    .button--secondary & {
        --button-background: var(--color-background);
        --button-background-hover: var(--button-background);
        --button-border: var(--color-border);
        --button-border-hover: var(--color-primary);
        --button-txt: var(--color-text-accent);
        --button-txt-hover: var(--button-txt);
        --button-outline: var(--color-focus-outline);

        &[disabled] {
            --button-background: var(--color-background);
            --button-background-hover: var(--button-background);
            --button-border: var(--color-border);
            --button-border-hover: var(--button-border);
            --button-txt: var(--color-text-secondary-transparent);
            --button-txt-hover: var(--button-txt);
        }
    }

    .button--subtle & {
        --button-subtle-text: var(--color-text-primary);
        --button-subtle-text-disabled: var(--color-text-secondary-transparent);
        --button-subtle-icon-background: var(--color-primary);
        --button-subtle-icon-background-disabled: var(--color-text-secondary-transparent);
        --button-subtle-icon-color: var(--color-text-primary-inverse);
        --button-subtle-icon-outline: var(--color-focus-outline);
        --button-subtle-text-hover: var(--color-text-link-hover);
        --button-subtle-icon: var(--icon-chevron-small);

        &:not(.cmp-button-download) {
            @include button-subtle;
        }

        &.cmp-button-download {
            --button-background: var(--color-background);
            --button-background-hover: var(--button-background);
            --button-border: var(--color-border);
            --button-border-hover: var(--color-primary);
            --button-txt: var(--color-text-accent);
            --button-txt-hover: var(--button-txt);
            --button-outline: var(--color-focus-outline);

            &[disabled] {
                --button-background: var(--color-background);
                --button-background-hover: var(--button-background);
                --button-border: var(--color-border);
                --button-border-hover: var(--button-border);
                --button-txt: var(--color-text-secondary-transparent);
                --button-txt-hover: var(--button-txt);
            }

            @include button-style;
            @include button-icon(--icon-external, $icon-only: false, $show-copy: true);

            &[download] {
                &::before {
                    mask-image: var(--icon-download);
                }
            }
        }
    }

    &__sr-only {
        @include visually-hidden;
    }
}
