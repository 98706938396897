@use 'sass:map';
@use '@oecd/util-breakpoint' as *;
@use '@oecd/foundation/src/site/config/spacing-vars' as *;

$search-widescreen-adjustment-mediaquery: 1384px;

.search-container {
    --search-container-margin-block-start: 0;

    @include breakpoint-from(desktop-small) {
        --search-container-margin-block-start: #{map.get($spacing, s-80)};
    }

    display: grid;
    grid-template-columns: [full-start] var(--grid-offset) [wrapper-start] minmax(0, var(--container-max-width)) [wrapper-end] var(--grid-offset) [full-end];
    margin-block-start: var(--search-container-margin-block-start);

    > .cmp-container {
        grid-column: wrapper;
    }

    @include breakpoint-from(desktop-small) {
        >.cmp-container {
            column-gap: var(--grid-gap);
            display: grid;
            grid-template-columns: repeat(12, 1fr);
            height: 100%;
            position: relative;
        }

        // Faceted search
        .search-facet-rail-container {
            grid-column: 1 / span 4;
        }

        .search-results,
        .global-search-results {
            grid-column: 5 / span 8;
        }
    }

    @include breakpoint-from(desktop) {
        .search-facet-rail-container {
            grid-column: 1 / span 3;
        }

        .search-results,
        .global-search-results {
            grid-column: 4 / span 9;
        }
    }

    &:has(.cmp-facet-rail) .cmp-search-results {
        @media (min-width: $search-widescreen-adjustment-mediaquery) {
            margin-inline-end: clamp(-64px, calc(((100vw - 1248px) / 2 ) * -1), 0vw);
            padding-inline-start: clamp(0vw, calc(((100vw - 1248px) / 2 ) * 1), 64px);
        }
    }
}

// Ensure margins on search container are ok when the searchbox container
// on a publication-landing-faceted-search-page is empty but the content area above has content
// And the last child is not a title and link component
.container-content:has(> .cmp-container):has(> .cmp-container >*:last-child:not(.title-and-link)) + .searchbox-container:has(> .cmp-container):not(:has(> .cmp-container *[class^='cmp'])) + .search-container {
    @include breakpoint-until(tablet) {
        --search-container-margin-block-start: #{map.get($spacing, s-24)};
    }

    @include breakpoint-only(tablet) {
        --search-container-margin-block-start: #{map.get($spacing, s-32)};
    }
}

// Ensure margins on search container are ok when the searchbox container
// on a publication-landing-faceted-search-page has content
.searchbox-container:has(> .cmp-container):has(> .cmp-container *[class^='cmp']) + .search-container {
    --search-container-margin-block-start: 0;
}

// Ensure margins on search container are ok when there is
// no content in either container above the search container on a publication-landing-faceted-search-page
.container-content:has(> .cmp-container):not(:has(> .cmp-container *[class^='cmp'])) + .searchbox-container:has(> .cmp-container):not(:has(> .cmp-container *[class^='cmp'])) + .search-container {
    @include breakpoint-from(desktop-small) {
        --search-container-margin-block-start: 0;
    }
}
