@use 'sass:map';
@use '@oecd/foundation/src/site/config/spacing-vars' as *;
@use '@oecd/util-breakpoint' as *;
@use '@oecd/util-distance' as d;
@use '@oecd/util-text-style' as *;

.cmp-brief-header {
    --navigation-height: var(--main-navigation-height, #{d.rem(64)});

    // Colors
    --header-background-color: var(--color-background);
    --header-text-color: var(--color-text-secondary);
    --header-title-color: var(--color-text-primary);
    --header-title-highlight-color: var(--color-text-title-accent);
    --header-description-color: var(--header-text-color);
    --header-description-margin-block-start: #{map.get($spacing, s-16)};
    --header-min-height: #{d.rem(480)};
    --header-prefix-margin-block-end: #{map.get($spacing, s-8)};

    @include breakpoint-from(desktop) {
        --header-description-margin-block-start: #{map.get($spacing, s-24)};
        --header-min-height: #{d.rem(536)};
    }

    background-color: var(--header-background-color);
    display: grid;
    grid-template-columns: [full-start] var(--grid-offset) [container-start] minmax(0, var(--container-max-width)) [container-end] var(--grid-offset) [full-end];

    @include breakpoint-from(desktop) {
        --navigation-height: var(--main-navigation-height, #{d.rem(80)});
    }

    &__container {
        grid-column: container;

        @include breakpoint-from(tablet) {
            column-gap: var(--grid-gap);
            display: grid;
            grid-template-columns: repeat(12, 1fr);
            min-height: var(--header-min-height);
        }
    }

    &__content-area {
        padding-block: var(--navigation-height) map.get($spacing, s-32); // Set padding top to account for main navigation
        padding-inline-end: map.get($spacing, s-32);

        @include breakpoint-from(tablet) {
            align-items: flex-start;
            display: flex;
            flex-direction: column;
            grid-column: 1 / span 10;
            height: 100%;
            justify-content: flex-end;
        }

        @include breakpoint-from(desktop) {
            grid-column: 1 / span 8;
            padding-block-end: map.get($spacing, s-64);
            padding-inline-end: map.get($spacing, s-64);
        }
    }

    &__prefix {
        color: var(--header-text-color);
        display: inline-block;
        margin-block-end: var(--header-prefix-margin-block-end);

        @include text-style(body, 2);

        @include breakpoint-from(desktop-small) {
            @include text-style(body, 1);
        }
    }

    &__title {
        color: var(--header-title-color);

        b,
        strong {
            color: var(--header-title-highlight-color);
            font-weight: inherit;
        }
    }

    &__description {
        color: var(--header-description-color);
        margin-block-start: var(--header-description-margin-block-start);

        @include text-style(body, 2);

        @include breakpoint-from(desktop-small) {
            @include text-style(body, 1);
        }
    }

    &--theme-shout {
        --color-focus-outline: var(--color-accent);
        --header-background-color: var(--color-background-inverse);
        --header-text-color: var(--color-text-primary-inverse-transparent);
        --header-title-color: var(--color-text-primary-inverse);
    }

    &--theme-silent {
        --header-background-color: var(--color-background);
        --header-text-color: var(--color-text-secondary);
        --header-title-color: var(--color-text-primary);
        --header-title-emphasis-color: var(--color-text-title-accent);
    }

    &--theme-whisper {
        --header-background-color: var(--color-primary-light);
    }
}
