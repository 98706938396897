@use 'sass:map';
@use '@oecd/foundation/src/site/config/spacing-vars' as *;

.cmp-report-navigation-buttons {
    display: flex;
    gap: map.get($spacing, s-16);
    justify-content: space-between;

    &:has(.cmp-button) {
        margin-block-start: var(--layout-spacing-default);
    }
}
