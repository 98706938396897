@use 'sass:map';
@use '@oecd/foundation/src/site/config/spacing-vars' as *;
@use '@oecd/util-a11y' as *;
@use '@oecd/util-breakpoint' as *;
@use '@oecd/util-distance' as d;
@use '@oecd/util-focus' as *;

.cmp-title {
    --title-copy-size: #{d.rem(24)};
    --title-copy-icon: var(--icon-link);
    --title-copy-icon-size: #{d.rem(16)};
    --title-copy-color: var(--color-text-primary);
    --title-copy-color-hover: var(--color-text-link-hover);

    &--has-copy-icon {
        display: flex;
        flex-direction: row-reverse;
        gap: d.rem(4);

        .cmp-title__text {
            flex: 1 1 auto;
        }
    }

    h1,
    h2 {
        margin-block-end: map.get($spacing, s-16);

        @include breakpoint-from(desktop-small) {
            margin-block-end: map.get($spacing, s-24);
        }

        + .cmp-title__copy {
            top: d.rem(3);
        }
    }

    h3 {
        margin-block-end: map.get($spacing, s-12);

        @include breakpoint-from(desktop-small) {
            margin-block-end: map.get($spacing, s-16);
        }

        + .cmp-title__copy {
            top: d.rem(2);
        }
    }

    h4,
    h5 {
        margin-block-end: map.get($spacing, s-8);

        @include breakpoint-from(desktop-small) {
            margin-block-end: map.get($spacing, s-16);
        }
    }

    h6 {
        margin-block-end: map.get($spacing, s-8);

        @include breakpoint-from(desktop-small) {
            margin-block-end: map.get($spacing, s-12);
        }
    }

    &__copy {
        color: var(--title-copy-color);
        flex: 0 0 auto;
        height: var(--title-copy-size);
        position: relative;
        width: var(--title-copy-size);

        &:hover {
            color: var(--title-copy-color-hover);
        }

        &:focus-visible {
            @include keyboardfocus;
        }

        @media (prefers-reduced-motion: no-preference) {
            transition: 0.15s linear color;
        }

        &::before {
            background-color: currentcolor;
            content: '';
            display: inline-block;
            height: var(--title-copy-icon-size);
            inset-block-start: 50%;
            inset-inline-start: 50%;
            mask-image: var(--icon-link);
            mask-position: center;
            mask-repeat: no-repeat;
            mask-size: contain;
            pointer-events: none;
            position: absolute;
            transform: translate(-50%, -50%);
            width: var(--title-copy-icon-size);

            @media screen and (forced-colors: active) {
                background-color: LinkText;
            }
        }

        &-txt {
            @include visually-hidden;
        }
    }
}

.title--hidden-page-title {
    @include visually-hidden;
}
